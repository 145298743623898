// React
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./Dropable";

// External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faSquareXmark,
  faPlus,
  faEllipsisVertical,
  faTrash,
  faSquareCaretUp,
  faSquareCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  motion,
  AnimatePresence,
  AnimateSharedLayout,
  useDragControls,
} from "framer-motion";

// Internal Components
import WeekLoading from "../Loading/WeekLoading";
import {
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  SaveIcon,
  TickIcon,
  PreviousDay,
} from "../../components/Icons/Icon";

// Styling
import "./objective.css";
import { sub } from "date-fns";

function Objective(props) {
  const [callout, setCallout] = useState(false);
  const [warning, setWarning] = useState(false);
  const [index, setIndex] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    const loadingTimeout = () => {
      var randNum = Math.random(1) * 3000 + Math.random(1) * 3000;
      setTimeout(() => {
        if (props.name === "" || props.name !== "") {
          setPageLoading(false);
        }
      }, randNum);
    };

    loadingTimeout();
  }, [props.data, index]);

  useEffect((event) => {
    if (props.data) {
      let subtask = document.getElementsByClassName("task--input");
      let arrayFromCollection = Array.from(subtask);
      arrayFromCollection.forEach((element, i) => {
        let actualHeight = element.scrollHeight;
        element.style.height = "auto";
        element.style.minHeight = "12px"; //
        element.style.height = `${actualHeight}px`;
      });
    }
  });

  useEffect(() => {
    const handleTextAreaResize = (event) => {
      const { target } = event;

      // Reset the height to auto before getting the updated scrollHeight
      target.style.height = "auto";
      target.style.minHeight = "12px"; //
      // Set the height to the updated scrollHeight
      target.style.height = `${target.scrollHeight}px`;
    };

    const initializeTextAreaResize = () => {
      if (props.data) {
        // Attach the event listener to each textarea with the class 'task--input'
        const textareas = document.getElementsByClassName("task--input");
        Array.from(textareas).forEach((textarea) => {
          textarea.addEventListener("input", handleTextAreaResize);
        });
      }
    };

    // Run on page load
    initializeTextAreaResize();

    // Attach the event listener for input events
    if (props.data) {
      const textareas = document.getElementsByClassName("task--input");
      Array.from(textareas).forEach((textarea) => {
        textarea.addEventListener("input", handleTextAreaResize);
      });
    }

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      if (props.data) {
        const textareas = document.getElementsByClassName("task--input");
        Array.from(textareas).forEach((textarea) => {
          textarea.removeEventListener("input", handleTextAreaResize);
        });
      }
    };
  }, [props.data]);

  const controls = useDragControls();

  const isUrl = (str) => {
    // Regular expression to match URL pattern
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return urlPattern.test(str);
  };

  // Example usage
  const urlString = "https://www.example.com";
  const isValidUrl = isUrl(urlString);

  const handleButtonClick = () => {
    const newState = !callout;
    setCallout(newState);
    props.onStateChange(newState); // Notify the parent about the state change
  };

  return (
    <>
      {pageLoading ? (
        <WeekLoading num={props.num} />
      ) : (
        props.data && (
          <Draggable draggableId={props.dragId} index={props.index}>
            {(provided) => (
              <div
                className={
                  props.name !== ""
                    ? props.overlay
                      ? `objective__col inactive`
                      : `objective__col`
                    : `objective__col disabled`
                }
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div className="objective__col--name">
                  <FontAwesomeIcon
                    className="objective__col--icon"
                    icon={faPlus}
                  />
                  <input
                    type="text"
                    className="col__name--input"
                    value={props.name}
                    placeholder="Objective Name"
                    onChange={(event) =>
                      props.handleCreateObjective(event, props.columnId)
                    }
                  ></input>
                  <div className="col__name--icon">
                    {props.objNum === 1 && <OneIcon />}
                    {props.objNum === 2 && <TwoIcon />}
                    {props.objNum === 3 && <ThreeIcon />}
                    {props.objNum === 4 && <FourIcon />}
                    {props.objNum === 5 && <FiveIcon />}
                    {props.objNum === 6 && <SixIcon />}
                    {props.objNum === 7 && <SevenIcon />}
                    {props.objNum === 8 && <EightIcon />}
                    {props.objNum === 9 && <NineIcon />}
                    {props.objNum === 10 && <TenIcon />}
                  </div>
                  <div className="col__name--trash">
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => setWarning(true)}
                    />
                  </div>
                </div>

                <DragDropContext
                  onDragEnd={(result) =>
                    props.onDragEnd(result, props.columnId)
                  }
                >
                  <StrictModeDroppable droppableId={`board`}>
                    {(provided) => (
                      <div
                        className="objective__col--subTasks"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {props.data.length > 0 ? (
                          props.data.map((d, i) => (
                            <div key={`${i}`} style={{ width: "100%" }}>
                              <Draggable draggableId={`${i}`} index={i}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="objective__col--wrapper"
                                  >
                                    <div
                                      className={
                                        d.completed === 0
                                          ? "objective__col--task"
                                          : "objective__col--task-active"
                                      }
                                    >
                                      <div
                                        className={
                                          d.completed === 0
                                            ? "task__radio"
                                            : "task__radio--active"
                                        }
                                        onClick={() =>
                                          props.toggleSubTask(i, props.columnId)
                                        }
                                      ></div>
                                      {isUrl(d.description) ? (
                                        <a
                                          href={d.description}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <textarea
                                            id="subTaskInput"
                                            className={
                                              d.completed === 0
                                                ? "task--input"
                                                : "task--input completed"
                                            }
                                            value={d.description}
                                            onChange={(event) =>
                                              props.updateDescription(
                                                event,
                                                props.columnId,
                                                i
                                              )
                                            }
                                            onClick={() =>
                                              props.toggleSubTask(i, props.num)
                                            }
                                          ></textarea>
                                        </a>
                                      ) : (
                                        <textarea
                                          id="subTaskInput"
                                          className={
                                            d.completed === 0
                                              ? "task--input"
                                              : "task--input completed"
                                          }
                                          value={d.description}
                                          onChange={(event) =>
                                            props.updateDescription(
                                              event,
                                              props.columnId,
                                              i
                                            )
                                          }
                                        ></textarea>
                                      )}

                                      <FontAwesomeIcon
                                        className="objective__col--icon ellipsis"
                                        icon={faEllipsisVertical}
                                        onClick={() => {
                                          handleButtonClick();
                                          setIndex(i);
                                        }}
                                      />
                                    </div>

                                    <div className="reorder">
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          props.handleSubTaskReorder(
                                            props.columnId,
                                            i,
                                            "up"
                                          );
                                        }}
                                        className="objective__col--reorder"
                                        icon={faSquareCaretUp}
                                      />
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          props.handleSubTaskReorder(
                                            props.columnId,
                                            i,
                                            "down"
                                          );
                                        }}
                                        className="objective__col--reorder"
                                        icon={faSquareCaretDown}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}

                        {provided.placeholder}
                      </div>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>

                <div className="objective__col--task">
                  <input
                    type="text"
                    className="task--input add"
                    placeholder="Add new action"
                    onKeyDown={(event) =>
                      props.addSubTask(event, props.columnId)
                    }
                  ></input>
                  <FontAwesomeIcon
                    className="objective__col--icon"
                    icon={faEllipsisVertical}
                  />
                </div>
                {provided.placeholder}
              </div>
            )}
          </Draggable>
        )
      )}
      <motion.div
        className={
          callout ? "objective__callout--active" : "objective__callout"
        }
      >
        {props.data &&
          props.data[index] &&
          props.data
            .filter((d, i) => index === i)
            .map((d, i) => (
              <div key={i} className="callout__header">
                <div className="callout__name">
                  <div className="task__radio"></div>
                  <textarea
                    className="callout--input"
                    value={d.description}
                    onChange={(event) =>
                      props.updateDescription(event, props.columnId, index)
                    }
                  ></textarea>
                </div>
                <FontAwesomeIcon
                  className="callout--icon"
                  icon={faSquareXmark}
                  onClick={() => {
                    handleButtonClick();
                  }}
                />
              </div>
            ))}

        <DragDropContext
          onDragEnd={(result) =>
            props.onDragEndSteps(result, props.columnId, index)
          }
        >
          <StrictModeDroppable droppableId={`board`}>
            {(provided) => (
              <div
                className="callout__actions"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {props.data &&
                  props.data[index] &&
                  props.data[index].steps.map((d, i) => (
                    <div key={`step-${i}`} style={{ width: "100%" }}>
                      <Draggable draggableId={`step-${i}`} index={i}>
                        {(provided) => (
                          <div
                            className="objective__col--wrapper"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              className={
                                d.completed === 0
                                  ? "objective__col--task"
                                  : "objective__col--task-active"
                              }
                            >
                              <div
                                className={
                                  d.completed === 0
                                    ? "task__radio"
                                    : "task__radio--active"
                                }
                                onClick={() =>
                                  props.toggleStep(index, i, props.columnId)
                                }
                              ></div>
                              <textarea
                                type="text"
                                className={
                                  d.completed === 0
                                    ? "task--input"
                                    : "task--input completed"
                                }
                                value={d.action}
                                onChange={(event) =>
                                  props.updateStep(
                                    props.columnId,
                                    index,
                                    i,
                                    event
                                  )
                                }
                              />
                              <FontAwesomeIcon
                                className="objective__col--icon trash"
                                icon={faTrash}
                                onClick={() =>
                                  props.deleteStep(props.columnId, index, i)
                                }
                              />
                            </div>
                            <div className="reorder">
                              <FontAwesomeIcon
                                onClick={() =>
                                  props.handleStepReorder(
                                    props.columnId,
                                    index,
                                    i,
                                    "up"
                                  )
                                }
                                className="objective__col--reorder"
                                icon={faSquareCaretUp}
                              />
                              <FontAwesomeIcon
                                onClick={() =>
                                  props.handleStepReorder(
                                    props.columnId,
                                    index,
                                    i,
                                    "down"
                                  )
                                }
                                className="objective__col--reorder"
                                icon={faSquareCaretDown}
                              />
                            </div>
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                <div className="objective__col--task">
                  <div className="task__radio"></div>
                  <input
                    type="text"
                    className="task--input add"
                    placeholder="Add a new step"
                    onKeyDown={(event) =>
                      props.addStep(event, props.columnId, index)
                    }
                  />
                  <FontAwesomeIcon
                    className="objective__col--icon"
                    icon={faEllipsisVertical}
                  />
                </div>
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
        <div className="callout__notes">
          {props.data &&
            props.data
              .filter((d, i) => index === i)
              .map((d, i) => (
                <textarea
                  key={i}
                  className="notes"
                  placeholder="Write notes"
                  value={d.notes}
                  onChange={(event) =>
                    props.updateNotes(props.columnId, index, event)
                  }
                />
              ))}
          <div
            className="callout__button"
            onClick={() => {
              props.deleteSubTask(props.columnId, index);
              handleButtonClick();
            }}
          >
            Delete Task
          </div>
        </div>
      </motion.div>
      <div
        className={warning ? "warning__callout--active" : "warning__callout"}
      >
        <strong>Are you sure you want to delete this objective?</strong>
        <div className="warning__button--row">
          <div
            className="callout__button"
            onClick={() => {
              props.deleteObjective(props.columnId);
              setWarning(false);
            }}
          >
            Yes
          </div>
          <div className="callout__button" onClick={() => setWarning(false)}>
            No
          </div>
        </div>
      </div>
    </>
  );
}

export default Objective;
