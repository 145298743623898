import React from "react";
import useSubscriptionCheck from "./useSubscriptionCheck"; // Path to your custom hook

const SubscriptionChecker = ({ userInfo }) => {
  useSubscriptionCheck(userInfo);

  return null;
};

export default SubscriptionChecker;
