//React & Redux
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import { useInView } from "react-intersection-observer";

//Internal Compoments
import CentralHeader from "../../components/Header/CentralHeader";
import CentralHeaderLP from "../../components/Header/CentralHeaderLP";
import MouseTooltip from "../../components/MouseTooltip/MouseTooltip";
import TestFooter from "../../components/Footer/TestFooter";
import PageLoading from "../../components/Loading/PageLoading";
import FullDock from "../../components/Dock/FullDock";
import HideDock from "../../components/Dock/HideDock";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faPodcast,
} from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp,
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
  faTiktok,
  faSpotify,
  faLine,
} from "@fortawesome/free-brands-svg-icons";

//Actions
import { listDays } from "../../actions/dayActions";
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";
import "./biosite.css";
import {
  DiaryIcon,
  InsightsIcon,
  QuoteIcon,
  RegisterIcon,
  SaveIcon,
  TickIcon,
  VisionIcon,
} from "../../components/Icons/Icon";

const BioSite = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <>
      {userInfo ? <CentralHeader /> : <CentralHeaderLP />}
      <main className="bio__site--main">
        <div className="bio-container">
          <div className="dateRow--header">
            <label
              className="score label"
              style={{ opacity: "1", fontWeight: "bold", fontSize: "1.75em" }}
            >
              Explore NoWastedDays
            </label>
          </div>
          <section className="links-section">
            <ul>
              {/* <h6>Jump into the site</h6> */}
              <li>
                <a href="/register">Create a journal for free.</a>
                <div>
                  <RegisterIcon />
                </div>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCMqVstA-okOwsuZK732k4zQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Listen to the podcast.
                </a>
                <div>
                  <QuoteIcon />
                </div>
              </li>
              <li>
                <a href="/blog">Read the blog.</a>
                <div>
                  <DiaryIcon />
                </div>
              </li>
              <li>
                <a href="/insight/quiz/">Start your Art of Looking adventure</a>
                <div>
                  <InsightsIcon />
                </div>
              </li>
              <li>
                <a
                  href="https://nowasteddays.ck.page/b4716f5926"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Subscribe to our newsletter
                </a>
                <div>
                  <TickIcon />
                </div>
              </li>
              <li>
                <a
                  href="https://youtube.com/playlist?list=PLoCp1ejVsiqvlbLTyc-5EMXXrXFDakYm6&si=8tKB0FzTHbEsaQEZ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Full length of social media clips
                </a>
                <div>
                  <VisionIcon />
                </div>
              </li>
            </ul>
          </section>
        </div>
        <div className="social-links">
          <ul>
            {/* <h6>Explore our Social Media</h6> */}
            <li>
              <a
                href="https://wa.me/?text=Start%20your%20NoWastedDays%20journal%20today%20for%20free%21%20%3A%20https%3A%2F%2Fnowasteddays.io/register"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/nowasteddays/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </li>

            <li>
              <a
                href="https://www.tiktok.com/@nowasteddays.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTiktok} size="2x" />
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/channel/UCMqVstA-okOwsuZK732k4zQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </li>
            <li>
              <a
                href="https://podcasts.apple.com/gb/podcast/the-nowasteddays-podcast/id1697163760"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faPodcast} size="2x" />
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/show/712AlXapv6PDU6ZAyCPoyq?si=c761a9be9f4f42d6"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faSpotify} size="2x" />
              </a>
            </li>
          </ul>
        </div>
      </main>
      <TestFooter />
    </>
  );
};

export default BioSite;
