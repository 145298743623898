const rtbData = [
  {
    orientation: {
      mobile: {
        imgPath: [
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/c2061938a5151bd19abd10dbf3b5bbc60e56173f.mp4",
            orient: "portrait",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/306d3a792e14745780fa3923f6940b7d521141cc.mp4",
            orient: "portrait",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/c2061938a5151bd19abd10dbf3b5bbc60e56173f.mp4",
            orient: "portrait",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/306d3a792e14745780fa3923f6940b7d521141cc.mp4",
            orient: "portrait",
          },
        ],
        poster:
          "https://cdn.sanity.io/images/ou6d2gec/production/8bb53d0f158378957d55965e6d1be3673174af44-634x1188.png",
      },
      desktop: {
        imgPath: [
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/749899cbd61d871d3ee4e03e0505e20b16ac63f0.mp4",
            orient: "landscape",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/5d7b286bf25b714bbf7e9eabeea4006e850c6276.mp4",
            orient: "landscape",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/749899cbd61d871d3ee4e03e0505e20b16ac63f0.mp4",
            orient: "landscape",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/5d7b286bf25b714bbf7e9eabeea4006e850c6276.mp4",
            orient: "landscape",
          },
        ],
      },
    },
    type: "carousel",
    header: "It is a Mindfulness Journal",
    text: "We have brought brand new ideas to the much loved gratitude and productivity journal format to increase the power and persistence of the mindfulness you experience throughout your day.",
    flex: "one",
    button: "LEARN MORE",
    buttonLink: "/tutorial/today",
    buttonLinkMobile: "/tutorial/mobile",
  },
  {
    orientation: {
      mobile: {
        imgPath: [
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/13e2addcf601291dbe19cdf25d8ca1355ba998b4.mp4",
            orient: "portrait",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/7280886276ac86a14f6687a970843115d917b1ea.mp4",
            orient: "portrait",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/13e2addcf601291dbe19cdf25d8ca1355ba998b4.mp4",
            orient: "portrait",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/7280886276ac86a14f6687a970843115d917b1ea.mp4",
            orient: "portrait",
          },
        ],
        poster:
          "https://cdn.sanity.io/images/ou6d2gec/production/b34ddf2659436498492a636f9cd25d1d4a6be6d6-594x880.png",
      },
      desktop: {
        imgPath: [
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/73dcbe0f67906a15ea2d62c2054b05f4f206d24f.mp4",
            orient: "landscape",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/5d7b286bf25b714bbf7e9eabeea4006e850c6276.mp4",
            orient: "landscape",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/73dcbe0f67906a15ea2d62c2054b05f4f206d24f.mp4",
            orient: "landscape",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/5d7b286bf25b714bbf7e9eabeea4006e850c6276.mp4",
            orient: "landscape",
          },
        ],
      },
    },
    type: "carousel",
    header: "It is a Daily Planner",
    text: "Break your day in 5 moments and score your success at each. This simple framework sets you up for success throughout the day and in the days to come.",
    flex: "two",
    button: "LEARN MORE",
    buttonLink: "/tutorial/today",
    buttonLinkMobile: "/tutorial/mobile",
  },
  {
    orientation: {
      mobile: {
        imgPath: [
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/e29c4647094cad15dcc28a60a822730c19c38bfe.mp4",
            orient: "portrait",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/e29c4647094cad15dcc28a60a822730c19c38bfe.mp4",
            orient: "portrait",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/e29c4647094cad15dcc28a60a822730c19c38bfe.mp4",
            orient: "portrait",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/e29c4647094cad15dcc28a60a822730c19c38bfe.mp4",
            orient: "portrait",
          },
        ],
        poster:
          "https://cdn.sanity.io/images/ou6d2gec/production/a72ebd2b7e01d918ebf8aa846187da2abf5c7c9e-594x900.png",
      },
      desktop: {
        imgPath: [
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/fe84c44da89385d635dbcb56ab23d83099d73a30.mp4",
            orient: "landscape",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/c39d0ff1a74c9ece3bcbbc6e7f7aff163ef11f48.mp4",
            orient: "landscape",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/fe84c44da89385d635dbcb56ab23d83099d73a30.mp4",
            orient: "landscape",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/c39d0ff1a74c9ece3bcbbc6e7f7aff163ef11f48.mp4",
            orient: "landscape",
          },
        ],
      },
    },
    type: "carousel",
    header: "It is a Personal Coach",
    text: "With each journal entry, you create unique data that describes your habits, feelings and more. This is turned into an insightful new story each day that coaches you how to find new joy and growth.",
    flex: "one",
    button: "SHOW ME",
    buttonLink: "/tutorial/today",
    buttonLinkMobile: "/tutorial/mobile",
  },
  {
    orientation: {
      mobile: {
        imgPath: [
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/e8c5e1149ced1b340423920dccd77e590cd181ba.mp4",
            orient: "portrait",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/e29c4647094cad15dcc28a60a822730c19c38bfe.mp4",
            orient: "portrait",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/e8c5e1149ced1b340423920dccd77e590cd181ba.mp4",
            orient: "portrait",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/e29c4647094cad15dcc28a60a822730c19c38bfe.mp4",
            orient: "portrait",
          },
        ],
        poster:
          "https://cdn.sanity.io/images/ou6d2gec/production/8de8682fd0f76afd31ad7ea24af6d704f533c4bd-596x1162.png",
      },
      desktop: {
        imgPath: [
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/fe84c44da89385d635dbcb56ab23d83099d73a30.mp4",
            orient: "landscape",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/cb9491eb7d2ca1bb268e80f4117414e120002119.mp4",
            orient: "landscape",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/fe84c44da89385d635dbcb56ab23d83099d73a30.mp4",
            orient: "landscape",
          },
          {
            link: "https://cdn.sanity.io/files/ou6d2gec/production/df0946984209b92ea2c58477fa885de1b48cec5f.mp4",
            orient: "landscape",
          },
        ],
      },
    },
    type: "carousel",
    header: "It is a Learning Platform",
    text: "Every month, we are adding new interactive experiences and exclusive content to help you learn more about yourself and the world you exist within.",
    flex: "two",
    button: "TRY NOW",
    buttonLink: "/values",
    buttonLinkMobile: "/values",
  },
];

const testimonyData = [
  {
    imgPath: "",
    testimony:
      "No Wasted Days has made life so straightforward. Setting goals, organising my day and thinking about myself used to cause me such overwhelm. Now, I have a simple, and free, place to make sense of my day, my mind and make the most of life again!",
    name: "Louise O'Brian",
    profession: "32, Creative Director",
  },
  {
    imgPath: "",
    testimony:
      "I have tried so many physical journals. They are great to write in, but in terms of actually learning about yourself, there is no contest. No Wasted Days is so much more empowering and insightful!",
    name: "Jamie Briggs",
    profession: "28, Risk Analyst",
  },
];

const faqQuestionData = [
  {
    question: "How much does it cost?",
  },
  {
    question: "Why is it free at the moment?",
  },
  {
    question: "Can anyone else see my data?",
  },
  {
    question: "Is No Wasted Days only this platform?",
  },
  {
    question: "How long does it take to sign up?",
  },
  {
    question: "What's the story behind No Wasted Days?",
  },
];

const faqAnswerData = [
  {
    answer:
      "For now, and the foreseeable future, you can sign up for free and make the most of all the features. ",
  },
  {
    answer:
      "I believe that any platform that helps you be more self-aware and self-assure should be made available to people at no cost. The effect of heightened self-awareness and self-assurance on your own happiness and health is massive, and society also benefits hugely from an influx of happier, healthier people. Why lose that behind a paywall or premium features?",
  },
  {
    answer:
      "No. All text fields in the journal are encrypted using cryptographic alogrithms meaning they are extremely safe and impossible to read. We believe your data is here to serve one person: you. It will never be sold or leveraged in any other way than to help you learn more about yourself.",
  },
  {
    answer:
      "No, it is just the beginning. We have a growing podcast and social channels that have the same mission; to help anyone become more self-aware and assure at a cost they completely control.",
  },
  {
    answer:
      "If you do the tutorial, about 2-3 minutes. If you don't, about 10 seconds.",
  },
  {
    answer:
      "It was created 5 years ago at a dark time in my life. I had become hugely stressed and really isolated and in the depths of that difficult moment, I create a little diary format in an excel sheet to help give everyday more structure and purpose. Over time, it evolved - with more data, more mindful elements, more insights - to be this huge organising force in my life, and have a transformative effect on my self-awareness and self-assurance. People kept asking me about this spreadsheet that I was using and I thought, 'This really should be an app that anyone could use!'. Lots of software development learning later, here we are: an living breathing platform that anyone can use for free to help themselves feel better and do more courtesy of being more self-aware and self-assure.",
  },
];

export { rtbData, testimonyData };
