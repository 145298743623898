import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { listDays } from "../../actions/dayActions";
import { Helmet } from "react-helmet";
import CentralHeader from "../../components/Header/CentralHeader";
import CentralHeaderLP from "../../components/Header/CentralHeaderLP";
import "./privacypolicy.css";
import TestFooter from "../../components/Footer/TestFooter";

const TermsConditions = ({ history }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const weekList = useSelector((state) => state.weekList);
  const { weeks } = weekList;

  const dayList = useSelector((state) => state.dayList); //
  const { loading, error, days } = dayList;

  const dayUpdate = useSelector((state) => state.dayUpdate);
  const { success: successUpdate } = dayUpdate;

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo, history]);

  return (
    <>
      {userInfo ? <CentralHeader /> : <CentralHeaderLP />}

      <main
        className="privacypolicy--container"
        style={{ marginBottom: "48px" }}
      >
        <div className="tile">
          <h2>Terms and Conditions of Use for NoWastedDays.io</h2>
          <ul className="list main">
            <li className="listItem">
              These terms and conditions ("Terms") govern your use of the web
              application NoWastedDays.io ("the App"). By accessing or using the
              App, you agree to be bound by these Terms. If you do not agree to
              these Terms, you may not use the App.
            </li>

            <ul className="list main">
              Account Registration
              <br></br>
              <br></br>
              <li className="listItem">
                1.1. To use the App, you must create an account by providing
                accurate and complete information, including your name, email
                address, and password. You agree to keep your account
                information up to date.
              </li>
              <br></br>
              <li className="listItem">
                1.2. You are responsible for maintaining the security and
                confidentiality of your account credentials. Any activity that
                occurs under your account is your sole responsibility. You must
                notify us immediately of any unauthorized use of your account.
              </li>
            </ul>
            <ul className="list main">
              User Content
              <br></br>
              <br></br>
              <li className="listItem">
                2.1. The App allows you to input and store personal information,
                including but not limited to your Diary Date, Gratitude
                Statement, Detachment Statement, Assurance Statement, Moment
                Scores, Remember Today Log, Leave Behind Log, Start and End
                Scores, Vision, Values, Date of Birth, Ikigai, and Quotes ("User
                Content").
              </li>
              <br></br>
              <li className="listItem">
                2.2. You retain all ownership rights in your User Content. By
                using the App, you grant us a worldwide, non-exclusive,
                royalty-free license to use, reproduce, modify, adapt, publish,
                translate, distribute, and display your User Content solely for
                the purpose of providing you with the services of the App.
              </li>
              <br></br>
              <li className="listItem">
                2.3. You represent and warrant that you have the necessary
                rights to grant the license in section 2.2 and that your User
                Content does not infringe or violate any third party's rights or
                any applicable laws.
              </li>
              <br></br>
              <li className="listItem">
                2.4. We reserve the right, but not the obligation, to review and
                remove any User Content that violates these Terms or is
                otherwise objectionable.
              </li>
            </ul>
            <ul className="list main">
              Data Privacy
              <br></br>
              <br></br>
              <li className="listItem">
                3.1. We are committed to protecting your privacy and handling
                your personal information in accordance with applicable data
                protection laws. Please review our Privacy Policy -{" "}
                <a href="/privacy-policy">Link to Privacy Policy</a> - to
                understand how we collect, use, and disclose your personal
                information.
              </li>
            </ul>
            <ul className="list main">
              Intellectual Property Rights
              <br></br>
              <br></br>
              <li className="listItem">
                4.1. The App and its contents, including but not limited to
                text, graphics, logos, images, software, and design elements,
                are owned by or licensed to us and are protected by intellectual
                property laws. You may not copy, modify, distribute, display,
                license, or sell any content obtained from the App without our
                prior written permission.
              </li>
            </ul>
            <ul className="list main">
              Disclaimer of Warranties
              <br></br>
              <br></br>
              <li className="listItem">
                5.1. The App is provided on an "as is" and "as available" basis,
                without any warranties or representations of any kind, whether
                express, implied, or statutory. We do not warrant that the App
                will be uninterrupted, error-free, or secure.
              </li>
            </ul>
            <ul className="list main">
              Limitation of Liability
              <br></br>
              <br></br>
              <li className="listItem">
                6.1. To the maximum extent permitted by law, we shall not be
                liable for any indirect, incidental, consequential, special,
                punitive, or exemplary damages, including but not limited to
                loss of profits, data, or goodwill, arising out of or in
                connection with the use of the App.
              </li>
            </ul>
            <ul className="list main">
              Termination
              <br></br>
              <br></br>
              <li className="listItem">
                7.1. We may terminate or suspend your access to the App without
                prior notice or liability if you breach these Terms.
              </li>
            </ul>
            <ul className="list main">
              Modifications to the Terms
              <br></br>
              <br></br>
              <li className="listItem">
                8.1. We reserve the right to modify or update these Terms at any
                time. We will notify you of any significant changes to these
                Terms by sending an email to the address associated with your
                account. Your continued use of the App after the changes have
                been made will constitute your acceptance of the revised Terms.
              </li>
            </ul>
            <ul className="list main">
              Governing Law and Jurisdiction
              <br></br>
              <br></br>
              <li className="listItem">
                9.1. These Terms shall be governed by and construed in
                accordance with the laws of United Kingdom. Any disputes arising
                out of or in connection with these Terms shall be subject to the
                exclusive jurisdiction of the courts of United Kingdom.
              </li>
            </ul>
            <ul className="list main">
              Competitions & The NoWastedDays Challenge
              <br></br>
              <br></br>
              <li className="listItem">
                10.1. We may reserve the right to not pay the £10 incentive if
                it is not completely clear that the criteria has been honestly
                and fairly completed, or if the number of entrants exceed our
                initial targeted amount.
              </li>
            </ul>
          </ul>

          {/* <h1>Privacy Policy</h1>
      <p>Last Updated: 20th May 2023</p>
      
      <p>Thank you for using NoWastedDays.io, a digital diary web app ("the App"). Your privacy is hugely important to us. This Privacy Policy explains the types of information we collect, how we use and protect that information, what fields are encryped and your choices regarding your information. By using the App, you consent to the collection and use of your information as described in this Privacy Policy.</p>

<ul className="list main">1. Information We Collect
<li className="listHeader">1.1 Personal Information: We may collect the following personal information from you</li>
<ul className="list sub">
<li className="listItem">- Name: We collect your name to personalize your experience within the App.</li>
<li className="listItem">- Email Address: We collect your email address to create and secure your account and to communicate with you regarding important updates or account-related information.</li>
<li className="listItem">- Password: We securely store an encrypted version of your password to protect your account.</li>
</ul>
<li>1.2 Diary Data</li>
<ul className="list sub">
The App captures and encrypts the following diary data from you:
<li className="listItem">- Diary Date*: We collect the date associated with each diary entry to organize and display your entries chronologically.</li>
<li className="listItem">- Grateful Statement: We collect your grateful statements to help you focus on positivity and gratitude.</li>
<li className="listItem">- Detach Statement: We collect your detach statements to assist you in letting go of negative thoughts or emotions.</li>
<li className="listItem">- Assurance Statement: We collect your assurance statements to help you build self-confidence and reassurance.</li>
<li className="listItem">- Moment Statements: We collect your moment statements, which are reflections on significant moments or experiences in your day.</li>
<li className="listItem">- Memory Statement: We collect your memory statements, which are meant to capture memorable events or experiences.</li>
<li className="listItem">- Leave Behind Statement: We collect your leave behind statements, which are reflections on things you want to leave behind or improve upon.</li>
<li className="listItem">- Moment Scores: We collect moment scores to allow you to rate the significance or impact of each moment.</li>
<li className="listItem">- Start Score and End Score*: We collect start and end scores to track your overall well-being or mood changes over time.</li>

Please note that all fields except the Diary Date and Score fields are encrypted to ensure the privacy and security of your data. This means that no one else, including our staff, can see or aggregate the encrypted data. The diary date and score fields are no encrypted to increase processing speed and in the context of all other sensitive data being encrypted are render completely uncorruptable by a third party.
</ul>
<li className="listHeader">2. How We Use Your Information</li>
<ul className="list sub">
We use your information for the following purposes:
<li className="listItem">- To provide and personalize the services and features of the App.</li>
<li className="listItem">- To communicate with you and send important updates or notifications.</li>
<li className="listItem">- To analyze usage trends and improve the App's functionality and user experience.</li>
<li className="listItem">- To protect the security and integrity of the App and our users' information.</li>
<li className="listItem">- To comply with legal obligations or enforce our rights arising from any contracts entered into between you and us.</li>
</ul>
<li className="listHeader">3. Data Retention</li>

<p>We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>

<li className="listHeader">4. Third-Party Services</li>

<p>We may use third-party services that collect, monitor, and analyze information to improve the App's functionality and user experience. These third-party service providers have their own privacy policies that govern the use of your information.</p>

<li className="listHeader">5. Children's Privacy</li>

<p>The App is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will promptly delete that information. If you believe that a child under 13 may have provided us with personal information, please contact us using the contact details provided below.</p>

<li className="listHeader">6. Your Choices</li>

<p>You have the right to access, correct, or delete your personal information. You can update or delete your account information within the App's settings. If you have any questions or need assistance, please contact us using the contact details provided below.</p>

<li className="listHeader">7. Changes to this Privacy Policy</li>

<p>We may update this Privacy Policy from time to time. The updated version will be indicated by an updated "Last Updated" date. We encourage you to review this Privacy Policy periodically for any changes. Your continued use of the App following the posting of changes to this Privacy Policy constitutes your acceptance of those changes.</p>

<li className="listHeader">8. Contact Us</li>

<p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:</p>
<p>NoWastedDays.io</p>
<p>Email: hello@nowastedays.org</p>
</ul> */}
        </div>
      </main>
      <TestFooter />
    </>
  );
};

export default TermsConditions;
