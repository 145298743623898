// React
import React, { useEffect, useState } from "react";

// Internal Components
import dateFormatter from "../../components/Functions/DateFormatter";
import zeroRemove from "../../components/Functions/ZeroRemove";
import generateSecret from "../../utils/generateSecret";

// External Packages
import CryptoJS from "crypto-js";
import * as d3 from "d3";

// Styling
import "./insightstory.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretSquareLeft,
  faCaretSquareRight,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";

export default function InsightStory(
  props,
  {
    updateOptionsSelected,
    updateGraphSelected,
    updateButtonSelected,
    updateNodeSelected,
  }
) {
  // Story State
  const [insights, setInsights] = useState([]);
  const [graphOptions, setGraphOptions] = useState([]);
  const [frame, setFrame] = useState(0);
  const [diaryDay, setDiaryDay] = useState();
  const [displayDiary, setDisplayDiary] = useState();
  const [displayMemory, setDisplayMemory] = useState("");

  // Parse Date D3 Function
  const parseDate = d3.timeParse("%Y-%m-%d");
  const key = generateSecret.toString();

  // Functions for Graph Passing State to Parent
  const handleClickOptions = (state) => {
    props.updateOptionsSelected(state);
  };

  const handleClickGraphs = (state) => {
    props.updateGraphSelected(state);
  };

  const handleClickButtons = (state) => {
    props.updateButtonSelected(state);
  };

  const handleClickNodes = (state) => {
    props.updateNodeSelected(state);
    setDiaryDay(state);
  };

  // Insights

  // Average Feeling
  const averageFeeling = () => {
    // Parameters
    const avgArray = [];

    // Map Props.endScore into Array
    props.data && props.data.map((each) => avgArray.push(each.endScore));

    // Remove Final Item
    zeroRemove(avgArray);

    // Construct Average
    const avg = Math.round(d3.mean(avgArray) * 10) / 10;
    return avg;
  };

  const openingFrame = () => {
    let testDate = "2023-01-01";

    return {
      insightCode: "opening",
      insightType: "opening_frame",
      insightHeadline: "Your Insights Story",
      insightStrapline: `Click to get started`,
      logDate: testDate,
      comparator: 10,
      details: "none",
    };
  };

  const endFrame = () => {
    let testDate = "2023-01-01";

    return {
      insightCode: "opening",
      insightType: "opening_frame",
      insightHeadline: "End of Today's Insight Story",
      insightStrapline: `Keep Exploring`,
      logDate: testDate,
      comparator: -10,
      details: "none",
    };
  };

  const bestDayFeeling = () => {
    // Create Parameters
    let maxScore =
      Array.isArray(props.data) &&
      d3.max(props.data, function (d) {
        return d.endScore;
      });
    const upperLimit = maxScore && maxScore - maxScore / 20;
    let bestDaysArr =
      Array.isArray(props.data) &&
      props.data.filter((day) => day.endScore > upperLimit);
    let randomDaySelector =
      bestDaysArr && Math.floor(Math.random() * bestDaysArr.length);
    let dayObj = bestDaysArr && bestDaysArr[randomDaySelector];
    let dayAverage = averageFeeling();
    let daySigma = dayObj && dayObj.endScore / dayAverage;
    let day = dayObj && dayObj.logDate.slice(0, 10);
    let dayID = dayObj && dayObj._id.toString();

    let dayFormatted = dateFormatter(day);

    return {
      insightCode: "time",
      insightType: "time_feeling_score",
      insightHeadline: "What a day!",
      insightStrapline: `Every day, no matter its shape, is full of signals and we are not just interesting in so called 'good' days. However, it is also true that looking at a high feeling score day might give you more signals than average regarding how you simply build more joy into every day. On ${dayFormatted}, you recorded one of your highest feeling scores.`,
      logDate: day,
      id: dayID,
      comparator: daySigma,
      details: "Fuck.",
      questionOne:
        "What were the particularly special parts of the day? Which made you feel warmest and most contented?",
      questionTwo:
        "Of the things that contributed to this great day, which could be a greater part of everyday than they are at the moment?",
      questionThree:
        "If the things that made it special can't be built into most days, could you make them a greater priority and, in doing so, increase their frequency in your life?",
    };
  };

  const bestDayProductive = () => {
    let bestDayData = props.data;
    let finalMemory = "";
    const key = generateSecret.toString();

    bestDayData &&
      bestDayData.forEach((day) => {
        let sum =
          (!isNaN(day.momentOneScore) && day.momentOneScore) +
          (!isNaN(day.momentTwoScore) && day.momentTwoScore) +
          (!isNaN(day.momentThreeScore) && day.momentThreeScore) +
          (!isNaN(day.momentFourScore) && day.momentFourScore) +
          (!isNaN(day.momentFiveScore) && day.momentFiveScore);
        day.total = sum;

        let count =
          (day.momentOneScore / day.momentOneScore === 1 ||
          day.momentOneScore == 0
            ? 1
            : 0) +
          (day.momentTwoScore / day.momentTwoScore == 1 ||
          day.momentTwoScore == 0
            ? 1
            : 0) +
          (day.momentThreeScore / day.momentThreeScore == 1 ||
          day.momentThreeScore == 0
            ? 1
            : 0) +
          (day.momentFourScore / day.momentFourScore === 1 ||
          day.momentFourScore == 0
            ? 1
            : 0) +
          (day.momentFiveScore / day.momentFiveScore === 1 ||
          day.momentFiveScore == 0
            ? 1
            : 0);
        day.tasksAttempted = count;

        let tcr = day.total / 10 / day.tasksAttempted;
        day.taskCompletionRate = tcr;
      });

    let maxScore =
      Array.isArray(bestDayData) &&
      d3.max(bestDayData, function (d) {
        return d.total;
      });
    let bestDaysArr =
      Array.isArray(bestDayData) &&
      bestDayData.filter((day) => day.total === maxScore);

    bestDaysArr.sort((a, b) => b.logDate - a.logDate);

    let randomDaySelector =
      bestDaysArr && Math.floor(Math.random() * bestDaysArr.length);

    let dayObj = bestDaysArr && bestDaysArr[randomDaySelector];

    const totalsArr = [];
    bestDayData && bestDayData.map((each) => bestDaysArr.push(each.total));
    zeroRemove(totalsArr);

    const avg = Math.round(d3.mean(totalsArr));

    let daySigma = dayObj && dayObj.total / avg;
    let day = dayObj && dayObj.logDate.slice(0, 10);
    let dayID = dayObj && dayObj._id.toString();
    let parsedDate = day && parseDate(day);
    let dayFormatted = dateFormatter(day);

    return {
      insightCode: "time",
      insightType: "time_moments_completed",
      insightHeadline: "What a day!",
      insightStrapline: `On ${dayFormatted}, you recorded one of the days in which you did the most. Lets take a look at it!`,
      logDate: day,
      id: dayID,
      comparator: daySigma,
      details: "Fuck.",
      questionOne:
        "What shape did the day take? Was it a growth focused day, or one mixed with joy, recovery and growth?",
      questionTwo:
        "What inspired or was the foundation for the amount you achieve? Was it motivation? Was it processes? Was it a mindset shift? Or a mixture, or something else entirely?",
      questionThree:
        "Going through each key factor in order of impact, how could you ensure that each plays a greater role each day than it does at the moment?",
    };
  };

  const bestDayEfficient = () => {
    let bestDayData = props.data;
    let finalMemory = "";
    const key = generateSecret.toString();
    let dataTrans = (arr) => {
      arr &&
        arr.forEach((day) => {
          let sum =
            (!isNaN(day.momentOneScore) && day.momentOneScore) +
            (!isNaN(day.momentTwoScore) && day.momentTwoScore) +
            (!isNaN(day.momentThreeScore) && day.momentThreeScore) +
            (!isNaN(day.momentFourScore) && day.momentFourScore) +
            (!isNaN(day.momentFiveScore) && day.momentFiveScore);
          day.total = sum;

          let count =
            (day.momentOneScore / day.momentOneScore === 1 ||
            day.momentOneScore == 0
              ? 1
              : 0) +
            (day.momentTwoScore / day.momentTwoScore == 1 ||
            day.momentTwoScore == 0
              ? 1
              : 0) +
            (day.momentThreeScore / day.momentThreeScore == 1 ||
            day.momentThreeScore == 0
              ? 1
              : 0) +
            (day.momentFourScore / day.momentFourScore === 1 ||
            day.momentFourScore == 0
              ? 1
              : 0) +
            (day.momentFiveScore / day.momentFiveScore === 1 ||
            day.momentFiveScore == 0
              ? 1
              : 0);
          day.tasksAttempted = count;

          let tcr = day.total / 10 / day.tasksAttempted;
          day.taskCompletionRate = tcr;
        });

      return arr;
    };

    dataTrans(bestDayData);

    bestDayData.sort((a, b) => b.taskCompletionRate - a.taskCompletionRate);

    let maxScore =
      Array.isArray(bestDayData) &&
      d3.max(bestDayData, function (d) {
        return d.taskCompletionRate;
      });

    let bestDaysArr =
      Array.isArray(bestDayData) &&
      bestDayData.filter((day) => day.taskCompletionRate === maxScore);

    let randomDaySelector =
      bestDaysArr && Math.floor(Math.random() * bestDaysArr.length);

    let dayObj = bestDaysArr && bestDaysArr[randomDaySelector];

    const totalsArr = [];
    bestDaysArr &&
      bestDaysArr.map((each) => totalsArr.push(each.taskCompletionRate));
    zeroRemove(totalsArr);

    const avg = Math.round(d3.mean(totalsArr));

    let daySigma = dayObj && dayObj.taskCompletionRate / avg;
    let day = dayObj && dayObj.logDate.slice(0, 10);
    let dayID = dayObj && dayObj._id.toString();
    let parsedDate = day && parseDate(day);
    let dayFormatted = dateFormatter(day);

    return {
      insightCode: "time",
      insightType: "time_efficiency_graph",
      insightHeadline: "You beast!",
      insightStrapline: `On ${dayFormatted}, you were really efficient in what you did. By this, I mean you did lots of what you said you would do!`,
      logDate: day,
      id: dayID,
      comparator: daySigma,
      details: "Fuck.",
      questionOne:
        "How do you feel on these days where you tick off everything you said you would?",
      questionTwo:
        "Does it feel better to tick everything off, or to flow more freely from one harder to define joy to another?",
      questionThree:
        "How can you give yourself the best of both worlds? Enough discipline yet enough freedom; enough activity yet enough rest?",
    };
  };

  const worstDayFeeling = () => {
    let minScore = 3.6;
    let finalMemory = "";
    const key = generateSecret.toString();
    let worstDaysArr =
      Array.isArray(props.data) &&
      props.data.filter((day) => day.endScore === minScore);

    let randomDaySelector =
      worstDaysArr && Math.floor(Math.random() * worstDaysArr.length);

    let dayObj = worstDaysArr[randomDaySelector];

    let dayAverage = averageFeeling();

    let daySigma = dayObj && dayObj.endScore / dayAverage;

    let day = dayObj && dayObj.logDate.slice(0, 10);
    let dayID = dayObj && dayObj._id.toString();
    let parsedDate = day && parseDate(day);
    let dayFormatted = dateFormatter(day);

    return {
      insightCode: "time",
      insightType: "time_feeling_score",
      insightHeadline: "A different kind of day.",
      insightStrapline: `On ${dayFormatted}, you recorded one of your lower feeling scores. Let's have a little explore.`,
      logDate: day,
      id: dayID,
      comparator: daySigma,
      details: "Fuck.",
      questionOne:
        "What made this day difficult relative to a day with a higher feeling score?",
      questionTwo:
        "Do you think differently about this day in hindsight, or does it remain an emotionally tough day?",
      questionThree:
        "In your opinion, which aspect of what made this day difficult do you think is most important not to repeat?",
    };
  };

  const feelingVariance = () => {
    const avgArray = [];

    props.data && props.data.map((each) => avgArray.push(each.endScore));

    zeroRemove(avgArray);

    let advice = "";
    let qOne = "";
    let qTwo = "";
    let qThree = "";
    let comparator;

    let deviation = Math.round(d3.deviation(avgArray) * 10) / 10;

    comparator = deviation / 1;

    if (comparator > 1) {
      advice = "Your feeling seems to vary a little bit.";
      qOne =
        "Do you notice any swings in day-to-day life even without seeing your data?";
      qTwo =
        "What tends to separate a higher feeling day from a lower feeling day?";
      qThree = "";
    } else {
      advice = "Your feeling is very consistent";
      qOne =
        "Is this true of how every day feels, or does your data tell a different story?";
      qTwo =
        "Is the consistency just a result of how you score things, and actually you can feel quite strong swings from day-to-day?";
      qThree =
        "If it is consistent with your feeling, how do you think you could push for more peaks without opening up risk for drops in feeling?";
    }

    let testDate = "2023-01-01";

    return {
      insightCode: "time",
      insightType: "time_feeling_score",
      insightHeadline: advice,
      insightStrapline: `This is a really interesting signal from which you can learn a lot about yourself`,
      logDate: testDate,
      comparator: comparator,
      questionOne: qOne,
      questionTwo: qTwo,
      questionThree: qThree,
    };
  };

  const feelingRecent = () => {
    let advice = "";
    let qOne = "";
    let qTwo = "";
    let qThree = "";

    const avgArray = [];

    props.data && props.data.map((each) => avgArray.push(each.endScore));

    const recentView =
      avgArray.length - 1 === undefined ||
      isNaN(avgArray.length - 1) ||
      avgArray.length - 1 === 0
        ? avgArray.slice(avgArray.length - 14)
        : avgArray.slice(-15, -1);

    zeroRemove(avgArray);

    const avg = d3.mean(avgArray);
    const recent = d3.mean(recentView);

    const newIndexTest = recent / avg;

    const newIndex = Number(recent / avg - 1).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });

    if (newIndexTest > 1) {
      advice = "Your feeling has been above your average recently.";
      qOne =
        "Have you noticed this increase even before seeing it in your data?";
      qTwo =
        "What do you think may have caused it? Greater productivity? Better perspective taking? A great person in your life?";
      qThree =
        "Which contributors to this positive shift are you most interested in continuing?";
    } else {
      advice = "Your feeling has been below your average recently.";
      qOne =
        "Have you noticed this decrease even before seeing it in your data?";
      qTwo =
        "What do you think may have caused it? It is completely ok to not feel quite as high feeling as normal, and it is great to we have a safe space here to understand why.";
      qThree =
        "Don't worry about the discomfort you may be experiencing. It is a sign you are close to a realisation. What could change regarding your behaviours and perspective to feel better?";
    }

    let comparator = newIndex / 0.5;
    let testDate = "2023-01-01";

    return {
      insightCode: "time",
      insightType: "time_feeling_score",
      insightHeadline: advice,
      insightStrapline: `This is a really interesting signal from which you can learn a lot about yourself`,
      logDate: testDate,
      comparator: newIndexTest,
      questionOne: qOne,
      questionTwo: qTwo,
      questionThree: qThree,
    };
  };

  const crossoverHigh = () => {
    let finalMemory = "";
    const key = generateSecret.toString();
    const maxScore =
      props.data &&
      d3.max(props.data, function (d) {
        return d.endScore;
      });

    const upperLimit = props.data && maxScore - maxScore / 10;

    const avgArray =
      props.data && props.data.filter((day) => day.endScore > upperLimit);

    avgArray.forEach((day) => {
      let sum =
        day.momentOneScore +
        day.momentTwoScore +
        day.momentThreeScore +
        day.momentFourScore +
        day.momentFiveScore;
      day.total = sum;
    });

    avgArray && avgArray.sort((a, b) => b.total - a.total);

    let maxScoreTotal =
      Array.isArray(avgArray) &&
      d3.max(avgArray, function (d) {
        return d.total;
      });

    let upperLimitTotal = maxScoreTotal && maxScoreTotal - maxScoreTotal / 10;

    let bestDaysArr =
      Array.isArray(avgArray) &&
      avgArray.filter((day) => day.total > upperLimitTotal);

    let arrLength = Math.floor(Math.random() * bestDaysArr.length);

    let day =
      bestDaysArr.length > 0 && bestDaysArr[arrLength].logDate.slice(0, 10);

    let dayObj = bestDaysArr && bestDaysArr[arrLength];
    let dayID = dayObj && dayObj._id.toString();

    let parsedDate = day && parseDate(day);

    let detailsTotal = bestDaysArr.length > 0 && bestDaysArr[arrLength].total;
    let detailsFeeling =
      bestDaysArr.length > 0 && bestDaysArr[arrLength].endScore;

    let dayFormatted = bestDaysArr.length > 0 && dateFormatter(day);

    return {
      insightCode: "cross",
      insightType: "cross_feeling_doing",
      insightHeadline: "High Feelings, High Productivity!",
      insightStrapline: `On ${dayFormatted}, you had a great feeling day and also did a lot of the things you wanted to do!`,
      logDate: day,
      id: dayID,
      comparator: 1.5,
      details: "Fuck.",
      questionOne: "What did you choose to focus on that day?",
      questionTwo:
        "Was it the overall productivity that contributed to the higher feeling, or one particularly special moment?",
      questionThree:
        "Was it goal orientated things or more joy and recovery focused things?",
    };
  };

  const crossoverLow = () => {
    /**
     * Returns completed crossoverHigh object. Shares top endScore and productivity day.
     *
     */

    const maxScore =
      props.data &&
      d3.max(props.data, function (d) {
        return d.endScore;
      });
    const key = generateSecret.toString();

    const upperLimit = props.data && maxScore - maxScore / 10;

    const avgArray =
      props.data && props.data.filter((day) => day.endScore > upperLimit);

    avgArray.forEach((day) => {
      let sum =
        day.momentOneScore +
        day.momentTwoScore +
        day.momentThreeScore +
        day.momentFourScore +
        day.momentFiveScore;
      day.total = sum;
    });

    avgArray.sort((a, b) => a.total - b.total);

    zeroRemove(avgArray);

    let maxScoreTotal =
      Array.isArray(avgArray) &&
      d3.max(avgArray, function (d) {
        return d.total;
      });

    const lowerLimitTotal = 0 + maxScoreTotal / 5;

    let bestDaysArr =
      Array.isArray(avgArray) &&
      avgArray.filter((day) => day.total < lowerLimitTotal);

    let arrLength = Math.floor(Math.random() * bestDaysArr.length);

    let day =
      bestDaysArr.length > 0 && bestDaysArr[arrLength].logDate.slice(0, 10);

    let parsedDate = day && parseDate(day);
    let detailsTotal = bestDaysArr.length > 0 && bestDaysArr[arrLength].total;
    let detailsFeeling =
      bestDaysArr.length > 0 && bestDaysArr[arrLength].endScore;

    let dayObj = bestDaysArr && bestDaysArr[arrLength];
    let dayID = dayObj && dayObj._id.toString();

    let dayFormatted = bestDaysArr.length > 0 && dateFormatter(day);

    return {
      insightCode: "cross",
      insightType: "cross_feeling_doing",
      insightHeadline: "High Feelings, Low Productivity!",
      insightStrapline: `On ${dayFormatted}, you had a great feeling day and didn't do much on the face of it!`,
      logDate: day,
      id: dayID,
      comparator: 1.5,
      details: "Fuck.",
      questionOne:
        "Did you actually not do very much or is the data deceiving?",
      questionTwo:
        "If you did have a less activity orientated day, what inspired the higher feeling?",
      questionThree:
        "Setting up a day to just focus on one or two things can produce a lot of high feeling?",
    };
  };

  // Insights Compiler

  const insightsCompiler = () => {
    const objectArray = [
      openingFrame(),
      bestDayFeeling(),
      worstDayFeeling(),
      feelingVariance(),
      feelingRecent(),
      crossoverHigh(),
      crossoverLow(),
      bestDayProductive(),
      bestDayEfficient(),
      // bestDayWave(),
      endFrame(),
    ];

    let sortedInsights = objectArray.sort(
      (a, b) => b.comparator - a.comparator
    );

    setInsights((insights) => sortedInsights);

    let graphOptionsArr = [];
    if (sortedInsights.length > 0) {
      sortedInsights.length > 0 &&
        sortedInsights.map((insight, index) => {
          let button = 1;

          if (insight.insightCode === "time") {
            button = 1;
          } else if (insight.insightCode === "week") {
            button = 2;
          } else if (insight.insightCode === "cross") {
            button = 3;
          }

          graphOptionsArr.push({
            logDate: insight.logDate,
            button: button,
            option: insight.insightCode,
            graph: insight.insightType,
          });
        });
    }

    let gOLength = graphOptionsArr.length > 0 && graphOptionsArr.length - 1;

    let graphOptionsData =
      graphOptionsArr.length > 0 && graphOptionsArr.slice(1, gOLength);

    setGraphOptions((graphOptions) => graphOptionsData);
  };

  useEffect(() => {
    insightsCompiler();
  }, [props.data]);

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  return (
    <>
      {insights &&
        insights.map((story, index) => (
          <div
            key={index}
            onClick={() => {
              setFrame(frame + 1);
              handleClickOptions(graphOptions[index].option);
              handleClickGraphs(graphOptions[index].graph);
              handleClickButtons(graphOptions[index].button);
              handleClickNodes(graphOptions[index].logDate);
            }}
            style={
              frame === index
                ? {
                    display: "flex",
                  }
                : { display: "none" }
            }
            className={
              frame === 0 || frame === insights.length - 1
                ? "story__frame"
                : "story__frame story__frame_live"
            }
          >
            <div>
              {frame === 0 || frame === insights.length - 1 ? (
                <h3 className="headline">{story.insightHeadline}</h3>
              ) : (
                <h5>{`Chapter #${index}: ${story.insightHeadline}`}</h5>
              )}
              <hr></hr>
              <p className="subline">{story.insightStrapline}</p>
            </div>
            {/* {vw < 767 ? (
              <div
                style={
                  frame === 0 || frame === insights.length - 1
                    ? { display: "none" }
                    : {
                        display: "block",
                        backgroundColor: "#e2dfcf",
                        padding: "6px",
                        borderRadius: "5px",
                      }
                }
              >
                <p style={{ fontWeight: "bold", color: "#004a22" }}>
                  Here is a snapshot of that day:
                </p>
                <p style={{ color: "#004a22" }}>{displayMemory}</p>
              </div>
            ) : (
              <></>
            )} */}
            <div>
              <span
                style={
                  frame === 0 || frame === insights.length - 1
                    ? { display: "none" }
                    : {
                        display: "block",
                        fontWeight: "bold",
                        fontSize: "1.2em",
                        paddingTop: "6px",
                      }
                }
              >
                Coaching Questions
              </span>
              <hr></hr>
              {story.questionOne && <p>{story.questionOne}</p>}
              {story.questionTwo && <p>{story.questionTwo}</p>}
              {story.questionThree && <p>{story.questionThree}</p>}
            </div>
            {story.details && vw < 1025 && (
              <>
                <div
                  style={
                    frame === 0 || frame === insights.length - 1
                      ? { display: "none" }
                      : {
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                  }
                >
                  <a href={`/today/${story.id}`}>
                    <button className="story__frame--button">
                      Visit this day
                    </button>
                  </a>
                </div>
                <div
                  style={
                    frame === 0 || frame === insights.length - 1
                      ? { display: "none" }
                      : {
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }
                  }
                >
                  <FontAwesomeIcon
                    icon={faCaretSquareLeft}
                    size="3x"
                    style={{ color: "#e2dfcf" }}
                  />
                  <button className="story__frame--button">
                    Rotate to see graph
                    <FontAwesomeIcon
                      icon={faRotateLeft}
                      style={{ color: "#004a22", marginLeft: "6px" }}
                    />
                  </button>
                  <FontAwesomeIcon
                    icon={faCaretSquareRight}
                    size="3x"
                    style={{ color: "#e2dfcf" }}
                    onClick={() => {
                      setFrame(frame + 1);
                      handleClickOptions(graphOptions[index].option);
                      handleClickGraphs(graphOptions[index].graph);
                      handleClickButtons(graphOptions[index].button);
                      handleClickNodes(graphOptions[index].logDate);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ))}
    </>
  );
}
