export const QUIZ_CREATE_REQUEST = "QUIZ_CREATE_REQUEST";
export const QUIZ_CREATE_SUCCESS = "QUIZ_CREATE_SUCCESS";
export const QUIZ_CREATE_FAIL = "QUIZ_CREATE_FAIL";

export const QUIZ_LIST_REQUEST = "QUIZ_LIST_REQUEST";
export const QUIZ_LIST_SUCCESS = "QUIZ_LIST_SUCCESS";
export const QUIZ_LIST_FAIL = "QUIZ_LIST_FAIL";

export const QUIZ_UPDATE_REQUEST = "QUIZ_UPDATE_REQUEST";
export const QUIZ_UPDATE_SUCCESS = "QUIZ_UPDATE_SUCCESS";
export const QUIZ_UPDATE_FAIL = "QUIZ_UPDATE_FAIL";
