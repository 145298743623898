//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//Actions
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";
import { listDays } from "../../actions/dayActions";
import { logout } from "../../actions/userActions";

//Internal Components
import DropdownLoading from "../Loading/DropdownLoading";
import { LoginIcon, RegisterIcon } from "../../components/Icons/Icon";

//External Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

//Styling
import "./header.css";

const CentralHeaderLP = () => {
  const [homepage, setHomepage] = useState(
    window.location.href === "/" ? true : false
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const weekList = useSelector((state) => state.weekList);
  const { weeks } = weekList;

  const dayList = useSelector((state) => state.dayList);
  const { loading, error, days } = dayList;

  useEffect(() => {
    dispatch(listYears());
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(listWeeks());
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo]);

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    setLoadingSpinner(true);
    setTimeout(() => {
      setLoadingSpinner(false);
    }, 1500);
  }, []);

  const override = {
    display: "block",
    top: "50vh",
    left: "50%",
    margin: 0,
  };

  const [pageLoading, setPageLoading] = useState(true);

  const loadingTimeout = () => {
    var randNum = Math.random(1) * 3000 + Math.random(1) * 3000;
    setTimeout(() => {
      if (days) {
        setPageLoading(false);
      }
    }, randNum);
  };

  const [menuState, setMenuState] = useState("menu");
  const [navMenu, setNavMenu] = useState("navMenu");
  const [navDock, setNavDock] = useState("navDock");

  const openMenu = () => {
    if (menuState === "menu") {
      setMenuState((menuState) => "menu active");
      setNavMenu((navMenu) => "navMenu active");
      setNavDock((navDock) => "navDock");
    } else {
      setMenuState((menuState) => "menu");
      setNavMenu((navMenu) => "navMenu");
      setNavDock((navDock) => "navDock");
    }
  };

  const openDock = () => {
    if (menuState === "menu") {
      setMenuState((menuState) => "menu active");
      setNavDock((navDock) => "navDock active");
      setNavMenu((navMenu) => "navMenu");
    } else {
      setMenuState((menuState) => "menu");
      setNavDock((navDock) => "navDock");
      setNavMenu((navMenu) => "navMenu");
    }
  };

  const vw = Math.max(document.documentElement.clientWidth);

  useEffect(() => {
    loadingTimeout();
  });

  return (
    <>
      {vw >= 1025 ? (
        <>
          <nav className="nav central">
            <div className="box left">
              <a href={"/"}>
                <img
                  src="https://cdn.sanity.io/images/ou6d2gec/production/87eec4d22fdeb99bdae99a9822c0eabb3ab18f36-1080x93.svg"
                  alt="no wasted days"
                  width="100%"
                ></img>
              </a>
            </div>

            <div className="box right">
              <a href={"/login"}>
                <div className="navElement">
                  <div className="header node white">
                    <LoginIcon />
                  </div>
                  <h6>Login</h6>
                </div>
              </a>

              <a href={"/register"}>
                <div className="navElement">
                  <div className="header node white">
                    {/* <FontAwesomeIcon className="icon green" icon={faUserPlus} /> */}
                    <RegisterIcon />
                  </div>
                  <h6>Register</h6>
                </div>
              </a>
            </div>
          </nav>
        </>
      ) : (
        <>
          <nav className="nav mobile">
            {pageLoading ? (
              <a id="nava">
                <DropdownLoading />
              </a>
            ) : (
              <a href={"/"}>
                <div style={{ height: "36px", width: "36px" }}>
                  <img
                    src="https://cdn.sanity.io/images/ou6d2gec/production/cd1a77ad4d64832bf1c08fedeb3c3701058809aa-493x336.svg"
                    alt="no wasted days"
                    width="100%"
                  ></img>
                </div>
              </a>
            )}

            <div onClick={openMenu} style={{ marginRight: "18px" }}>
              <FontAwesomeIcon
                id="burgerMenu"
                className="burger"
                icon={faBars}
              />
            </div>
          </nav>

          <div className={menuState}>
            <ul className={navMenu}>
              <li className="listItem">
                {pageLoading ? (
                  <a id="nava">
                    <DropdownLoading />
                  </a>
                ) : (
                  <a id="nava" href={"/login"}>
                    <strong>Login</strong>
                    <div id="underline"></div>
                  </a>
                )}
              </li>
              <li className="listItem">
                {pageLoading ? (
                  <a id="nava">
                    <DropdownLoading />
                  </a>
                ) : (
                  <a id="nava" href={"/register"}>
                    <strong>Register</strong>
                    <div id="underline"></div>
                  </a>
                )}
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default CentralHeaderLP;
