const quoteObj = [
    {
      quote: 'The shoe that fits one person pinches another; there is no recipe for living that suits all cases.',
      author: 'Carl Jung',
      searchTerms: ['living','meaning','searching'],
    },
    {
        quote: 'Reality is merely an illusion, albeit a very persistent one.',
        author: 'Albert Einstein',
        searchTerms: ['reality','illusion','relativity'],
      },
      {
        quote: 'These individuals have riches just as we say that we “have fever”, when really the fever has us.',
        author: 'Seneca',
        searchTerms: ['wealth','riches','possession'],
      },
      {
        quote: 'What you want, wants you too. Just get out of the way and let it come through.',
        author: 'Bronnie Ware',
        searchTerms: ['want','manifestation','detachment'],
      },
      {
        quote: 'It is not enough to take steps which may some day lead to a goal; each step must be itself a goal and a step likewise.',
        author: 'Goethe',
        searchTerms: ['goals','goal setting','progress'],
      },
      {
        quote: 'Freedom can be best defined as the opportunity for self-discipline.',
        author: 'Eisenhower',
        searchTerms: ['freedom','discipline','opportunity'],
      },
      {
        quote: 'Tis easier to know how to speak than how to be silent',
        author: 'Thomas Fuller',
        searchTerms: ['silence','speaking','speak'],
      },
      {
        quote: 'We must each find our separate meaning, the persuasion of our days, until we meet in the meaning of the world',
        author: 'Christopher Fry',
        searchTerms: ['searching','meaning','world'],
      },
      {
        quote: 'Meanings change amazingly. When people get accustomed to horrors, these form the foundation for good style',
        author: 'Boris Pasternack',
        searchTerms: ['meaning','trends','style'],
      },
      {
        quote: 'The meaning of things lies not in the things themselves, but in our attitude towards them',
        author: 'Saint Exupery ',
        searchTerms: ['meaning','reality','attitude'],
      },
      {
        quote: 'The mind knows no answers. The heart knows no questions.',
        author: 'Buddha',
        searchTerms: ['heart','heart','answers'],
      },
      {
        quote: 'Life loves the one who dares to live it.',
        author: 'Maya Angelou',
        searchTerms: ['life','loves','dares'],
      },
      {
        quote: 'Freedom suppressed and again regained bites with keener fangs then freedom never endangered.',
        author: 'Cicero',
        searchTerms: ['freedom','oppression','bites'],
      },
      {
        quote: 'A man that is young in years may be old in hours, if he have lost no time.',
        author: 'Francis Bacon',
        searchTerms: ['age','wisdom','time'],
      },
      {
        quote: 'We do but learn to-day what our better advanced judgements will unteach us tomorrow.',
        author: 'Sir Thomas Browne',
        searchTerms: ['learn','unlearn','teach'],
      },
      {
        quote: 'We cannot all do everything.',
        author: 'Virgil',
        searchTerms: ['reality','everything','doing'],
      },
      {
        quote: 'I never saw an ugly thing in my life, for let the form of an object be what it may - light, shade, perspective, will always make it beautiful.',
        author: 'John Constable',
        searchTerms: ['beauty','form','ugly'],
      },
      {
        quote: "As some point in life, the worlds beauty becomes enough. You don't need to photograph, paint or even remember it - it is enough.",
        author: 'Toni Morrison',
        searchTerms: ['beauty','photograph','enough'],
      },
      {
        quote: 'Suit the action to the word, the word to the action.',
        author: 'William Shakespeare',
        searchTerms: ['integrity','acting','word'],
      },
      {
        quote: 'Better to light a candle than curse the darkness.',
        author: 'Peter Benenson',
        searchTerms: ['hope','action','optimism'],
      },
      {
        quote: 'Get the advice of everybody whose advise is worth having - they are very few - and then do what you think best yourself.',
        author: 'Charles Stewart Parnell',
        searchTerms: ['advice','action','commitment'],
      },
      {
        quote: 'All ambitions are lawful except those which climb upwards on the miseries or credulities of mankind.',
        author: 'Joseph Conrad',
        searchTerms: ['ambtion','moral','climb'],
      },
      {
        quote: 'There is nothing worse than a brilliant beginning.',
        author: 'Pablo Picasso',
        searchTerms: ['beginning','brilliant','worse'],
      },
      {
        quote: 'He that will not apply new remedies must expect new evils; for time is the greatest innovator.',
        author: 'Francis Bacon',
        searchTerms: ['change','time','remedy'],
      },
      {
        quote: 'You can’t step twice into the same river.',
        author: 'Heraclitus',
        searchTerms: ['change','time','mindful'],
      },
      {
        quote: 'Mr Bond, they have a saying in Chicago, ‘Once is happenstance. Twice is coincidence. The third time is enemy action.',
        author: 'Goldfinger',
        searchTerms: ['chance','trend','reality'],
      },
];

  export default quoteObj;