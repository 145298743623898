import {
  QUIZ_CREATE_FAIL,
  QUIZ_CREATE_REQUEST,
  QUIZ_CREATE_SUCCESS,
  QUIZ_LIST_FAIL,
  QUIZ_LIST_REQUEST,
  QUIZ_LIST_SUCCESS,
  QUIZ_UPDATE_FAIL,
  QUIZ_UPDATE_REQUEST,
  QUIZ_UPDATE_SUCCESS,
} from "../constants/quizConstants";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

export const listQuizes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: QUIZ_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://nwd-backend.herokuapp.com/api/quiz/`, config);

    dispatch({
      type: QUIZ_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: QUIZ_LIST_FAIL,
      payload: message,
    });
  }
};

export const createQuizAction = (
chapterOneScore,
chapterTwoScore,
chapterThreeScore,
chapterFourScore,
) => async ( dispatch, getState ) => {
  try {
    dispatch({
      type: QUIZ_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

 
    const { data } = await axios.post(
      `https://nwd-backend.herokuapp.com/api/quiz/insight/quiz/`,
      {  chapterOneScore,
        chapterTwoScore,
        chapterThreeScore,
        chapterFourScore, },
      config
    ); 


    await dispatch({
      type: QUIZ_CREATE_SUCCESS,
      payload: data,
    });

return data;
  } catch (error) {
    
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: QUIZ_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateQuizAction = (
  id,
  chapterOneScore,
  chapterTwoScore,
  chapterThreeScore,
  chapterFourScore,
) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: QUIZ_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `https://nwd-backend.herokuapp.com/api/quiz/insight/quiz/${id}`,
      { chapterOneScore,
        chapterTwoScore,
        chapterThreeScore,
        chapterFourScore, },
      config
    );

    dispatch({
      type: QUIZ_UPDATE_SUCCESS,
      payload: data,
    });

    return data;
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: QUIZ_UPDATE_FAIL,
      payload: message,
    });
  }
};
