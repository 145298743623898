import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
} from "../../components/Icons/Icon";
import "./pageloading.css";

const WeekLoading = (props) => {
  return (
    <div className="rectWeek">
      <FontAwesomeIcon className="objective__col--icon" icon={faPlus} />
      <div className="rectWeek__name--icon">
        {props.num === 1 && <OneIcon />}
        {props.num === 2 && <TwoIcon />}
        {props.num === 3 && <ThreeIcon />}
        {props.num === 4 && <FourIcon />}
        {props.num === 5 && <FiveIcon />}
        {props.num === 6 && <SixIcon />}
        {props.num === 7 && <SevenIcon />}
        {props.num === 8 && <EightIcon />}
        {props.num === 9 && <NineIcon />}
        {props.num === 10 && <TenIcon />}
      </div>
    </div>
  );
};

export default WeekLoading;
