// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// Internal Components
import { ErrorMessage } from "../Error/ErrorMessage";

// User Actions
import { updateProfile } from "../../actions/userActions";

// Page Data
import {
  nationalitiesList,
  genderList,
  educationLevels,
  journallingLevels,
  countryList,
} from "./registerData";
import TileLoading from "../Loading/TileLoading";
import CentralHeader from "../Header/CentralHeader";
import CentralHeaderLP from "../Header/CentralHeaderLP";

export default function UserDemographic({ history }) {
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [nationality, setNationality] = useState("");
  const [location, setLocation] = useState("");
  const [education, setEducation] = useState("");
  const [journalBefore, setJournalBefore] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vw = Math.max(document.documentElement.clientWidth);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { loading, error, success } = userUpdate;

  const submitHandler = (e) => {
    e.preventDefault();

    let demographicObj = {
      gender: gender,
      birthDate: birthDate,
      nationality: nationality,
      location: location,
      education: education,
      journal_habit: journalBefore,
    };

    dispatch(updateProfile(demographicObj));

    navigate("/today");
  };

  useEffect(() => {
    console.log(userInfo);

    if (!userInfo) {
      window.location.reload();
    }
  }, []);

  return (
    <>
      <main className="container">
        <section className="tile reg">
          {!userInfo ? (
            <TileLoading />
          ) : (
            <section className="registerContainer">
              <div className="tutorial access">
                <label style={{ fontWeight: "bold" }}>Your Details</label>
              </div>
              <p>
                By understanding who uses NoWastedDays.io, we can both help you
                more and find more people like you who we may be able to help.
                As such, we would love to learn a little bit about you.
              </p>
              <img
                className="sticker profile"
                src="https://cdn.sanity.io/images/ou6d2gec/production/088a4919740e18a8e075aea3319a06cbe5dfba5d-4500x4500.png"
                width="25%"
                alt="daily planner"
                loading="lazy"
              />
              <form className="register form" onSubmit={submitHandler}>
                <div className="demographic__row">
                  <label style={{ color: "#004a22" }} htmlFor="name">
                    Your Gender: I identify as...
                  </label>

                  <select
                    onChange={(e) => setGender(e.target.value)}
                    className="demographic__option"
                  >
                    {genderList &&
                      genderList.map((g, i) => (
                        <option key={i} value={g}>
                          {g}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="demographic__row">
                  <label style={{ color: "#004a22" }} htmlFor="email">
                    When were you born?
                  </label>
                  <input
                    className="register__date"
                    type="date"
                    value={birthDate}
                    onChange={(e) => setBirthDate(e.target.value)}
                  />
                </div>
                <div className="demographic__row">
                  <label style={{ color: "#004a22" }} htmlFor="password">
                    What nationality are you?
                  </label>
                  <select
                    onChange={(e) => setNationality(e.target.value)}
                    className="demographic__option"
                  >
                    {nationalitiesList &&
                      nationalitiesList.map((n, i) => (
                        <option key={i} value={n}>
                          {n}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="demographic__row">
                  <label style={{ color: "#004a22" }} htmlFor="password">
                    Where do you currently live and work?
                  </label>
                  <select
                    onChange={(e) => setLocation(e.target.value)}
                    className="demographic__option"
                  >
                    {countryList &&
                      countryList.map((c, i) => (
                        <option key={i} value={c}>
                          {c}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="demographic__row">
                  <label style={{ color: "#004a22" }} htmlFor="confPassword">
                    Which option best represents your exposure to and engagement
                    with formal education?
                  </label>
                  <select
                    onChange={(e) => setEducation(e.target.value)}
                    className="demographic__option"
                  >
                    {educationLevels &&
                      educationLevels.map((edu, i) => (
                        <option key={i} value={edu}>
                          {edu}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="demographic__row">
                  <label style={{ color: "#004a22" }} htmlFor="confPassword">
                    How much have you or do you journal?
                  </label>
                  <select
                    onChange={(e) => setJournalBefore(e.target.value)}
                    className="demographic__option"
                  >
                    {journallingLevels &&
                      journallingLevels.map((j, i) => (
                        <option key={i} value={j}>
                          {j}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="demographic__row--flex">
                  <a href="/today">
                    <button className="registerButton">Skip</button>
                  </a>
                  <button className="registerButton" type="submit">
                    Save and Continue
                  </button>
                </div>
              </form>
            </section>
          )}
        </section>
      </main>
    </>
  );
}
