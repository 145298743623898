import {
  DEBRIEFS_CREATE_FAIL,
  DEBRIEFS_CREATE_REQUEST,
  DEBRIEFS_CREATE_SUCCESS,
  DEBRIEFS_LIST_FAIL,
  DEBRIEFS_LIST_REQUEST,
  DEBRIEFS_LIST_SUCCESS,
  DEBRIEFS_UPDATE_FAIL,
  DEBRIEFS_UPDATE_REQUEST,
  DEBRIEFS_UPDATE_SUCCESS,
  DEBRIEFS_DELETE_FAIL,
  DEBRIEFS_DELETE_REQUEST,
  DEBRIEFS_DELETE_SUCCESS,
} from "../constants/debriefConstants";

export const debriefCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DEBRIEFS_CREATE_REQUEST:
      return { loading: true };
    case DEBRIEFS_CREATE_SUCCESS:
      return { loading: false, success: true };
    case DEBRIEFS_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const debriefListReducer = (state = { debriefs: [] }, action) => {
  switch (action.type) {
    case DEBRIEFS_LIST_REQUEST:
      return { loading: true };
    case DEBRIEFS_LIST_SUCCESS:
      return { loading: false, debriefs: action.payload };
    case DEBRIEFS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const debriefUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case DEBRIEFS_UPDATE_REQUEST:
      return { loading: true };
    case DEBRIEFS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case DEBRIEFS_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const debriefDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DEBRIEFS_DELETE_REQUEST:
      return { loading: true };
    case DEBRIEFS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case DEBRIEFS_DELETE_FAIL:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};
