import React from "react";
import "./tutorialwindow.css";
import { motion } from "framer-motion";

function TutorialWindow(props) {
  return (
    <motion.div
      className={
        props.tutorialStep === 0
          ? "tutorial__window"
          : props.tutorialStep === 1
            ? "tutorial__window left"
            : props.tutorialStep === 2
              ? "tutorial__window edge"
              : props.tutorialStep === 3
                ? "tutorial__window right"
                : props.tutorialStep === 4
                  ? "tutorial__window upper"
                  : props.tutorialStep === 5
                    ? "tutorial__window lower-left"
                    : props.tutorialStep === 6
                      ? "tutorial__window lower-right"
                      : "tutorial__window"
      }
      drag
    >
      {renderTutorial(props.instruction, props.tutorialStep)}
      <div className="tutorial__window--buttonRow">
        <div onClick={props.backStep} className="tutorial__button">
          Back
        </div>
        {props.tutorialStep < 7 ? (
          <div onClick={props.nextStep} className="tutorial__button live">
            Next
          </div>
        ) : (
          <div onClick={props.tutorialChange} className="tutorial__button">
            Let's Journal!
          </div>
        )}
      </div>
    </motion.div>
  );
}

function renderTutorial(block, tutorialStep = 1) {
  return (
    block &&
    block
      .filter((el, i) => i === tutorialStep)
      .map((element, i) => {
        if (tutorialStep === 0) {
          return (
            <label className="score label">
              {element.map((el, i) => (
                <h1 key={i} style={{ color: "#e2dfcf" }}>
                  {el}
                </h1>
              ))}
            </label>
          );
        } else {
          return (
            <label className="score label">
              {element.map((el, i) => (
                <h3 key={i} style={{ color: "#e2dfcf", paddingBottom: "24px" }}>
                  {el}
                </h3>
              ))}
            </label>
          );
        }
      })
  );
}

export { TutorialWindow, renderTutorial };
