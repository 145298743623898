import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Subscription Checker
import SubscriptionChecker from "./components/Hooks/SubscriptionChecker";

// Year
import CreateYearScreen from "./screens/Year/CreateYearScreen";
import UpdateYearScreen from "./screens/Year/UpdateYearScreen";
import TutorialCreateYearScreen from "./screens/Year/TutorialCreateYearScreen";

// Day
import CreateDayScreen from "./screens/Day/CreateDayScreen";
import UpdateDayScreen from "./screens/Day/UpdateDayScreen";
import TutorialCreateDayScreen from "./screens/Day/TutorialCreateDayScreen";
import TutorialCreateDayScreenFinal from "./screens/Day/TutorialCreateDayScreenFinal";

// Week
import UpdateWeekScreen from "./screens/Wek/UpdateWeekScreen";
import CreateWeekScreen from "./screens/Wek/CreateWeekScreen";

// Landing
import LandingScreen from "./screens/LandingPage/LandingScreen";
import TutorialMobile from "./screens/Tutorial/TutorialMobile";

// Diary
import ViewDiaryScreen from "./screens/Diary/ViewDiaryScreen";

// Insight
import ViewInsightScreen from "./screens/Insight/ViewInsightScreen";
import InsightsScreen from "./screens/Insight/InsightsScreen";
import TutorialViewInsightScreen from "./screens/Insight/TutorialViewInsightScreen";

// Payment Plans
import ChoosePaymentScreen from "./screens/Payments/ChoosePaymentScreen";
import CheckoutForm from "./screens/Payments/CheckoutForm";
import Payment from "./screens/Payments/Payment";
import Completion from "./screens/Payments/Completion";

// Quiz
import QuizIntroScreen from "./screens/Quiz/Intro/QuizIntroScreen";
import QuizFeelingScreen from "./screens/Quiz/Feeling/QuizFeelingScreen";
import QuizTasksScreen from "./screens/Quiz/Tasks/QuizTasksScreen";
import QuizMindsetScreen from "./screens/Quiz/Mindset/QuizMindsetScreen";
import QuizWaveScreen from "./screens/Quiz/Wave/QuizWaveScreen";
import QuizRepresentativeScreen from "./screens/Quiz/Representative/QuizRepresentativeScreen";
import QuizDebriefScreen from "./screens/Quiz/Debrief/QuizDebriefScreen";
import RealQuizScreen from "./screens/Quiz/Real/RealQuizScreen";
import FinalQuizScreen from "./screens/Quiz/Real/FinalQuizScreen";

// Login / Register
import ViewLoginScreen from "./screens/Login/ViewLoginScreen";
import ViewRegisterScreen from "./screens/Register/ViewRegisterScreen";
import RegisterDemographicScreen from "./screens/Register/RegisterDemographicScreen";

//Experiences: Values
import ValuesScreen from "./screens/Values/ValuesScreen";
import DebriefCreateScreen from "./screens/Debrief/DebriefCreateScreen";
import DebriefUpdateScreen from "./screens/Debrief/DebriefUpdateScreen";

// Gallery
import GalleryScreen from "./screens/Gallery/GalleryScreen";

//Experiences: Vision
// import VisionScreen from './screens/Values/VisionScreen';

//Experiences: Ikigai
// import IkigaiScreen from './screens/Values/IkigaiScreen';

//Experiences: Why
// import WhyScreen from './screens/Values/WhyScreen';

//Experiences: Quote
// import QuoteScreen from './screens/Values/QuoteScreen';

// Feedback Screen
import FeedbackScreen from "./screens/Feedback/FeedbackScreen";

// Admin Page
// import DashboardScreen from "./screens/Admin/DashboardScreen";

// My Account
import ProfileScreen from "./screens/Profile/ProfileScreen";
import ProfileMenuScreen from "./screens/Profile/ProfileMenu";
import ProfileFAQScreen from "./screens/Profile/ProfileFAQScreen";

// Blog
import BlogScreen from "./screens/Blog/BlogScreen";
import BlogPostTest from "./screens/Blog/BlogPostTest";
import BlogSearchScreen from "./screens/Blog/BlogSearchScreen";

//Footer
import AboutScreen from "./screens/About/AboutScreen";
import MissionScreen from "./screens/About/MissionScreen";
import ContactScreen from "./screens/Contact/ContactScreen";

import PrivacyScreen from "./screens/PrivacyPolicy/PrivacyPolicy";
import TermsConditionsScreen from "./screens/PrivacyPolicy/TermsConditions";
import BioSite from "./screens/BioSite/BioSite";

//Loading
import PropagateLoader from "react-spinners/PropagateLoader";

//External Packages
import { loadStripe } from "@stripe/stripe-js";

//Styles
import "./App.css";
import "./index.css";

//Misc
// import ExportScreen from "./screens/Export/ExportScreen";
import ExternalScreen from "./screens/External/ExternalScreen";
import GoogleAnalytics from "./utils/googleanalytics.js";
import SelfSurvey from "./screens/Survey/SelfSurvey";

function App() {
  const [stripePromise, setStripePromise] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    fetch("https://nwd-backend.herokuapp.com/config")
      .then(async (r) => {
        if (!r.ok) {
          throw new Error("Failed to fetch config");
        }

        const { publishableKey } = await r.json();
        setStripePromise(loadStripe(publishableKey));
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, []);

  return (
    <Router>
      <SubscriptionChecker userInfo={userInfo} />
      <Routes>
        <Route path="/" element={<LandingScreen />} />
        <Route path="/login" element={<ViewLoginScreen />} />
        <Route path="/about-nowasteddays" element={<AboutScreen />} />
        <Route path="/our-mission" element={<MissionScreen />} />
        <Route path="/register" element={<ViewRegisterScreen />} />
        <Route
          path="/register/profile"
          element={<RegisterDemographicScreen />}
        />
        <Route path="/insight" element={<InsightsScreen />} />
        {/* <Route path="/admin" element={<DashboardScreen />} /> */}
        <Route path="/resources" element={<ExternalScreen />} />
        <Route path="/privacy-policy" element={<PrivacyScreen />} />
        <Route
          path="/terms-and-conditions"
          element={<TermsConditionsScreen />}
        />
        <Route path="/gallery" element={<GalleryScreen />} />
        <Route path="/insight/quiz/" element={<QuizIntroScreen />} />
        <Route path="/insight/quiz/:id" element={<QuizIntroScreen />} />
        <Route
          path="/insight/quiz/:id/feeling"
          element={<QuizFeelingScreen />}
        />
        <Route
          path="/insight/quiz/:id/mindset"
          element={<QuizMindsetScreen />}
        />
        <Route path="/insight/quiz/:id/moments" element={<QuizTasksScreen />} />
        <Route path="/insight/quiz/:id/wave" element={<QuizWaveScreen />} />
        <Route
          path="/insight/quiz/:id/representative"
          element={<QuizRepresentativeScreen />}
        />
        <Route
          path="/insight/quiz/:id/debrief"
          element={<QuizDebriefScreen />}
        />
        <Route
          path="/insight/quiz/:id/real_quiz"
          element={<RealQuizScreen />}
        />
        <Route
          path="/insight/quiz/:id/real_quiz/end"
          element={<FinalQuizScreen />}
        />
        <Route path="/tutorial/today" element={<TutorialCreateDayScreen />} />
        <Route
          path="/tutorial/insight"
          element={<TutorialViewInsightScreen />}
        />
        <Route path="/tutorial/year" element={<TutorialCreateYearScreen />} />
        <Route
          path="/tutorial/today/final"
          element={<TutorialCreateDayScreenFinal />}
        />
        <Route path="/tutorial/mobile" element={<TutorialMobile />} />
        <Route
          path="/plans/choose"
          element={<ChoosePaymentScreen stripePromise={stripePromise} />}
        />
        <Route
          path="/subscribe/:tier"
          element={<Payment stripePromise={stripePromise} />}
        />
        <Route
          path="/completion"
          element={<Completion stripePromise={stripePromise} />}
        />
        <Route path="/blog" element={<BlogScreen />} />
        <Route path="/blog/:slug" element={<BlogPostTest />} />
        <Route path="/blog/search/:term" element={<BlogSearchScreen />} />
        <Route path="/biosite" element={<BioSite />} />
        {/* <Route path="/self-survey" element={<SelfSurvey />} /> */}
        <Route path="/my-account/contact" element={<ContactScreen />} />
        <Route path="/my-account/security" element={<ProfileScreen />} />
        <Route path="/my-account/menu" element={<ProfileMenuScreen />} />
        <Route path="/my-account/faqs" element={<ProfileFAQScreen />} />
        <Route path="/week/create" element={<CreateWeekScreen />} />
        <Route path="/week/:id" element={<UpdateWeekScreen />} />
        <Route path="/feedback" element={<FeedbackScreen />} />
        <Route path="/contact" element={<ContactScreen />} />
        <Route path="/contact/:type" element={<ContactScreen />} />
        <Route path="/values" element={<ValuesScreen />} />
        <Route path="/debrief" element={<DebriefCreateScreen />} />
        <Route path="/debrief/:id" element={<DebriefUpdateScreen />} />
        <Route path="/year/create" element={<CreateYearScreen />} />
        <Route path="/year/:id" element={<UpdateYearScreen />} />
        <Route path="/today" element={<CreateDayScreen />} />
        <Route path="/today/:id" element={<UpdateDayScreen />} />
        <Route path="/diary" element={<ViewDiaryScreen />} />
      </Routes>
    </Router>
  );
}
export default App;
