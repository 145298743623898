import {
  Spinner
} from 'react-bootstrap';
import './pageloading.css';

const DropdownLoading = () => {

  return ( <div className="rect"><span>Loading</span></div>
  )
}

export default DropdownLoading;
