// React
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

// Internal Components
import { artData } from "./ARTDATA";
import CentralHeader from "../../components/Header/CentralHeader";
import CentralHeaderLP from "../../components/Header/CentralHeaderLP";
import TestFooter from "../../components/Footer/TestFooter";
import { WaveFinal } from "../../Images/Assets/PageBreakers/Waves";

// Assets
import wavy from "../../Images/Assets/PageBreakers/wavy_final.svg";
import wavy_bottom from "../../Images/Assets/PageBreakers/1920x100_Top.svg";
import wavy_top from "../../Images/Assets/PageBreakers/1920x100_Bottom.svg";

// Styling
import "./gallery.css";

function GalleryScreen() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vw = Math.max(document.documentElement.clientWidth);

  const imageUrl =
    "https://drive.google.com/uc?export=view&id=1JUL7MKiRaOlDNp6Qneg-J5OecRT-QiGv";

  return (
    <>
      {userInfo ? <CentralHeader /> : <CentralHeaderLP />}
      <main className="gallery__main">
        <section className="gallery__gallery">
          {artData.map((art, index) => (
            <div className="gallery__block" key={index}>
              {userInfo ? (
                <img
                  src={art.art_src}
                  alt={art.art_title}
                  className="gallery__image"
                />
              ) : (
                <img
                  src={art.art_src_wm}
                  alt={art.art_title}
                  className="gallery__image"
                />
              )}
              <a href={userInfo ? "" : "/register"}>
                <button className="gallery__button">Download</button>
              </a>
            </div>
          ))}
        </section>
      </main>
    </>
  );
}

export default GalleryScreen;
