// React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//External Packages

// Internal Components
import Register from "../../components/Register/Register";
import MouseTooltip from "../../components/MouseTooltip/MouseTooltip";
import TestFooter from "../../components/Footer/TestFooter";
import CentralHeaderLP from "../../components/Header/CentralHeaderLP";

// User Actions

// Images

// Styling
import "./registerscreen.css";

export default function ViewRegisterScreen() {
  const navigate = useNavigate();
  const [paycPicker, setPaycPicker] = useState("");

  return (
    <>
      <CentralHeaderLP />

      <section id="registerScreen">
        <Register />
      </section>

      <TestFooter />
    </>
  );
}
