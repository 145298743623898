const faqQuestionData = [
  {
    question: "How much does it cost?",
  },
  {
    question: "Why do you make all features available, even on the free tier?",
  },
  {
    question: "Can anyone else see my data?",
  },
  {
    question: "Is No Wasted Days only this platform?",
  },
  {
    question: "How long does it take to sign up?",
  },
  {
    question: "What's the story behind No Wasted Days?",
  },
];

const faqAnswerData = [
  {
    answer:
      "For now, and the foreseeable future, you can sign up for free and make the most of all the features. The free tier includes advertising to help us cover the server, hosting and development cost of each free user. The classic and coach & classic tiers disable ads whilst also providing you more support in designing your days and dissecting your data.",
  },
  {
    answer:
      "I believe that any platform that could help you be more self-aware and self-assure should be made available to people as freely and easily as possible. The effect of heightened self-awareness and self-assurance on your own happiness and health is massive, and society also benefits hugely from an influx of happier, healthier people. Why lose that behind a prohibitive paywall or protected premium features?",
  },
  {
    answer:
      "No. All text fields in the journal are encrypted using cryptographic algorithms meaning they are extremely safe and impossible to read. Your data is here to serve one person and one person only: you. It will never be sold or leveraged in any other way than to help you learn more about yourself.",
  },
  {
    answer:
      "No, far from it. We have a growing podcast (The NoWastedDays Podcast) and social channels (@nowasteddays) that have the same mission; to allow anyone to understand themselves better at a cost they completely control.",
  },
  {
    answer:
      "If you do the tutorial, about 2-3 minutes. If you don't, about 10 seconds.",
  },
  {
    answer:
      "It was created 5 years ago at a dark time in my life. I had become hugely stressed and really isolated and, in the depths of that difficult moment, I create a little diary format in an excel sheet to help give everyday more structure and purpose. Over time, it evolved - with more data, more mindful elements, more insights - to be this huge organising force in my life, and have a transformative effect on my self-awareness & self-assurance; happiness and health. People kept asking me about this spreadsheet that I was using and I thought, 'This really should be an app that anyone could use!'. Lots of software development learning later, here we are: an living, breathing platform that anyone can use for free to help themselves feel better and do more courtesy of being more self-aware and self-assure.",
  },
];

export { faqAnswerData, faqQuestionData };
