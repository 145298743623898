// React 
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { Card, Button, Row, Col, Container, Form } from 'react-bootstrap';

// External Packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faEraser } from '@fortawesome/free-solid-svg-icons'

// Internal Components
import Intro from '../../components/ValuesProcess/Intro'
import TestFooter from '../../components/Footer/TestFooter';
import { ErrorMessage } from '../../components/Error/ErrorMessage';

// User Actions
import { updateProfile } from '../../actions/dayActions';

// Images


// Styling
import './values.css';


export default function ValuesScreen() {

  return (
    <>
<main style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: "center"}}>

<Intro />
</main>
<TestFooter />
</>
  );
}
