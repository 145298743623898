// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Internal Components
import CentralHeader from "../../components/Header/CentralHeader";
import CentralHeaderLP from "../../components/Header/CentralHeaderLP";
import TestFooter from "../../components/Footer/TestFooter";

// External Packages
import axios from "axios";
import * as d3 from "d3";

// Sanity Client
import { client } from "../../client";

import "./blog.css";

function BlogSearchScreen() {
  const [content, setContent] = useState();
  const [all, setAll] = useState();
  const parseDate = d3.timeParse("%Y-%m-%d");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { term } = useParams();

  async function getPosts() {
    const posts = await client.fetch(`*[_type == 'post']{
      _id,
      title,
      body,
      slug,
      categories[] -> {
        _id,
        title,
        description
      },
      _createdAt,
      mainImage {
        asset -> {
          _id,
          url
        },
      },
    }`);
    return posts;
  }

  useEffect(() => {
    const fetchData = async () => {
      let searchedPosts = [];

      try {
        const response = await getPosts();
        if (response) {
          response.forEach((post) => {
            if (post.categories) {
              for (let i = 0; i < post.categories.length; i++) {
                if (post.categories[i].title === term) {
                  searchedPosts.push(post);
                }
              }
            }
          });
          setContent(searchedPosts);
          setAll(term);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {userInfo ? <CentralHeader /> : <CentralHeaderLP />}
      <main className="blog__main">
        <section className="blog__category--list">
          <a href="/blog">Back to Main</a>
          {/* <a href={`/blog/search/${all}`}>{all}</a> */}
        </section>
        <section className="blog__list">
          {content &&
            content.map((post) => (
              <div
                key={post._id}
                className={
                  post.mainImage
                    ? post.mainImage.asset.url.includes("1280")
                      ? "blog__tile--wide"
                      : "blog__tile"
                    : "blog__tile"
                }
              >
                {post.mainImage && (
                  <img
                    className="post--image"
                    src={post.mainImage.asset.url}
                    alt={post.mainImage._type}
                    width="100%"
                  />
                )}
                <div className="blog__tile--details">
                  <strong className="blog__tile--header">{post.title}</strong>
                  <div>
                    <div style={{ display: "inline", padding: "6px 0" }}>
                      {post.body &&
                        post.body[0].children.map((p, i) => (
                          <span key={p._key} className="blog__tile--excerpt">
                            {p.text}
                          </span>
                        ))}
                    </div>
                  </div>
                  <a
                    className="blog__tile--link"
                    href={`/blog/${post.slug.current}`}
                  >
                    <div className="blog__tile--date-cta">
                      {/* <span>{post._createdAt.substring(0, 10)}</span> */}
                      <strong>Read Now</strong>
                    </div>
                  </a>
                </div>
              </div>
            ))}
        </section>
      </main>
      <TestFooter />
    </>
  );
}

export default BlogSearchScreen;
