//React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

//External Packages
import CryptoJS from "crypto-js";
import axios from "axios";
import { compareAsc, format } from "date-fns";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faSignature,
  faDna,
  faCalendarDay,
  faArrowUp91,
  faQuoteLeft,
  faLandmarkDome,
  faCompassDrafting,
  faQuestion,
  faPenToSquare,
  faEraser,
  faGlasses,
} from "@fortawesome/free-solid-svg-icons";

//Actions
import { updateYearAction, listYears } from "../../actions/yearActions";
import { listDays } from "../../actions/dayActions";

//Internal Components
import HideDock from "../../components/Dock/HideDock";
import TestFooter from "../../components/Footer/TestFooter";
import TileLoading from "../../components/Loading/TileLoading";
import CentralHeader from "../../components/Header/CentralHeader";
import generateSecret from "../../utils/generateSecret";
import dateFormatter from "../../components/Functions/DateFormatter";
import {
  CreateDayIcon,
  CreateDayIconV2,
  ValuesIcon,
  NameIcon,
  NameIconV2,
  NameIconV3,
  NameIconV4,
  DobIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  YearIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  SaveIcon,
  TickIcon,
  PreviousDay,
  VitIcon,
  WeekIcon,
} from "../../components/Icons/Icon";

import "./yearscreen.css";

export default function UpdateYearScreen({ history }) {
  const [yourName, setYourName] = useState();
  const [inputName, setInputName] = useState();
  const [myValues_1, setMyValues_1] = useState();
  const [inputValues_1, setInputValues_1] = useState();
  const [myValues_1_Text, setMyValues_1_Text] = useState();
  const [inputValues_1_Text, setInputValues_1_Text] = useState();
  const [myValues_2, setMyValues_2] = useState();
  const [inputValues_2, setInputValues_2] = useState();
  const [myValues_2_Text, setMyValues_2_Text] = useState();
  const [inputValues_2_Text, setInputValues_2_Text] = useState();
  const [myValues_3, setMyValues_3] = useState();
  const [inputValues_3, setInputValues_3] = useState();
  const [myValues_3_Text, setMyValues_3_Text] = useState();
  const [inputValues_3_Text, setInputValues_3_Text] = useState();
  const [myValues_4, setMyValues_4] = useState();
  const [inputValues_4, setInputValues_4] = useState();
  const [myValues_4_Text, setMyValues_4_Text] = useState();
  const [inputValues_4_Text, setInputValues_4_Text] = useState();
  const [myValues_5, setMyValues_5] = useState();
  const [inputValues_5, setInputValues_5] = useState();
  const [myValues_5_Text, setMyValues_5_Text] = useState();
  const [inputValues_5_Text, setInputValues_5_Text] = useState();
  const [myVision_1, setMyVision_1] = useState();
  const [inputVision_1, setInputVision_1] = useState();
  const [myVision_2, setMyVision_2] = useState();
  const [inputVision_2, setInputVision_2] = useState();
  const [myVision_3, setMyVision_3] = useState();
  const [inputVision_3, setInputVision_3] = useState();
  const [myVision_4, setMyVision_4] = useState();
  const [inputVision_4, setInputVision_4] = useState();
  const [myVision_5, setMyVision_5] = useState();
  const [inputVision_5, setInputVision_5] = useState();
  const [whyNWD, setWhyNWD] = useState();
  const [rememberToday, setRememberToday] = useState();
  const [inputWhy, setInputWhy] = useState();
  const [myIkigai, setMyIkigai] = useState();
  const [inputIkigai, setInputIkigai] = useState();
  const [navigationalQuote, setNavigationalQuote] = useState();
  const [inputQuote, setInputQuote] = useState();

  const [birthDate, setBirthDate] = useState();
  const [inputBirthDate, setInputBirthDate] = useState();
  const [cursorDisplayState, setCursorDisplayState] = useState(true);

  const [videoDisplay, setVideoDisplay] = useState(true);
  const [videoLink, setVideoLink] = useState("TpLVtoE6bFg");

  const [pageLoading, setPageLoading] = useState(true);

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { loading, error, years } = yearList;

  const yearUpdate = useSelector((state) => state.yearUpdate);
  const { success: successUpdate } = yearUpdate;

  useEffect(() => {
    dispatch(listYears());
  }, [dispatch, userInfo, history]);

  const dayList = useSelector((state) => state.dayList);
  const { days } = dayList;

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo, history]);

  const cursorDisplayToggle = () => {
    setCursorDisplayState((cursorDisplayState) => !cursorDisplayState);
  };

  const key = generateSecret().toString();

  useEffect(() => {
    const fetching = async () => {
      const key = generateSecret().toString();

      try {
        const { data } = await axios.get(
          `https://nwd-backend.herokuapp.com/api/years/year/${id}`
        );
        setYourName(
          CryptoJS.AES.decrypt(data.yourName, key).toString(CryptoJS.enc.Utf8)
        );
        setBirthDate(data.birthDate);
        setMyValues_1(
          localStorage.Values_1
            ? localStorage.Values_1
            : CryptoJS.AES.decrypt(data.myValues_1, key).toString(
                CryptoJS.enc.Utf8
              )
        );
        setMyValues_1_Text(
          localStorage.Values_1_Text
            ? localStorage.Values_1_Text
            : CryptoJS.AES.decrypt(data.myValues_1_Text, key).toString(
                CryptoJS.enc.Utf8
              )
        );
        setMyValues_2(
          localStorage.Values_2
            ? localStorage.Values_2
            : CryptoJS.AES.decrypt(data.myValues_2, key).toString(
                CryptoJS.enc.Utf8
              )
        );
        setMyValues_2_Text(
          localStorage.Values_2_Text
            ? localStorage.Values_2_Text
            : CryptoJS.AES.decrypt(data.myValues_2_Text, key).toString(
                CryptoJS.enc.Utf8
              )
        );
        setMyValues_3(
          localStorage.Values_3
            ? localStorage.Values_3
            : CryptoJS.AES.decrypt(data.myValues_3, key).toString(
                CryptoJS.enc.Utf8
              )
        );
        setMyValues_3_Text(
          localStorage.Values_3_Text
            ? localStorage.Values_3_Text
            : CryptoJS.AES.decrypt(data.myValues_3_Text, key).toString(
                CryptoJS.enc.Utf8
              )
        );
        setMyValues_4(
          localStorage.Values_4
            ? localStorage.Values_4
            : CryptoJS.AES.decrypt(data.myValues_4, key).toString(
                CryptoJS.enc.Utf8
              )
        );
        setMyValues_4_Text(
          localStorage.Values_4_Text
            ? localStorage.Values_4_Text
            : CryptoJS.AES.decrypt(data.myValues_4_Text, key).toString(
                CryptoJS.enc.Utf8
              )
        );
        setMyValues_5(
          localStorage.Values_5
            ? localStorage.Values_5
            : CryptoJS.AES.decrypt(data.myValues_5, key).toString(
                CryptoJS.enc.Utf8
              )
        );
        setMyValues_5_Text(
          localStorage.Values_5_Text
            ? localStorage.Values_5_Text
            : CryptoJS.AES.decrypt(data.myValues_5_Text, key).toString(
                CryptoJS.enc.Utf8
              )
        );
        setMyVision_1(
          CryptoJS.AES.decrypt(data.myVision_1, key).toString(CryptoJS.enc.Utf8)
        );
        setMyVision_2(
          CryptoJS.AES.decrypt(data.myVision_2, key).toString(CryptoJS.enc.Utf8)
        );
        setMyVision_3(
          CryptoJS.AES.decrypt(data.myVision_3, key).toString(CryptoJS.enc.Utf8)
        );
        setMyVision_4(
          CryptoJS.AES.decrypt(data.myVision_4, key).toString(CryptoJS.enc.Utf8)
        );
        setMyVision_5(
          CryptoJS.AES.decrypt(data.myVision_5, key).toString(CryptoJS.enc.Utf8)
        );
        setWhyNWD(
          CryptoJS.AES.decrypt(data.whyNWD, key).toString(CryptoJS.enc.Utf8)
        );
        setMyIkigai(
          CryptoJS.AES.decrypt(data.myIkigai, key).toString(CryptoJS.enc.Utf8)
        );
        setNavigationalQuote(
          CryptoJS.AES.decrypt(data.navigationalQuote, key).toString(
            CryptoJS.enc.Utf8
          )
        );
      } catch (error) {
        console.log(error);
      }
    };

    const dateValid = async () => {
      await fetching();
      setBirthDate(birthDate.replace(/-/g, "/").substring(0, 10));
    };

    dateValid();
  }, [id, birthDate]);

  const resetHandler = () => {
    setYourName("");
    setBirthDate("");
    setMyValues_1("");
    setMyValues_1_Text("");
    setMyValues_2("");
    setMyValues_2_Text("");
    setMyValues_3("");
    setMyValues_3_Text("");
    setMyValues_4("");
    setMyValues_4_Text("");
    setMyValues_5("");
    setMyValues_5_Text("");
    setMyVision_1("");
    setMyVision_2("");
    setMyVision_3("");
    setMyVision_4("");
    setMyVision_5("");
    setWhyNWD("");
    setMyIkigai("");
    setNavigationalQuote("");
  };

  const updateHandler = async (e) => {
    // await e.preventDefault();

    const key = generateSecret().toString();

    dispatch(
      updateYearAction(
        id,
        CryptoJS.AES.encrypt(yourName, key).toString(),
        birthDate,
        CryptoJS.AES.encrypt(myValues_1, key).toString(),
        CryptoJS.AES.encrypt(myValues_1_Text, key).toString(),
        CryptoJS.AES.encrypt(myValues_2, key).toString(),
        CryptoJS.AES.encrypt(myValues_2_Text, key).toString(),
        CryptoJS.AES.encrypt(myValues_3, key).toString(),
        CryptoJS.AES.encrypt(myValues_3_Text, key).toString(),
        CryptoJS.AES.encrypt(myValues_4, key).toString(),
        CryptoJS.AES.encrypt(myValues_4_Text, key).toString(),
        CryptoJS.AES.encrypt(myValues_5, key).toString(),
        CryptoJS.AES.encrypt(myValues_5_Text, key).toString(),
        CryptoJS.AES.encrypt(myVision_1, key).toString(),
        CryptoJS.AES.encrypt(myVision_2, key).toString(),
        CryptoJS.AES.encrypt(myVision_3, key).toString(),
        CryptoJS.AES.encrypt(myVision_4, key).toString(),
        CryptoJS.AES.encrypt(myVision_5, key).toString(),
        CryptoJS.AES.encrypt(whyNWD, key).toString(),
        CryptoJS.AES.encrypt(myIkigai, key).toString(),
        CryptoJS.AES.encrypt(navigationalQuote, key).toString()
      )
    )
      .then((response) => {
        if (response) {
          localStorage.removeItem("Values_1");
          localStorage.removeItem("Values_1_Text");
          localStorage.removeItem("Values_2");
          localStorage.removeItem("Values_2_Text");
          localStorage.removeItem("Values_3");
          localStorage.removeItem("Values_3_Text");
          localStorage.removeItem("Values_4");
          localStorage.removeItem("Values_4_Text");
          localStorage.removeItem("Values_5");
          localStorage.removeItem("Values_5_Text");

          setTimeout(() => {
            window.location.reload();
          }, 250);
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (
      !yourName ||
      !birthDate ||
      !myValues_1 ||
      !myValues_1_Text ||
      !myValues_2 ||
      !myValues_2_Text ||
      !myValues_3 ||
      !myValues_3_Text ||
      !myValues_4 ||
      !myValues_4_Text ||
      !myValues_5 ||
      !myValues_5_Text ||
      !myVision_1 ||
      !myVision_2 ||
      !myVision_3 ||
      !myVision_4 ||
      !myVision_5 ||
      !whyNWD ||
      !myIkigai ||
      !navigationalQuote
    )
      return;

    // resetHandler();
  };

  let birthday =
    years &&
    years
      .filter((year, i, years) => years.indexOf(year) === years.length - 1)
      .map((year) => year.birthDate);

  let day1 = new Date(birthday);

  let day2 = new Date();

  let diff = Math.abs(day2 - day1);

  let daysCalc = diff / (1000 * 3600 * 24);

  let days1 = Math.floor(daysCalc);

  const [continuation, setContinuation] = useState(80000);

  const loadingTimeout = () => {
    var randNum = Math.random(1) * 3000 + Math.random(1) * 3000 + 6000;
    setTimeout(() => {
      if (days) {
        setPageLoading(false);
      }
    }, randNum);
  };

  const showHideState = {
    show: {
      display: "flex",
    },
    hide: {
      display: "none",
    },
  };

  const localRemove = () => {};

  const [tutorialState, setTutorialState] = useState("tutorial");

  const showTutorial = () => {
    if (tutorialState === "tutorial video") {
      setTutorialState((tutorialState) => "tutorial");
    } else {
      setTutorialState((tutorialState) => "tutorial video");
    }
  };

  const [birthDateDisplay, setBirthDateDisplay] = useState(true);
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const dateString = birthDate;
  const date = new Date(dateString);

  const formattedDate = dateFormatter(dateString);

  useEffect(() => {
    loadingTimeout();
    setContinuation(days1);
  }, [days1]);

  const [yourNameHover, setYourNameHover] = useState(false);

  const handleHoverIn = () => {
    setYourNameHover((yourNameHover) => true);
  };
  const handleHoverOut = () => {
    setYourNameHover((yourNameHover) => false);
  };

  const [birthDateHover, setBirthDateHover] = useState(false);
  const handleHoverInDOB = () => {
    setBirthDateHover((birthDateHover) => true);
  };
  const handleHoverOutDOB = () => {
    setBirthDateHover((birthDateHover) => false);
  };

  const [myValuesHover, setMyValuesHover] = useState(false);

  const handleHoverInValues = () => {
    setMyValuesHover((myValuesHover) => true);
  };
  const handleHoverOutValues = () => {
    setMyValuesHover((myValuesHover) => false);
  };

  const [myVisionHover, setMyVisionHover] = useState(false);
  const handleHoverInVision = () => {
    setMyVisionHover((myVisionHover) => true);
  };
  const handleHoverOutVision = () => {
    setMyVisionHover((myVisionHover) => false);
  };

  const [myIkigaiHover, setMyIkigaiHover] = useState(false);
  const handleHoverInIkigai = () => {
    setMyIkigaiHover((myIkigaiHover) => true);
  };
  const handleHoverOutIkigai = () => {
    setMyIkigaiHover((myIkigaiHover) => false);
  };

  const [navigationalQuoteHover, setNavigationalQuoteHover] = useState(false);
  const handleHoverInNavigationalQuote = () => {
    setNavigationalQuoteHover((navigationalQuoteHover) => true);
  };
  const handleHoverOutNavigationalQuote = () => {
    setNavigationalQuoteHover((navigationalQuoteHover) => false);
  };

  const [continuationalHover, setContinuationalHover] = useState(false);
  const handleHoverInContinuational = () => {
    setContinuationalHover((continuationalHover) => true);
  };
  const handleHoverOutContinuational = () => {
    setContinuationalHover((continuationalHover) => false);
  };

  const [memoryHover, setMemoryHover] = useState(false);

  const [pastDay, setPastDayState] = useState("");

  const pastDayFunc = () => {
    if (Array.isArray(days)) {
      let memArray = [];
      let memArrayFinal = [];

      memArray.push(days && days.map((day) => day.rememberToday));

      for (let i = 0; i < memArray[0].length; i++) {
        if (memArray[0][i].includes(" ")) {
          memArrayFinal.push(memArray[0][i]);
        } else {
          memArrayFinal.push(
            CryptoJS.AES.decrypt(memArray[0][i], key).toString(
              CryptoJS.enc.Utf8
            )
          );
        }
      }

      if (memArrayFinal.length > 1) {
        memArrayFinal.filter((mem) => mem != []);
      }

      let memorySelected = memArrayFinal
        .filter(
          (day, i, days) =>
            days.indexOf(day) === Math.floor(Math.random() * days.length)
        )
        .filter((day, i, days) => days.indexOf(day) === 0)
        .map((day, index, days) => day);

      if (
        memorySelected === [] ||
        memorySelected === "" ||
        memorySelected === " " ||
        memorySelected.length === 0
      ) {
        setPastDayState("No memory on this day. Hover over again");
      } else {
        setPastDayState((pastDay) => memorySelected);
      }
    }
  };

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (vw > 1024) {
        setIsVisible(window.scrollY <= 0);
      } else {
        setIsVisible(window.scrollY <= 1000);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(()=>{
  //   pastDayFunc();
  // });

  const handleChange_Name = (e) => {
    setYourName(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_One = (e) => {
    setMyValues_1(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_One_Text = (e) => {
    setMyValues_1_Text(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Two = (e) => {
    setMyValues_2(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Two_Text = (e) => {
    setMyValues_2_Text(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Three = (e) => {
    setMyValues_3(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Three_Text = (e) => {
    setMyValues_3_Text(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Four = (e) => {
    setMyValues_4(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Four_Text = (e) => {
    setMyValues_4_Text(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Five = (e) => {
    setMyValues_5(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Five_Text = (e) => {
    setMyValues_5_Text(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Vision_One = (e) => {
    setMyVision_1(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Vision_Two = (e) => {
    setMyVision_2(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Vision_Three = (e) => {
    setMyVision_3(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Vision_Four = (e) => {
    setMyVision_4(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Vision_Five = (e) => {
    setMyVision_5(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Ikigai = (e) => {
    setMyIkigai(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Quote = (e) => {
    setNavigationalQuote(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  return (
    <>
      <Helmet>
        <title>Update | Year</title>
      </Helmet>
      <CentralHeader />

      <form onSubmit={updateHandler}>
        <main className="yearContainer">
          {pageLoading ? (
            <div
              style={{
                height: "50vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TileLoading />
            </div>
          ) : (
            <>
              <motion.div
                className="dock--container"
                initial={{ opacity: 1 }}
                animate={{ opacity: isVisible ? 1 : 0 }}
                transition={{ duration: 0.3 }}
                style={{
                  zIndex: 999,
                }}
              >
                <HideDock
                  toggleOverlay={cursorDisplayToggle}
                  showTutorial={showTutorial}
                  updateYear={updateHandler}
                />
              </motion.div>
              <div id="yeartest">
                {yourNameHover === false && vw > 1024 ? (
                  <div
                    className={`yeartile yn active`}
                    onMouseEnter={handleHoverIn}
                  >
                    <h2>Name</h2>
                  </div>
                ) : (
                  <div
                    className="yeartile yn"
                    onMouseLeave={vw > 1024 && handleHoverOut}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Name</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <VitIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      wrap="soft"
                      autoComplete="off"
                      value={yourName}
                      placeholder="John Doer"
                      onChange={handleChange_Name}
                    />
                  </div>
                )}

                {birthDateHover === false && vw > 1024 ? (
                  <div
                    className="yeartile dob active"
                    onMouseEnter={handleHoverInDOB}
                  >
                    <h2>D.O.B</h2>
                  </div>
                ) : (
                  <div
                    className="yeartile dob"
                    onMouseLeave={handleHoverOutDOB}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>D.O.B.</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <WeekIcon />
                        </div>
                      </div>
                    </div>

                    {birthDateDisplay ? (
                      <>
                        <div className="birthRow">
                          <div>
                            <span>{formattedDate}</span>
                          </div>
                          <FontAwesomeIcon
                            className="icon birthday"
                            icon={faPenToSquare}
                            onClick={() => {
                              setBirthDateDisplay((birthDateDisplay) => false);
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="birthRow">
                          <div>
                            <motion.input
                              className="dobInput"
                              type="date"
                              value={birthDate}
                              onChange={(e) => setBirthDate(e.target.value)}
                              animate={inputBirthDate ? "show" : "hide"}
                            ></motion.input>
                          </div>
                          <FontAwesomeIcon
                            className="icon birthday"
                            icon={faX}
                            onClick={() => {
                              setBirthDateDisplay((birthDateDisplay) => true);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}

                {myValuesHover === false && vw > 1024 ? (
                  <div
                    className="yeartile values active"
                    onMouseEnter={handleHoverInValues}
                  >
                    <h2>Values</h2>
                  </div>
                ) : (
                  <div
                    className="yeartile values"
                    onMouseLeave={handleHoverOutValues}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Values</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <ValuesIcon />
                        </div>
                      </div>
                    </div>
                    <div className="valuesRow">
                      <div className="valuesDiv">
                        <input
                          type="text"
                          wrap="soft"
                          autoComplete="off"
                          value={myValues_1}
                          placeholder="..."
                          onChange={handleChange_Values_One}
                        />
                        <textarea
                          wrap="soft"
                          autoComplete="off"
                          value={myValues_1_Text}
                          placeholder="..."
                          onChange={handleChange_Values_One_Text}
                        />
                      </div>
                      <div className="valuesDiv">
                        <input
                          type="text"
                          wrap="soft"
                          autoComplete="off"
                          value={myValues_2}
                          placeholder="..."
                          onChange={handleChange_Values_Two}
                        />
                        <textarea
                          wrap="soft"
                          autoComplete="off"
                          value={myValues_2_Text}
                          placeholder="..."
                          onChange={handleChange_Values_Two_Text}
                        />
                      </div>
                      <div className="valuesDiv">
                        <input
                          type="text"
                          wrap="soft"
                          autoComplete="off"
                          value={myValues_3}
                          placeholder="..."
                          onChange={handleChange_Values_Three}
                        />
                        <textarea
                          wrap="soft"
                          autoComplete="off"
                          value={myValues_3_Text}
                          placeholder="..."
                          onChange={handleChange_Values_Three_Text}
                        />
                      </div>
                      <div className="valuesDiv">
                        <input
                          type="text"
                          wrap="soft"
                          autoComplete="off"
                          value={myValues_4}
                          placeholder="..."
                          onChange={handleChange_Values_Four}
                        />
                        <textarea
                          wrap="soft"
                          autoComplete="off"
                          value={myValues_4_Text}
                          placeholder="..."
                          onChange={handleChange_Values_Four_Text}
                        />
                      </div>
                      <div className="valuesDiv">
                        <input
                          type="text"
                          wrap="soft"
                          autoComplete="off"
                          value={myValues_5}
                          placeholder="..."
                          onChange={handleChange_Values_Five}
                        />
                        <textarea
                          wrap="soft"
                          autoComplete="off"
                          value={myValues_5_Text}
                          placeholder="..."
                          onChange={handleChange_Values_Five_Text}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {myVisionHover === false && vw > 1024 ? (
                  <div
                    className="yeartile vision active"
                    onMouseEnter={handleHoverInVision}
                  >
                    <h2>Vision</h2>
                  </div>
                ) : (
                  <div
                    className="yeartile vision"
                    onMouseLeave={handleHoverOutVision}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Vision</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <VisionIcon />
                        </div>
                      </div>
                    </div>
                    <div className="valuesRow">
                      <div className="valuesDiv">
                        <textarea
                          wrap="soft"
                          autoComplete="off"
                          value={myVision_1}
                          placeholder="..."
                          onChange={handleChange_Vision_One}
                        />
                      </div>
                      <div className="valuesDiv">
                        <textarea
                          wrap="soft"
                          autoComplete="off"
                          value={myVision_2}
                          placeholder="..."
                          onChange={handleChange_Vision_Two}
                        />
                      </div>
                      <div className="valuesDiv">
                        <textarea
                          wrap="soft"
                          autoComplete="off"
                          value={myVision_3}
                          placeholder="..."
                          onChange={handleChange_Vision_Three}
                        />
                      </div>
                      <div className="valuesDiv">
                        <textarea
                          wrap="soft"
                          autoComplete="off"
                          value={myVision_4}
                          placeholder="..."
                          onChange={handleChange_Vision_Four}
                        />
                      </div>
                      <div className="valuesDiv">
                        <textarea
                          wrap="soft"
                          autoComplete="off"
                          value={myVision_5}
                          placeholder="..."
                          onChange={handleChange_Vision_Five}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {myIkigaiHover === false && vw > 1024 ? (
                  <div
                    className="yeartile ikigai active"
                    onMouseEnter={() => {
                      setMyIkigaiHover((myIkigaiHover) => true);
                    }}
                  >
                    <h2>Ikigai</h2>
                  </div>
                ) : (
                  <div
                    className="yeartile ikigai"
                    onMouseLeave={() => {
                      setMyIkigaiHover((myIkigaiHover) => false);
                    }}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Ikigai</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <IkigaiIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      wrap="soft"
                      autoComplete="off"
                      value={myIkigai}
                      placeholder="My Ikigai is..."
                      onChange={handleChange_Ikigai}
                    />
                  </div>
                )}

                {continuationalHover === false && vw > 1024 ? (
                  <div
                    className="yeartile continuation active"
                    onMouseEnter={() => {
                      setContinuationalHover((continuationalHover) => true);
                    }}
                  >
                    <h2>Continuation</h2>
                  </div>
                ) : (
                  <div
                    className="yeartile continuation"
                    onMouseLeave={() => {
                      setContinuationalHover((continuationalHover) => false);
                    }}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Continuation</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <YearIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      id="cont"
                      className="formInput year"
                      wrap="soft"
                      autoComplete="off"
                      value={`Today is unique. It is day number ${continuation} in my life so far.`}
                      placeholder=""
                    />
                  </div>
                )}

                {navigationalQuoteHover === false && vw > 1024 ? (
                  <div
                    className="yeartile navquote active"
                    onMouseEnter={() => {
                      setNavigationalQuoteHover(
                        (navigationalQuoteHover) => true
                      );
                    }}
                  >
                    <h2>Quote</h2>
                  </div>
                ) : (
                  <div
                    className="yeartile navquote"
                    onMouseLeave={() => {
                      setNavigationalQuoteHover(
                        (navigationalQuoteHover) => false
                      );
                    }}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Quote</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuoteIconV2 />
                        </div>
                      </div>
                    </div>
                    <textarea
                      wrap="soft"
                      autoComplete="off"
                      value={navigationalQuote}
                      placeholder="'Live as if your actions could be made a rule for the world.'"
                      onChange={handleChange_Quote}
                    />
                  </div>
                )}

                {memoryHover === false && vw > 1024 ? (
                  <div
                    className="yeartile memory active"
                    onMouseEnter={() => {
                      setMemoryHover((memoryHover) => true);
                      pastDayFunc();
                    }}
                  >
                    <h2>Memories</h2>
                  </div>
                ) : (
                  //   <div className="yeartile memory"
                  //   onMouseLeave={()=>{
                  //     setMemoryHover(memoryHover => false);
                  //   }}>
                  //       <div className="tutorial access">
                  //       <label className="score_label"><strong>Memories</strong></label>
                  //       <FontAwesomeIcon className="icon" icon={faLandmarkDome} />
                  //       <div className="question_mark" >
                  //         <FontAwesomeIcon  icon={faQuestion} style={{color: '#004a22'}}/>
                  //       </div>
                  //       </div>
                  // { !days ? <></> : days
                  //         .filter((day, i, days) => days.indexOf(day) === Math.floor((Math.random() * days.length )))
                  //         .filter((day, i, days) => days.indexOf(day) === 0 )
                  //         .map((day, index, days) =>
                  //          <textarea
                  //          key={day._id[0]}
                  //           wrap="soft"
                  //           autoComplete="off"
                  //           value={CryptoJS.AES.decrypt(day.rememberToday, key).toString(CryptoJS.enc.Utf8)}
                  //           />)}
                  //   </div>

                  <div
                    className="yeartile memory"
                    onMouseLeave={() => {
                      setMemoryHover((memoryHover) => false);
                    }}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Memories</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <MemoryIcon />
                        </div>
                      </div>
                    </div>

                    <textarea
                      wrap="soft"
                      autoComplete="off"
                      value={pastDay}
                      readOnly
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </main>
      </form>
      <TestFooter />
    </>
  );
}
