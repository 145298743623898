import {
  DEBRIEFS_CREATE_FAIL,
  DEBRIEFS_CREATE_REQUEST,
  DEBRIEFS_CREATE_SUCCESS,
  DEBRIEFS_LIST_FAIL,
  DEBRIEFS_LIST_REQUEST,
  DEBRIEFS_LIST_SUCCESS,
  DEBRIEFS_UPDATE_FAIL,
  DEBRIEFS_UPDATE_REQUEST,
  DEBRIEFS_UPDATE_SUCCESS,
  DEBRIEFS_DELETE_FAIL,
  DEBRIEFS_DELETE_REQUEST,
  DEBRIEFS_DELETE_SUCCESS,
} from "../constants/debriefConstants";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

export const listDebriefs = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEBRIEFS_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`https://nwd-backend.herokuapp.com/api/debriefs/`, config);

    dispatch({
      type: DEBRIEFS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: DEBRIEFS_LIST_FAIL,
      payload: message,
    });
  }
};

export const createDebriefAction = (
  debriefName,
  debriefOutcome,
pic, 
poc,
nic,
noc,
) => async ( dispatch, getState ) => {
  try {
    dispatch({
      type: DEBRIEFS_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

 
    const { data } = await axios.post(
      `https://nwd-backend.herokuapp.com/api/debriefs/debrief`,
      {  
        debriefName,
        debriefOutcome,
        pic, 
        poc,
        nic,
        noc, },
      config
    ); 


    await dispatch({
      type: DEBRIEFS_CREATE_SUCCESS,
      payload: data,
    });

return data;
  } catch (error) {
    
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: DEBRIEFS_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateDebriefAction = (
  id,
  debriefName,
  debriefOutcome,
  pic, 
poc,
nic,
noc,
) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: DEBRIEFS_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `https://nwd-backend.herokuapp.com/api/debriefs/debrief/${id}`,
      {     debriefName,
        debriefOutcome,
        pic, 
        poc,
        nic,
        noc, },
      config
    );

    dispatch({
      type: DEBRIEFS_UPDATE_SUCCESS,
      payload: data,
    });

    return data;
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: DEBRIEFS_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const deleteDebriefAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEBRIEFS_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`https://nwd-backend.herokuapp.com/api/debriefs/debrief/${id}`, config);

    dispatch({
      type: DEBRIEFS_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: DEBRIEFS_DELETE_FAIL,
      payload: message,
    });
  }
};
