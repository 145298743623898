// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// Actions
import { createYearAction, listYears } from "../../actions/yearActions";

// Internal Components
import TileLoading from "../../components/Loading/TileLoading";
import CentralHeader from "../../components/Header/CentralHeader";
import MouseTooltip from "../../components/MouseTooltip/MouseTooltip";
import HideDock from "../../components/Dock/HideDock";
import PageLoading from "../../components/Loading/PageLoading";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import generateSecret from "../../utils/generateSecret";
import TestFooter from "../../components/Footer/TestFooter";
import {
  CreateDayIcon,
  CreateDayIconV2,
  ValuesIcon,
  NameIcon,
  NameIconV2,
  NameIconV3,
  NameIconV4,
  DobIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  YearIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  SaveIcon,
  TickIcon,
  PreviousDay,
  VitIcon,
  WeekIcon,
} from "../../components/Icons/Icon";

// External Packages
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignature,
  faDna,
  faCalendarDay,
  faArrowUp91,
  faQuoteLeft,
  faLandmarkDome,
  faCompassDrafting,
  faQuestion,
  faPenToSquare,
  faEraser,
  faGlasses,
} from "@fortawesome/free-solid-svg-icons";
import CryptoJS from "crypto-js";

// CSS
import "./yearscreen.css";

export default function CreateYearScreen({ history }) {
  const [yourName, setYourName] = useState("");
  const [birthDate, setBirthDate] = useState("2000-01-01");
  const [myValues_1, setMyValues_1] = useState(
    localStorage.Values_1 ? localStorage.Values_1 : ""
  );
  const [myValues_1_Text, setMyValues_1_Text] = useState(
    localStorage.Values_1_Text ? localStorage.Values_1_Text : ""
  );
  const [myValues_2, setMyValues_2] = useState(
    localStorage.Values_2 ? localStorage.Values_2 : ""
  );
  const [myValues_2_Text, setMyValues_2_Text] = useState(
    localStorage.Values_2_Text ? localStorage.Values_2_Text : ""
  );
  const [myValues_3, setMyValues_3] = useState(
    localStorage.Values_3 ? localStorage.Values_3 : ""
  );
  const [myValues_3_Text, setMyValues_3_Text] = useState(
    localStorage.Values_3_Text ? localStorage.Values_3_Text : ""
  );
  const [myValues_4, setMyValues_4] = useState(
    localStorage.Values_4 ? localStorage.Values_4 : ""
  );
  const [myValues_4_Text, setMyValues_4_Text] = useState(
    localStorage.Values_4_Text ? localStorage.Values_4_Text : ""
  );
  const [myValues_5, setMyValues_5] = useState(
    localStorage.Values_5 ? localStorage.Values_5 : ""
  );
  const [myValues_5_Text, setMyValues_5_Text] = useState(
    localStorage.Values_5_Text ? localStorage.Values_5_Text : ""
  );
  const [myVision_1, setMyVision_1] = useState("");
  const [myVision_2, setMyVision_2] = useState("");
  const [myVision_3, setMyVision_3] = useState("");
  const [myVision_4, setMyVision_4] = useState("");
  const [myVision_5, setMyVision_5] = useState("");
  const [whyNWD, setWhyNWD] = useState("");
  const [myIkigai, setMyIkigai] = useState("");
  const [navigationalQuote, setNavigationalQuote] = useState("");

  const [yourNameHover, setYourNameHover] = useState(false);
  const handleHoverIn = () => {
    setYourNameHover((yourNameHover) => true);
  };
  const handleHoverOut = () => {
    setYourNameHover((yourNameHover) => false);
  };

  const [birthDateHover, setBirthDateHover] = useState(false);
  const handleHoverInDOB = () => {
    setBirthDateHover((birthDateHover) => true);
  };
  const handleHoverOutDOB = () => {
    setBirthDateHover((birthDateHover) => false);
  };

  const [myValuesHover, setMyValuesHover] = useState(false);

  const handleHoverInValues = () => {
    setMyValuesHover((myValuesHover) => true);
  };
  const handleHoverOutValues = () => {
    setMyValuesHover((myValuesHover) => false);
  };

  const [myVisionHover, setMyVisionHover] = useState(false);
  const handleHoverInVision = () => {
    setMyVisionHover((myVisionHover) => true);
  };
  const handleHoverOutVision = () => {
    setMyVisionHover((myVisionHover) => false);
  };

  const [myIkigaiHover, setMyIkigaiHover] = useState(false);
  const handleHoverInIkigai = () => {
    setMyIkigaiHover((myIkigaiHover) => true);
  };
  const handleHoverOutIkigai = () => {
    setMyIkigaiHover((myIkigaiHover) => false);
  };

  const [navigationalQuoteHover, setNavigationalQuoteHover] = useState(false);
  const handleHoverInNavigationalQuote = () => {
    setNavigationalQuoteHover((navigationalQuoteHover) => true);
  };
  const handleHoverOutNavigationalQuote = () => {
    setNavigationalQuoteHover((navigationalQuoteHover) => false);
  };

  const [continuationalHover, setContinuationalHover] = useState(false);
  const handleHoverInContinuational = () => {
    setContinuationalHover((continuationalHover) => true);
  };
  const handleHoverOutContinuational = () => {
    setContinuationalHover((continuationalHover) => false);
  };

  const [memoryHover, setMemoryHover] = useState(false);

  const [videoDisplay, setVideoDisplay] = useState(false);
  const [videoLink, setVideoLink] = useState("N-lf8NgD6-o");

  const [pageLoading, setPageLoading] = useState(true);

  const [lpCursorState, setLpCursorState] = useState(
    "A Truly Modern Mindfulness Journal"
  );

  const [cursorState, setCursorState] = useState("");
  const [cursorDisplayState, setCursorDisplayState] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearCreate = useSelector((state) => state.yearCreate); //
  const { loading, error, years } = yearCreate;

  const submitHandler = () => {
    const key = generateSecret().toString();

    dispatch(
      createYearAction(
        CryptoJS.AES.encrypt(yourName, key).toString(),
        birthDate,
        CryptoJS.AES.encrypt(myValues_1, key).toString(),
        CryptoJS.AES.encrypt(myValues_1_Text, key).toString(),
        CryptoJS.AES.encrypt(myValues_2, key).toString(),
        CryptoJS.AES.encrypt(myValues_2_Text, key).toString(),
        CryptoJS.AES.encrypt(myValues_3, key).toString(),
        CryptoJS.AES.encrypt(myValues_3_Text, key).toString(),
        CryptoJS.AES.encrypt(myValues_4, key).toString(),
        CryptoJS.AES.encrypt(myValues_4_Text, key).toString(),
        CryptoJS.AES.encrypt(myValues_5, key).toString(),
        CryptoJS.AES.encrypt(myValues_5_Text, key).toString(),
        CryptoJS.AES.encrypt(myVision_1, key).toString(),
        CryptoJS.AES.encrypt(myVision_2, key).toString(),
        CryptoJS.AES.encrypt(myVision_3, key).toString(),
        CryptoJS.AES.encrypt(myVision_4, key).toString(),
        CryptoJS.AES.encrypt(myVision_5, key).toString(),
        CryptoJS.AES.encrypt(whyNWD, key).toString(),
        CryptoJS.AES.encrypt(myIkigai, key).toString(),
        CryptoJS.AES.encrypt(navigationalQuote, key).toString()
      )
    )
      .then((response) => {
        if (response) {
          let id = response._id;
          navigate(`/year/${response._id}`);
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const valueState = {
    hide: {
      display: "none",
    },
    show: {
      display: "block",
    },
  };

  const loadingTimeout = () => {
    var randNum = Math.random(1) * 3000 + Math.random(1) * 3000 + 6000;
    setTimeout(() => {
      setPageLoading(false);
    }, randNum);
  };

  const showHideStateTwo = {
    show: {
      opacity: 1,
      transition: {
        duration: 4,
        ease: "easeInOut",
      },
    },
    hide: {
      opacity: 0,
      transition: {
        duration: "0.05",
        ease: "easeOut",
      },
    },
  };

  const showHideState = {
    show: {
      height: "auto",
      padding: "8px",
      borderRadius: "0 0 0 0",
      opacity: 1,
      backgroundColor: "rgba(0, 74, 35,1)",
      color: "rgba(226, 223, 207,1)",
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    hide: {
      height: "0px",
      opacity: 0,
      padding: "0px",
      borderRadius: "0 0 0 0",
      backgroundColor: "rgba(0, 74, 35,0)",
      color: "rgba(226, 223, 207,0)",
      transition: {
        duration: 1,
        ease: "easeOut",
      },
    },
  };

  const [helpStateIntro, setHelpStateIntro] = useState(false);
  const [helpStateName, setHelpStateName] = useState(false);
  const [helpStateDOB, setHelpStateDOB] = useState(false);
  const [helpStateVision, setHelpStateVision] = useState(false);
  const [helpStateValues, setHelpStateValues] = useState(false);
  const [helpStateIki, setHelpStateIki] = useState(false);
  const [helpStateQuote, setHelpStateQuote] = useState(false);
  const [helpStateCont, setHelpStateCont] = useState(false);
  const [helpStateMemory, setHelpStateMemory] = useState(false);

  useEffect(() => {
    loadingTimeout();
  });

  const cursorDisplayToggle = () => {
    setCursorDisplayState((cursorDisplayState) => !cursorDisplayState);
  };

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (vw > 1024) {
        setIsVisible(window.scrollY <= 0);
      } else {
        setIsVisible(window.scrollY <= 1000);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChange_Name = (e) => {
    setYourName(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_One = (e) => {
    setMyValues_1(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_One_Text = (e) => {
    setMyValues_1_Text(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Two = (e) => {
    setMyValues_2(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Two_Text = (e) => {
    setMyValues_2_Text(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Three = (e) => {
    setMyValues_3(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Three_Text = (e) => {
    setMyValues_3_Text(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Four = (e) => {
    setMyValues_4(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Four_Text = (e) => {
    setMyValues_4_Text(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Five = (e) => {
    setMyValues_5(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Values_Five_Text = (e) => {
    setMyValues_5_Text(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Vision_One = (e) => {
    setMyVision_1(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Vision_Two = (e) => {
    setMyVision_2(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Vision_Three = (e) => {
    setMyVision_3(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Vision_Four = (e) => {
    setMyVision_4(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Vision_Five = (e) => {
    setMyVision_5(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Ikigai = (e) => {
    setMyIkigai(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_Quote = (e) => {
    setNavigationalQuote(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  return (
    <>
      <>
        <Helmet>
          <title>Create | Year</title>
        </Helmet>
      </>
      <CentralHeader />
      <form onSubmit={submitHandler}>
        <main className="yearContainer">
          <>
            <motion.div
              className="dock--container"
              initial={{ opacity: 1 }}
              animate={{ opacity: isVisible ? 1 : 0 }}
              transition={{ duration: 0.3 }}
              style={{
                zIndex: 999,
              }}
            >
              <HideDock
                toggleOverlay={cursorDisplayToggle}
                saveYear={submitHandler}
              />
            </motion.div>
            <div id="yeartest">
              {pageLoading ? (
                <div
                  style={{
                    height: "50vh",
                    width: "100vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TileLoading />
                </div>
              ) : (
                <>
                  <div className="mobileHeader">
                    <div
                      className="tutorial access"
                      style={{ alignItems: "flex-start" }}
                    >
                      <h2
                        style={{
                          color: "#e2dfcf",
                          padding: "0",
                          marginBottom: "12px",
                        }}
                      >
                        The Year Map
                      </h2>
                      <div
                        className="question_mark"
                        onClick={() => {
                          setHelpStateIntro(
                            (helpStateIntro) => !helpStateIntro
                          );
                        }}
                      >
                        <div className="newIcon">
                          <QuestionIcon style={{ color: "#e2dfcf" }} />
                        </div>
                      </div>
                    </div>

                    <motion.div
                      className="helpText"
                      variants={showHideState}
                      initial="hide"
                      animate={helpStateIntro ? "show" : "hide"}
                      viewport={{ onViewportLeave: "hide" }}
                    >
                      <motion.p
                        initial={{ opacity: 0 }}
                        whileInView={{
                          opacity: 1,
                          transition: { duration: 3, ease: "easeOut" },
                        }}
                      >
                        One of the trickiest things about thinking clearly is
                        keeping what really matters at the very front of your
                        mind. The Year map is built to help with this issue.{" "}
                      </motion.p>
                      <motion.p
                        initial={{ opacity: 0 }}
                        whileInView={{
                          opacity: 1,
                          transition: { duration: 3, ease: "easeOut" },
                        }}
                      >
                        Firstly, it provides a space to collect a lot of very
                        helpful pieces of thinking which, if available at the
                        front of your mind when making daily plans and
                        decisions, can make for much happier, healthier days.
                      </motion.p>
                      <motion.p
                        initial={{ opacity: 0 }}
                        whileInView={{
                          opacity: 1,
                          transition: { duration: 3, ease: "easeOut" },
                        }}
                      >
                        If you need any help with thinking about any one of
                        them, try clicking on the ? or using the interactive
                        experiences I am adding.
                      </motion.p>
                      <motion.p
                        initial={{ opacity: 0 }}
                        whileInView={{
                          opacity: 1,
                          transition: { duration: 3, ease: "easeOut" },
                        }}
                      >
                        After you have added these ideas to your year map, you
                        can access them through the dock on your year and diary
                        pages to ensure they are there when you need them!
                      </motion.p>
                      <motion.p>
                        Also, don't worry if you can't fill in all of the parts
                        of the map now. They are very powerful as a complete
                        set, but can be added one at a time over the coming
                        weeks and months with more knowledge and confidence.
                      </motion.p>
                    </motion.div>
                  </div>

                  {yourNameHover === false && vw > 1024 ? (
                    <div
                      className="yeartile yn active"
                      onMouseEnter={handleHoverIn}
                    >
                      <h2>Name</h2>
                    </div>
                  ) : (
                    <div
                      className="yeartile yn"
                      data-tooltip="Your Name"
                      onMouseLeave={handleHoverOut}
                    >
                      <div className="tutorial access">
                        <div className="iconDiv">
                          <div className="newIcon">
                            <VitIcon />
                          </div>
                          <label className="score_label">
                            <strong>Name</strong>
                          </label>
                        </div>
                        <div className="icon_div">
                          <div
                            className="question_mark icon"
                            onClick={() => {
                              setHelpStateName(
                                (helpStateName) => !helpStateName
                              );
                            }}
                          >
                            <div className="newIcon">
                              <QuestionIcon style={{ color: "#e2dfcf" }} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <motion.div
                        className="helpText"
                        variants={showHideState}
                        initial="hide"
                        animate={helpStateName ? "show" : "hide"}
                        viewport={{ onViewportLeave: "hide" }}
                      >
                        <motion.span
                          initial={{ opacity: 0 }}
                          whileInView={{
                            opacity: 1,
                            transition: { duration: 3, ease: "easeOut" },
                          }}
                        >
                          Adding your name helps keep your unique point of view
                          and personal journey front of mind.
                        </motion.span>
                      </motion.div>

                      <textarea
                        wrap="soft"
                        autoComplete="off"
                        value={yourName}
                        placeholder="John Doer"
                        onChange={handleChange_Name}
                      />
                    </div>
                  )}

                  {birthDateHover === false && vw > 1024 ? (
                    <div
                      className="yeartile dob active"
                      onMouseEnter={handleHoverInDOB}
                    >
                      <h2>D.O.B</h2>
                    </div>
                  ) : (
                    <div
                      className="yeartile dob"
                      onMouseLeave={handleHoverOutDOB}
                    >
                      <div className="tutorial access">
                        <div className="iconDiv">
                          <div className="newIcon">
                            <DobIcon />
                          </div>
                          <label className="score_label">
                            <strong>D.O.B.</strong>
                          </label>
                        </div>
                        <div className="icon_div">
                          <div
                            className="question_mark icon"
                            onClick={() => {
                              setHelpStateDOB((helpStateDOB) => !helpStateDOB);
                            }}
                          >
                            <div className="newIcon">
                              <QuestionIcon style={{ color: "#e2dfcf" }} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <motion.div
                        className="helpText"
                        variants={showHideState}
                        initial="hide"
                        animate={helpStateDOB ? "show" : "hide"}
                        viewport={{ onViewportLeave: "hide" }}
                      >
                        <motion.p
                          initial={{ opacity: 0 }}
                          whileInView={{
                            opacity: 1,
                            transition: { duration: 3, ease: "easeOut" },
                          }}
                        >
                          Your date of birth allows you remember that your life
                          has both a start date and end date.
                        </motion.p>
                        <motion.p
                          initial={{ opacity: 0 }}
                          whileInView={{
                            opacity: 1,
                            transition: { duration: 3, ease: "easeOut" },
                          }}
                        >
                          Identifying with both points unlocks a lot of wisdom.
                          Learn more about this by clicking{" "}
                          <a
                            href={"https://youtu.be/Jbt6VGOYU3E"}
                            target="_blank"
                          >
                            here
                          </a>
                        </motion.p>
                      </motion.div>

                      <input
                        className="dobInput"
                        style={{ zIndex: "1000" }}
                        type="date"
                        value={birthDate}
                        placeholder=""
                        onChange={(e) => setBirthDate(e.target.value)}
                      />
                    </div>
                  )}

                  {myValuesHover === false && vw > 1024 ? (
                    <div
                      className="yeartile values active"
                      onMouseEnter={handleHoverInValues}
                    >
                      <h2>Values</h2>
                    </div>
                  ) : (
                    <div
                      className="yeartile values"
                      onMouseLeave={handleHoverOutValues}
                    >
                      <div className="tutorial access">
                        <div className="iconDiv">
                          <div className="newIcon">
                            <ValuesIcon />
                          </div>
                          <label className="score_label">
                            <strong>Values</strong>
                          </label>
                        </div>
                        <div className="icon_div">
                          <div
                            className="question_mark icon"
                            onClick={() => {
                              setHelpStateValues(
                                (helpStateValues) => !helpStateValues
                              );
                            }}
                          >
                            <div className="newIcon">
                              <QuestionIcon style={{ color: "#e2dfcf" }} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <motion.div
                        className="helpText"
                        variants={showHideState}
                        initial="hide"
                        animate={helpStateValues ? "show" : "hide"}
                        whileInView={{
                          opacity: 1,
                          transition: { duration: 3, ease: "easeOut" },
                        }}
                      >
                        <motion.p>
                          We all have a value system that we move through the
                          world with but rarely, if ever, write it down.
                        </motion.p>
                        <motion.p>
                          Once written, we can better use it as a filter for our
                          plans and decisions and, in doing so, improve their
                          quality and integrity.
                        </motion.p>
                        <motion.p>
                          Thinking about your values can be really tricky. If
                          you need any help, I have create a little walkthrough
                          process that, step-by-step, will help you think about
                          what your values are.
                        </motion.p>
                        <motion.div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <span
                            style={{ color: "#e2dfcf", marginRight: "6px" }}
                          >
                            Click the Button
                          </span>
                          <motion.a
                            href={"/values"}
                            style={{ color: "#e2dfcf" }}
                          >
                            <motion.div
                              className={`dock node tutorial`}
                              style={{
                                transform: "scale(0.75)",
                                backgroundColor: "#e2dfcf",
                                margin: 0,
                                padding: 0,
                              }}
                              variants={showHideStateTwo}
                              initial="hide"
                              animate={helpStateValues ? "show" : "hide"}
                            >
                              <div className="newIcon">
                                <ValuesIcon />
                              </div>
                            </motion.div>
                          </motion.a>
                        </motion.div>
                      </motion.div>

                      <div className="valuesRow">
                        <div className="valuesDiv">
                          <input
                            type="text"
                            wrap="soft"
                            autoComplete="off"
                            value={
                              localStorage.Values_1
                                ? localStorage.Values_1
                                : myValues_1
                            }
                            placeholder="Effort"
                            onChange={handleChange_Values_One}
                          />
                          <textarea
                            wrap="soft"
                            autoComplete="off"
                            value={
                              localStorage.Values_1_Text
                                ? localStorage.Values_1_Text
                                : myValues_1_Text
                            }
                            placeholder="Giving my best at every opportunity"
                            onChange={handleChange_Values_One_Text}
                          />
                        </div>

                        <div className="valuesDiv">
                          <input
                            type="text"
                            wrap="soft"
                            autoComplete="off"
                            value={
                              localStorage.Values_2
                                ? localStorage.Values_2
                                : myValues_2
                            }
                            placeholder="Respect"
                            onChange={handleChange_Values_Two}
                          />
                          <textarea
                            wrap="soft"
                            autoComplete="off"
                            value={
                              localStorage.Values_2_Text
                                ? localStorage.Values_2_Text
                                : myValues_2_Text
                            }
                            placeholder="Treat everyone as an equal"
                            onChange={handleChange_Values_Two_Text}
                          />
                        </div>
                        <div className="valuesDiv">
                          <input
                            type="text"
                            wrap="soft"
                            autoComplete="off"
                            value={
                              localStorage.Values_3
                                ? localStorage.Values_3
                                : myValues_3
                            }
                            placeholder="Vision"
                            onChange={handleChange_Values_Three}
                          />
                          <textarea
                            wrap="soft"
                            autoComplete="off"
                            value={
                              localStorage.Values_3_Text
                                ? localStorage.Values_3_Text
                                : myValues_3_Text
                            }
                            placeholder="Make the difference that is written on my bones"
                            onChange={handleChange_Values_Three_Text}
                          />
                        </div>
                        <div className="valuesDiv">
                          <input
                            type="text"
                            wrap="soft"
                            autoComplete="off"
                            value={
                              localStorage.Values_4
                                ? localStorage.Values_4
                                : myValues_4
                            }
                            placeholder="Character"
                            onChange={handleChange_Values_Four}
                          />
                          <textarea
                            wrap="soft"
                            autoComplete="off"
                            value={
                              localStorage.Values_4_Text
                                ? localStorage.Values_4_Text
                                : myValues_4_Text
                            }
                            placeholder="Resolute in adversity, composed in victory"
                            onChange={handleChange_Values_Four_Text}
                          />
                        </div>
                        <div className="valuesDiv">
                          <input
                            type="text"
                            wrap="soft"
                            autoComplete="off"
                            value={
                              localStorage.Values_5
                                ? localStorage.Values_5
                                : myValues_5
                            }
                            placeholder="Love"
                            onChange={handleChange_Values_Five}
                          />
                          <textarea
                            wrap="soft"
                            autoComplete="off"
                            value={
                              localStorage.Values_5_Text
                                ? localStorage.Values_5_Text
                                : myValues_5_Text
                            }
                            placeholder="Unconditionally support other people"
                            onChange={handleChange_Values_Five_Text}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {myVisionHover === false && vw > 1024 ? (
                    <div
                      className="yeartile vision active"
                      onMouseEnter={handleHoverInVision}
                    >
                      <h2>Vision</h2>
                    </div>
                  ) : (
                    <div
                      className="yeartile vision"
                      onMouseLeave={handleHoverOutVision}
                    >
                      <div className="tutorial access">
                        <div className="iconDiv">
                          <div className="newIcon">
                            <VisionIcon />
                          </div>
                          <label className="score_label">
                            <strong>Vision</strong>
                          </label>
                        </div>
                        <div className="icon_div">
                          <div
                            className="question_mark icon"
                            onClick={() => {
                              setHelpStateVision(
                                (helpStateVision) => !helpStateVision
                              );
                            }}
                          >
                            <div className="newIcon">
                              <QuestionIcon style={{ color: "#e2dfcf" }} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <motion.div
                        className="helpText"
                        variants={showHideState}
                        initial="hide"
                        animate={helpStateVision ? "show" : "hide"}
                        whileInView={{
                          opacity: 1,
                          transition: { duration: 3, ease: "easeOut" },
                        }}
                      >
                        <motion.p>
                          Clearly describing your vision(s) is an invaluable
                          step towards making it much easier to achieve.
                        </motion.p>
                        <motion.p>
                          Most people have some experience with goal setting and
                          having a vision, however, we will soon be putting
                          together a step-by-step walkthrough, like the values
                          one, for crafting a great vision set.
                        </motion.p>
                        <motion.p>
                          If you have any suggestions about what this could or
                          should include, please feel free to message using out
                          contact form or on social media (@NoWastedDays on
                          Instagram, YouTube and TikTok){" "}
                        </motion.p>
                      </motion.div>

                      <div className="valuesRow">
                        <div className="valuesDiv">
                          <textarea
                            wrap="soft"
                            autoComplete="off"
                            value={myVision_1}
                            placeholder="Run a Marathon"
                            onChange={handleChange_Vision_One}
                          />
                        </div>
                        <div className="valuesDiv">
                          <textarea
                            wrap="soft"
                            autoComplete="off"
                            value={myVision_2}
                            placeholder="Learn a language"
                            onChange={handleChange_Vision_Two}
                          />
                        </div>
                        <div className="valuesDiv">
                          <textarea
                            wrap="soft"
                            autoComplete="off"
                            value={myVision_3}
                            placeholder="Meditate once per day"
                            onChange={handleChange_Vision_Three}
                          />
                        </div>
                        <div className="valuesDiv">
                          <textarea
                            wrap="soft"
                            autoComplete="off"
                            value={myVision_4}
                            placeholder="Finish my degree"
                            onChange={handleChange_Vision_Four}
                          />
                        </div>
                        <div className="valuesDiv">
                          <textarea
                            wrap="soft"
                            autoComplete="off"
                            value={myVision_5}
                            placeholder="Be a better friend"
                            onChange={handleChange_Vision_Five}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {myIkigaiHover === false && vw > 1024 ? (
                    <div
                      className="yeartile ikigai active"
                      onMouseEnter={() => {
                        setMyIkigaiHover((myIkigaiHover) => true);
                      }}
                    >
                      <h2>Ikigai</h2>
                    </div>
                  ) : (
                    <div
                      className="yeartile ikigai"
                      onMouseLeave={() => {
                        setMyIkigaiHover((myIkigaiHover) => false);
                      }}
                    >
                      <div className="tutorial access">
                        <div className="iconDiv">
                          <div className="newIcon">
                            <IkigaiIcon />
                          </div>
                          <label className="score_label">
                            <strong>Ikigai</strong>
                          </label>
                        </div>
                        <div className="icon_div">
                          <div
                            className="question_mark icon"
                            onClick={() => {
                              setHelpStateIki((helpStateIki) => !helpStateIki);
                            }}
                          >
                            <div className="newIcon">
                              <QuestionIcon style={{ color: "#e2dfcf" }} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <motion.div
                        className="helpText"
                        variants={showHideState}
                        initial="hide"
                        animate={helpStateIki ? "show" : "hide"}
                        whileInView={{
                          opacity: 1,
                          transition: { duration: 3, ease: "easeOut" },
                        }}
                      >
                        <motion.p>
                          A japanese concept often translated as your reason for
                          being.
                        </motion.p>
                        <motion.p>
                          Having and renewing your ikigai is strongly correlated
                          with greater happiness and longevity.
                        </motion.p>
                      </motion.div>

                      <textarea
                        wrap="soft"
                        autoComplete="off"
                        value={myIkigai}
                        placeholder="Live, laugh, love"
                        onChange={handleChange_Ikigai}
                      />
                    </div>
                  )}

                  {continuationalHover === false && vw > 1024 ? (
                    <div
                      className="yeartile continuation active"
                      onMouseEnter={() => {
                        setContinuationalHover((continuationalHover) => true);
                      }}
                    >
                      <h2>Continuation</h2>
                    </div>
                  ) : (
                    <div
                      className="yeartile continuation"
                      onMouseLeave={() => {
                        setContinuationalHover((continuationalHover) => false);
                      }}
                    >
                      <div className="tutorial access">
                        <div className="iconDiv">
                          <div className="newIcon">
                            <FontAwesomeIcon
                              className="icon"
                              icon={faArrowUp91}
                            />
                          </div>

                          <label className="score_label">
                            <strong>Continuation</strong>
                          </label>
                        </div>
                        <div className="icon_div">
                          <div
                            className="question_mark icon"
                            onClick={() => {
                              setHelpStateCont(
                                (helpStateCont) => !helpStateCont
                              );
                            }}
                          >
                            <div className="newIcon">
                              <QuestionIcon style={{ color: "#e2dfcf" }} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <motion.div
                        className="helpText"
                        variants={showHideState}
                        initial="hide"
                        animate={helpStateCont ? "show" : "hide"}
                        whileInView={{
                          opacity: 1,
                          transition: { duration: 3, ease: "easeOut" },
                        }}
                      >
                        <motion.p>
                          Every day in your life is completely unique, yet
                          modern life makes it seem as if we live the same days
                          over and over again.
                        </motion.p>
                        <motion.p>
                          Seeing the unique number that today represents in your
                          journey helps you treat it more like the unique and
                          special thing it really is.
                        </motion.p>
                      </motion.div>

                      <textarea
                        id="cont"
                        className="formInput year"
                        wrap="soft"
                        autoComplete="off"
                        value={`Everyday is unique. Your unique day number will appear here once you have saved your year map.`}
                        placeholder=""
                      />
                    </div>
                  )}

                  {navigationalQuoteHover === false && vw > 1024 ? (
                    <div
                      className="yeartile navquote active"
                      onMouseEnter={() => {
                        setNavigationalQuoteHover(
                          (navigationalQuoteHover) => true
                        );
                      }}
                    >
                      <h2>Quote</h2>
                    </div>
                  ) : (
                    <div
                      className="yeartile navquote"
                      onMouseLeave={() => {
                        setNavigationalQuoteHover(
                          (navigationalQuoteHover) => false
                        );
                      }}
                    >
                      <div className="tutorial access">
                        <div className="iconDiv">
                          <div className="newIcon">
                            <QuoteIconV2 />
                          </div>
                          <label className="score_label">
                            <strong>Quote</strong>
                          </label>
                        </div>
                        <div className="icon_div">
                          <div
                            className="question_mark icon"
                            onClick={() => {
                              setHelpStateQuote(
                                (helpStateQuote) => !helpStateQuote
                              );
                            }}
                          >
                            <div className="newIcon">
                              <QuestionIcon style={{ color: "#e2dfcf" }} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <motion.div
                        className="helpText"
                        variants={showHideState}
                        initial="hide"
                        animate={helpStateQuote ? "show" : "hide"}
                        whileInView={{
                          opacity: 1,
                          transition: { duration: 3, ease: "easeOut" },
                        }}
                      >
                        <motion.span>
                          Quotes can be fantastic tools for providing short to
                          medium term focus and enthusiasm.
                        </motion.span>
                      </motion.div>

                      <textarea
                        wrap="soft"
                        autoComplete="off"
                        value={navigationalQuote}
                        placeholder="'Live as if your actions could be made a rule for the world'"
                        onChange={handleChange_Quote}
                      />
                    </div>
                  )}

                  {memoryHover === false && vw > 1024 ? (
                    <div
                      className="yeartile memory active"
                      onMouseEnter={() => {
                        setMemoryHover((memoryHover) => true);
                      }}
                    >
                      <h2>Memories</h2>
                    </div>
                  ) : (
                    <div
                      className="yeartile memory"
                      onMouseLeave={() => {
                        setMemoryHover((memoryHover) => false);
                      }}
                    >
                      <div className="tutorial access">
                        <div className="iconDiv">
                          <div className="newIcon">
                            <MemoryIcon />
                          </div>
                          <label className="score_label">
                            <strong>Memories</strong>
                          </label>
                        </div>
                        <div className="icon_div">
                          <div
                            className="question_mark icon"
                            onClick={() => {
                              setHelpStateMemory(
                                (helpStateMemory) => !helpStateMemory
                              );
                            }}
                          >
                            <div className="newIcon">
                              <QuestionIcon style={{ color: "#e2dfcf" }} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <motion.div
                        className="helpText"
                        variants={showHideState}
                        initial="hide"
                        animate={helpStateMemory ? "show" : "hide"}
                        whileInView={{
                          opacity: 1,
                          transition: { duration: 3, ease: "easeOut" },
                        }}
                      >
                        <motion.p>
                          Every time you load it up, your year map will show you
                          a random memory from your diary.
                        </motion.p>
                        <motion.p>
                          This allows you to easily be inspired by days from the
                          past.
                        </motion.p>
                      </motion.div>
                      <textarea
                        wrap="soft"
                        autoComplete="off"
                        placeholder="The things you remember each day will randomly appear to guide and inspire you once you have saved your first year map."
                        readOnly
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        </main>
      </form>
      <TestFooter />
    </>
  );
}
