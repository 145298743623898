import React from 'react';
import { Page, Text, Document, StyleSheet, View, Font} from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#ffffff',
      },
      column: {
        flex: 1,
        margin: 12,
      },
    title: {
      fontSize: 14,
      margin: 12,
      fontFamily: 'Oswald'
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 14,
      fontFamily: 'Oswald'
    },
    text: {
      marginTop: 20,
      fontSize: 10,
      textAlign: 'start',
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
      opacity: '0.5',
    },
    signature: {
      position: 'absolute',
      fontSize: 8,
      bottom: 30,
      left: 24,
      textAlign: 'start',
      color: 'grey',
      opacity: '0.5',
    },
  });

const PrintableContent = ({ posIn, posOut, negIn, negOut, name, aim }) => (
  <Document author="me" >
    <Page size="A4" orientation='landscape'>
    <Text style={styles.title}>Name: {name}</Text>
    <Text style={styles.title}>I want to understand the following more clearly and easily: {aim}</Text>
    <View style={styles.page}>
    <View style={styles.column}>
    <Text style={styles.subtitle}>Positive & Inside Your Control</Text>
      <Text style={styles.text}>{posIn}</Text>
      </View>
      <View style={styles.column}>
      <Text style={styles.subtitle}>Positive & Outside Your Control</Text>
      <Text style={styles.text}>{posOut}</Text>
      </View>
      <View style={styles.column}>
      <Text style={styles.subtitle}>Negative & Inside Your Control</Text>
      <Text style={styles.text}>{negIn}</Text>
      </View>
      <View style={styles.column}>
      <Text style={styles.subtitle}>Negative & Outside Your Control</Text>
      <Text style={styles.text}>{negOut}</Text>
      </View>
     
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
          <Text style={styles.signature} fixed >https://www.nowasteddays.io / @nowasteddays</Text>
    </Page>
  </Document>
);

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

export default PrintableContent;