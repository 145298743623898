const zeroRemove = (array) => {
    let lastItem = array.length - 1;
  
    if ( array[lastItem] === undefined || isNaN(array[lastItem])) {
      return array.pop();
    }

    if ( array[lastItem].es || array[lastItem].endScore) {

      if (   array[lastItem].es === undefined || 
        isNaN(array[lastItem].es) || 
        array[lastItem].endScore === undefined || 
        isNaN(array[lastItem].endScore)){
          return array.pop();
        }

    } else if (   array[lastItem].total ) {
        if (   array[lastItem].total === undefined || 
          isNaN(array[lastItem].total)) {
            return array.pop();
          }
      } else if ( array[lastItem].tcr) {
        if (   array[lastItem].tcr === undefined || 
          isNaN(array[lastItem].tcr)) {
            return array.pop();
          }
      } else if (array[lastItem].wave) {
        if (
   
          array[lastItem].wave === undefined || 
          isNaN(array[lastItem].wave) 
          ) {
            return array.pop();
          }
      } else {
      return array;
    }
  }

  export default zeroRemove;