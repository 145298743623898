// React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

// Actions
import { listYears } from "../../actions/yearActions";
import { listDays } from "../../actions/dayActions";

// Internal Components
import dateFormatter from "../../components/Functions/DateFormatter";
import {
  createWeekAction,
  listWeeks,
  updateWeekAction,
} from "../../actions/weekActions";
import Objective from "../../components/Week/Objective";
import TileLoading from "../../components/Loading/TileLoading";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import CentralHeader from "../../components/Header/CentralHeader";
import MouseTooltip from "../../components/MouseTooltip/MouseTooltip";
import DisabledDock from "../../components/Dock/DisabledDock";
import HideDock from "../../components/Dock/HideDock";
import generateSecret from "../../utils/generateSecret";
import TestFooter from "../../components/Footer/TestFooter";
import MidDock from "../../components/Dock/MidDock";

// External Packages
import axios from "axios";
import { Helmet } from "react-helmet";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlasses,
  faCompassDrafting,
  faChartLine,
  faEarthAmericas,
  faCalendarPlus,
  faBook,
  faCalendarDays,
  faSignature,
  faLandmarkDome,
  faDna,
  faQuoteLeft,
  faBars,
  faCalendarWeek,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import CryptoJS from "crypto-js";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./Dropable";

// Styling
import "./weeksscreen.css";

export default function ViewWeekScreen({ history }) {
  // Form State
  const [objectives, setObjectives] = useState();
  const [objectiveOne_score, setObjectiveOne_score] = useState();
  const [objectiveTwo_score, setObjectiveTwo_score] = useState();
  const [objectiveThree_score, setObjectiveThree_score] = useState();
  const [objectiveFour_score, setObjectiveFour_score] = useState();
  const [objectiveFive_score, setObjectiveFive_score] = useState();
  const [objectiveSix_score, setObjectiveSix_score] = useState();
  const [objectiveSeven_score, setObjectiveSeven_score] = useState();
  const [objectiveEight_score, setObjectiveEight_score] = useState();
  const [objectiveNine_score, setObjectiveNine_score] = useState();
  const [objectiveTen_score, setObjectiveTen_score] = useState();

  const [pageLoading, setPageLoading] = useState(true);
  const [cursorDisplayState, setCursorDisplayState] = useState(false);
  const [cursorState, setCursorState] = useState("");
  const [tutorialState, setTutorialState] = useState("tutorial");
  const [logoState, setLogoState] = useState(faEarthAmericas);
  const [saved, setSaved] = useState(false);

  // const [callout, setCallout] = useState(false);
  // const [index, setIndex] = useState(0);

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const weekList = useSelector((state) => state.weekList);
  const { loading, error, weeks } = weekList;

  const weekUpdate = useSelector((state) => state.weekUpdate);
  const { success: successUpdate } = weekUpdate;

  useEffect(() => {
    dispatch(listWeeks());
  }, [dispatch, userInfo, history]);

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const dayList = useSelector((state) => state.dayList); //
  const { days } = dayList;

  useEffect(() => {
    dispatch(listYears());
  }, [dispatch, userInfo, history]);

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo, history]);

  useEffect(() => {
    const fetching = async () => {
      const key = generateSecret().toString();
      try {
        const { data } = await axios.get(
          `https://nwd-backend.herokuapp.com/api/weeks/week/${id}`
        );
        setObjectives(
          JSON.parse(
            CryptoJS.AES.decrypt(data.objectives, key).toString(
              CryptoJS.enc.Utf8
            )
          )
        );
        setObjectiveOne_score(Number(data.objectiveOne_score));
        setObjectiveTwo_score(Number(data.objectiveTwo_score));
        setObjectiveThree_score(Number(data.objectiveThree_score));
        setObjectiveFour_score(Number(data.objectiveFour_score));
        setObjectiveFive_score(Number(data.objectiveFive_score));
        setObjectiveSix_score(Number(data.objectiveSix_score));
        setObjectiveSeven_score(Number(data.objectiveSeven_score));
        setObjectiveEight_score(Number(data.objectiveEight_score));
        setObjectiveNine_score(Number(data.objectiveNine_score));
        setObjectiveTen_score(Number(data.objectiveTen_score));
      } catch (error) {
        console.log(error);
      }
    };
    fetching();
  }, [id]);

  const logoObj = {
    name: faSignature,
    dob: faCalendarDays,
    values: faCompassDrafting,
    vision: faGlasses,
    ikigai: faDna,
    memories: faLandmarkDome,
    quote: faQuoteLeft,
  };

  const updateHandler = (e) => {
    // e.preventDefault();
    const key = generateSecret().toString();
    try {
      dispatch(
        updateWeekAction(
          id,
          CryptoJS.AES.encrypt(JSON.stringify(objectives), key).toString(),
          Number(objectiveOne_score).toString(),
          Number(objectiveTwo_score).toString(),
          Number(objectiveThree_score).toString(),
          Number(objectiveFour_score).toString(),
          Number(objectiveFive_score).toString(),
          Number(objectiveSix_score).toString(),
          Number(objectiveSeven_score).toString(),
          Number(objectiveEight_score).toString(),
          Number(objectiveNine_score).toString(),
          Number(objectiveTen_score).toString()
        )
      ).then((response) => {
        if (response) {
          console.log(response);
          setSaved(true);
        } else {
          console.log("something still not right");
        }
      });
    } catch (error) {
      console.error(error);
    }

    if (
      !objectives ||
      !objectiveOne_score ||
      !objectiveTwo_score ||
      !objectiveThree_score ||
      !objectiveFour_score ||
      !objectiveFive_score ||
      !objectiveSix_score ||
      !objectiveSeven_score ||
      !objectiveEight_score ||
      !objectiveNine_score ||
      !objectiveTen_score
    )
      return;
  };

  const handleKeyPress = (event) => {
    // Check if the pressed key is the "Enter" key (key code 13)
    if (event.key === "Enter") {
      setTimeout(() => {
        updateHandler(event);
      }, 2000);
    }
  };

  const handleCreateObjective = (event, columnId) => {
    let newTitle = event.target.value;

    setObjectives((prevState) => {
      // Create a copy of the state
      let newState = { ...prevState };

      // Update the title of the specified column
      newState.columns[columnId] = {
        ...newState.columns[columnId],
        title: newTitle,
      };

      newState = {
        ...newState,
        active: 1,
      };

      // Return the updated state
      return newState;
    });
  };

  const handleDescriptionChange = (event, columnId) => {
    const descriptionValue = event.target.value;

    if (event.key === "Enter") {
      setObjectives((prevState) => ({
        ...prevState,
        objectives: {
          ...prevState.objectives,
          [columnId]: {
            ...prevState.objectives[columnId],
            subTasks: [
              ...(prevState.objectives[columnId].subTasks || []), // Ensure prevState.objectives[columnId].subTasks is initialized as an array
              {
                description: descriptionValue,
                steps: [],
                notes: "",
                completed: 0,
              },
            ],
          },
        },
      }));
      event.target.value = "";
    }
  };

  const toggleCompletedSubTasks = (index, columnId) => {
    // Assuming objectives and setObjectives are defined in your component
    // Make sure objectives is a state variable and setObjectives is a setState function

    // Make a copy of objectives[columnId]
    const columnObjectives = { ...objectives.objectives[columnId] };

    // Ensure columnObjectives has a subTasks array
    if (columnObjectives && Array.isArray(columnObjectives.subTasks)) {
      // Toggle the completed value of the specified subTask
      columnObjectives.subTasks[index].completed =
        columnObjectives.subTasks[index].completed === 0 ? 1 : 0;

      // Update the state with the new copy
      setObjectives((prevObjectives) => ({
        ...prevObjectives,
        [columnId]: columnObjectives,
      }));
    } else {
      console.error(
        `Invalid columnId or subTasks array not found for column ${columnId}`
      );
    }
  };

  const toggleStep = (subTaskIndex, stepIndex, columnId) => {
    // Make a copy of objectives
    const updatedObjectives = { ...objectives };
    const columnObjectives = updatedObjectives.objectives[columnId];

    // Ensure columnObjectives has a subTasks array
    if (columnObjectives && Array.isArray(columnObjectives.subTasks)) {
      // Make a copy of subTasks array
      const updatedSubTasks = [...columnObjectives.subTasks];
      // Make a copy of steps array
      const updatedSteps = [...updatedSubTasks[subTaskIndex].steps];

      // Toggle the completed value of the specified subTask
      updatedSteps[stepIndex] = {
        ...updatedSteps[stepIndex],
        completed: updatedSteps[stepIndex].completed === 0 ? 1 : 0,
      };

      // Update the subTasks array with the new copy
      updatedSubTasks[subTaskIndex] = {
        ...updatedSubTasks[subTaskIndex],
        steps: updatedSteps,
      };

      // Update the columnObjectives with the new subTasks array
      updatedObjectives.objectives[columnId] = {
        ...columnObjectives,
        subTasks: updatedSubTasks,
      };

      // Update the state with the new copy
      setObjectives(updatedObjectives);
    } else {
      console.error(
        `Invalid columnId or subTasks array not found for column ${columnId}`
      );
    }
  };

  const updateDescription = (event, columnId, index) => {
    let newDescription = event.target.value;

    setObjectives((prevState) => {
      const updatedObjectives = { ...prevState };
      const task = updatedObjectives.objectives[columnId];
      if (task && task.subTasks[index]) {
        task.subTasks[index].description = newDescription;
      }
      return updatedObjectives;
    });
  };

  const updateNotes = (columnId, index, event) => {
    let newNotes = event.target.value;

    const updatedObjectives = { ...objectives };
    updatedObjectives.objectives[columnId].subTasks[index] = {
      ...updatedObjectives.objectives[columnId].subTasks[index],
      notes: newNotes,
    };
    setObjectives(updatedObjectives);
  };

  const deleteStep = (columnId, subTaskIndex, stepIndex) => {
    const updatedObjectives = { ...objectives };
    updatedObjectives.objectives[columnId].subTasks[subTaskIndex].steps.splice(
      stepIndex,
      1
    );
    setObjectives(updatedObjectives);
  };

  const deleteSubtask = (columnId, subtaskIndex) => {
    const updatedObjectives = { ...objectives };
    updatedObjectives.objectives[columnId].subTasks.splice(subtaskIndex, 1);
    setObjectives(updatedObjectives);
  };

  const deleteObjective = (id) => {
    setObjectives((prevState) => ({
      ...prevState,
      objectives: {
        ...prevState.objectives,
        [id]: {
          ...prevState.objectives[id],
          active: 0,
          subTasks: [],
        },
      },
      columns: {
        ...prevState.columns,
        [id]: {
          ...prevState.columns[id],
          title: "",
        },
      },
    }));
  };

  const handleSubTaskReorder = (columnId, subTaskIndex, direction) => {
    setObjectives((prevObjectives) => {
      let lastIndex = prevObjectives.objectives[columnId].subTasks.length - 1;

      let newIndex =
        direction === "up"
          ? subTaskIndex === 0
            ? subTaskIndex
            : subTaskIndex - 1
          : subTaskIndex === lastIndex
            ? subTaskIndex
            : subTaskIndex + 1;

      return {
        ...prevObjectives,
        objectives: {
          ...prevObjectives.objectives,
          [columnId]: {
            ...prevObjectives.objectives[columnId],
            subTasks: prevObjectives.objectives[columnId].subTasks.map(
              (task, index) => {
                if (index === subTaskIndex) {
                  return prevObjectives.objectives[columnId].subTasks[newIndex];
                } else if (index === newIndex) {
                  return prevObjectives.objectives[columnId].subTasks[
                    subTaskIndex
                  ];
                } else {
                  return task;
                }
              }
            ),
          },
        },
      };
    });
  };

  const handleStepReorder = (columnId, subTaskIndex, stepIndex, direction) => {
    const updatedObjectives = { ...objectives };
    const targetSubTask =
      updatedObjectives.objectives[columnId].subTasks[subTaskIndex];
    const steps = targetSubTask.steps;

    // Check if the step can be moved in the specified direction
    if (
      (direction === "up" && stepIndex === 0) ||
      (direction === "down" && stepIndex === steps.length - 1)
    ) {
      return; // Cannot move step further
    }

    // Swap steps
    const targetStep = steps[stepIndex];
    const adjacentStep =
      steps[direction === "up" ? stepIndex - 1 : stepIndex + 1];
    steps[stepIndex] = adjacentStep;
    steps[direction === "up" ? stepIndex - 1 : stepIndex + 1] = targetStep;

    setObjectives(updatedObjectives); // Update state
  };

  const updateStep = (columnId, subTaskIndex, stepIndex, event) => {
    const { value } = event.target;
    setObjectives((prevObjectives) => {
      const newObjectives = { ...prevObjectives };
      newObjectives.objectives[columnId].subTasks[subTaskIndex].steps[
        stepIndex
      ].action = value;
      return newObjectives;
    });
  };

  let objectiveScoreSetter = (arr) => {
    /**
     * Score Setter Function for Each Objective
     * Returns cast that can be translated into opacity value for dock icons
     * Parameters:
     * Objective obj.subtask Array
     * Objective obj.steps Array
     */

    // Calculate denominator i.e. subTasks array length

    let denominator = arr.length;

    // Calculate numerator

    let generateNumerator = (arr) => {
      let globalNumerator = 0;

      // Count length of uncompleted subtasks (z)
      let incompleteTasks = 0;
      // Count how many subtasks have a completed of 1
      arr
        .filter((d, i) => d.completed === 1)
        .forEach((d, i) => globalNumerator++);

      arr
        .filter((d, i) => d.completed === 0)
        .forEach((d, i) => incompleteTasks++);

      // Transform remaining subtasks into shape favourable to extracting completed steps
      let testArr = [];
      arr
        .filter((d, i) => d.completed === 0)
        .filter((d, i) => d.steps.length > 0)
        .forEach((d, i) => testArr.push(d.steps));

      let numArr = [];
      let denArr = [];

      for (let i = 0; i < testArr.length; i++) {
        for (let j = 0; j < testArr[i].length; j++) {
          denArr.push(1);
          if (testArr[i][j].completed === 1) {
            numArr.push(testArr[i][j].completed);
          }
        }
      }

      if (numArr.length !== 0 && denArr.length !== 0) {
        let initialValue = 0;

        const x = numArr.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          initialValue
        );

        const y = denArr.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          initialValue
        );

        // Multiply x by the result of dividing x and y
        let final = (x / y) * testArr.length;

        // Add to previous global numerator to create final numerator
        let finalFinal = globalNumerator + final;

        return finalFinal;
      } else {
        return globalNumerator;
      }
    };

    let result =
      !isNaN((generateNumerator(arr) / denominator).toFixed(2)) &&
      (generateNumerator(arr) / denominator).toFixed(2);

    return result;
  };

  useEffect(() => {
    objectives &&
      setObjectiveOne_score(
        objectiveScoreSetter(objectives.objectives["obj-one"].subTasks)
      );
    objectives &&
      setObjectiveTwo_score(
        objectiveScoreSetter(objectives.objectives["obj-two"].subTasks)
      );
    objectives &&
      setObjectiveThree_score(
        objectiveScoreSetter(objectives.objectives["obj-three"].subTasks)
      );
    objectives &&
      setObjectiveFour_score(
        objectiveScoreSetter(objectives.objectives["obj-four"].subTasks)
      );
    objectives &&
      setObjectiveFive_score(
        objectiveScoreSetter(objectives.objectives["obj-five"].subTasks)
      );
    objectives &&
      setObjectiveSix_score(
        objectiveScoreSetter(objectives.objectives["obj-six"].subTasks)
      );
    objectives &&
      setObjectiveSeven_score(
        objectiveScoreSetter(objectives.objectives["obj-seven"].subTasks)
      );
    objectives &&
      setObjectiveEight_score(
        objectiveScoreSetter(objectives.objectives["obj-eight"].subTasks)
      );
    objectives &&
      setObjectiveNine_score(
        objectiveScoreSetter(objectives.objectives["obj-nine"].subTasks)
      );
    objectives &&
      setObjectiveTen_score(
        objectiveScoreSetter(objectives.objectives["obj-ten"].subTasks)
      );
  }, [objectives]);

  const showHideState = {
    hide: {
      display: "none",
    },
    show: {
      display: "block",
    },
  };

  const showHideStateMobile = {
    hidden: {
      display: "none",
    },
    show: {
      minWidth: "auto",
      paddingRight: "auto",
      maxWidth: "80vw",
      position: "fixed",
      display: "flex",
      bottom: "0%",
      left: "0%",
      margin: "6px 6px 12px 6px",
      height: "auto",
      opacity: 1,
      zIndex: 26,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    hide: {
      display: "none",
      height: "0px",
      opacity: 0,
      zIndex: 0,
      transition: {
        duration: 1,
        ease: "easeOut",
      },
    },
  };

  const valueState = {
    hide: {
      display: "none",
    },
    show: {
      display: "block",
    },
  };

  const path = document.referrer;

  const loadingTimeout = () => {
    if (path.includes("/week/create")) {
      setTimeout(() => {
        if (days) {
          setPageLoading(false);
        }
      }, 0);
    } else {
      var randNum = Math.random(1) * 3000 + Math.random(1) * 3000 + 6000;
      setTimeout(() => {
        if (days) {
          setPageLoading(false);
        }
      }, randNum);
    }
  };

  useEffect(() => {
    loadingTimeout();
    changeMade();
  });

  const cursorDisplayToggle = () => {
    setCursorDisplayState((cursorDisplayState) => !cursorDisplayState);
  };

  const cursorChangeName = () => {
    setLogoState((logoState) => logoObj.name);
    const key = generateSecret().toString();

    let name =
      years &&
      years
        .filter((year, i, years) => years.indexOf(year) === years.length - 1)
        .map((year) =>
          CryptoJS.AES.decrypt(year.yourName, key).toString(CryptoJS.enc.Utf8)
        );

    const birthDate =
      years &&
      years
        .filter((year, i, years) => years.indexOf(year) === years.length - 1)
        .map((year) => year.birthDate.substring(0, 10));

    const dateString = birthDate;
    const date = new Date(dateString);

    const formattedDate = dateFormatter(dateString);

    let day1 = new Date(birthDate);

    let day2 = new Date();

    let diff = Math.abs(day2 - day1);

    let daysCalc = diff / (1000 * 3600 * 24);

    let days1 = Math.floor(daysCalc);

    setCursorState(
      (cursorState) =>
        `I am ${name}. I was born on ${formattedDate}. Today is day #${days1} of my life.`
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const namePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map((year) =>
            CryptoJS.AES.decrypt(year.yourName, key).toString(CryptoJS.enc.Utf8)
          )
      );

      if (name[0].length >= 1) {
        boolean = true;
      }
    }
    return boolean;
  };

  const dobPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              years && years.map((year) => year.birthDate.substring(0, 10))
          )
      );
      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const getBackgroundStyle = (data) => {
    const percent = (data / 10) * 100; // Calculate percentage value
    return `linear-gradient(to right, rgba(0, 74, 35, 0.5) ${percent}%, transparent ${percent}%)`;
  };

  const cursorChangeValuesOne = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setCursorState(
      (cursorState) =>
        years &&
        years.map(
          (year) =>
            `${CryptoJS.AES.decrypt(year.myValues_1, key).toString(
              CryptoJS.enc.Utf8
            )}: ${CryptoJS.AES.decrypt(year.myValues_1_Text, key).toString(
              CryptoJS.enc.Utf8
            )}`
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const valuesOnePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map((year) =>
            CryptoJS.AES.decrypt(year.myValues_1, key).toString(
              CryptoJS.enc.Utf8
            )
          )
      );
      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeValuesTwo = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setCursorState(
      (cursorState) =>
        years &&
        years.map(
          (year) =>
            `${CryptoJS.AES.decrypt(year.myValues_2, key).toString(
              CryptoJS.enc.Utf8
            )}: ${CryptoJS.AES.decrypt(year.myValues_2_Text, key).toString(
              CryptoJS.enc.Utf8
            )}`
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const valuesTwoPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map((year) =>
            CryptoJS.AES.decrypt(year.myValues_2, key).toString(
              CryptoJS.enc.Utf8
            )
          )
      );

      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeValuesThree = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setCursorState(
      (cursorState) =>
        years &&
        years.map(
          (year) =>
            `${CryptoJS.AES.decrypt(year.myValues_3, key).toString(
              CryptoJS.enc.Utf8
            )}: ${CryptoJS.AES.decrypt(year.myValues_3_Text, key).toString(
              CryptoJS.enc.Utf8
            )}`
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const valuesThreePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map((year) =>
            CryptoJS.AES.decrypt(year.myValues_3, key).toString(
              CryptoJS.enc.Utf8
            )
          )
      );

      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeValuesFour = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setCursorState(
      (cursorState) =>
        years &&
        years.map(
          (year) =>
            `${CryptoJS.AES.decrypt(year.myValues_4, key).toString(
              CryptoJS.enc.Utf8
            )}: ${CryptoJS.AES.decrypt(year.myValues_4_Text, key).toString(
              CryptoJS.enc.Utf8
            )}`
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const valuesFourPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map((year) =>
            CryptoJS.AES.decrypt(year.myValues_4, key).toString(
              CryptoJS.enc.Utf8
            )
          )
      );

      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeValuesFive = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setCursorState(
      (cursorState) =>
        years &&
        years.map(
          (year) =>
            `${CryptoJS.AES.decrypt(year.myValues_5, key).toString(
              CryptoJS.enc.Utf8
            )}: ${CryptoJS.AES.decrypt(year.myValues_5_Text, key).toString(
              CryptoJS.enc.Utf8
            )}`
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const valuesFivePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map((year) =>
            CryptoJS.AES.decrypt(year.myValues_5, key).toString(
              CryptoJS.enc.Utf8
            )
          )
      );

      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeVisionFive = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.vision);
    setCursorState(
      (cursorState) =>
        years &&
        years.map((year) =>
          CryptoJS.AES.decrypt(year.myVision_5, key).toString(CryptoJS.enc.Utf8)
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const visionFivePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              years &&
              years.map((year) =>
                CryptoJS.AES.decrypt(year.myVision_5, key).toString(
                  CryptoJS.enc.Utf8
                )
              )
          )
      );
      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeVisionOne = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.vision);
    setCursorState(
      (cursorState) =>
        years &&
        years.map(
          (year) =>
            `${CryptoJS.AES.decrypt(year.myVision_1, key).toString(
              CryptoJS.enc.Utf8
            )}`
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const visionOnePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              years &&
              years.map((year) =>
                CryptoJS.AES.decrypt(year.myVision_1, key).toString(
                  CryptoJS.enc.Utf8
                )
              )
          )
      );
      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeVisionTwo = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.vision);
    setCursorState(
      (cursorState) =>
        years &&
        years.map(
          (year) =>
            `${CryptoJS.AES.decrypt(year.myVision_2, key).toString(
              CryptoJS.enc.Utf8
            )}`
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const visionTwoPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              years &&
              years.map((year) =>
                CryptoJS.AES.decrypt(year.myVision_2, key).toString(
                  CryptoJS.enc.Utf8
                )
              )
          )
      );
      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeVisionThree = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.vision);
    setCursorState(
      (cursorState) =>
        years &&
        years.map(
          (year) =>
            `${CryptoJS.AES.decrypt(year.myVision_3, key).toString(
              CryptoJS.enc.Utf8
            )}`
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const visionThreePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              years &&
              years.map((year) =>
                CryptoJS.AES.decrypt(year.myVision_3, key).toString(
                  CryptoJS.enc.Utf8
                )
              )
          )
      );
      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeVisionFour = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.vision);
    setCursorState(
      (cursorState) =>
        years &&
        years.map(
          (year) =>
            `${CryptoJS.AES.decrypt(year.myVision_4, key).toString(
              CryptoJS.enc.Utf8
            )}`
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const visionFourPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              years &&
              years.map((year) =>
                CryptoJS.AES.decrypt(year.myVision_4, key).toString(
                  CryptoJS.enc.Utf8
                )
              )
          )
      );
      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeIkigai = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.ikigai);
    setCursorState(
      (cursorState) =>
        years &&
        years.map((year) =>
          CryptoJS.AES.decrypt(year.myIkigai, key).toString(CryptoJS.enc.Utf8)
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const ikigaiPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map((year) =>
            CryptoJS.AES.decrypt(year.myIkigai, key).toString(CryptoJS.enc.Utf8)
          )
      );
      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (vw > 1024) {
        setIsVisible(window.scrollY <= 0);
      } else {
        setIsVisible(window.scrollY <= 900);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const cursorChangeQuote = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.quote);
    setCursorState(
      (cursorState) =>
        years &&
        years.map((year) =>
          CryptoJS.AES.decrypt(year.navigationalQuote, key).toString(
            CryptoJS.enc.Utf8
          )
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const quotePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map((year) =>
            CryptoJS.AES.decrypt(year.navigationalQuote, key).toString(
              CryptoJS.enc.Utf8
            )
          )
      );
      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const [memoryPresentState, setMemoryPresentState] = useState();
  const key = generateSecret().toString();

  const cursorChangeMemory = () => {
    setLogoState((logoState) => logoObj.memories);

    let memArray = [];
    let memArrayFinal = [];

    memArray.push(days && days.map((day) => day.rememberToday));

    for (let i = 0; i < memArray[0].length; i++) {
      if (memArray[0][i].includes(" ")) {
        memArrayFinal.push(memArray[0][i]);
      } else {
        memArrayFinal.push(
          CryptoJS.AES.decrypt(memArray[0][i], key).toString(CryptoJS.enc.Utf8)
        );
      }
    }

    if (memArrayFinal.length > 1) {
      memArrayFinal.filter((mem) => mem.length >= 1);
    }

    let memorySelected = memArrayFinal
      .filter(
        (day, i, days) =>
          days.indexOf(day) === Math.floor(Math.random() * days.length)
      )
      .filter((day, i, days) => days.indexOf(day) === 0)
      .map((day, index, days) => day);

    if (
      memorySelected === [] ||
      memorySelected === "" ||
      memorySelected === " " ||
      memorySelected.length === 0
    ) {
      setCursorState("No memory on this day. Click again");
    } else {
      setCursorState((cursorState) => memorySelected);
      setMemoryPresentState(true);
    }

    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const memoryPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (days) {
      let name = [];
      name.push(days && days.map((day) => day.rememberToday));

      if (name[0].length > 1) {
        boolean = true;
      }
    }

    return boolean;
  };

  const weekVideo = "https://share.vidyard.com/watch/Vry5iUQHge3eD6djb2k912?";

  const showTutorial = () => {
    if (tutorialState === "tutorial video") {
      setTutorialState((tutorialState) => "tutorial");
    } else {
      setTutorialState((tutorialState) => "tutorial video");
    }
  };

  const weeksLogged = () => {
    if (Array.isArray(weeks) && weeks.length == 0) {
      return 0;
    } else {
      if (Array.isArray(weeks)) {
        return weeks.length;
      }
    }
  };

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const [parentState, setParentState] = useState("");
  const [fontState, setFontState] = useState(56);

  const changeMade = () => {
    if (
      objectives ||
      objectiveOne_score ||
      objectiveTwo_score ||
      objectiveThree_score ||
      objectiveFour_score ||
      objectiveFive_score ||
      objectiveSix_score ||
      objectiveSeven_score ||
      objectiveEight_score ||
      objectiveNine_score ||
      objectiveTen_score
    ) {
      setParentState("indicate");
    }
  };

  const fontResizer = () => {
    if (cursorState) {
      if (vw > 1300) {
        if (cursorState[0].length > 90) {
          setFontState((fontState) => 72);
        } else {
          setFontState((fontState) => 96);
        }
      } else {
        if (cursorState[0].length > 90) {
          setFontState((fontState) => 56);
        } else {
          setFontState((fontState) => 72);
        }
      }
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    setObjectives((prevObjectives) => {
      let newColumnOrder = Array.from(prevObjectives.columnOrder);
      let sourceIndex = source.index;
      let destinationIndex = destination.index;
      let [removed] = newColumnOrder.splice(sourceIndex, 1); // Remove the dragged item
      newColumnOrder.splice(destinationIndex, 0, removed); // Insert the dragged item at the new position

      return {
        ...prevObjectives,
        columnOrder: newColumnOrder, // Update the columnOrder in state
      };
    });
  };

  const onDragEndSubTask = (result, columnId) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    setObjectives((prevObjectives) => {
      // Copy the previous state
      const newObjectives = { ...prevObjectives };

      // Get the subTasks array that needs to be updated
      const subTasksToUpdate = [...newObjectives.objectives[columnId].subTasks];

      // Remove the dragged item from its previous position
      const [removed] = subTasksToUpdate.splice(source.index, 1);

      // Insert the dragged item at its new position
      subTasksToUpdate.splice(destination.index, 0, removed);

      // Update the subTasks array in the newObjectives
      newObjectives.objectives[columnId].subTasks = subTasksToUpdate;

      return newObjectives;
    });
  };

  const onDragEndStep = (result, columnId, subTaskIndex) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    setObjectives((prevObjectives) => {
      // Copy the previous state
      const newObjectives = { ...prevObjectives };

      // Get the subTasks array that needs to be updated
      const subTasksToUpdate = [
        ...newObjectives.objectives[columnId].subTasks[subTaskIndex].steps,
      ];

      // Remove the dragged item from its previous position
      const [removed] = subTasksToUpdate.splice(source.index, 1);

      // Insert the dragged item at its new position
      subTasksToUpdate.splice(destination.index, 0, removed);

      // Update the subTasks array in the newObjectives
      newObjectives.objectives[columnId].subTasks[subTaskIndex].steps =
        subTasksToUpdate;

      return newObjectives;
    });
  };

  const addStep = (event, columnId, subTaskIndex) => {
    const descriptionValue = event.target.value;

    if (event.key === "Enter") {
      setObjectives((prevState) => {
        const updatedObjectives = { ...prevState };
        const objective = updatedObjectives.objectives[columnId];
        if (objective) {
          const subTask = objective.subTasks[subTaskIndex];
          if (subTask) {
            subTask.steps.push({
              action: descriptionValue,
              completed: 0,
            });
          }
        }
        return updatedObjectives;
      });
      event.target.value = "";
    }
  };

  const [isOverlayVisible, setOverlayVisible] = useState(false);

  // Function to handle state change from the child
  const handleStateChange = (newState) => {
    setOverlayVisible(newState);
  };

  return (
    <>
      <Helmet>
        <title>Update | Week</title>
      </Helmet>
      <CentralHeader />
      {vw < 500 ? (
        <></>
      ) : (
        <div className="mouseTooltip">
          <MouseTooltip
            visible={cursorDisplayState}
            offsetX={15}
            offsetY={10}
            zIndex={1000}
          >
            <h1 className="sickTooltip" style={{ fontSize: `${fontState}px` }}>
              {cursorState}
            </h1>
          </MouseTooltip>
        </div>
      )}
      <form onKeyDown={(e) => handleKeyPress(e)}>
        <main
          className={
            isOverlayVisible ? "wekContainer--overlay" : "wekContainer"
          }
        >
          {pageLoading ? (
            vw > 1024 ? (
              <DisabledDock />
            ) : (
              <></>
            )
          ) : (
            <motion.div
              className="dock--container"
              initial={{ opacity: 1, height: "auto" }}
              animate={{
                opacity: isVisible ? 1 : 0,
                height: isVisible ? "auto" : "50px",
              }}
              transition={{ duration: 0.3 }}
              style={{
                zIndex: 999,
              }}
            >
              <MidDock
                changeName={cursorChangeName}
                namePresent={namePresent}
                dobPresent={dobPresent}
                changeValuesOne={cursorChangeValuesOne}
                valuesOnePresent={valuesOnePresent}
                changeValuesTwo={cursorChangeValuesTwo}
                valuesTwoPresent={valuesTwoPresent}
                changeValuesThree={cursorChangeValuesThree}
                valuesThreePresent={valuesThreePresent}
                changeValuesFour={cursorChangeValuesFour}
                valuesFourPresent={valuesFourPresent}
                changeValuesFive={cursorChangeValuesFive}
                valuesFivePresent={valuesFivePresent}
                changeVisionOne={cursorChangeVisionOne}
                visionOnePresent={visionOnePresent}
                changeVisionTwo={cursorChangeVisionTwo}
                visionTwoPresent={visionTwoPresent}
                changeVisionThree={cursorChangeVisionThree}
                visionThreePresent={visionThreePresent}
                changeVisionFour={cursorChangeVisionFour}
                visionFourPresent={visionFourPresent}
                changeVisionFive={cursorChangeVisionFive}
                visionFivePresent={visionFivePresent}
                changeIkigai={cursorChangeIkigai}
                ikigaiPresent={ikigaiPresent}
                changeQuote={cursorChangeQuote}
                quotePresent={quotePresent}
                changeMemory={cursorChangeMemory}
                memoryPresent={memoryPresent}
                toggleOverlay={cursorDisplayToggle}
                updateWeek={updateHandler}
                showTutorial={showTutorial}
                changeMade={parentState}
                weeksLogged={weeksLogged}
                saved={saved}
              />
            </motion.div>
          )}
          <div id="wektest">
            {pageLoading ? (
              <div
                style={{
                  height: "50vh",
                  width: "100vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TileLoading />
              </div>
            ) : (
              <>
                {vw > 1024 ? (
                  <></>
                ) : (
                  <>
                    <motion.div
                      variants={showHideStateMobile}
                      initial="hide"
                      animate={cursorDisplayState ? "show" : "hide"}
                      drag
                    >
                      <div className="navElement">
                        <div
                          className="dock node white"
                          onClick={() => setCursorDisplayState(false)}
                        >
                          <FontAwesomeIcon className="icon green" icon={faX} />
                        </div>
                        <span>{cursorState}</span>
                      </div>
                    </motion.div>
                  </>
                )}
                {pageLoading && !objectives ? (
                  <></>
                ) : (
                  <DragDropContext onDragEnd={onDragEnd}>
                    {objectives &&
                      objectives.columnOrder &&
                      objectives.columnOrder.map((columnId, i) => {
                        const column = objectives.columns[columnId];
                        const name = objectives.columns[columnId].title;
                        const data = objectives.objectives[columnId].subTasks;
                        return (
                          <StrictModeDroppable
                            key={i}
                            droppableId={`${columnId}`}
                          >
                            {(provided) => (
                              <div
                                key={columnId}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{ width: "100%" }}
                              >
                                <Objective
                                  onDragEnd={onDragEndSubTask}
                                  onDragEndSteps={onDragEndStep}
                                  columnId={columnId}
                                  column={column}
                                  index={i}
                                  dragId={`id-${i}`}
                                  name={name}
                                  data={data}
                                  objNum={i + 1}
                                  handleCreateObjective={handleCreateObjective}
                                  toggleSubTask={toggleCompletedSubTasks}
                                  updateDescription={updateDescription}
                                  handleSubTaskReorder={handleSubTaskReorder}
                                  addSubTask={handleDescriptionChange}
                                  toggleStep={toggleStep}
                                  updateStep={updateStep}
                                  deleteStep={deleteStep}
                                  handleStepReorder={handleStepReorder}
                                  addStep={addStep}
                                  updateNotes={updateNotes}
                                  deleteSubTask={deleteSubtask}
                                  deleteObjective={deleteObjective}
                                  onStateChange={handleStateChange}
                                  overlay={isOverlayVisible}
                                ></Objective>

                                {provided.placeholder}
                              </div>
                            )}
                          </StrictModeDroppable>
                        );
                      })}
                  </DragDropContext>
                )}
              </>
            )}
          </div>
        </main>
      </form>
      <TestFooter />
    </>
  );
}
