// React 
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { Card, Button, Row, Col, Container, Form } from 'react-bootstrap';

// External Packages 
import * as d3 from 'd3';
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faEraser } from '@fortawesome/free-solid-svg-icons'

// Internal Components 
import TestFooter from '../../../components/Footer/TestFooter';
import CentralHeader from '../../../components/Header/CentralHeader';
import { ErrorMessage } from '../../../components/Error/ErrorMessage';

// User Actions 
import { listDays } from '../../../actions/dayActions';
import { listYears } from '../../../actions/yearActions';
import { listWeeks } from '../../../actions/weekActions';
import { updateProfile } from '../../../actions/dayActions';

// Images


// Styling
import './realquiz.css';

export default function FinalQuizScreen(history) {
  const [titleDiv, setTitleDiv] = useState(true);
  const [kickOff, setKickOff] = useState(false);
  const [q1, setQ1] = useState(false);


  const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const yearList = useSelector((state) => state.yearList);
    const { years } = yearList;

    const weekList = useSelector((state) => state.weekList);
    const { weeks } = weekList;

    const dayList = useSelector((state) => state.dayList);
    const { loading, error,  days } = dayList;

    useEffect(() => {
  dispatch(listYears());
    }, [dispatch, userInfo, history])

    useEffect(() => {
      dispatch(listWeeks());
    }, [dispatch, userInfo, history]);

    useEffect(() => {
      dispatch(listDays());
    }, [dispatch, userInfo, history]);

  const showHideState = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  }

  const showHideGrid = {
    hide: {
      display: "none",
    },
    show: {
      display: "grid",
    },
  }

  	const parseDate = d3.timeParse('%Y-%m-%d')

    days && days.forEach(day => {
      let sum = day.momentOneScore + day.momentTwoScore + day.momentThreeScore + day.momentFourScore + day.momentFiveScore;
      day.total = sum;

      let count = (day.momentOneScore / day.momentOneScore === 1 ? 1 : 0) + (day.momentTwoScore / day.momentTwoScore === 1 ? 1 : 0) + (day.momentThreeScore / day.momentThreeScore === 1 ? 1 : 0) + (day.momentFourScore / day.momentFourScore === 1 ? 1 : 0) + (day.momentFiveScore / day.momentFiveScore === 1 ? 1 : 0);
      day.tasksAttempted = count;

      let tcr = ((day.total / 10) / day.tasksAttempted)
        day.taskCompletionRate = tcr;
    });

    const avgArray = [];

    days && days.map(each => avgArray.push(each.taskCompletionRate));

    const zeroRemove = () => {
      let lastItem = avgArray.length - 1;

      if (avgArray[lastItem] === 0) {
        return avgArray.pop();
      } else {
        return avgArray;
      }
    }

    zeroRemove();

    const daysLogged = d3.count(avgArray, d => d);

    const daysRemaining = 7 - daysLogged;

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);


    useEffect(() => {
      document.title = "Art of Looking";
    }, []);
    
  return (
    <>

<CentralHeader />
<main style={{display: 'flex', justifyContent: 'center', marginBottom: '24px'}}> 
<motion.div
  className="titleDiv"
  animate={ titleDiv ? "show" : "hide"}
  variants={vw > 1024 ? showHideGrid : showHideState}
  onClick={()=> {
  setTitleDiv(titleDiv => false);
  setKickOff(kickOff => true);
  localStorage.setItem("quiz_end", 1);
}}>
  <span>You Have a Finished the Quiz!</span>
  <h1>Well Done</h1>
  <span><strong>Click Anywhere to Celebrate</strong></span>
</motion.div>

<motion.div
animate={ kickOff ? "show" : "hide"}
variants={vw > 1024 ? showHideGrid : showHideState}
className="quizDiv">
  <div className="leftCol">
    <div className="colTitle">
      <h4 className="quizHeaderWhite">The Facts</h4>
    </div>
    <br />
    <span>
      Awesome work! That is a really good effort to put in the time and effort to smash through that quiz.
    </span>
    <br />
    <br />
    <span>
      I really hope you know feel like you have a better understanding and more confidence than when you started.
    </span>
    <br />
    <br />
    <span>
    If you have logged more than 7 days of data now, you can dive into your insights right away. If you are still building up to that,
    keep logging and maybe have another revisit of some key quiz content before you get going.
    </span>
    <br />
    <br />
    <span>
    Also, we have a set of other experiences to help you think more clearly and easily about yourself in the footer below. Check them out!  
    </span>
    <br />
    <br />
    <span>
    Well done again! That is a really good effort and I really hope it will help immensely as you start to learn from your own NWD data.
    </span>
</div>
<div className="rightCol">
<div className="rightContent">
  <h2>Quiz Complete!</h2>
  <hr className="dividingLine" />
  <h2>Days Logged: {daysLogged}</h2>
  <h2>Days Needed to Unlock Insights: {daysRemaining}</h2>

</div>
</div>
<div className="buttonRow">
<Button className="quizButton" href="/">Homepage</Button>
<Button className="quizButton" href="/today">Start a Day in your Diary</Button>
</div>
</motion.div>
</main>
<TestFooter />


    </>
  );
}
