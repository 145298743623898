import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import { login } from "../../actions/userActions";
import Footer from "../../components/Footer/Footer";
import PropagateLoader from "react-spinners/PropagateLoader";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate("/today");
    }
  }, [userInfo]);

  const override = {
    display: "block",
    margin: 0,
    borderColor: "red",
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <>
      <main className="updateday--container">
        <section className="tile reg">
          <section className="registerContainer">
            {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
            <div className="tutorial access">
              <label style={{ fontWeight: "bold" }}>Login</label>
            </div>
            <img
              className="sticker"
              src="https://cdn.sanity.io/images/ou6d2gec/production/088a4919740e18a8e075aea3319a06cbe5dfba5d-4500x4500.png"
              width="25%"
              alt="daily planner"
              loading="lazy"
            />
            <form className="register form" onSubmit={submitHandler}>
              <div className="demographic__row--first">
                <label style={{ color: "#004a22" }} htmlFor="email">
                  Email
                </label>

                <input
                  id="email"
                  className="formInput register"
                  type="email"
                  value={email}
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="demographic__row">
                <label style={{ color: "#004a22" }} htmlFor="password">
                  Password
                </label>

                <input
                  id="password"
                  className="formInput register"
                  type="password"
                  placeholder="Create your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <button className="loginButton" type="submit">
                Login
              </button>

              <span>
                If you haven't already registered, click{" "}
                <a href="/register">here...</a>
              </span>
            </form>
          </section>
        </section>
      </main>
    </>
  );
}
