//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPersonChalkboard,
  faUser,
  faQuestion,
  faToggleOff,
  faEraser,
  faToggleOn,
  faSignature,
  faCalendarDays,
  faCompassDrafting,
  faLandmarkDome,
  faDna,
  faGlasses,
  faQuoteLeft,
  faPersonCircleQuestion,
  faGripLinesVertical,
  faBookOpen,
  faEyeSlash,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
  faBolt,
  faFloppyDisk,
  faPlus,
  faCalendarPlus,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";

//Internal Components
import {
  CreateDayIcon,
  CreateDayIconV2,
  SevenIcon,
  ValuesIcon,
  NameIcon,
  NameIconV2,
  NameIconV3,
  NameIconV4,
  DobIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  SaveIcon,
  ProfileIcon,
} from "../../components/Icons/Icon";

import "./dock.css";

export default function HideDock(props) {
  const navigate = useNavigate();
  const [yourName, setYourName] = useState();

  const [helpNodeState, setHelpNodeState] = useState("hidedock node inactive");
  const [colorStateOne, setColorStateOne] = useState("cls-1-invert");
  const [colorStateTwo, setColorStateTwo] = useState("cls-2-invert");

  const showHelpNodes = () => {
    if (helpNodeState === "hidedock node inactive") {
      setHelpNodeState((helpNodeState) => "hidedock node");
    } else {
      setHelpNodeState((helpNodeState) => "hidedock node inactive");
    }
  };

  const currentWindow = window.location.href;

  const [isAnimatingStart, setIsAnimatingStart] = useState(false);
  const [isAnimatingTutorial, setIsAnimatingTutorial] = useState(false);
  const [isAnimatingEye, setIsAnimatingEye] = useState(false);
  const [isAnimatingSave, setIsAnimatingSave] = useState(false);
  const [isAnimatingValues, setIsAnimatingValues] = useState(false);
  const [isAnimatingVision, setIsAnimatingVision] = useState(false);
  const [isAnimatingIki, setIsAnimatingIki] = useState(false);
  const [isAnimatingQuote, setIsAnimatingQuote] = useState(false);
  const [isAnimatingProfile, setIsAnimatingProfile] = useState(false);
  const [isAnimatingQuiz, setIsAnimatingQuiz] = useState(false);

  useEffect(() => {
    if (
      localStorage.Values_1 ||
      localStorage.Values_2 ||
      localStorage.Values_3 ||
      localStorage.Values_4 ||
      localStorage.Values_5
    ) {
      setIsAnimatingSave(true);
    }
  });

  return (
    <div className="hidedock--container">
      {currentWindow.endsWith("/") ||
      currentWindow.endsWith("/insight") ||
      currentWindow.endsWith("/profile") ? (
        <>
          <div
            className={`hidedock node ${isAnimatingStart ? "animate" : ""}`}
            data-tooltip="Start a New Day"
            onClick={() => {
              navigate("/today");
            }}
            onMouseDown={() => {
              setIsAnimatingStart(true);
            }}
            onMouseUp={() => {
              setIsAnimatingStart(false);
            }}
          >
            <CreateDayIconV2 />
          </div>

          <div className="hidedock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>

          {props.viewWidth > 500 ? (
            <div
              className={`hidedock node ${
                isAnimatingTutorial ? "animate" : ""
              }`}
              data-tooltip="Click for Help"
              onClick={() => {
                navigate(
                  currentWindow.endsWith("/insight")
                    ? "/tutorial/insight"
                    : "/tutorial/today"
                );
              }}
              onMouseDown={() => {
                setIsAnimatingTutorial(true);
              }}
              onMouseUp={() => {
                setIsAnimatingTutorial(false);
              }}
            >
              <QuestionIcon />
            </div>
          ) : (
            <div
              className={`hidedock node ${
                isAnimatingTutorial ? "animate" : ""
              }`}
              data-tooltip="Click for Help"
              onClick={props.showTutorial}
              onMouseDown={() => {
                setIsAnimatingTutorial(true);
              }}
              onMouseUp={() => {
                setIsAnimatingTutorial(false);
              }}
            >
              <QuestionIcon />
            </div>
          )}
          {currentWindow.endsWith("/profile") ? (
            <></>
          ) : (
            <div
              className={`hidedock node ${isAnimatingProfile ? "animate" : ""}`}
              data-tooltip="Your Profile"
              onClick={() => {
                navigate("/profile");
              }}
              onMouseDown={() => {
                setIsAnimatingProfile(true);
              }}
              onMouseUp={() => {
                setIsAnimatingProfile(false);
              }}
            >
              <ProfileIcon />
            </div>
          )}

          <div
            className={`hidedockwide node ${isAnimatingQuiz ? "animate" : ""}`}
            data-tooltip="Art of Looking"
            onClick={() => {
              navigate("/insight/quiz");
            }}
            onMouseDown={() => {
              setIsAnimatingQuiz(true);
            }}
            onMouseUp={() => {
              setIsAnimatingQuiz(false);
            }}
          >
            <VisionIcon />
          </div>
        </>
      ) : currentWindow.endsWith("/create") ? (
        currentWindow.endsWith("/year/create") ? (
          <>
            {/* <div className={`dock node ${isAnimatingEye ? 'animate' : ''}`}
    data-tooltip="Toggle Help"
   onClick={props.toggleOverlay}
   onMouseDown={(()=>{
    setIsAnimatingEye(true);
  })}
  onMouseUp={(()=>{
    setIsAnimatingEye(false);
  })}>
    <FontAwesomeIcon
    id="openIcon"
    className="icon"
    icon={faEyeSlash}/>
      </div> 

   

        <div className={`hidedock node ${isAnimatingTutorial ? 'animate' : ''}`}
        data-tooltip="Click for Tutorial"
    onClick={()=> {
      navigate('/tutorial/year');
    }}
    onMouseDown={(()=>{
      setIsAnimatingTutorial(true);
    })}
    onMouseUp={(()=>{
      setIsAnimatingTutorial(false);
    })}
  >
  <QuestionIcon />
</div>
<div className="hidedock separator">
    <FontAwesomeIcon
    id="separatorIcon"
    className="separator"
    icon={faGripLinesVertical}/>
      </div>
*/}
            <div
              className={`hidedock node ${isAnimatingValues ? "animate" : ""}`}
              data-tooltip="Try the NWD Values Process"
              onClick={() => {
                navigate("/values");
              }}
              onMouseDown={() => {
                setIsAnimatingValues(true);
              }}
              onMouseUp={() => {
                setIsAnimatingValues(false);
              }}
            >
              <ValuesIcon />
            </div>

            <div
              className={`hidedockwide node ${
                isAnimatingVision ? "animate" : ""
              }`}
              data-tooltip="Vision Process Coming Soon."
              style={{ opacity: "0.25" }}
              onMouseDown={() => {
                setIsAnimatingVision(true);
              }}
              onMouseUp={() => {
                setIsAnimatingVision(false);
              }}
            >
              <VisionIcon />
            </div>

            <div
              className={`hidedock node ${isAnimatingIki ? "animate" : ""}`}
              data-tooltip="Ikigai Process Coming Soon."
              style={{ opacity: "0.25" }}
              onMouseDown={() => {
                setIsAnimatingIki(true);
              }}
              onMouseUp={() => {
                setIsAnimatingIki(false);
              }}
            >
              <IkigaiIcon />
            </div>

            <div
              className={`hidedock node ${isAnimatingQuote ? "animate" : ""}`}
              data-tooltip="Quote Finder Coming Soon."
              style={{ opacity: "0.25" }}
              onMouseDown={() => {
                setIsAnimatingQuote(true);
              }}
              onMouseUp={() => {
                setIsAnimatingQuote(false);
              }}
            >
              <QuoteIconV2 />
            </div>
            <div className="hidedock separator">
              <FontAwesomeIcon
                id="separatorIcon"
                className="separator"
                icon={faGripLinesVertical}
              />
            </div>
            <div
              className={`hidedock node_save ${
                isAnimatingSave ? "animate" : ""
              }`}
              data-tooltip={
                isAnimatingSave ? "Save your new values" : "Click to Save"
              }
              onClick={() => {
                props.saveYear();

                setTimeout(() => {
                  localStorage.removeItem("Values_1");
                  localStorage.removeItem("Values_1_Text");
                  localStorage.removeItem("Values_2");
                  localStorage.removeItem("Values_2_Text");
                  localStorage.removeItem("Values_3");
                  localStorage.removeItem("Values_3_Text");
                  localStorage.removeItem("Values_4");
                  localStorage.removeItem("Values_4_Text");
                  localStorage.removeItem("Values_5");
                  localStorage.removeItem("Values_5_Text");
                }, 1000);
              }}
              onMouseDown={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseOver={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseOut={() => {
                setColorStateTwo("cls-2-invert");
              }}
            >
              <SaveIcon colorStateTwo={colorStateTwo} />
            </div>
          </>
        ) : (
          <>
            <div
              className={`hidedock node ${isAnimatingEye ? "animate" : ""}`}
              data-tooltip="Toggle Help"
              onMouseOver={props.toggleOverlay}
              onClick={props.toggleOverlay}
              onMouseDown={() => {
                setIsAnimatingEye(true);
              }}
              onMouseUp={() => {
                setIsAnimatingEye(false);
              }}
            >
              <HideOverlayIcon />
            </div>

            <div
              className={`hidedock node_save ${
                isAnimatingSave ? "animate" : ""
              }`}
              data-tooltip="Click to Save"
              onClick={props.saveWeek}
              onMouseDown={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseOver={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseOut={() => {
                setColorStateTwo("cls-2-invert");
              }}
            >
              <SaveIcon colorStateTwo={colorStateTwo} />
            </div>

            <div
              className={`hidedock node ${
                isAnimatingTutorial ? "animate" : ""
              }`}
              data-tooltip="Click for Tutorial"
              onClick={() => {
                navigate("/tutorial/year");
              }}
              onMouseDown={() => {
                setIsAnimatingTutorial(true);
              }}
              onMouseUp={() => {
                setIsAnimatingTutorial(false);
              }}
            >
              <QuestionIcon />
            </div>
          </>
        )
      ) : currentWindow.match("/year/") ? (
        <>
          {/* <div className={`dock node ${isAnimatingEye ? 'animate' : ''}`}
    data-tooltip="Toggle Help"
   onClick={props.toggleOverlay}
   onMouseDown={(()=>{
    setIsAnimatingEye(true);
  })}
  onMouseUp={(()=>{
    setIsAnimatingEye(false);
  })}>
    <FontAwesomeIcon
    id="openIcon"
    className="icon"
    icon={faEyeSlash}/>
      </div> */}

          <div
            className={`hidedock node ${isAnimatingTutorial ? "animate" : ""}`}
            data-tooltip="Click for Tutorial"
            onClick={() => {
              navigate("/tutorial/year");
            }}
            onMouseDown={() => {
              setIsAnimatingTutorial(true);
            }}
            onMouseUp={() => {
              setIsAnimatingTutorial(false);
            }}
          >
            <QuestionIcon />
          </div>

          <div className="hidedock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>

          <div
            className={`hidedock node ${isAnimatingValues ? "animate" : ""}`}
            data-tooltip="Try the NWD Values Process"
            onClick={() => {
              navigate("/values");
            }}
            onMouseDown={() => {
              setIsAnimatingValues(true);
            }}
            onMouseUp={() => {
              setIsAnimatingValues(false);
            }}
          >
            <ValuesIcon />
          </div>

          <div
            className={`hidedockwide node ${
              isAnimatingVision ? "animate" : ""
            }`}
            data-tooltip="Vision Process Coming Soon."
            style={{ opacity: "0.25" }}
          >
            <VisionIcon />
          </div>

          <div
            className={`hidedock node ${isAnimatingIki ? "animate" : ""}`}
            data-tooltip="Ikigai Process Coming Soon."
            style={{ opacity: "0.25" }}
            onMouseDown={() => {
              setIsAnimatingIki(true);
            }}
            onMouseUp={() => {
              setIsAnimatingIki(false);
            }}
          >
            <IkigaiIcon />
          </div>

          <div
            className={`hidedock node ${isAnimatingQuote ? "animate" : ""}`}
            data-tooltip="Quote Finder Coming Soon."
            style={{ opacity: "0.25" }}
            onMouseDown={() => {
              setIsAnimatingQuote(true);
            }}
            onMouseUp={() => {
              setIsAnimatingQuote(false);
            }}
          >
            <QuoteIconV2 />
          </div>

          <div className="hidedock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>

          <div
            className={`hidedock node_save ${isAnimatingSave ? "animate" : ""}`}
            data-tooltip={
              isAnimatingSave ? "Save your new values" : "Click to Save"
            }
            onClick={() => {
              props.updateYear();
              setTimeout(() => {
                localStorage.removeItem("Values_1");
                localStorage.removeItem("Values_1_Text");
                localStorage.removeItem("Values_2");
                localStorage.removeItem("Values_2_Text");
                localStorage.removeItem("Values_3");
                localStorage.removeItem("Values_3_Text");
                localStorage.removeItem("Values_4");
                localStorage.removeItem("Values_4_Text");
                localStorage.removeItem("Values_5");
                localStorage.removeItem("Values_5_Text");
              }, 1000);
            }}
            onMouseDown={() => {
              setColorStateTwo("cls-2");
            }}
            onMouseOver={() => {
              setColorStateTwo("cls-2");
            }}
            onMouseOut={() => {
              setColorStateTwo("cls-2-invert");
            }}
          >
            <SaveIcon colorStateTwo={colorStateTwo} />
          </div>
        </>
      ) : (
        <>
          <div
            className={`jordandock node_save ${
              isAnimatingEye ? "animate" : ""
            }`}
            data-tooltip="Toggle Help"
            onMouseOver={props.toggleOverlay}
            onMouseDown={() => {
              setIsAnimatingEye(true);
            }}
            onMouseUp={() => {
              setIsAnimatingEye(false);
            }}
          >
            <HideOverlayIcon />
          </div>
          <div
            className={`jordandock node_save ${
              isAnimatingSave ? "animate" : ""
            }`}
            data-tooltip="Click to Save"
            onClick={props.updateWeek}
            onMouseDown={() => {
              setColorStateTwo("cls-2");
            }}
            onMouseOver={() => {
              setColorStateTwo("cls-2");
            }}
            onMouseOut={() => {
              setColorStateTwo("cls-2-invert");
            }}
          >
            <SaveIcon colorStateTwo={colorStateTwo} />
          </div>
          <a href="/week/create">
            <div
              className={`dock node ${isAnimatingStart ? "animate" : ""}`}
              data-tooltip="Start a New Week"
              onMouseDown={() => {
                setIsAnimatingStart(true);
              }}
              onMouseUp={() => {
                setIsAnimatingStart(false);
              }}
            >
              <CreateDayIconV2 />
            </div>
          </a>
          <div
            className={`hidedock node ${isAnimatingTutorial ? "animate" : ""}`}
            data-tooltip="Click for Help"
            onClick={props.showTutorial}
            onMouseDown={() => {
              setIsAnimatingTutorial(true);
            }}
            onMouseUp={() => {
              setIsAnimatingTutorial(false);
            }}
          >
            <QuestionIcon />
          </div>
        </>
      )}
    </div>
  );
}
