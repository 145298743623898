// React
import React, { useEffect, useState } from "react";

// External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareXmark,
  faPlus,
  faEllipsisVertical,
  faTrash,
  faCirclePlus,
  faSquareCaretDown,
  faSquareCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";

// Internal Components
import {
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  SaveIcon,
  TickIcon,
  PreviousDay,
} from "../Icons/Icon";

// Styling
import "./objective.css";

function Objective(props) {
  const [callout, setCallout] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect((event) => {
    if (props.data.subTasks) {
      let subtask = document.getElementsByClassName("task--input");
      let arrayFromCollection = Array.from(subtask);
      arrayFromCollection.forEach((element, i) => {
        let actualHeight = element.scrollHeight;
        element.style.height = "auto";
        element.style.height = `${actualHeight}px`;
      });
    }
  });

  useEffect(() => {
    const handleTextAreaResize = (event) => {
      const { target } = event;

      // Reset the height to auto before getting the updated scrollHeight
      target.style.height = "auto";

      // Set the height to the updated scrollHeight
      target.style.height = `${target.scrollHeight}px`;
    };

    const initializeTextAreaResize = () => {
      if (props.data.subTasks) {
        // Attach the event listener to each textarea with the class 'task--input'
        const textareas = document.getElementsByClassName("task--input");
        Array.from(textareas).forEach((textarea) => {
          textarea.addEventListener("input", handleTextAreaResize);
        });
      }
    };

    // Run on page load
    initializeTextAreaResize();

    // Attach the event listener for input events
    if (props.data.subTasks) {
      const textareas = document.getElementsByClassName("task--input");
      Array.from(textareas).forEach((textarea) => {
        textarea.addEventListener("input", handleTextAreaResize);
      });
    }

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      if (props.data.subTasks) {
        const textareas = document.getElementsByClassName("task--input");
        Array.from(textareas).forEach((textarea) => {
          textarea.removeEventListener("input", handleTextAreaResize);
        });
      }
    };
  }, [props.data.subTasks]);

  const [saving, setSaving] = useState(false);

  const savingTimeout = () => {
    if (saving === true) {
      setTimeout(() => {
        setSaving((saving) => false);
      }, 3000);
    }
  };

  useEffect(() => {
    savingTimeout();
  }, [saving]);

  return (
    <>
      <div className={props.name !== "" ? `obj__col` : `obj__col disabled`}>
        <div className={saving ? "obj__col--name saving" : "obj__col--name"}>
          <FontAwesomeIcon className="obj__col--icon" icon={faPlus} />
          <input
            type="text"
            className="col__name--input"
            value={props.name}
            placeholder="Objective Name"
            onChange={async (event) => {
              await props.handleCreateObjective(event, props.columnId);
              await props.updateWeek();
              setSaving(true);
            }}
          ></input>
          <div className="col__name--icon">
            {props.num === 1 && <OneIcon />}
            {props.num === 2 && <TwoIcon />}
            {props.num === 3 && <ThreeIcon />}
            {props.num === 4 && <FourIcon />}
            {props.num === 5 && <FiveIcon />}
            {props.num === 6 && <SixIcon />}
            {props.num === 7 && <SevenIcon />}
            {props.num === 8 && <EightIcon />}
            {props.num === 9 && <NineIcon />}
            {props.num === 10 && <TenIcon />}
          </div>
          <div className="col__name--trash">
            <FontAwesomeIcon
              icon={faSquareXmark}
              onClick={props.closeObjectiveState}
            />
          </div>
        </div>

        <div className="obj__col--subTasks">
          {props.data &&
          props.data.subTasks &&
          props.data.subTasks.length > 0 ? (
            props.data.subTasks.map((d, i) => (
              <div key={i} className="objective__col--wrapper">
                <div
                  className={
                    d.completed === 0
                      ? "obj__col--task"
                      : "obj__col--task-active"
                  }
                >
                  <div
                    className={
                      d.completed === 0 ? "task__radio" : "task__radio--active"
                    }
                    onClick={async () => {
                      await props.toggleSubTask(i, props.columnId);
                      await props.updateWeek();
                      setSaving(true);
                    }}
                  ></div>
                  <textarea
                    className={
                      d.completed === 0
                        ? "task--input"
                        : "task--input completed"
                    }
                    value={d.description}
                    onDoubleClick={(event) => props.select(event)}
                    onChange={async (event) => {
                      await props.updateDescription(event, props.columnId, i);
                      await props.updateWeek();
                      setSaving(true);
                    }}
                  ></textarea>
                  <FontAwesomeIcon
                    className="obj__col--icon ellipsis"
                    icon={faEllipsisVertical}
                    onClick={() => {
                      setCallout(true);
                      setIndex(i);
                    }}
                  />
                </div>
                <div className="reorder">
                  <FontAwesomeIcon
                    onClick={async () => {
                      await props.handleSubTaskReorder(props.columnId, i, "up");
                      await props.updateWeek();
                      setSaving(true);
                    }}
                    className="objective__col--reorder"
                    icon={faSquareCaretUp}
                  />
                  <FontAwesomeIcon
                    onClick={async () => {
                      await props.handleSubTaskReorder(props.columnId, i, "up");
                      await props.updateWeek();
                      setSaving(true);
                    }}
                    className="objective__col--reorder"
                    icon={faSquareCaretDown}
                  />
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
          <div className="obj__col--task">
            <input
              type="text"
              className="task--input add"
              placeholder="Add new action"
              onKeyDown={async (event) => {
                await props.addSubTask(event, props.columnId);
                await props.updateWeek();
                setSaving(true);
              }}
            ></input>
          </div>
        </div>
      </div>
      <div className={callout ? "obj__callout--active" : "obj__callout"}>
        {props.data.subTasks.length > 0 &&
          props.data.subTasks
            .filter((d, i) => index === i)
            .map((d, i) => (
              <div key={i} className="callout__header">
                <div className="callout__name">
                  <div className="task__radio"></div>
                  <textarea
                    className="callout--input"
                    value={d.description}
                    wrap="soft"
                    onChange={async (event) => {
                      await props.updateDescription(
                        event,
                        props.columnId,
                        index
                      );
                      await props.updateWeek();
                      setSaving(true);
                    }}
                  ></textarea>
                </div>
                <FontAwesomeIcon
                  className="callout--icon"
                  icon={faSquareXmark}
                  onClick={() => {
                    setCallout(false);
                    props.closeMoment();
                    setIndex(0);
                  }}
                />
              </div>
            ))}
        <div className="callout__actions">
          {props.data.subTasks &&
          props.data.subTasks.length > 0 &&
          props.data.subTasks[index] ? (
            props.data.subTasks[index].steps.map((d, i) => (
              <div key={i} className="objective__col--wrapper">
                <div
                  className={
                    d.completed === 0
                      ? "obj__col--task"
                      : "obj__col--task-active"
                  }
                >
                  <div
                    className={
                      d.completed === 0 ? "task__radio" : "task__radio--active"
                    }
                    onClick={async () => {
                      await props.toggleStep(index, i, props.columnId);
                      await props.updateWeek();
                      setSaving(true);
                    }}
                  ></div>
                  <textarea
                    className={
                      d.completed === 0
                        ? "task--input"
                        : "task--input completed"
                    }
                    value={d.action}
                    onDoubleClick={(event) => props.select(event)}
                    onChange={async (event) => {
                      await props.updateStep(props.columnId, index, i, event);
                      await props.updateWeek();
                    }}
                  />
                  <FontAwesomeIcon
                    className="obj__col--icon trash"
                    icon={faTrash}
                    onClick={async () => {
                      await props.deleteStep(props.columnId, index, i);
                      await props.updateWeek();
                      setSaving(true);
                    }}
                  />
                </div>
                <div className="reorder">
                  <FontAwesomeIcon
                    onClick={async () => {
                      await props.handleStepReorder(
                        props.columnId,
                        index,
                        i,
                        "up"
                      );
                      await props.updateWeek();
                      setSaving(true);
                    }}
                    className="objective__col--reorder"
                    icon={faSquareCaretUp}
                  />
                  <FontAwesomeIcon
                    onClick={async () => {
                      await props.handleStepReorder(
                        props.columnId,
                        index,
                        i,
                        "down"
                      );
                      await props.updateWeek();
                      setSaving(true);
                    }}
                    className="objective__col--reorder"
                    icon={faSquareCaretDown}
                  />
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
          <div className="obj__col--task">
            <div className="task__radio"></div>
            <input
              type="text"
              className="task--input add"
              placeholder="Add a new step"
              onKeyDown={async (event) => {
                await props.addStep(event, props.columnId, index);
                await props.updateWeek();
                setSaving(true);
              }}
            />
            <FontAwesomeIcon
              className="objective__col--icon"
              icon={faEllipsisVertical}
            />
          </div>
        </div>

        <div className="callout__notes">
          {props.data.subTasks.length > 0 &&
            props.data.subTasks
              .filter((d, i) => index === i)
              .map((d, i) => (
                <textarea
                  key={i}
                  className="notes"
                  placeholder="Write notes"
                  value={d.notes}
                  onChange={async (event) => {
                    await props.updateNotes(props.columnId, index, event);
                    await props.updateWeek();
                    setSaving(true);
                  }}
                />
              ))}
          <div
            className="callout__button"
            onClick={async () => {
              await props.deleteSubTask(props.columnId, index);
              await props.updateWeek();
              setCallout(false);
            }}
          >
            Delete Task
          </div>
        </div>
      </div>
      <div
        className={
          props.momentSelected ? "moment__callout--active" : "moment__callout"
        }
      >
        <div className="obj__col--name">
          <FontAwesomeIcon className="obj__col--icon" icon={faPlus} />
          <input
            type="text"
            className="col__name--input"
            value="Add this action to..."
            readOnly
          ></input>
          <div className="col__name--close">
            <FontAwesomeIcon
              style={{ color: "#e2dfcf" }}
              icon={faSquareXmark}
              onClick={() => {
                props.closeMoment();
                setIndex(0);
              }}
            />
          </div>
        </div>
        <div className="moment__button--row">
          <div
            className="callout__button"
            onClick={() => {
              props.stateChange(1);
              setCallout(false);
            }}
          >
            Moment One
          </div>
          <div
            className="callout__button"
            onClick={() => {
              props.stateChange(2);
              setCallout(false);
            }}
          >
            Moment Two
          </div>
          <div
            className="callout__button"
            onClick={() => {
              props.stateChange(3);
              setCallout(false);
            }}
          >
            Moment Three
          </div>
          <div
            className="callout__button"
            onClick={() => {
              props.stateChange(4);
              setCallout(false);
            }}
          >
            Moment Four
          </div>
          <div
            className="callout__button"
            onClick={() => {
              props.stateChange(5);
              setCallout(false);
            }}
          >
            Moment Five
          </div>
        </div>
      </div>
    </>
  );
}

export default Objective;
