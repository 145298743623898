// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

// External Packages
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faEraser,
  faSquareCaretRight,
  faSquareCaretLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Card, Button, Row, Col, Container, Form } from "react-bootstrap";

// Internal Components
import generateSecret from "../../../utils/generateSecret";
import CentralHeader from "../../../components/Header/CentralHeader";
import CentralHeaderLP from "../../../components/Header/CentralHeaderLP";
import TestFooter from "../../../components/Footer/TestFooter";
import { ErrorMessage } from "../../../components/Error/ErrorMessage";

// User Actions
import { updateQuizAction, listQuizes } from "../../../actions/quizActions";
import { updateProfile } from "../../../actions/dayActions";

// Styling
import "./quizwave.css";

export default function QuizWaveScreen(history) {
  const [titleDiv, setTitleDiv] = useState(false);
  const [whatYou, setWhatYou] = useState(true);
  const [myLearnings, setMyLearnings] = useState(false);
  const [myLearnings1, setMyLearnings1] = useState(false);
  const [myLearnings2, setMyLearnings2] = useState(false);
  const [myLearnings3, setMyLearnings3] = useState(false);

  const [input, setInput] = useState(true);
  const [output, setOutput] = useState(false);

  const [wave, setWave] = useState(true);
  const [spiral, setSpiral] = useState(true);

  const [chapterOneScore, setChapterOneScore] = useState(73.7);
  const [chapterTwoScore, setChapterTwoScore] = useState(0);
  const [chapterThreeScore, setChapterThreeScore] = useState(0);
  const [chapterFourScore, setChapterFourScore] = useState(0);

  const [chapterOneScoreLive, setChapterOneScoreLive] = useState();
  const [chapterTwoScoreLive, setChapterTwoScoreLive] = useState();
  const [chapterThreeScoreLive, setChapterThreeScoreLive] = useState();
  const [chapterFourScoreLive, setChapterFourScoreLive] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const quizUpdate = useSelector((state) => state.quizUpdate); //
  const { success: successUpdate } = quizUpdate;

  const quizList = useSelector((state) => state.quizList);
  const { quizes } = quizList;

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  useEffect(() => {
    dispatch(listQuizes());
  }, [dispatch, userInfo, history]);

  const updateHandler = (e) => {
    const key = generateSecret().toString();
    // e.preventDefault();

    dispatch(
      updateQuizAction(
        id,
        chapterOneScore,
        chapterTwoScore,
        chapterThreeScore,
        chapterFourScore
      )
    )
      .then((response) => {
        if (response) {
          console.log("all jiggy");
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (
      !chapterOneScore ||
      !chapterTwoScore ||
      !chapterThreeScore ||
      !chapterFourScore
    )
      return;

    // resetHandler();
  };

  const showHideState = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const showHideGrid = {
    hide: {
      display: "none",
    },
    show: {
      display: "grid",
    },
  };

  const showHideFlex = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const showHideFlexCol = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
      flexDirection: "column",
    },
  };

  const dianaVreeland = "3wRmpc-Fh3o";

  useEffect(() => {
    document.title = "Art of Looking";
  }, []);

  const [hasQuiz, setHasQuiz] = useState(false);
  const [linkId, setLinkId] = useState();

  useEffect(() => {
    if (Array.isArray(quizes)) {
      if (quizes.length === 0) {
        setHasQuiz((hasQuiz) => false);
      } else if (quizes.length === 1) {
        setLinkId((linkId) => quizes[0]._id);

        setChapterOneScoreLive(quizes[0].chapterOneScore);

        if (chapterOneScoreLive > chapterOneScore) {
          setChapterOneScore(chapterOneScoreLive);
        }

        setChapterTwoScoreLive(quizes[0].chapterTwoScore);

        if (chapterTwoScoreLive > chapterTwoScore) {
          setChapterTwoScore(chapterTwoScoreLive);
        }

        setChapterThreeScoreLive(quizes[0].chapterThreeScore);

        if (chapterThreeScoreLive > chapterThreeScore) {
          setChapterThreeScore(chapterThreeScoreLive);
        }

        setChapterFourScoreLive(quizes[0].chapterFourScore);

        if (chapterFourScoreLive > chapterFourScore) {
          setChapterFourScore(chapterFourScoreLive);
        }
      }
    } else {
      setHasQuiz((hasQuiz) => true);
    }
  });

  return (
    <>
      {userInfo ? <CentralHeader /> : <CentralHeaderLP />}
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "24px",
        }}
      >
        <motion.div
          className="titleDiv"
          animate={titleDiv ? "show" : "hide"}
          variants={showHideState}
          onClick={() => {
            setTitleDiv((titleDiv) => false);
            setWhatYou((whatYou) => true);
          }}
        >
          <h1>Harnessing the Wave</h1>
          <span>
            <strong>Click to get started</strong>
          </span>
        </motion.div>

        <motion.div
          animate={whatYou ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">Waves</h4>
            </div>
            <p>
              <br />
              <a href="https://en.wikipedia.org/wiki/Diana_Vreeland">
                Diana Vreeland
              </a>{" "}
              loved surfers.
              <br />
              <br />
              <strong>
                "You know I am really only envious of one thing and that is a
                surfer. I think it is the most beautiful thing. You see, I'm mad
                about water.[...] and to be surfer, between the sky and water,
                would be, to me, the most wonderful thing"
              </strong>
              <br />
              <br />
              Surfers ably ride the waves the sea, wind and moon create, and
              must experience such flow and serenity in doing so by treating the
              pursuit of a wave, its building and cresting, and the ride towards
              the shore with equal presence, effort and attention.
              <br />
              <br />
              Whilst only fractionally as visceral as the experience of the
              surfer, there is wave phenomenon in your NWD insights that it is
              powerful to understand and harness.
            </p>
          </div>
          <div className="rightCol">
            <div className="rightContent">
              <iframe
                className="videoPlayerQuiz"
                title="Youtube player"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/${dianaVreeland}`}
              ></iframe>
            </div>
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setTitleDiv((titleDiv) => true);
                setWhatYou((whatYou) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setWhatYou((whatYou) => false);
                setMyLearnings((myLearnings) => true);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={myLearnings ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">What is the Wave?</h4>
            </div>
            <div className="mobileImage">
              <motion.img
                className="waveContentChart"
                src="https://cdn.sanity.io/images/ou6d2gec/production/92352c6807b1d65ab4f717520c8c16a36ccf37ac-2434x1110.png"
                width="100%"
              />
            </div>
            <br />
            <p>
              In an early version of NWD, I built a kind of super graph that
              united a few key elements of the then format into one
              visualisation (it is still in the latest version too if you click
              on{" "}
              <strong>
                "What does it look like if you combine all those ideas?"
              </strong>
              ).
              <br />
              <br />
              It mapped my feeling score, my (as it was then know) task
              completed score and task completion rate and shared their combined
              value as a thick pink line.
              <br />
              <br />
              It made for a really interesting check-in point because to see a
              wave crest, and for it to really mean something, I needed to
              really extend myself and demonstrate peak self-awareness and
              action. To do everything you said you would do to feel that sense
              of satisfaction that comes with knowing you did the best you could
              have done, left everything on the table, is no mean feat.
              <br />
              <br />
              As you can see quite clearly now, but was only revealing itself
              slowly and incrementally at the time, there was this wave-like
              pattern emerging.
            </p>
          </div>
          <div className="rightCol">
            <div className="rightContent">
              <p>Sexy Graph Alert!</p>
              <motion.img
                className="waveContentChart"
                src="https://cdn.sanity.io/images/ou6d2gec/production/92352c6807b1d65ab4f717520c8c16a36ccf37ac-2434x1110.png"
                width="100%"
              />
            </div>
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setMyLearnings((myLearnings) => false);
                setWhatYou((whatYou) => true);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setMyLearnings((myLearnings) => false);
                setMyLearnings1((myLearnings1) => true);

                if (chapterOneScore > chapterOneScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterOneScore((chapterOneScore) => 81);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={myLearnings1 ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">
                Harnessing the Waves You Create.
              </h4>
            </div>
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/5e8073d86d5a3fa2704157a8767be53ddeb0dbd7-5000x3250.png"
                width="90%"
              ></img>
            </div>
            <br />
            <p>
              At certain points, I would hit small strings of these peak days
              wherein I took care of every moment, attempted an ambitious
              quality of moment, made time for really suitable recovery and did
              the other things in my life that I was becoming increasingly
              confident contributed to my joy and growth. This would manifest as
              a nice crest on the top of the wave.
            </p>
            <p>
              However, of course, that crest only shares one half of the story?
              What about when the wave breaks?
            </p>
            <hr className="dividingLine" />
            <br />
            <p>
              After a peak series of days and a nice crest, the breaks come
              courtesy of the inevitability that, if you are pushing yourself
              and looking more deeply inside yourself, a cost is going to be
              felt somewhere.
            </p>
            <p>
              Trying to push a little bit harder and do a bit more is naturally
              is going to ask more from...
              <br />
              <br />
              a) your relationship with your self,
              <br />
              b) your overall conditioning at what you are trying to do and
              <br />
              c) your daily habits and rituals
              <br />
              <br />
              ...than was required in the previous span of time.
            </p>
            <p>
              Maybe you achieve what you hoped but you didn't feel as good.
              Maybe you couldn't quite do everything because it was more than
              you had ever tried to do and, on that day, the conditioning
              required to do it, you don't yet possess.
            </p>
            <p>
              Anyway, regardless of the reason, a crashing of the wave is not a
              classic regression to the mean, or fall in motivation, but is
              rather a near inevitable product of trying to find a way to break
              new genuine personal territory.
            </p>
            <p>
              As ever, what the break represents is a <strong>signal</strong>{" "}
              that should prompt some good revealing questions.
            </p>
            <p>
              Just as a surfer, whether he or she crashes off the board in the
              heart of a wave or gamely sails it to shore, that experience is
              engaged with only in the present and the experience itself has
              only moved them closer to greater engagement with the sea in the
              future. Whether successful or not, there are learnings to take
              away regarding performance, perspective and much more.
            </p>
            <p>
              To conclude, the crest of wave is great as it represents hearty
              productivity, awareness and joy. The break is great as it likely
              indicates where your next piece of growth needs to come to move
              towards greater joy and productivity in the future
            </p>
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/5e8073d86d5a3fa2704157a8767be53ddeb0dbd7-5000x3250.png"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setMyLearnings((myLearnings) => true);
                setMyLearnings1((myLearnings1) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setMyLearnings2((myLearnings2) => true);
                setMyLearnings1((myLearnings1) => false);

                if (chapterOneScore > chapterOneScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterOneScore((chapterOneScore) => 88.3);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={myLearnings2 ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">
                "Beginnings always hide themselves in ends."
              </h4>
            </div>
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/dcc77f20254b5d3e3b9b2293b4ab24490c269425-5000x3250.png"
                width="90%"
              ></img>
            </div>
            <br />
            <p>
              The title for this little section comes from a frame at the end of
              song by Mike Posner. Famous for his major hits and club bangers,
              he also has a lane of many thought provoking songs. One of these
              is called 'Live Before I Die'.
            </p>
            <p>
              Having suffered the loss of his father dying at a relatively young
              age, this pain prompted lots of reflections on life and what his
              time meant to him. Since then he has made lots of lifestyle
              changes and walked across America as means of trying to have
              deeper, more trascendent life experiences and improve his
              relationship with himself. This thought that rounds out his video
              is perfect for our wave chapter as well.
            </p>
            <p>
              Each peak, each crest of a wave is quite clearly an end (the
              culmination of time, energy and attention in developing new
              condition and awareness). But, it is also a beginning (the head of
              a route towards the next such plateau - wherever that may be, and
              whatever form that might take).
            </p>
            <p>
              Our brain sometimes kids us, in order to make us more blinkered on
              survival, that certain ends are very final and are the only thing
              that matters. But there is no end that is not a beginning; a
              chance for something new, something greater, something lighter,
              something deeper.
            </p>
            <p>
              To add to this idea of each end presenting a brand new beginning,
              a stanza from the T.S Eliot poem 'Little Gidding' also springs to
              mind:
            </p>
            <p>
              <strong>
                "We shall not cease from exploration
                <br />
                And the end of all our exploring
                <br />
                Will be to arrive where we started
                <br />
                And know the place for the first time."
              </strong>
            </p>
            <p>
              Within the context of the wave graph, each wave can only peak so
              high, and on the face of it, every peak will look the same; like
              you are simply revisiting the same numerical point; banging your
              head against the same graphical ceiling. But the reality is that
              no one wave is ever the same as any before it because you, the
              user, has changed; you are different in some small but significant
              way from before.
            </p>
            <p>
              Growth is a perpetual opportunity. Our lives as humans on earth
              are so rich, diverse and challenging that you could live for a
              million years and still be finding new ways to grow your
              condition, relate to your environment, contend with existence,
              learn new skills and look more deeply inside yourself.
            </p>
            <p>
              Some people see this scale and possibility as overwhelming and
              feel defeated - assuming that, without a fixed end, it is
              impossible to start.
            </p>
            <p>
              I find the opposite is true. To grow each day is a joy of a
              lifetime. It is an opportunity that each day affords. Everything
              you can learn, about something or yourself, offers you a little
              parcel of joy and growth that it is fun to capture. Yes, you will
              never exhaust every option, but you can use this presience to have
              a lot more fun than you may have otherwise had by releasing
              yourself from the shackles of overwhelm, expectations or
              overspecialisation. The growth can also come from huge, concerted
              physical and mental effort, or simply the courage to sit still and
              embrace in-action & uncertainty a bit longer than the day before.
            </p>

            <p>
              To summarise, the wave is a great way to experience and mentally
              manage the transition from one level of awareness, condition and
              assurance to the next. The wave embraces and engages with each
              natural phase, and doesn't obsess over the idea of contentment
              only being possible when you accomplish something, or the point of
              accomplishment being final.
            </p>
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/dcc77f20254b5d3e3b9b2293b4ab24490c269425-5000x3250.png"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setMyLearnings1((myLearnings1) => true);
                setMyLearnings2((myLearnings2) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setChapterOneScore((chapterOneScore) => 95);

                setTimeout(() => {
                  updateHandler();
                  navigate(`/insight/quiz/${linkId}/mindset`);
                }, 1000);
              }}
            />
          </div>
        </motion.div>
      </main>
      <TestFooter />
    </>
  );
}
