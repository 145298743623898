export const DAYS_CREATE_REQUEST = "DAYS_CREATE_REQUEST";
export const DAYS_CREATE_SUCCESS = "DAYS_CREATE_SUCCESS";
export const DAYS_CREATE_FAIL = "DAYS_CREATE_FAIL";

export const DAYS_LIST_REQUEST = "DAYS_LIST_REQUEST";
export const DAYS_LIST_SUCCESS = "DAYS_LIST_SUCCESS";
export const DAYS_LIST_FAIL = "DAYS_LIST_FAIL";

export const DAYS_UPDATE_REQUEST = "DAYS_UPDATE_REQUEST";
export const DAYS_UPDATE_SUCCESS = "DAYS_UPDATE_SUCCESS";
export const DAYS_UPDATE_FAIL = "DAYS_UPDATE_FAIL";
