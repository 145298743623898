//React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

//Actions
import { updateDayAction, listDays } from "../../actions/dayActions";
import { updateWeekAction } from "../../actions/weekActions";

//Fetching Data
import axios from "axios";

//Internal Components
import dateFormatter from "../../components/Functions/DateFormatter";
import FullDock from "../../components/Dock/FullDock";
import MouseTooltip from "../../components/MouseTooltip/MouseTooltip";
import CentralHeader from "../../components/Header/CentralHeader";
import TileLoading from "../../components/Loading/TileLoading";
import TestFooter from "../../components/Footer/TestFooter";
import DisabledDock from "../../components/Dock/DisabledDock";
import MidDock from "../../components/Dock/MidDock";
import {
  FullDockMobileOne,
  FullDockMobileTwo,
} from "../../components/Dock/FullDockMobile";
import generateSecret from "../../utils/generateSecret";
import Objective from "../../components/Day/Objective";
import { TutorialWindow } from "../../components/Tutorial/TutorialWindow";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignature,
  faCalendarDays,
  faCompassDrafting,
  faGlasses,
  faDna,
  faLandmarkDome,
  faQuoteLeft,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import CryptoJS from "crypto-js";

// Styling
import "./dayscreens.css";

export default function UpdateDayScreen({ history }) {
  // Form State
  const [startScore, setStartScore] = useState();
  const [logDate, setLogDate] = useState(() => "2023-01-01");
  const [thankYou, setThankYou] = useState();
  const [selfLess, setSelfLess] = useState();
  const [mindBody, setMindBody] = useState();
  const [momentOne, setMomentOne] = useState();
  const [momentOneScore, setMomentOneScore] = useState();
  const [momentTwo, setMomentTwo] = useState();
  const [momentTwoScore, setMomentTwoScore] = useState();
  const [momentThree, setMomentThree] = useState();
  const [momentThreeScore, setMomentThreeScore] = useState();
  const [momentFour, setMomentFour] = useState();
  const [momentFourScore, setMomentFourScore] = useState();
  const [momentFive, setMomentFive] = useState();
  const [momentFiveScore, setMomentFiveScore] = useState();
  const [rememberToday, setRememberToday] = useState();
  const [leaveBehind, setLeaveBehind] = useState();
  const [onMyMind, setOnMyMind] = useState();
  const [endScore, setEndScore] = useState();
  const [pageLoading, setPageLoading] = useState(true);
  const [parentState, setParentState] = useState("");
  const [saved, setSaved] = useState(false);
  const [continuation, setContinuation] = useState();

  // Dock State
  const [classState, setClassState] = useState("");
  const [cursorState, setCursorState] = useState("");
  const [cursorDisplayState, setCursorDisplayState] = useState(false);

  // State_Week
  const [objectives, setObjectives] = useState();
  const [objectiveOne_score, setObjectiveOne_score] = useState();
  const [objectiveTwo_score, setObjectiveTwo_score] = useState();
  const [objectiveThree_score, setObjectiveThree_score] = useState();
  const [objectiveFour_score, setObjectiveFour_score] = useState();
  const [objectiveFive_score, setObjectiveFive_score] = useState();
  const [objectiveSix_score, setObjectiveSix_score] = useState();
  const [objectiveSeven_score, setObjectiveSeven_score] = useState();
  const [objectiveEight_score, setObjectiveEight_score] = useState();
  const [objectiveNine_score, setObjectiveNine_score] = useState();
  const [objectiveTen_score, setObjectiveTen_score] = useState();
  const [weekId, setWeekId] = useState();

  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [tutorialState, setTutorialState] = useState({
    kick_off: [
      "Your NoWastedDays journal is made up of three powerful pages.",
      "The Priming Page: Built to get your mind warmed up and ready for a healthy, happy day.",
      "The Planning Page: Structured to simplify, streamline and score what you try to do.",
      "The Processing Page: Crafted to capture the distinctness of a day and detach from its destructive moments.",
    ],
    priming_the_day:
      "The Priming Page: built to get your mind ready for a healthy, happy day.",
    diary_entry_date: "Add date for journal entry.",
    start_score_label:
      "Check-in with how you feeling before you start journaling using the slider below.",
    thank_you_what: "Give a bit of gratitude",
    thank_you_why: [
      "Having a daily gratitude routine has been shown to improve overall wellbeing by reducing anxiety, strengthening your connection with others and increasing your resilience. Write in here something for which you feel grateful and would like to give thanks. Mine can range from parts of my body, to inanimate objects, to people, to existence; nothing is too small or too large to be worth your appreciation.",
    ],
    detach_today_what: "Gain some healthy detachment",
    detach_today_why: [
      "Detaching from 'self' means observing your thoughts and emotions without identifying with them. Write in here any useful reminder or helpful instruction that can ensure you maintain this extremely valuable non-judgemental distance. A really common reflection of mine is to enjoy each thing, one at a time versus rushing ahead either through excitement or fear to something in the future.",
    ],
    mind_and_body_what: "Take a moment to appreciate yourself",
    mind_and_body_why: [
      "Unshakeable self-compassion is the result of a lot of small acts of kindness. Your mind and your body are where you live and, as such, are the spaces most richly deserving of kindness and compassion. Write in here why you are happy to live in your mind and body today. I often say, 'because they are here; here with me.' as a kind nod to how my brain and body have navigated me to this very point. Not to say that this point is some laudable, or that I am free of scars; but I am simply here.",
    ],
    dock_one: [
      "Next, the journal dock.",
      "Your journal dock plays a hugely important role in improving the impact of your daily journal.",
      "After you have added them to your map, there is no better way to ensure that your vision for the year, personal values, ikigai, favourite quote are extremely present when making and reviewing a plan for the day.",
    ],
    dock_two: [
      "This section of journal dock gives you access to all of your weekly objectives that you have put into your planner",
      "Click on a node to see the assigned objective, and the tasks you have set yourself to realise it.",
      "Then click on the journal button to add the task into your plan for the day.",
      "Each node fills up as you complete the tasks you have set yourself in each objective. It is weirdly motivating to try and fill them up on any given week!",
    ],
    planning_the_day:
      "The Planning Page: to simplify, streamline and score your day.",
    moment_one_text:
      "Use the 5 spaces to divide up your day into do-able chunks",
    moment_two_text:
      "and use the sliders to score each as you go to see how you got on.",
    moment_three_text: "Don't worry. A lot more matters than...",
    moment_four_text: "...getting everything done everyday.",
    moment_five_text: "Just do your best and learn from the rest.",
    processing_the_day:
      "The Processing Page: to capture day in both its cherished memories to hold closely and destructive feelings to leave behind.",
    memories_today_what: "Capture the core memories from the day.",
    memories_today_why: [
      "Which set of events and reflections capture the very distinct, unique day you've just had? Don't be afraid of detail here as your reflections will be a really helpful tool for learning about your days in the future.",
    ],
    leave_behind_what: "Treat yourself to leaving things behind",
    leave_behind_why: [
      "Don't carry with you anything you could leave behind today. Any thought, feeling, reaction, action that you don't want to repeat or don't need to carry, take a moment to leave it behind.",
    ],
    on_my_mind_what: "Clear your mind for tomorrow.",
    on_my_mind_why: [
      "Externalising your thoughts helps you think more clearly, see things more objectively and sleep much better. Don't let a thought bumble around in your brain when it doesn't need to be there.",
    ],
    end_score: "Then, lastly, score the day overall from 0.0 to 5.0",
    insights_hub: [
      "Each day you log becomes an invaluable data point in your insights.",
      "Log 7 days to unlock your own insights and personal coaching story.",
    ],
  });

  //Actions & Package Calls
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const weekList = useSelector((state) => state.weekList);
  const { weeks } = weekList;

  const dayList = useSelector((state) => state.dayList); //
  const { loading, error, days } = dayList;

  const dayUpdate = useSelector((state) => state.dayUpdate);
  const { success: successUpdate } = dayUpdate;

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo, history]);

  //Event Handlers
  const key = generateSecret().toString();

  useEffect(() => {
    const fetching = async () => {
      const key = generateSecret().toString();

      try {
        const { data } = await axios.get(
          `https://nwd-backend.herokuapp.com/api/days/today/${id}`
        );
        setLogDate(data.logDate);
        setStartScore(data.startScore);
        setThankYou(
          CryptoJS.AES.decrypt(data.thankYou, key).toString(CryptoJS.enc.Utf8)
        );
        setSelfLess(
          CryptoJS.AES.decrypt(data.selfLess, key).toString(CryptoJS.enc.Utf8)
        );
        setMindBody(
          CryptoJS.AES.decrypt(data.mindBody, key).toString(CryptoJS.enc.Utf8)
        );
        setMomentOne(
          CryptoJS.AES.decrypt(data.momentOne, key).toString(CryptoJS.enc.Utf8)
        );
        setMomentOneScore(data.momentOneScore);
        setMomentTwo(
          CryptoJS.AES.decrypt(data.momentTwo, key).toString(CryptoJS.enc.Utf8)
        );
        setMomentTwoScore(data.momentTwoScore);
        setMomentThree(
          CryptoJS.AES.decrypt(data.momentThree, key).toString(
            CryptoJS.enc.Utf8
          )
        );
        setMomentThreeScore(data.momentThreeScore);
        setMomentFour(
          CryptoJS.AES.decrypt(data.momentFour, key).toString(CryptoJS.enc.Utf8)
        );
        setMomentFourScore(data.momentFourScore);
        setMomentFive(
          CryptoJS.AES.decrypt(data.momentFive, key).toString(CryptoJS.enc.Utf8)
        );
        setMomentFiveScore(data.momentFiveScore);
        setRememberToday(
          CryptoJS.AES.decrypt(data.rememberToday, key).toString(
            CryptoJS.enc.Utf8
          )
        );
        setLeaveBehind(
          CryptoJS.AES.decrypt(data.leaveBehind, key).toString(
            CryptoJS.enc.Utf8
          )
        );

        if (data.onMyMind) {
          setOnMyMind(
            CryptoJS.AES.decrypt(data.onMyMind, key).toString(CryptoJS.enc.Utf8)
          );
        }

        setEndScore(data.endScore);
      } catch (error) {
        console.log(error);
      }
    };
    fetching();
  }, [id]);

  const deleteStep = (columnId, subTaskIndex, stepIndex) => {
    const updatedObjectives = { ...objectives };
    updatedObjectives.objectives[columnId].subTasks[subTaskIndex].steps.splice(
      stepIndex,
      1
    );
    setObjectives(updatedObjectives);
  };

  const deleteSubTask = (columnId, subtaskIndex) => {
    const updatedObjectives = { ...objectives };
    updatedObjectives.objectives[columnId].subTasks.splice(subtaskIndex, 1);
    setObjectives(updatedObjectives);
  };

  const updateDescription = (event, columnId, index) => {
    let newDescription = event.target.value;

    setObjectives((prevState) => {
      const updatedObjectives = { ...prevState };
      const task = updatedObjectives.objectives[columnId];
      if (task && task.subTasks[index]) {
        task.subTasks[index].description = newDescription;
      }
      return updatedObjectives;
    });
  };

  const updateStep = (columnId, subTaskIndex, stepIndex, event) => {
    const { value } = event.target;
    setObjectives((prevObjectives) => {
      const newObjectives = { ...prevObjectives };
      newObjectives.objectives[columnId].subTasks[subTaskIndex].steps[
        stepIndex
      ].action = value;
      return newObjectives;
    });
  };

  const updateNotes = (columnId, index, event) => {
    let newNotes = event.target.value;

    const updatedObjectives = { ...objectives };
    updatedObjectives.objectives[columnId].subTasks[index] = {
      ...updatedObjectives.objectives[columnId].subTasks[index],
      notes: newNotes,
    };
    setObjectives(updatedObjectives);
  };

  let datesArr = [];
  let state = "";
  days && days.forEach((d) => datesArr.push(d.logDate.substring(0, 10)));

  if (datesArr.includes(logDate) || datesArr.includes(logDate < 2023)) {
    state = "disabled";
  }

  const toggleCompletedSubTasks = (index, columnId) => {
    // Assuming objectives and setObjectives are defined in your component
    // Make sure objectives is a state variable and setObjectives is a setState function

    // Make a copy of objectives[columnId]
    const columnObjectives = { ...objectives.objectives[columnId] };

    // Ensure columnObjectives has a subTasks array
    if (columnObjectives && Array.isArray(columnObjectives.subTasks)) {
      // Toggle the completed value of the specified subTask
      columnObjectives.subTasks[index].completed =
        columnObjectives.subTasks[index].completed === 0 ? 1 : 0;

      // Update the state with the new copy
      setObjectives((prevObjectives) => ({
        ...prevObjectives,
        [columnId]: columnObjectives,
      }));
    } else {
      console.error(
        `Invalid columnId or subTasks array not found for column ${columnId}`
      );
    }
  };

  const toggleStep = (subTaskIndex, stepIndex, columnId) => {
    // Make a copy of objectives
    const updatedObjectives = { ...objectives };
    const columnObjectives = updatedObjectives.objectives[columnId];

    // Ensure columnObjectives has a subTasks array
    if (columnObjectives && Array.isArray(columnObjectives.subTasks)) {
      // Make a copy of subTasks array
      const updatedSubTasks = [...columnObjectives.subTasks];
      // Make a copy of steps array
      const updatedSteps = [...updatedSubTasks[subTaskIndex].steps];

      // Toggle the completed value of the specified subTask
      updatedSteps[stepIndex] = {
        ...updatedSteps[stepIndex],
        completed: updatedSteps[stepIndex].completed === 0 ? 1 : 0,
      };

      // Update the subTasks array with the new copy
      updatedSubTasks[subTaskIndex] = {
        ...updatedSubTasks[subTaskIndex],
        steps: updatedSteps,
      };

      // Update the columnObjectives with the new subTasks array
      updatedObjectives.objectives[columnId] = {
        ...columnObjectives,
        subTasks: updatedSubTasks,
      };

      // Update the state with the new copy
      setObjectives(updatedObjectives);
    } else {
      console.error(
        `Invalid columnId or subTasks array not found for column ${columnId}`
      );
    }
  };

  const updateHandler = (e) => {
    const key = generateSecret().toString();

    // e.preventDefault();

    dispatch(
      updateDayAction(
        id,
        logDate,
        startScore,
        CryptoJS.AES.encrypt(thankYou, key).toString(),
        CryptoJS.AES.encrypt(selfLess, key).toString(),
        CryptoJS.AES.encrypt(mindBody, key).toString(),
        CryptoJS.AES.encrypt(momentOne, key).toString(),
        momentOneScore,
        CryptoJS.AES.encrypt(momentTwo, key).toString(),
        momentTwoScore,
        CryptoJS.AES.encrypt(momentThree, key).toString(),
        momentThreeScore,
        CryptoJS.AES.encrypt(momentFour, key).toString(),
        momentFourScore,
        CryptoJS.AES.encrypt(momentFive, key).toString(),
        momentFiveScore,
        CryptoJS.AES.encrypt(rememberToday, key).toString(),
        CryptoJS.AES.encrypt(leaveBehind, key).toString(),
        CryptoJS.AES.encrypt(onMyMind, key).toString(),
        endScore
      )
    )
      .then((response) => {
        if (response) {
          console.log("all jiggy");
          setParentState("");
          setSaved(true);
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (
      !logDate ||
      !startScore ||
      !thankYou ||
      !selfLess ||
      !mindBody ||
      !momentOne ||
      !momentOneScore ||
      !momentTwo ||
      !momentTwoScore ||
      !momentThree ||
      !momentThreeScore ||
      !momentFour ||
      !momentFourScore ||
      !momentFive ||
      !momentFiveScore ||
      !rememberToday ||
      !leaveBehind ||
      !onMyMind ||
      !endScore
    )
      return;

    // resetHandler();
  };

  const path = document.referrer;

  const loadingTimeout = () => {
    if (path.endsWith("/today")) {
      setTimeout(() => {
        if (Array.isArray(days)) {
          setPageLoading(false);
        }
      }, 0);
    } else {
      var randNum = Math.random(1) * 3000 + Math.random(1) * 3000 + 6000;
      setTimeout(() => {
        if (days) {
          setPageLoading(false);
        }
      }, randNum);
    }
  };

  const closeMomentState = () => {
    setMomentSelect(false);
  };

  const logoObj = {
    name: faSignature,
    dob: faCalendarDays,
    values: faCompassDrafting,
    vision: faGlasses,
    ikigai: faDna,
    memories: faLandmarkDome,
    quote: faQuoteLeft,
  };

  const [fontState, setFontState] = useState();
  const [objectiveData, setObjectiveData] = useState([
    {
      active: 0,
      name: "",
      subTasks: [],
    },
  ]);
  const [objectiveState, setObjectiveState] = useState(false);
  const [objectiveNum, setObjectiveNum] = useState();
  const [selected, setSelected] = useState("");
  const [momentSelect, setMomentSelect] = useState(false);

  const closeObjectiveState = () => {
    setObjectiveState(false);
  };

  const handleSelect = (event) => {
    setSelected(event.target.value);
    setMomentSelect(true);
  };

  useEffect(() => {
    const fetching = async () => {
      const key = generateSecret().toString();
      try {
        let data = weeks
          ? weeks.length === 1
            ? await weeks[0]
            : await weeks
                .filter(
                  (week, i, weeks) => weeks.indexOf(week) === weeks.length - 1
                )
                .pop()
          : {};

        if (typeof data === "object" && data !== null && data) {
          setWeekId(data._id);

          setObjectives(
            JSON.parse(
              CryptoJS.AES.decrypt(data.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );
          setObjectiveOne_score(Number(data.objectiveOne_score));
          setObjectiveTwo_score(Number(data.objectiveTwo_score));
          setObjectiveThree_score(Number(data.objectiveThree_score));
          setObjectiveFour_score(Number(data.objectiveFour_score));
          setObjectiveFive_score(Number(data.objectiveFive_score));
          setObjectiveSix_score(Number(data.objectiveSix_score));
          setObjectiveSeven_score(Number(data.objectiveSeven_score));
          setObjectiveEight_score(Number(data.objectiveEight_score));
          setObjectiveNine_score(Number(data.objectiveNine_score));
          setObjectiveTen_score(Number(data.objectiveTen_score));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetching();
  }, [weeks, weekId]);

  const weekHandler = (e) => {
    // e.preventDefault();
    const key = generateSecret().toString();
    let id = weekId && weekId;

    try {
      dispatch(
        updateWeekAction(
          id,
          CryptoJS.AES.encrypt(JSON.stringify(objectives), key).toString(),
          Number(objectiveOne_score).toString(),
          Number(objectiveTwo_score).toString(),
          Number(objectiveThree_score).toString(),
          Number(objectiveFour_score).toString(),
          Number(objectiveFive_score).toString(),
          Number(objectiveSix_score).toString(),
          Number(objectiveSeven_score).toString(),
          Number(objectiveEight_score).toString(),
          Number(objectiveNine_score).toString(),
          Number(objectiveTen_score).toString()
        )
      ).then((response) => {
        if (response) {
          setSaved(true);
        } else {
          console.log("something still not right");
        }
      });
    } catch (error) {
      console.error(error);
    }

    if (
      !objectives ||
      !objectiveOne_score ||
      !objectiveTwo_score ||
      !objectiveThree_score ||
      !objectiveFour_score ||
      !objectiveFive_score ||
      !objectiveSix_score ||
      !objectiveSeven_score ||
      !objectiveEight_score ||
      !objectiveNine_score ||
      !objectiveTen_score
    )
      return;
  };

  const handleDescriptionChange = (event, columnId) => {
    const descriptionValue = event.target.value;

    if (event.key === "Enter") {
      setObjectives((prevState) => ({
        ...prevState,
        objectives: {
          ...prevState.objectives,
          [columnId]: {
            ...prevState.objectives[columnId],
            subTasks: [
              ...(prevState.objectives[columnId].subTasks || []), // Ensure prevState.objectives[columnId].subTasks is initialized as an array
              {
                description: descriptionValue,
                steps: [],
                notes: "",
                completed: 0,
              },
            ],
          },
        },
      }));
      event.target.value = "";
    }
  };

  const handleSubTaskReorder = (columnId, subTaskIndex, direction) => {
    setObjectives((prevObjectives) => {
      let lastIndex = prevObjectives.objectives[columnId].subTasks.length - 1;

      let newIndex =
        direction === "up"
          ? subTaskIndex === 0
            ? subTaskIndex
            : subTaskIndex - 1
          : subTaskIndex === lastIndex
            ? subTaskIndex
            : subTaskIndex + 1;

      return {
        ...prevObjectives,
        objectives: {
          ...prevObjectives.objectives,
          [columnId]: {
            ...prevObjectives.objectives[columnId],
            subTasks: prevObjectives.objectives[columnId].subTasks.map(
              (task, index) => {
                if (index === subTaskIndex) {
                  return prevObjectives.objectives[columnId].subTasks[newIndex];
                } else if (index === newIndex) {
                  return prevObjectives.objectives[columnId].subTasks[
                    subTaskIndex
                  ];
                } else {
                  return task;
                }
              }
            ),
          },
        },
      };
    });
  };

  const handleStepReorder = (columnId, subTaskIndex, stepIndex, direction) => {
    const updatedObjectives = { ...objectives };
    const targetSubTask =
      updatedObjectives.objectives[columnId].subTasks[subTaskIndex];
    const steps = targetSubTask.steps;

    // Check if the step can be moved in the specified direction
    if (
      (direction === "up" && stepIndex === 0) ||
      (direction === "down" && stepIndex === steps.length - 1)
    ) {
      return; // Cannot move step further
    }

    // Swap steps
    const targetStep = steps[stepIndex];
    const adjacentStep =
      steps[direction === "up" ? stepIndex - 1 : stepIndex + 1];
    steps[stepIndex] = adjacentStep;
    steps[direction === "up" ? stepIndex - 1 : stepIndex + 1] = targetStep;

    setObjectives(updatedObjectives); // Update state
  };

  const stateChange = (num) => {
    let moment = num;

    switch (moment) {
      case 1:
        if (momentOne === "") {
          setMomentOne(selected);
          setMomentSelect(false);
        } else {
          setMomentOne((prevState) => prevState + " + " + selected);
          setMomentSelect(false);
        }
        break;
      case 2:
        if (momentTwo === "") {
          setMomentTwo(selected);
          setMomentSelect(false);
        } else {
          setMomentTwo((prevState) => prevState + " + " + selected);
          setMomentSelect(false);
        }
        break;
      case 3:
        if (momentThree === "") {
          setMomentThree(selected);
          setMomentSelect(false);
        } else {
          setMomentThree((prevState) => prevState + " + " + selected);
          setMomentSelect(false);
        }
        break;
      case 4:
        if (momentFour === "") {
          setMomentFour(selected);
          setMomentSelect(false);
        } else {
          setMomentFour((prevState) => prevState + " + " + selected);
          setMomentSelect(false);
        }
        break;
      case 5:
        if (momentFive === "") {
          setMomentFive(selected);
          setMomentSelect(false);
        } else {
          setMomentFive((prevState) => prevState + " + " + selected);
          setMomentSelect(false);
        }
        break;
      default:
        console.log("fuck");
    }
  };

  const cursorDisplayHide = () => {
    setCursorDisplayState((cursorDisplayState) => false);
    setObjectiveState((objectiveState) => false);
  };

  const cursorChangeName = () => {
    setLogoState((logoState) => logoObj.name);

    let name =
      years &&
      years
        .filter((year, i, years) => years.indexOf(year) === years.length - 1)
        .map((year) =>
          CryptoJS.AES.decrypt(year.yourName, key).toString(CryptoJS.enc.Utf8)
        );

    const birthDate =
      years &&
      years
        .filter((year, i, years) => years.indexOf(year) === years.length - 1)
        .map((year) => year.birthDate.substring(0, 10));

    const dateString = birthDate;
    const date = new Date(dateString);

    const formattedDate = dateFormatter(dateString);

    let day1 = new Date(birthDate);
    let day2 = new Date();
    let diff = Math.abs(day2 - day1);
    let daysCalc = diff / (1000 * 3600 * 24);
    let days1 = Math.floor(daysCalc);

    setCursorState(
      (cursorState) =>
        `I am ${name}. I was born on ${formattedDate}. Today is day #${days1} of my life.`
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const contFunc = () => {
    const birthDate =
      years &&
      years
        .filter((year, i, years) => years.indexOf(year) === years.length - 1)
        .map((year) => year.birthDate.substring(0, 10));

    if (birthDate) {
      if (logDate) {
        let dayOne = new Date(birthDate);
        let dayTwo = new Date(logDate);
        let difference = Math.abs(dayTwo - dayOne);
        let daysCalculated = difference / (1000 * 3600 * 24);
        let daysOne = Math.floor(daysCalculated);
        setContinuation(daysOne);
      } else {
        let day1 = new Date(birthDate);
        let day2 = new Date();
        let diff = Math.abs(day2 - day1);
        let daysCalc = diff / (1000 * 3600 * 24);
        let days1 = Math.floor(daysCalc);
        setContinuation(days1);
      }
    } else {
      setContinuation("");
    }
  };

  const namePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map((year) =>
            CryptoJS.AES.decrypt(year.yourName, key).toString(CryptoJS.enc.Utf8)
          )
      );

      if (name[0].length >= 1) {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeDOB = () => {
    setLogoState((logoState) => logoObj.dob);

    const birthDate =
      years &&
      years
        .filter((year, i, years) => years.indexOf(year) === years.length - 1)
        .map((year) => year.birthDate.substring(0, 10));

    const dateString = birthDate;
    const date = new Date(dateString);

    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const monthsOfYear = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate() + 1;
    const month = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    let suffix;
    if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
      suffix = "st";
    } else if (dayOfMonth === 2 || dayOfMonth === 22) {
      suffix = "nd";
    } else if (dayOfMonth === 3 || dayOfMonth === 23) {
      suffix = "rd";
    } else {
      suffix = "th";
    }

    const formattedDate = `${dayOfWeek} ${dayOfMonth}${suffix} ${month} ${year}`;

    setCursorState((cursorState) => formattedDate);

    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const dobPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              years && years.map((year) => year.birthDate.substring(0, 10))
          )
      );

      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeValuesOne = () => {
    setLogoState((logoState) => logoObj.values);
    setCursorState(
      (cursorState) =>
        years &&
        years
          .filter((year, i, years) => years.indexOf(year) === years.length - 1)
          .map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myValues_1, key).toString(
                CryptoJS.enc.Utf8
              )}: ${CryptoJS.AES.decrypt(year.myValues_1_Text, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const valuesOnePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myValues_1, key).toString(
                CryptoJS.enc.Utf8
              )}: ${CryptoJS.AES.decrypt(year.myValues_1_Text, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
      );
      if (name[0].length >= 1 && name[0] != ":") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeValuesTwo = () => {
    setLogoState((logoState) => logoObj.values);
    setCursorState(
      (cursorState) =>
        years &&
        years
          .filter((year, i, years) => years.indexOf(year) === years.length - 1)
          .map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myValues_2, key).toString(
                CryptoJS.enc.Utf8
              )}: ${CryptoJS.AES.decrypt(year.myValues_2_Text, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const valuesTwoPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myValues_2, key).toString(
                CryptoJS.enc.Utf8
              )}: ${CryptoJS.AES.decrypt(year.myValues_2_Text, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
      );
      if (name[0].length >= 1 && name[0] != ":") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorYearAlert = () => {
    setCursorState(
      (cursorState) => "Create your year map to access this in the dock."
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorWeekAlert = () => {
    setCursorState(
      (cursorState) => "Create your week planner to access this in the dock."
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const cursorChangeValuesThree = () => {
    setLogoState((logoState) => logoObj.values);
    setCursorState(
      (cursorState) =>
        years &&
        years
          .filter((year, i, years) => years.indexOf(year) === years.length - 1)
          .map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myValues_3, key).toString(
                CryptoJS.enc.Utf8
              )}: ${CryptoJS.AES.decrypt(year.myValues_3_Text, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const valuesThreePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myValues_3, key).toString(
                CryptoJS.enc.Utf8
              )}: ${CryptoJS.AES.decrypt(year.myValues_3_Text, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
      );
      if (name[0].length >= 1 && name[0] != ":") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeValuesFour = () => {
    setLogoState((logoState) => logoObj.values);
    setCursorState(
      (cursorState) =>
        years &&
        years
          .filter((year, i, years) => years.indexOf(year) === years.length - 1)
          .map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myValues_4, key).toString(
                CryptoJS.enc.Utf8
              )}: ${CryptoJS.AES.decrypt(year.myValues_4_Text, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const valuesFourPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myValues_4, key).toString(
                CryptoJS.enc.Utf8
              )}: ${CryptoJS.AES.decrypt(year.myValues_4_Text, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
      );

      if (name[0].length >= 1 && name[0] != ":") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeValuesFive = () => {
    setLogoState((logoState) => logoObj.values);
    setCursorState(
      (cursorState) =>
        years &&
        years
          .filter((year, i, years) => years.indexOf(year) === years.length - 1)
          .map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myValues_5, key).toString(
                CryptoJS.enc.Utf8
              )}: ${CryptoJS.AES.decrypt(year.myValues_5_Text, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const valuesFivePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myValues_5, key).toString(
                CryptoJS.enc.Utf8
              )}: ${CryptoJS.AES.decrypt(year.myValues_5_Text, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
      );
      if (name[0].length >= 1 && name[0] != ":") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeVisionFive = () => {
    setLogoState((logoState) => logoObj.vision);
    setCursorState(
      (cursorState) =>
        years &&
        years
          .filter((year, i, years) => years.indexOf(year) === years.length - 1)
          .map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myVision_5, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const visionFivePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              years &&
              years.map((year) =>
                CryptoJS.AES.decrypt(year.myVision_5, key).toString(
                  CryptoJS.enc.Utf8
                )
              )
          )
      );
      if (name[0].length >= 1 && name[0] != ":") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeVisionOne = () => {
    setLogoState((logoState) => logoObj.vision);
    setCursorState(
      (cursorState) =>
        years &&
        years
          .filter((year, i, years) => years.indexOf(year) === years.length - 1)
          .map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myVision_1, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const visionOnePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              years &&
              years.map((year) =>
                CryptoJS.AES.decrypt(year.myVision_1, key).toString(
                  CryptoJS.enc.Utf8
                )
              )
          )
      );
      if (name[0].length >= 1 && name[0] != ":") {
        boolean = true;
      }
    }
    return boolean;
  };

  const getBackgroundStyle = (data) => {
    const percent = (data / 10) * 100; // Calculate percentage value
    if (tutorialOpen) {
      return `linear-gradient(to right, rgba(226, 223, 207, 0.5) ${percent}%, transparent ${percent}%)`;
    } else {
      return `linear-gradient(to right, rgba(0, 74, 35, 0.5) ${percent}%, transparent ${percent}%)`;
    }
  };

  const getBackgroundStyleTwo = (data) => {
    const percent = (data / 5) * 100; // Calculate percentage value
    if (tutorialOpen) {
      return `linear-gradient(to right, rgba(226, 223, 207, 0.5) ${percent}%, transparent ${percent}%)`;
    } else {
      return `linear-gradient(to right, rgba(0, 74, 35, 0.5) ${percent}%, transparent ${percent}%)`;
    }
  };

  const cursorChangeVisionTwo = () => {
    setLogoState((logoState) => logoObj.vision);
    setCursorState(
      (cursorState) =>
        years &&
        years
          .filter((year, i, years) => years.indexOf(year) === years.length - 1)
          .map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myVision_2, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const visionTwoPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              years &&
              years.map((year) =>
                CryptoJS.AES.decrypt(year.myVision_2, key).toString(
                  CryptoJS.enc.Utf8
                )
              )
          )
      );
      if (name[0].length >= 1 && name[0] != ":") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeVisionThree = () => {
    setLogoState((logoState) => logoObj.vision);
    setCursorState(
      (cursorState) =>
        years &&
        years
          .filter((year, i, years) => years.indexOf(year) === years.length - 1)
          .map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myVision_3, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const visionThreePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              years &&
              years.map((year) =>
                CryptoJS.AES.decrypt(year.myVision_3, key).toString(
                  CryptoJS.enc.Utf8
                )
              )
          )
      );
      if (name[0].length >= 1 && name[0] != ":") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeVisionFour = () => {
    setLogoState((logoState) => logoObj.vision);
    setCursorState(
      (cursorState) =>
        years &&
        years
          .filter((year, i, years) => years.indexOf(year) === years.length - 1)
          .map(
            (year) =>
              `${CryptoJS.AES.decrypt(year.myVision_4, key).toString(
                CryptoJS.enc.Utf8
              )}`
          )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const visionFourPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map(
            (year) =>
              years &&
              years.map((year) =>
                CryptoJS.AES.decrypt(year.myVision_4, key).toString(
                  CryptoJS.enc.Utf8
                )
              )
          )
      );
      if (name[0].length >= 1 && name[0] != ":") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeIkigai = () => {
    setLogoState((logoState) => logoObj.ikigai);
    setCursorState(
      (cursorState) =>
        years &&
        years.map((year) =>
          CryptoJS.AES.decrypt(year.myIkigai, key).toString(CryptoJS.enc.Utf8)
        )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const ikigaiPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map((year) =>
            CryptoJS.AES.decrypt(year.myIkigai, key).toString(CryptoJS.enc.Utf8)
          )
      );
      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeQuote = () => {
    setLogoState((logoState) => logoObj.quote);
    setCursorState(
      (cursorState) =>
        years &&
        years
          .filter((year, i, years) => years.indexOf(year) === years.length - 1)
          .map((year) =>
            CryptoJS.AES.decrypt(year.navigationalQuote, key).toString(
              CryptoJS.enc.Utf8
            )
          )
    );
    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const quotePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (years) {
      let name = [];
      name.push(
        years &&
          years.map((year) =>
            CryptoJS.AES.decrypt(year.navigationalQuote, key).toString(
              CryptoJS.enc.Utf8
            )
          )
      );
      if (name[0].length >= 1 && name[0] != "") {
        boolean = true;
      }
    }
    return boolean;
  };

  const cursorChangeMemory = () => {
    setLogoState((logoState) => logoObj.memories);

    let memArray = [];
    let memArrayFinal = [];

    memArray.push(days && days.map((day) => day.rememberToday));

    for (let i = 0; i < memArray[0].length; i++) {
      if (memArray[0][i].includes(" ")) {
        memArrayFinal.push(memArray[0][i]);
      } else {
        memArrayFinal.push(
          CryptoJS.AES.decrypt(memArray[0][i], key).toString(CryptoJS.enc.Utf8)
        );
      }
    }

    if (memArrayFinal.length > 1) {
      memArrayFinal.filter((mem) => mem.length >= 1);
    }

    let memorySelected = memArrayFinal
      .filter(
        (day, i, days) =>
          days.indexOf(day) === Math.floor(Math.random() * days.length)
      )
      .filter((day, i, days) => days.indexOf(day) === 0)
      .map((day, index, days) => day);

    if (
      memorySelected === [] ||
      memorySelected === "" ||
      memorySelected === " " ||
      memorySelected.length === 0
    ) {
      setCursorState("No memory on this day. Click again");
    } else {
      setCursorState((cursorState) => memorySelected);
      setMemoryPresentState(true);
    }

    {
      cursorDisplayState == false
        ? setCursorDisplayState((cursorDisplayState) => true)
        : setCursorDisplayState((cursorDisplayState) => true);
    }
  };

  const [memoryPresentState, setMemoryPresentState] = useState(false);

  // const memoryPresent = () => {

  //   let name = [];
  //   name = days && name.push(days.map((day) => day.rememberToday));

  //     if (name.length >= 2) {
  //       setMemoryPresentState(memoryPresentState => true);
  //     };

  // }

  // useEffect(()=>{
  //   memoryPresent();
  // },[]);

  const showHideState = {
    hide: {
      display: "none",
    },
    show: {
      display: "block",
    },
  };

  const addObjective = () => {
    if (cursorState) {
      if (!momentOne) {
        setMomentOne((momentOne) => cursorState[0]);
        console.log(cursorState);
      } else if (momentOne && momentTwo && momentThree && momentFour) {
        setMomentFive((momentFive) => cursorState[0]);
      } else if (momentOne && momentTwo && momentThree) {
        setMomentFour((momentFour) => cursorState[0]);
      } else if (momentOne && momentTwo) {
        setMomentThree((momentThree) => cursorState[0]);
        console.log(cursorState);
      } else if (momentOne) {
        setMomentTwo((momentTwo) => cursorState[0]);
        console.log(cursorState);
      } else {
        setMomentFive((momentFive) => cursorState[0]);
      }
    }
  };

  const cursorChangeObjOne = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setObjectiveData((objectiveData) => {
      let obj =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );
      return obj[0].objectives["obj-one"].subTasks;
    });
    setObjectiveNum(1);

    objectiveState === false && setObjectiveState(true);
  };

  const objOnePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (Array.isArray(weeks) && objectives) {
      let week =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );

      if (
        week[0].objectives["obj-one"] &&
        week[0].objectives["obj-one"].subTasks &&
        week[0].objectives["obj-one"].subTasks.length > 0
      ) {
        if (week[0].columns["obj-one"].title !== "") {
          boolean = true;
        }
      }
    }

    return boolean;
  };

  const cursorChangeObjTwo = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setObjectiveData((objectiveData) => {
      let obj =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );
      return obj[0].objectives["obj-two"].subTasks;
    });
    setObjectiveNum(2);

    objectiveState === false && setObjectiveState(true);
  };

  const objTwoPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (Array.isArray(weeks) && objectives) {
      let week =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );

      if (
        week[0].objectives["obj-two"] &&
        week[0].objectives["obj-two"].subTasks &&
        week[0].objectives["obj-two"].subTasks.length > 0
      ) {
        if (week[0].columns["obj-two"].title !== "") {
          boolean = true;
        }
      }
    }

    return boolean;
  };

  const cursorChangeObjThree = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setObjectiveData((objectiveData) => {
      let obj =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );
      return obj[0].objectives["obj-three"].subTasks;
    });
    setObjectiveNum(3);

    objectiveState === false && setObjectiveState(true);
  };

  const objThreePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (Array.isArray(weeks) && objectives) {
      let week =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );
      if (
        week[0].objectives["obj-three"] &&
        week[0].objectives["obj-three"].subTasks &&
        week[0].objectives["obj-three"].subTasks.length > 0
      ) {
        if (week[0].columns["obj-three"].title !== "") {
          boolean = true;
        }
      }
    }

    return boolean;
  };

  const cursorChangeObjFour = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setObjectiveData((objectiveData) => {
      let obj =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );
      return obj[0].objectives["obj-four"].subTasks;
    });
    setObjectiveNum(4);

    objectiveState === false && setObjectiveState(true);
  };

  const objFourPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;

    if (Array.isArray(weeks) && objectives) {
      let week =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );

      if (
        week[0].objectives["obj-four"] &&
        week[0].objectives["obj-four"].subTasks &&
        week[0].objectives["obj-four"].subTasks.length > 0
      ) {
        if (week[0].columns["obj-four"].title !== "") {
          boolean = true;
        }
      }
    }

    return boolean;
  };

  const cursorChangeObjFive = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setObjectiveData((objectiveData) => {
      let obj =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );
      return obj[0].objectives["obj-five"].subTasks;
    });
    setObjectiveNum(5);

    objectiveState === false && setObjectiveState(true);
  };

  const objFivePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (Array.isArray(weeks) && objectives) {
      let week =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );

      if (
        week[0].objectives["obj-five"] &&
        week[0].objectives["obj-five"].subTasks &&
        week[0].objectives["obj-five"].subTasks.length > 0
      ) {
        if (week[0].columns["obj-five"].title !== "") {
          boolean = true;
        }
      }
    }

    return boolean;
  };

  const cursorChangeObjSix = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setObjectiveData((objectiveData) => {
      let obj =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );
      return obj[0].objectives["obj-six"].subTasks;
    });
    setObjectiveNum(6);

    objectiveState === false && setObjectiveState(true);
  };

  const objSixPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (Array.isArray(weeks) && objectives) {
      let week =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );

      if (
        week[0].objectives["obj-six"] &&
        week[0].objectives["obj-six"].subTasks &&
        week[0].objectives["obj-six"].subTasks.length > 0
      ) {
        if (week[0].columns["obj-six"].title !== "") {
          boolean = true;
        }
      }
    }

    return boolean;
  };

  const cursorChangeObjSeven = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setObjectiveData((objectiveData) => {
      let obj =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );
      return obj[0].objectives["obj-seven"].subTasks;
    });
    setObjectiveNum(7);

    objectiveState === false && setObjectiveState(true);
  };

  const objSevenPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (Array.isArray(weeks) && objectives) {
      let week =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );

      if (
        week[0].objectives["obj-seven"] &&
        week[0].objectives["obj-seven"].subTasks &&
        week[0].objectives["obj-seven"].subTasks.length > 0
      ) {
        if (week[0].columns["obj-seven"].title !== "") {
          boolean = true;
        }
      }
    }

    return boolean;
  };

  const tutorialFlag = () => {
    const key = generateSecret().toString();
    let weekBoolean = false;
    let yearBoolean = false;
    let dayBoolean = false;
    let overallBoolean = false;

    if (weeks) {
      let week = [];
      week.push(
        weeks &&
          weeks
            .filter(
              (week, i, weeks) => weeks.indexOf(week) === weeks.length - 1
            )
            .map((week) =>
              JSON.parse(
                CryptoJS.AES.decrypt(week.objectives, key).toString(
                  CryptoJS.enc.Utf8
                )
              )
            )
      );

      if (week.length > 0) {
        weekBoolean = true;
      }
    }

    if (years) {
      let name = [];
      name.push(
        years &&
          years.map((year) =>
            CryptoJS.AES.decrypt(year.yourName, key).toString(CryptoJS.enc.Utf8)
          )
      );
      if (name[0].length >= 1) {
        yearBoolean = true;
      }
    }

    if (days) {
      let name = [];
      name.push(days && days.map((day) => day.rememberToday));
      if (name.length > 1) {
        dayBoolean = true;
      }
    }

    if (!dayBoolean && !weekBoolean && !yearBoolean) {
      overallBoolean = true;
    }

    return overallBoolean;
  };

  const cursorChangeObjEight = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setObjectiveData((objectiveData) => {
      let obj =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );
      return obj[0].objectives["obj-eight"].subTasks;
    });
    setObjectiveNum(8);

    objectiveState === false && setObjectiveState(true);
  };

  const objEightPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (Array.isArray(weeks) && objectives) {
      let week =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );

      if (
        week[0].objectives["obj-eight"] &&
        week[0].objectives["obj-eight"].subTasks &&
        week[0].objectives["obj-eight"].subTasks.length > 0
      ) {
        if (week[0].columns["obj-eight"].title !== "") {
          boolean = true;
        }
      }
    }

    return boolean;
  };

  const [logoState, setLogoState] = useState(faEarthAmericas);

  const cursorChangeObjNine = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setObjectiveData((objectiveData) => {
      let obj =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );
      return obj[0].objectives["obj-nine"].subTasks;
    });
    setObjectiveNum(9);

    objectiveState === false && setObjectiveState(true);
  };

  const objNinePresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (Array.isArray(weeks) && objectives) {
      let week =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );

      if (
        week[0].objectives["obj-nine"] &&
        week[0].objectives["obj-nine"].subTasks &&
        week[0].objectives["obj-nine"].subTasks.length > 0
      ) {
        if (week[0].columns["obj-nine"].title !== "") {
          boolean = true;
        }
      }
    }

    return boolean;
  };

  const cursorChangeObjTen = () => {
    const key = generateSecret().toString();
    setLogoState((logoState) => logoObj.values);
    setObjectiveData((objectiveData) => {
      let obj =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );
      return obj[0].objectives["obj-ten"].subTasks;
    });
    setObjectiveNum(10);

    objectiveState === false && setObjectiveState(true);
  };

  const objTenPresent = () => {
    const key = generateSecret().toString();
    let boolean = false;
    if (Array.isArray(weeks) && objectives) {
      let week =
        weeks &&
        weeks
          .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
          .map((week) =>
            JSON.parse(
              CryptoJS.AES.decrypt(week.objectives, key).toString(
                CryptoJS.enc.Utf8
              )
            )
          );

      if (
        week[0].objectives["obj-ten"] &&
        week[0].objectives["obj-ten"].subTasks &&
        week[0].objectives["obj-ten"].subTasks.length > 0
      ) {
        if (week[0].columns["obj-ten"].title !== "") {
          boolean = true;
        }
      }
    }

    return boolean;
  };

  const addStep = (event, columnId, subTaskIndex) => {
    const descriptionValue = event.target.value;

    if (event.key === "Enter") {
      setObjectives((prevState) => {
        const updatedObjectives = { ...prevState };
        const objective = updatedObjectives.objectives[columnId];
        if (objective) {
          const subTask = objective.subTasks[subTaskIndex];
          if (subTask) {
            subTask.steps.push({
              action: descriptionValue,
              completed: 0,
            });
          }
        }
        return updatedObjectives;
      });
      event.target.value = "";
    }
  };

  const previousDay = () => {
    let id;

    if (Array.isArray(days) === true) {
      id =
        days &&
        days
          .filter((day, i, days) => days.indexOf(day) === days.length - 2)
          .map((day) => day._id);

      navigate(`/today/${id}`);
    }
  };

  const previousDayPresent = () => {
    let boolean = false;

    if (
      Array.isArray(days) === true &&
      days
        .filter((day, i, days) => days.indexOf(day) === days.length - 2)
        .map((day) => day._id)
    ) {
      boolean = true;
    }

    return boolean;
  };

  const valOne = weeks
    ? weeks
        .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
        .map((week) => week.objectiveOne_score)
    : 0.15;

  const valTwo = weeks
    ? weeks
        .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
        .map((week) => week.objectiveTwo_score)
    : 0.15;

  const valThree = weeks
    ? weeks
        .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
        .map((week) => week.objectiveThree_score)
    : 0.15;

  const valFour = weeks
    ? weeks
        .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
        .map((week) => week.objectiveFour_score)
    : 0.15;

  const valFive = weeks
    ? weeks
        .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
        .map((week) => week.objectiveFive_score)
    : 0.15;

  const valSix = weeks
    ? weeks
        .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
        .map((week) => week.objectiveSix_score)
    : 0.15;

  const valSeven = weeks
    ? weeks
        .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
        .map((week) => week.objectiveSeven_score)
    : 0.15;

  const valEight = weeks
    ? weeks
        .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
        .map((week) => week.objectiveEight_score)
    : 0.15;

  const valNine = weeks
    ? weeks
        .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
        .map((week) => week.objectiveNine_score)
    : 0.15;

  const valTen = weeks
    ? weeks
        .filter((week, i, weeks) => weeks.indexOf(week) === weeks.length - 1)
        .map((week) => week.objectiveTen_score)
    : 0.15;

  const vw = Math.max(document.documentElement.clientWidth);
  const [viewWidth, setViewWidth] = useState(vw);

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (vw > 1024) {
        setIsVisible(window.scrollY <= 0);
      } else {
        setIsVisible(window.scrollY <= 1000);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const changeMade = () => {
    if (
      logDate &&
      startScore &&
      thankYou &&
      selfLess &&
      mindBody &&
      momentOne &&
      momentOneScore &&
      momentTwo &&
      momentTwoScore &&
      momentThree &&
      momentThreeScore &&
      momentFour &&
      momentFourScore &&
      momentFive &&
      momentFiveScore &&
      rememberToday &&
      leaveBehind &&
      endScore
    ) {
      setParentState("indicate");
    }
  };

  const [momentsError, setMomentsError] = useState(true);

  const momentsFalse = () => {
    if (days) {
      if (
        (momentOne !== "" && momentOneScore === null) ||
        (momentTwo !== "" && momentTwoScore === null) ||
        (momentThree !== "" && momentThreeScore == null) ||
        (momentFour !== "" && momentFourScore == null) ||
        (momentFive !== "" && momentFiveScore == null)
      ) {
        setMomentsError((momentsError) => false);
      } else {
        setMomentsError((momentsError) => true);
      }
    }
  };

  const noPreviousDay = () => {
    let prevArr = [];
    let noPrev = "";

    Array.isArray(days) && days.forEach((d) => prevArr.push(d.endScore));

    if (
      prevArr[prevArr.length - 1] === null ||
      prevArr[prevArr.length - 1] === 0
    ) {
      noPrev = "disabled";
    }

    return noPrev;
  };

  const daysTrue = () => {
    if (!dayList.loading) {
      return false;
    } else {
      return true;
    }
  };

  const showHideStateMobile = {
    off: {
      display: "none",
    },
    show: {
      minWidth: "auto",
      paddingRight: "auto",
      maxWidth: "80vw",
      position: "fixed",
      display: "flex",
      bottom: "0%",
      left: "0%",
      margin: "6px 6px 12px 6px",
      height: "auto",
      opacity: 1,
      zIndex: 26,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    hide: {
      display: "none",
      height: "0px",
      opacity: 0,
      zIndex: 0,
      transition: {
        duration: 1,
        ease: "easeOut",
      },
    },
  };

  useEffect(() => {
    loadingTimeout();
    changeMade();
    momentsFalse();

    if (vw > 1024) {
      setFontState(60);
    } else {
      setFontState(48);
    }
  });

  useEffect(() => {
    contFunc();
  }, [years]);

  const handleChange_thankYou = (e) => {
    setThankYou(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_selfLess = (e) => {
    setSelfLess(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_MindBody = (e) => {
    setMindBody(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_MomentOne = (e) => {
    setMomentOne(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_MomentTwo = (e) => {
    setMomentTwo(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_MomentThree = (e) => {
    setMomentThree(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_MomentFour = (e) => {
    setMomentFour(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_MomentFive = (e) => {
    setMomentFive(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_RememberToday = (e) => {
    setRememberToday(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_LeaveBehind = (e) => {
    setLeaveBehind(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const handleChange_onMyMind = (e) => {
    setOnMyMind(e.target.value);
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the new height
  };

  const [value, setValue] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const [tooltipPositionStart, setTooltipPositionStart] = useState({
    position: 0,
    opacity: 0,
  });
  const [tooltipPositionEnd, setTooltipPositionEnd] = useState({
    position: 0,
    opacity: 0,
  });
  const [tooltipPositionOne, setTooltipPositionOne] = useState({
    position: 0,
    opacity: 0,
  });
  const [tooltipPositionTwo, setTooltipPositionTwo] = useState({
    position: 0,
    opacity: 0,
  });
  const [tooltipPositionThree, setTooltipPositionThree] = useState({
    position: 0,
    opacity: 0,
  });
  const [tooltipPositionFour, setTooltipPositionFour] = useState({
    position: 0,
    opacity: 0,
  });
  const [tooltipPositionFive, setTooltipPositionFive] = useState({
    position: 0,
    opacity: 0,
  });

  const handleSliderChange = (event, num, pos) => {
    const sliderValue = event.target.value;

    setValue(sliderValue);

    // Calculate the tooltip position based on the slider value
    const sliderWidth = event.target.clientWidth - 15;

    const newPercent = sliderValue / num;
    const newPosition = sliderWidth * newPercent;

    switch (pos) {
      case 1:
        setTooltipPositionStart({ position: newPosition + 20, opacity: 1 });
        break;
      case 2:
        setTooltipPositionEnd({ position: newPosition + 20, opacity: 1 });
        break;
      case 3:
        setTooltipPositionOne({ position: newPosition + 20, opacity: 1 });
        break;
      case 4:
        setTooltipPositionTwo({ position: newPosition + 20, opacity: 1 });
        break;
      case 5:
        setTooltipPositionThree({ position: newPosition + 20, opacity: 1 });
        break;
      case 6:
        setTooltipPositionFour({ position: newPosition + 20, opacity: 1 });
        break;
      case 7:
        setTooltipPositionFive({ position: newPosition + 20, opacity: 1 });
        break;
    }
  };

  useEffect((event) => {
    if (objectiveData[0] && objectiveData[0].subTasks) {
      let subtask = document.getElementsByClassName("formInput");
      let arrayFromCollection = Array.from(subtask);
      arrayFromCollection.forEach((element, i) => {
        let actualHeight = element.scrollHeight;
        element.style.height = "auto";
        element.style.minHeight = "12px"; //
        element.style.height = `${actualHeight}px`;
      });
    }
  });

  useEffect(() => {
    const handleTextAreaResize = (event) => {
      const { target } = event;

      // Reset the height to auto before getting the updated scrollHeight
      target.style.height = "auto";
      target.style.minHeight = "12px"; //
      // Set the height to the updated scrollHeight
      target.style.height = `${target.scrollHeight}px`;
    };

    const initializeTextAreaResize = () => {
      console.log();
      if (objectiveData[0] && objectiveData[0].subTasks) {
        // Attach the event listener to each textarea with the class 'formInput'
        const textareas = document.getElementsByClassName("formInput");
        Array.from(textareas).forEach((textarea) => {
          textarea.addEventListener("input", handleTextAreaResize);
        });
      }
    };

    // Run on page load
    initializeTextAreaResize();

    // Attach the event listener for input events
    if (objectiveData[0] && objectiveData[0].subTasks) {
      const textareas = document.getElementsByClassName("formInput");
      Array.from(textareas).forEach((textarea) => {
        textarea.addEventListener("input", handleTextAreaResize);
      });
    }

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      if (objectiveData[0] && objectiveData[0].subTasks) {
        const textareas = document.getElementsByClassName("formInput");
        Array.from(textareas).forEach((textarea) => {
          textarea.removeEventListener("input", handleTextAreaResize);
        });
      }
    };
  }, [objectiveData[0].subTasks]);

  const [tutorialStep, setTutorialStep] = useState(0);
  const [instruction, setInstruction] = useState([
    ["Your NoWastedDays journal is made up of three powerful pages."],
    [
      "The Priming Page: built to get your mind warmed up and ready for a healthy, happy day.",
      "It includes a self-check slider, and three great prompts that you fill in each morning.",
      "Its combination of gratitude, detachment and self-compassion is the perfect primer for any kind of day.",
    ],
    [
      "The Planning Page: structured to simplify, streamline and score what you try to do on any given day.",
      "It includes five blocks to divide up your day into do-able chunks; each with a slider to score how you get on.",
      "This simplicity of planning and ease of scoring supports both productive, purposeful days in the present as well as the potential for huge personal insights in the future.",
    ],
    [
      "The Processing Page: crafted to capture the distinctness of a day and help you detach from its most destructive moments.",
      "It includes three complementary prompts; one focused on capturing memories, one focused on leaving thoughts, feelings and behaviours behind, and the last to clear your mind for the following day.",
      "Remember to wrap up each journal entry with these reflections.",
    ],
    [
      "Each journal entry becomes a diverse data point in your insights.",
      "On top of understanding your feeling and productivity, you have a personalised coaching story that is constantly responding to updates in your data.",
      "We don't want you to simply feel better in the short term. We are looking to build your self-awareness and self-assurance so you live as many happy, purposeful days as possible.",
    ],
    [
      "Your journal comes with its very own dock.",
      "The journal dock plays a hugely important role in improving the impact of your daily journal writing.",
      "On the left side, after you have added them to your map, clicking on a node will allow you to plan your day directly through your vision for the year, personal values, ikigai, favourite quote and more!",
      "To realise our goals or best live our values, it is extremely helpful to have these ideas as easy to access and present in our planning as possible.",
    ],
    [
      "This section of journal dock gives you access to all of your weekly objectives that you have put into your planner",
      "Click on a node to see the assigned objective, and the tasks you have set yourself to realise it.",
      "Then click on the journal button to add the task into your plan for the day.",
      "Each node fills up as you complete the tasks you have set yourself in each objective. It is weirdly motivating to try and fill them up on any given week!",
    ],
    [
      "And that is it.",
      "A world of free self-awareness and self-assurance awaits.",
      "Finish your journal for today and thanks for using NoWastedDays.io.",
    ],
  ]);

  // Function to increment the tutorialStep by 1
  const nextStep = () => {
    setTutorialStep((prevStep) => prevStep + 1);
  };

  const backStep = () => {
    setTutorialStep((prevStep) => prevStep - 1);
  };

  const handleTutorialChange = () => {
    setTutorialOpen((tutorialOpen) => !tutorialOpen);
    setTutorialStep(0);
  };

  return (
    <>
      <Helmet>
        <title>Update | Today</title>
      </Helmet>
      <div className="mouseTooltip">
        <MouseTooltip
          visible={cursorDisplayState}
          offsetX={15}
          offsetY={10}
          zIndex={1000}
        >
          <h1 className="sickTooltip" style={{ fontSize: `${fontState}px` }}>
            {cursorState}
          </h1>
        </MouseTooltip>
      </div>
      <motion.div
        className={
          objectiveState ? "objective__window" : "objective__window--inactive"
        }
        drag
      >
        {objectiveNum === 1 && (
          <Objective
            data={objectives.objectives["obj-one"]}
            name={objectives.columns["obj-one"].title}
            num={objectiveNum}
            columnId={"obj-one"}
            addSubTask={handleDescriptionChange}
            addStep={addStep}
            handleSubTaskReorder={handleSubTaskReorder}
            handleStepReorder={handleStepReorder}
            toggleSubTask={toggleCompletedSubTasks}
            toggleStep={toggleStep}
            updateDescription={updateDescription}
            updateStep={updateStep}
            updateWeek={weekHandler}
            updateNotes={updateNotes}
            deleteStep={deleteStep}
            deleteSubTask={deleteSubTask}
            select={handleSelect}
            momentSelected={momentSelect}
            closeMoment={closeMomentState}
            stateChange={stateChange}
            closeObjectiveState={closeObjectiveState}
          />
        )}
        {objectiveNum === 2 && (
          <Objective
            data={objectives.objectives["obj-two"]}
            name={objectives.columns["obj-two"].title}
            num={objectiveNum}
            columnId={"obj-two"}
            addSubTask={handleDescriptionChange}
            addStep={addStep}
            handleSubTaskReorder={handleSubTaskReorder}
            handleStepReorder={handleStepReorder}
            toggleSubTask={toggleCompletedSubTasks}
            toggleStep={toggleStep}
            updateDescription={updateDescription}
            updateStep={updateStep}
            updateWeek={weekHandler}
            updateNotes={updateNotes}
            deleteStep={deleteStep}
            deleteSubTask={deleteSubTask}
            select={handleSelect}
            momentSelected={momentSelect}
            closeMoment={closeMomentState}
            stateChange={stateChange}
            closeObjectiveState={closeObjectiveState}
          />
        )}
        {objectiveNum === 3 && (
          <Objective
            data={objectives.objectives["obj-three"]}
            name={objectives.columns["obj-three"].title}
            num={objectiveNum}
            columnId={"obj-three"}
            addSubTask={handleDescriptionChange}
            addStep={addStep}
            handleSubTaskReorder={handleSubTaskReorder}
            handleStepReorder={handleStepReorder}
            toggleSubTask={toggleCompletedSubTasks}
            toggleStep={toggleStep}
            updateDescription={updateDescription}
            updateStep={updateStep}
            updateWeek={weekHandler}
            updateNotes={updateNotes}
            deleteStep={deleteStep}
            deleteSubTask={deleteSubTask}
            select={handleSelect}
            momentSelected={momentSelect}
            closeMoment={closeMomentState}
            stateChange={stateChange}
            closeObjectiveState={closeObjectiveState}
          />
        )}
        {objectiveNum === 4 && (
          <Objective
            data={objectives.objectives["obj-four"]}
            name={objectives.columns["obj-four"].title}
            num={objectiveNum}
            columnId={"obj-four"}
            addSubTask={handleDescriptionChange}
            addStep={addStep}
            handleSubTaskReorder={handleSubTaskReorder}
            handleStepReorder={handleStepReorder}
            toggleSubTask={toggleCompletedSubTasks}
            toggleStep={toggleStep}
            updateDescription={updateDescription}
            updateStep={updateStep}
            updateWeek={weekHandler}
            updateNotes={updateNotes}
            deleteStep={deleteStep}
            deleteSubTask={deleteSubTask}
            select={handleSelect}
            momentSelected={momentSelect}
            closeMoment={closeMomentState}
            stateChange={stateChange}
            closeObjectiveState={closeObjectiveState}
          />
        )}
        {objectiveNum === 5 && (
          <Objective
            data={objectives.objectives["obj-five"]}
            name={objectives.columns["obj-five"].title}
            num={objectiveNum}
            columnId={"obj-five"}
            addSubTask={handleDescriptionChange}
            addStep={addStep}
            handleSubTaskReorder={handleSubTaskReorder}
            handleStepReorder={handleStepReorder}
            toggleSubTask={toggleCompletedSubTasks}
            toggleStep={toggleStep}
            updateDescription={updateDescription}
            updateStep={updateStep}
            updateWeek={weekHandler}
            updateNotes={updateNotes}
            deleteStep={deleteStep}
            deleteSubTask={deleteSubTask}
            select={handleSelect}
            momentSelected={momentSelect}
            closeMoment={closeMomentState}
            stateChange={stateChange}
            closeObjectiveState={closeObjectiveState}
          />
        )}
        {objectiveNum === 6 && (
          <Objective
            data={objectives.objectives["obj-six"]}
            name={objectives.columns["obj-six"].title}
            num={objectiveNum}
            columnId={"obj-six"}
            addSubTask={handleDescriptionChange}
            addStep={addStep}
            handleSubTaskReorder={handleSubTaskReorder}
            handleStepReorder={handleStepReorder}
            toggleSubTask={toggleCompletedSubTasks}
            toggleStep={toggleStep}
            updateDescription={updateDescription}
            updateStep={updateStep}
            updateWeek={weekHandler}
            updateNotes={updateNotes}
            deleteStep={deleteStep}
            deleteSubTask={deleteSubTask}
            select={handleSelect}
            momentSelected={momentSelect}
            closeMoment={closeMomentState}
            stateChange={stateChange}
            closeObjectiveState={closeObjectiveState}
          />
        )}
        {objectiveNum === 7 && (
          <Objective
            data={objectives.objectives["obj-seven"]}
            name={objectives.columns["obj-seven"].title}
            num={objectiveNum}
            columnId={"obj-seven"}
            addSubTask={handleDescriptionChange}
            addStep={addStep}
            handleSubTaskReorder={handleSubTaskReorder}
            handleStepReorder={handleStepReorder}
            toggleSubTask={toggleCompletedSubTasks}
            toggleStep={toggleStep}
            updateDescription={updateDescription}
            updateStep={updateStep}
            updateWeek={weekHandler}
            updateNotes={updateNotes}
            deleteStep={deleteStep}
            deleteSubTask={deleteSubTask}
            select={handleSelect}
            momentSelected={momentSelect}
            closeMoment={closeMomentState}
            stateChange={stateChange}
            closeObjectiveState={closeObjectiveState}
          />
        )}
        {objectiveNum === 8 && (
          <Objective
            data={objectives.objectives["obj-eight"]}
            name={objectives.columns["obj-eight"].title}
            num={objectiveNum}
            columnId={"obj-eight"}
            addSubTask={handleDescriptionChange}
            addStep={addStep}
            handleSubTaskReorder={handleSubTaskReorder}
            handleStepReorder={handleStepReorder}
            toggleSubTask={toggleCompletedSubTasks}
            toggleStep={toggleStep}
            updateDescription={updateDescription}
            updateStep={updateStep}
            updateWeek={weekHandler}
            updateNotes={updateNotes}
            deleteStep={deleteStep}
            deleteSubTask={deleteSubTask}
            select={handleSelect}
            momentSelected={momentSelect}
            closeMoment={closeMomentState}
            stateChange={stateChange}
            closeObjectiveState={closeObjectiveState}
          />
        )}
        {objectiveNum === 9 && (
          <Objective
            data={objectives.objectives["obj-nine"]}
            name={objectives.columns["obj-nine"].title}
            num={objectiveNum}
            columnId={"obj-nine"}
            addSubTask={handleDescriptionChange}
            addStep={addStep}
            handleSubTaskReorder={handleSubTaskReorder}
            handleStepReorder={handleStepReorder}
            toggleSubTask={toggleCompletedSubTasks}
            toggleStep={toggleStep}
            updateDescription={updateDescription}
            updateStep={updateStep}
            updateWeek={weekHandler}
            updateNotes={updateNotes}
            deleteStep={deleteStep}
            deleteSubTask={deleteSubTask}
            select={handleSelect}
            momentSelected={momentSelect}
            closeMoment={closeMomentState}
            stateChange={stateChange}
            closeObjectiveState={closeObjectiveState}
          />
        )}

        {objectiveNum === 10 && (
          <Objective
            data={objectives.objectives["obj-ten"]}
            name={objectives.columns["obj-ten"].title}
            num={objectiveNum}
            columnId={"obj-ten"}
            addSubTask={handleDescriptionChange}
            addStep={addStep}
            handleSubTaskReorder={handleSubTaskReorder}
            handleStepReorder={handleStepReorder}
            toggleSubTask={toggleCompletedSubTasks}
            toggleStep={toggleStep}
            updateDescription={updateDescription}
            updateStep={updateStep}
            updateWeek={weekHandler}
            updateNotes={updateNotes}
            deleteStep={deleteStep}
            deleteSubTask={deleteSubTask}
            select={handleSelect}
            momentSelected={momentSelect}
            closeMoment={closeMomentState}
            stateChange={stateChange}
            closeObjectiveState={closeObjectiveState}
          />
        )}
      </motion.div>
      <CentralHeader tutorialOpen={tutorialOpen} tutorialStep={tutorialStep} />

      <form onChange={tutorialOpen === false && updateHandler}>
        <main
          className={
            tutorialOpen ? "today__tutorial--container" : "updateday--container"
          }
        >
          {pageLoading || vw > 1024 ? (
            <></>
          ) : (
            <motion.div
              className="dock--container"
              initial={{ opacity: 1 }}
              animate={{ opacity: isVisible ? 1 : 0 }}
              transition={{ duration: 0.3 }}
              style={{
                zIndex: 999,
              }}
            >
              <MidDock
                previousDay={previousDay}
                previousDayPresent={previousDayPresent}
                tutorialFlag={tutorialFlag}
                changeMade={parentState}
                saved={saved}
                submitDisabled={state}
                momentsError={momentsError}
                noCreate={noPreviousDay()}
                hideOverlay={cursorDisplayHide}
                changeName={cursorChangeName}
                namePresent={namePresent}
                dobPresent={dobPresent}
                changeValuesOne={cursorChangeValuesOne}
                valuesOnePresent={valuesOnePresent}
                changeValuesTwo={cursorChangeValuesTwo}
                valuesTwoPresent={valuesTwoPresent}
                changeValuesThree={cursorChangeValuesThree}
                valuesThreePresent={valuesThreePresent}
                changeValuesFour={cursorChangeValuesFour}
                valuesFourPresent={valuesFourPresent}
                changeValuesFive={cursorChangeValuesFive}
                valuesFivePresent={valuesFivePresent}
                changeVisionOne={cursorChangeVisionOne}
                visionOnePresent={visionOnePresent}
                changeVisionTwo={cursorChangeVisionTwo}
                visionTwoPresent={visionTwoPresent}
                changeVisionThree={cursorChangeVisionThree}
                visionThreePresent={visionThreePresent}
                changeVisionFour={cursorChangeVisionFour}
                visionFourPresent={visionFourPresent}
                changeVisionFive={cursorChangeVisionFive}
                visionFivePresent={visionFivePresent}
                changeIkigai={cursorChangeIkigai}
                ikigaiPresent={ikigaiPresent}
                changeQuote={cursorChangeQuote}
                quotePresent={quotePresent}
                changeMemory={cursorChangeMemory}
                memoryPresent={memoryPresentState}
                changeObjOne={cursorChangeObjOne}
                objOnePresent={objOnePresent}
                changeObjTwo={cursorChangeObjTwo}
                objTwoPresent={objTwoPresent}
                changeObjThree={cursorChangeObjThree}
                objThreePresent={objThreePresent}
                changeObjFour={cursorChangeObjFour}
                objFourPresent={objFourPresent}
                changeObjFive={cursorChangeObjFive}
                objFivePresent={objFivePresent}
                changeObjSix={cursorChangeObjSix}
                objSixPresent={objSixPresent}
                changeObjSeven={cursorChangeObjSeven}
                objSevenPresent={objSevenPresent}
                changeObjEight={cursorChangeObjEight}
                objEightPresent={objEightPresent}
                changeObjNine={cursorChangeObjNine}
                objNinePresent={objNinePresent}
                changeObjTen={cursorChangeObjTen}
                objTenPresent={objTenPresent}
                endScore={endScore}
                updateDay={updateHandler}
                opacityOne={valOne[0]}
                opacityTwo={valTwo[0]}
                opacityFour={valFour[0]}
                opacityThree={valThree[0]}
                opacityFive={valFive[0]}
                opacitySix={valSix[0]}
                opacitySeven={valSeven[0]}
                opacityEight={valEight[0]}
                opacityNine={valNine[0]}
                opacityTen={valTen[0]}
                tutorialChange={handleTutorialChange}
                tutorialOpen={tutorialOpen}
              />
            </motion.div>
          )}
          {pageLoading ? (
            <div
              style={{
                height: "50vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TileLoading />
            </div>
          ) : (
            <>
              {viewWidth > 1024 ? (
                <></>
              ) : (
                <>
                  <motion.div
                    variants={showHideStateMobile}
                    initial="off"
                    animate={cursorDisplayState ? "show" : "hide"}
                  >
                    <motion.div className="navElement" onClick={addObjective}>
                      <div id="start" className="dock node white">
                        <FontAwesomeIcon
                          className="icon green"
                          icon={logoState}
                        />
                      </div>
                      <span>{cursorState}</span>
                    </motion.div>
                  </motion.div>
                </>
              )}
              {tutorialOpen && (
                <TutorialWindow
                  instruction={instruction}
                  tutorialStep={tutorialStep}
                  nextStep={nextStep}
                  backStep={backStep}
                  tutorialChange={handleTutorialChange}
                />
              )}
              {tutorialOpen && (
                <div className={vw < 1025 ? "tile tut" : "tile tut--inactive"}>
                  <div className="dateRow--header--tutorial blank">
                    <label className="score label">
                      <h1>{tutorialState.kick_off[0]}</h1>
                    </label>
                  </div>

                  <div className="dateRow--header--tutorial prime">
                    <label className="score label">
                      The Priming Page: Built to get your mind warmed up and
                      ready for a healthy, happy day.
                    </label>
                  </div>

                  <div className="dateRow--header--tutorial plan">
                    <label className="score label">
                      The Planning Page: Structured to simplify, streamline and
                      score what you try to do.
                    </label>
                  </div>

                  <div className="dateRow--header--tutorial process">
                    <label className="score label">
                      The Processing Page: Crafted to capture the distinctness
                      of a day and detach from its destructive moments.
                    </label>
                  </div>
                </div>
              )}

              <div
                className={
                  tutorialOpen
                    ? tutorialStep === 0 ||
                      tutorialStep === 2 ||
                      tutorialStep >= 3
                      ? "tile left opac50"
                      : "tile left tut"
                    : "tile left"
                }
              >
                {!tutorialOpen && (
                  <div className="dateRow--header">
                    <label
                      className="score label"
                      style={{ opacity: "1", fontWeight: "bold" }}
                    >
                      {tutorialOpen
                        ? tutorialState.priming_the_day
                        : "Priming the Day"}
                    </label>
                  </div>
                )}

                <div className="upperDiv">
                  <div
                    className={
                      tutorialOpen ? "dateRow--tutorial prime" : "dateRow"
                    }
                  >
                    <label htmlFor="loggedDate" className="score label">
                      {tutorialOpen
                        ? tutorialState.diary_entry_date
                        : "Diary Entry Date:"}
                    </label>
                    {pageLoading ? (
                      <></>
                    ) : !pageLoading ? (
                      <input
                        id="loggedDate"
                        type="date"
                        style={{
                          color: "#004a22",
                          fontWeight: "600",
                          height: "100%",
                          margin: 0,
                        }}
                        value={logDate.substring(0, 10)}
                        onChange={(e) => setLogDate(e.target.value)}
                        required
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="scoring__row">
                    <div
                      className={
                        tutorialOpen
                          ? "score__row--tutorial prime"
                          : "score__row"
                      }
                      style={
                        startScore === "5" ||
                        (startScore === undefined && vw < 426)
                          ? { borderRadius: "5px 5px 0px 0px" }
                          : { borderRadius: "5px 5px 5px 0px" }
                      }
                    >
                      <label htmlFor="beforeScore">
                        {tutorialOpen
                          ? tutorialState.start_score_label
                          : "Before filling in my NWD today, I feel"}{" "}
                      </label>{" "}
                      {startScore > 0 && (
                        <label className="score__display">{startScore}</label>
                      )}
                    </div>
                    <input
                      id="beforeScore"
                      className={
                        tutorialOpen
                          ? "weekSlider tutorial"
                          : startScore
                            ? "weekSlider"
                            : "weekSlider off"
                      }
                      type="range"
                      min="0"
                      max="5"
                      step="0.1"
                      value={startScore ? startScore : "0"}
                      style={{ background: getBackgroundStyleTwo(startScore) }}
                      onChange={(e) => {
                        setStartScore(e.target.value);
                        handleSliderChange(e, 5, 1);
                      }}
                    />

                    <span
                      style={{
                        left: `${tooltipPositionStart.position}px`,
                        zIndex: "-1",
                        bottom: "22px",
                        opacity: `${tooltipPositionStart.opacity}`,
                      }}
                      className="score--tooltip"
                    >
                      {startScore ? startScore : " "}
                    </span>
                  </div>
                </div>

                <div
                  className={
                    tutorialOpen ? "yeartile--day--tutorial" : "yeartile--day"
                  }
                >
                  <div
                    className={
                      tutorialOpen
                        ? "tutorial access--tutorial prime"
                        : "tutorial access"
                    }
                  >
                    <div className="iconDiv--day">
                      <label htmlFor="thankYou" className="score label">
                        {tutorialOpen
                          ? tutorialStep > 0
                            ? "Today, I would like to say thank you to..."
                            : tutorialState.thank_you_what
                          : "Today, I would like to say thank you to..."}
                      </label>
                    </div>
                  </div>
                  <textarea
                    id="thankYou"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={thankYou}
                    placeholder={
                      tutorialOpen
                        ? tutorialState.thank_you_why
                        : "my body for carrying me through the day."
                    }
                    onChange={handleChange_thankYou}
                  />
                </div>

                <div
                  className={
                    tutorialOpen ? "yeartile--day--tutorial" : "yeartile--day"
                  }
                >
                  <div
                    className={
                      tutorialOpen
                        ? "tutorial access--tutorial prime"
                        : "tutorial access"
                    }
                  >
                    <div className="iconDiv--day">
                      <label htmlFor="selfLess" className="score label">
                        {tutorialOpen
                          ? tutorialStep > 0
                            ? "I will detach from self today by..."
                            : tutorialState.detach_today_what
                          : "I will detach from self today by..."}
                      </label>
                    </div>
                  </div>
                  <textarea
                    id="thankYou"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={selfLess}
                    placeholder={
                      tutorialOpen
                        ? tutorialState.detach_today_why
                        : "simply breathing and staying in the moment"
                    }
                    onChange={handleChange_selfLess}
                  />
                </div>
                <div
                  className={
                    tutorialOpen ? "yeartile--day--tutorial" : "yeartile--day"
                  }
                >
                  <div
                    className={
                      tutorialOpen
                        ? "tutorial access--tutorial prime"
                        : "tutorial access"
                    }
                  >
                    <div className="iconDiv--day">
                      <label htmlFor="mindBody" className="score label">
                        {tutorialOpen
                          ? tutorialStep > 0
                            ? "I am pleased to live in my mind & body because..."
                            : tutorialState.mind_and_body_what
                          : "I am pleased to live in my mind & body because..."}
                      </label>
                    </div>
                  </div>
                  <textarea
                    id="mindBody"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={mindBody}
                    placeholder={
                      tutorialOpen
                        ? tutorialState.mind_and_body_why
                        : "it is here. Here with me. Here in the world."
                    }
                    onChange={handleChange_MindBody}
                  />
                </div>
              </div>
              <FullDockMobileOne
                cursorYearAlert={cursorYearAlert}
                changeName={cursorChangeName}
                namePresent={namePresent}
                dobPresent={dobPresent}
                changeValuesOne={cursorChangeValuesOne}
                valuesOnePresent={valuesOnePresent}
                changeValuesTwo={cursorChangeValuesTwo}
                valuesTwoPresent={valuesTwoPresent}
                changeValuesThree={cursorChangeValuesThree}
                valuesThreePresent={valuesThreePresent}
                changeValuesFour={cursorChangeValuesFour}
                valuesFourPresent={valuesFourPresent}
                changeValuesFive={cursorChangeValuesFive}
                valuesFivePresent={valuesFivePresent}
                changeVisionOne={cursorChangeVisionOne}
                visionOnePresent={visionOnePresent}
                changeVisionTwo={cursorChangeVisionTwo}
                visionTwoPresent={visionTwoPresent}
                changeVisionThree={cursorChangeVisionThree}
                visionThreePresent={visionThreePresent}
                changeVisionFour={cursorChangeVisionFour}
                visionFourPresent={visionFourPresent}
                changeVisionFive={cursorChangeVisionFive}
                visionFivePresent={visionFivePresent}
                changeIkigai={cursorChangeIkigai}
                ikigaiPresent={ikigaiPresent}
                changeQuote={cursorChangeQuote}
                quotePresent={quotePresent}
                changeMemory={cursorChangeMemory}
                memoryPresent={memoryPresentState}
                hideOverlay={cursorDisplayHide}
                tutorialOpen={tutorialOpen}
              />
              {tutorialOpen && (
                <div className={vw < 1024 ? "tile tut" : "tile tut--inactive"}>
                  <div
                    className={
                      tutorialOpen
                        ? "mobiledock--tutorial"
                        : "mobiledock--tutorial--inactive"
                    }
                  >
                    <div
                      className={
                        tutorialOpen
                          ? "dateRow--header--tutorial blank"
                          : "dateRow--header"
                      }
                    >
                      <label className="score label">
                        {tutorialState.dock_one.map((el, i) => (
                          <h3 key={i}>{el}</h3>
                        ))}
                      </label>
                    </div>
                  </div>
                </div>
              )}

              <div
                className={
                  tutorialOpen
                    ? tutorialStep === 0 ||
                      tutorialStep === 1 ||
                      tutorialStep >= 3
                      ? "tile centre opac50"
                      : "tile centre tut"
                    : "tile left"
                }
              >
                {!tutorialOpen && (
                  <div className="dateRow--header">
                    <label className="score label">
                      {isNaN(continuation)
                        ? `My Plan for Day`
                        : `My Plan for Day #${continuation}`}
                    </label>
                  </div>
                )}
                <div className="moment__submission">
                  <div
                    className={
                      tutorialOpen
                        ? "score__row--moment tut"
                        : momentOneScore === "10" ||
                            momentOneScore === undefined
                          ? `score__row--moment complete`
                          : "score__row--moment"
                    }
                  >
                    <textarea
                      className={
                        tutorialOpen
                          ? "formInput moment tut"
                          : "formInput moment"
                      }
                      wrap="soft"
                      value={momentOne}
                      autoComplete="off"
                      placeholder={
                        tutorialOpen
                          ? tutorialState.moment_one_text
                          : "Slow mindful first hour of the day; tea, stretch, no phone"
                      }
                      onChange={handleChange_MomentOne}
                    />

                    <div className="textarea-tooltip">
                      <div className="textarea-tooltip-content">
                        {momentOneScore ? momentOneScore : 0}
                      </div>
                    </div>
                  </div>
                  <input
                    className={
                      tutorialOpen
                        ? "weekSlider tutorial"
                        : momentOneScore
                          ? "weekSlider"
                          : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentOneScore}
                    defaultValue="0"
                    onChange={(e) => {
                      setMomentOneScore(e.target.value);
                      handleSliderChange(e, 10, 3);
                    }}
                    style={{ background: getBackgroundStyle(momentOneScore) }}
                  />
                  <span
                    className="tooltip--span"
                    style={{
                      left: `${tooltipPositionOne.position}px`,
                      zIndex: "-1",
                      bottom: "26px",
                      opacity: `${tooltipPositionOne.opacity}`,
                    }}
                  >
                    {momentOneScore}
                  </span>
                </div>

                <div className="moment__submission">
                  <div
                    className={
                      tutorialOpen
                        ? "score__row--moment tut"
                        : momentTwoScore === "10" ||
                            momentTwoScore === undefined
                          ? `score__row--moment complete`
                          : "score__row--moment"
                    }
                  >
                    <textarea
                      className={
                        tutorialOpen
                          ? "formInput moment tut"
                          : "formInput moment"
                      }
                      wrap="soft"
                      value={momentTwo}
                      autoComplete="off"
                      placeholder={
                        tutorialOpen
                          ? tutorialState.moment_two_text
                          : "Focus in on important task"
                      }
                      onChange={handleChange_MomentTwo}
                    />
                    <div className="textarea-tooltip">
                      <div className="textarea-tooltip-content">
                        {momentTwoScore ? momentTwoScore : 0}
                      </div>
                    </div>
                  </div>
                  <input
                    className={
                      tutorialOpen
                        ? "weekSlider tutorial"
                        : momentTwoScore
                          ? "weekSlider"
                          : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentTwoScore}
                    defaultValue="0"
                    onChange={(e) => {
                      setMomentTwoScore(e.target.value);
                      handleSliderChange(e, 10, 4);
                    }}
                    style={{ background: getBackgroundStyle(momentTwoScore) }}
                  />
                  <span
                    className="tooltip--span"
                    style={{
                      left: `${tooltipPositionTwo.position}px`,
                      zIndex: "-1",
                      bottom: "26px",
                      opacity: `${tooltipPositionTwo.opacity}`,
                    }}
                  >
                    {momentTwoScore}
                  </span>
                </div>

                <div className="moment__submission">
                  <div
                    className={
                      tutorialOpen
                        ? "score__row--moment tut"
                        : momentThreeScore === "10" ||
                            momentThreeScore === undefined
                          ? `score__row--moment complete`
                          : "score__row--moment"
                    }
                  >
                    <textarea
                      className={
                        tutorialOpen
                          ? "formInput moment tut"
                          : "formInput moment"
                      }
                      wrap="soft"
                      value={momentThree}
                      autoComplete="off"
                      placeholder={
                        tutorialOpen
                          ? tutorialState.moment_three_text
                          : "Read new book for 45 minutes. Meditate for 15 minutes"
                      }
                      onChange={handleChange_MomentThree}
                    />
                    <div className="textarea-tooltip">
                      <div className="textarea-tooltip-content">
                        {momentThreeScore ? momentThreeScore : 0}
                      </div>
                    </div>
                  </div>
                  <input
                    className={
                      tutorialOpen
                        ? "weekSlider tutorial"
                        : momentThreeScore
                          ? "weekSlider"
                          : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentThreeScore}
                    defaultValue="0"
                    onChange={(e) => {
                      setMomentThreeScore(e.target.value);
                      handleSliderChange(e, 10, 5);
                    }}
                    style={{ background: getBackgroundStyle(momentThreeScore) }}
                  />
                  <span
                    className="tooltip--span"
                    style={{
                      left: `${tooltipPositionThree.position}px`,
                      zIndex: "-1",
                      bottom: "26px",
                      opacity: `${tooltipPositionThree.opacity}`,
                    }}
                  >
                    {momentThreeScore}
                  </span>
                </div>

                <div className="moment__submission">
                  <div
                    className={
                      tutorialOpen
                        ? "score__row--moment tut"
                        : momentFourScore === "10" ||
                            momentFourScore === undefined
                          ? `score__row--moment complete`
                          : "score__row--moment"
                    }
                  >
                    <textarea
                      className={
                        tutorialOpen
                          ? "formInput moment tut"
                          : "formInput moment"
                      }
                      wrap="soft"
                      value={momentFour}
                      autoComplete="off"
                      placeholder={
                        tutorialOpen
                          ? tutorialState.moment_four_text
                          : "Workout: Biceps"
                      }
                      onChange={handleChange_MomentFour}
                    />

                    <div className="textarea-tooltip">
                      <div className="textarea-tooltip-content">
                        {momentFourScore ? momentFourScore : 0}
                      </div>
                    </div>
                  </div>
                  <input
                    className={
                      tutorialOpen
                        ? "weekSlider tutorial"
                        : momentFourScore
                          ? "weekSlider"
                          : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentFourScore}
                    defaultValue="0"
                    onChange={(e) => {
                      setMomentFourScore(e.target.value);
                      handleSliderChange(e, 10, 6);
                    }}
                    style={{ background: getBackgroundStyle(momentFourScore) }}
                  />
                  <span
                    className="tooltip--span"
                    style={{
                      left: `${tooltipPositionFour.position}px`,
                      zIndex: "-1",
                      bottom: "26px",
                      opacity: `${tooltipPositionFour.opacity}`,
                    }}
                  >
                    {momentFourScore}
                  </span>
                </div>

                <div className="moment__submission">
                  <div
                    className={
                      tutorialOpen
                        ? "score__row--moment tut"
                        : momentFiveScore === "10" ||
                            momentFiveScore === undefined
                          ? `score__row--moment complete`
                          : "score__row--moment"
                    }
                  >
                    <textarea
                      className={
                        tutorialOpen
                          ? "formInput moment tut"
                          : "formInput moment"
                      }
                      wrap="soft"
                      value={momentFive}
                      autoComplete="off"
                      placeholder={
                        tutorialOpen
                          ? tutorialState.moment_five_text
                          : "Wind down before bed; phone away"
                      }
                      onChange={handleChange_MomentFive}
                    />
                    <div className="textarea-tooltip">
                      <div className="textarea-tooltip-content">
                        {momentFiveScore ? momentFiveScore : 0}
                      </div>
                    </div>
                  </div>
                  <input
                    className={
                      tutorialOpen
                        ? "weekSlider tutorial"
                        : momentFiveScore
                          ? "weekSlider"
                          : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="10"
                    step="0.1"
                    value={momentFiveScore}
                    defaultValue="0"
                    onChange={(e) => {
                      setMomentFiveScore(e.target.value);
                      handleSliderChange(e, 10, 7);
                    }}
                    style={{ background: getBackgroundStyle(momentFiveScore) }}
                  />
                  <span
                    className="tooltip--span"
                    style={{
                      left: `${tooltipPositionFive.position}px`,
                      zIndex: "-1",
                      bottom: "26px",
                      opacity: `${tooltipPositionFive.opacity}`,
                    }}
                  >
                    {momentFiveScore}
                  </span>
                </div>
              </div>

              <div className="mobileDockRow">
                <FullDockMobileTwo
                  cursorWeekAlert={cursorWeekAlert}
                  hideOverlay={cursorDisplayHide}
                  changeObjOne={cursorChangeObjOne}
                  objOnePresent={objOnePresent}
                  changeObjTwo={cursorChangeObjTwo}
                  objTwoPresent={objTwoPresent}
                  changeObjThree={cursorChangeObjThree}
                  objThreePresent={objThreePresent}
                  changeObjFour={cursorChangeObjFour}
                  objFourPresent={objFourPresent}
                  changeObjFive={cursorChangeObjFive}
                  objFivePresent={objFivePresent}
                  changeObjSix={cursorChangeObjSix}
                  objSixPresent={objSixPresent}
                  changeObjSeven={cursorChangeObjSeven}
                  objSevenPresent={objSevenPresent}
                  changeObjEight={cursorChangeObjEight}
                  objEightPresent={objEightPresent}
                  changeObjNine={cursorChangeObjNine}
                  objNinePresent={objNinePresent}
                  changeObjTen={cursorChangeObjTen}
                  objTenPresent={objTenPresent}
                  opacityOne={valOne[0]}
                  opacityTwo={valTwo[0]}
                  opacityFour={valFour[0]}
                  opacityThree={valThree[0]}
                  opacityFive={valFive[0]}
                  opacitySix={valSix[0]}
                  opacitySeven={valSeven[0]}
                  opacityEight={valEight[0]}
                  opacityNine={valNine[0]}
                  opacityTen={valTen[0]}
                  tutorialOpen={tutorialOpen}
                />
              </div>
              {tutorialOpen && (
                <div className={vw < 1024 ? "tile tut" : "tile tut--inactive"}>
                  <div
                    className={
                      tutorialOpen
                        ? "mobiledock--tutorial"
                        : "mobiledock--tutorial--inactive"
                    }
                  >
                    <div
                      className={
                        tutorialOpen
                          ? "dateRow--header--tutorial blank"
                          : "dateRow--header"
                      }
                    >
                      <label className="score label">
                        {tutorialState.dock_two.map((el, i) => (
                          <h3 key={i}>{el}</h3>
                        ))}
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={
                  tutorialOpen
                    ? tutorialStep === 0 ||
                      tutorialStep === 1 ||
                      tutorialStep === 2 ||
                      tutorialStep >= 4
                      ? "tile centre opac50"
                      : "tile right tut"
                    : "tile right"
                }
              >
                {!tutorialOpen && (
                  <div className="dateRow--header">
                    <label
                      className="score label"
                      style={{ opacity: "1", fontWeight: "bold" }}
                    >
                      Reflecting on the Day
                    </label>
                  </div>
                )}
                <div
                  className={
                    tutorialOpen ? "yeartile--day--tutorial" : "yeartile--day"
                  }
                >
                  <div
                    className={
                      tutorialOpen
                        ? "tutorial access--tutorial process"
                        : "tutorial access"
                    }
                  >
                    <div className="iconDiv--day">
                      <label htmlFor="rememberToday" className="score label">
                        {tutorialOpen
                          ? tutorialState.memories_today_what
                          : "The memories from today that will capture it forever are..."}
                      </label>
                    </div>
                  </div>
                  <textarea
                    id="rememberToday"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={rememberToday}
                    placeholder={
                      tutorialOpen
                        ? tutorialState.memories_today_why
                        : "A great morning of stretching and a cup of tea; tough day at work, but a good chat - needed that."
                    }
                    onChange={handleChange_RememberToday}
                  />
                </div>
                <div
                  className={
                    tutorialOpen ? "yeartile--day--tutorial" : "yeartile--day"
                  }
                >
                  <div
                    className={
                      tutorialOpen
                        ? "tutorial access--tutorial process"
                        : "tutorial access"
                    }
                  >
                    <div className="iconDiv--day">
                      <label htmlFor="leaveBehind" className="score label">
                        {tutorialOpen
                          ? tutorialState.leave_behind_what
                          : "The thoughts & feelings from today I would like to leave behind are..."}
                      </label>
                    </div>
                  </div>

                  <textarea
                    id="leaveBehind"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={leaveBehind}
                    placeholder={
                      tutorialOpen
                        ? tutorialState.leave_behind_why
                        : "Not being patient"
                    }
                    onChange={handleChange_LeaveBehind}
                  />
                </div>

                <div
                  className={
                    tutorialOpen ? "yeartile--day--tutorial" : "yeartile--day"
                  }
                >
                  <div
                    className={
                      tutorialOpen
                        ? "tutorial access--tutorial process"
                        : "tutorial access"
                    }
                  >
                    <div className="iconDiv--day">
                      <label htmlFor="leaveBehind" className="score label">
                        {tutorialOpen
                          ? tutorialState.on_my_mind_what
                          : "and finally, anything else that is on my mind"}
                      </label>
                    </div>
                  </div>
                  <textarea
                    id="leaveBehind"
                    className="formInput moment"
                    wrap="soft"
                    autoComplete="off"
                    value={onMyMind}
                    placeholder={
                      tutorialOpen
                        ? tutorialState.on_my_mind_why
                        : "Not an easy day today."
                    }
                    onChange={handleChange_onMyMind}
                  />
                </div>

                <div className="scoring__row" style={{ borderBottom: "none" }}>
                  <div
                    className={
                      tutorialOpen
                        ? "score__row--tutorial process"
                        : "score__row"
                    }
                    style={
                      endScore === "5" || (endScore === undefined && vw < 425)
                        ? { borderRadius: "5px 5px 0px 0px" }
                        : { borderRadius: "5px 5px 5px 0px" }
                    }
                  >
                    <label htmlFor="endScore">
                      {tutorialOpen
                        ? tutorialState.end_score
                        : "After the day described above, I feel..."}
                    </label>
                    {endScore > 0 && (
                      <label className="score__display">{endScore}</label>
                    )}
                  </div>
                  <input
                    id="endScore"
                    className={
                      tutorialOpen
                        ? "weekSlider tutorial"
                        : endScore
                          ? "weekSlider"
                          : "weekSlider off"
                    }
                    type="range"
                    min="0"
                    max="5"
                    step="0.1"
                    value={endScore}
                    defaultValue="0"
                    style={{ background: getBackgroundStyleTwo(endScore) }}
                    onChange={(e) => {
                      setEndScore(e.target.value);
                      handleSliderChange(e, 5, 2);
                    }}
                  />
                  <span
                    className="tooltip--span"
                    style={{
                      left: `${tooltipPositionEnd.position}px`,
                      zIndex: "-1",
                      bottom: "22px",
                      opacity: `${tooltipPositionEnd.opacity}`,
                    }}
                  >
                    {endScore ? endScore : " "}
                  </span>
                </div>
                {tutorialOpen && (
                  <div
                    className={vw < 1024 ? "tile tut" : "tile tut--inactive"}
                  >
                    <div
                      className={
                        tutorialOpen
                          ? "dateRow--header--tutorial blank"
                          : "dateRow--header"
                      }
                    >
                      <label
                        className="score label"
                        style={{ opacity: "1", fontWeight: "bold" }}
                      >
                        {tutorialState.insights_hub.map((el, i) => (
                          <h3 key={i}>{el}</h3>
                        ))}
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </main>
      </form>
      <footer className="dock footer">
        {pageLoading ? (
          <DisabledDock
            opacityOne={valOne[0]}
            opacityTwo={valTwo[0]}
            opacityFour={valFour[0]}
            opacityThree={valThree[0]}
            opacityFive={valFive[0]}
            opacitySix={valSix[0]}
            opacitySeven={valSeven[0]}
            opacityEight={valEight[0]}
            opacityNine={valNine[0]}
            opacityTen={valTen[0]}
          />
        ) : (
          <motion.div
            className="dock--container"
            initial={{ opacity: 1 }}
            animate={{ opacity: isVisible ? 1 : 0 }}
            transition={{ duration: 0.3 }}
            style={{
              zIndex: 999,
            }}
          >
            <FullDock
              previousDay={previousDay}
              previousDayPresent={previousDayPresent}
              tutorialFlag={tutorialFlag}
              changeMade={parentState}
              addObjective={addObjective}
              momentsError={momentsError}
              noCreate={noPreviousDay()}
              hideOverlay={cursorDisplayHide}
              changeName={cursorChangeName}
              namePresent={namePresent}
              dobPresent={dobPresent}
              changeValuesOne={cursorChangeValuesOne}
              valuesOnePresent={valuesOnePresent}
              changeValuesTwo={cursorChangeValuesTwo}
              valuesTwoPresent={valuesTwoPresent}
              changeValuesThree={cursorChangeValuesThree}
              valuesThreePresent={valuesThreePresent}
              changeValuesFour={cursorChangeValuesFour}
              valuesFourPresent={valuesFourPresent}
              changeValuesFive={cursorChangeValuesFive}
              valuesFivePresent={valuesFivePresent}
              changeVisionOne={cursorChangeVisionOne}
              visionOnePresent={visionOnePresent}
              changeVisionTwo={cursorChangeVisionTwo}
              visionTwoPresent={visionTwoPresent}
              changeVisionThree={cursorChangeVisionThree}
              visionThreePresent={visionThreePresent}
              changeVisionFour={cursorChangeVisionFour}
              visionFourPresent={visionFourPresent}
              changeVisionFive={cursorChangeVisionFive}
              visionFivePresent={visionFivePresent}
              changeIkigai={cursorChangeIkigai}
              ikigaiPresent={ikigaiPresent}
              changeQuote={cursorChangeQuote}
              quotePresent={quotePresent}
              changeMemory={cursorChangeMemory}
              memoryPresent={memoryPresentState}
              changeObjOne={cursorChangeObjOne}
              objOnePresent={objOnePresent}
              changeObjTwo={cursorChangeObjTwo}
              objTwoPresent={objTwoPresent}
              changeObjThree={cursorChangeObjThree}
              objThreePresent={objThreePresent}
              changeObjFour={cursorChangeObjFour}
              objFourPresent={objFourPresent}
              changeObjFive={cursorChangeObjFive}
              objFivePresent={objFivePresent}
              changeObjSix={cursorChangeObjSix}
              objSixPresent={objSixPresent}
              changeObjSeven={cursorChangeObjSeven}
              objSevenPresent={objSevenPresent}
              changeObjEight={cursorChangeObjEight}
              objEightPresent={objEightPresent}
              changeObjNine={cursorChangeObjNine}
              objNinePresent={objNinePresent}
              changeObjTen={cursorChangeObjTen}
              objTenPresent={objTenPresent}
              updateDay={updateHandler}
              opacityOne={valOne[0]}
              opacityTwo={valTwo[0]}
              opacityFour={valFour[0]}
              opacityThree={valThree[0]}
              opacityFive={valFive[0]}
              opacitySix={valSix[0]}
              opacitySeven={valSeven[0]}
              opacityEight={valEight[0]}
              opacityNine={valNine[0]}
              opacityTen={valTen[0]}
              objData={objectives}
              saved={saved}
              tutorialChange={handleTutorialChange}
              tutorialOpen={tutorialOpen}
              tutorialStep={tutorialStep}
            />
          </motion.div>
        )}
      </footer>
      <TestFooter />
    </>
  );
}
