import React, {
  useEffect,
  useRef,
  useLayoutEffect,
  useState,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as d3 from "d3";
import ZeroRemove from "../../../Functions/ZeroRemove";
import "../feelingscorechart.css";

export default function FeelingScoreWD(props) {
  const d3Chart = useRef();
  let device = props.device;
  const parseDate = d3.timeParse("%Y-%m-%d");

  useEffect(() => {
    const startScoreData =
      Array.isArray(props.data) &&
      props.data.map((each) => {
        return {
          date: parseDate(each.logDate.slice(0, 10)),
          es: each.endScore,
        };
      });

    const ssDataFinal = startScoreData.sort((a, b) => a.date - b.date);

    let ssDataFinalFinal = () => {
      // if (ssDataFinal.length > 5) {
      //   let ssDataLength = ssDataFinal.length - 1;

      //   if (ssDataFinal[ssDataLength].es === undefined) {
      //     ssDataFinal.pop();
      //   }
      // }

      ZeroRemove(ssDataFinal);

      const avgArray = [];

      ssDataFinal && ssDataFinal.map((each, i) => avgArray.push(each.es));

      const avg =
        avgArray && parseFloat(((d3.mean(avgArray) * 10) / 10).toFixed(2));

      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      let dayScores = [];

      ssDataFinal.forEach((entry) => {
        const dayOfWeek = daysOfWeek[entry.date.getDay()];

        if (!dayScores[dayOfWeek]) {
          dayScores[dayOfWeek] = { sum: 0, count: 0 };
        }

        entry.es && (dayScores[dayOfWeek].sum += entry.es);
        entry.es && (dayScores[dayOfWeek].count += 1);
      });

      const averageScores = [];

      Object.keys(dayScores).forEach((day, index) => {
        averageScores.push({
          order: index,
          weekDay: day,
          average: parseFloat(
            (dayScores[day].sum / dayScores[day].count).toFixed(2)
          ),
        });
      });

      averageScores.sort((a, b) => a.average - b.average);

      return { graphData: averageScores, avg: avg };
    };

    const margin = { top: 24, right: 24, bottom: 48, left: 72 };
    const width =
      parseInt(d3.select(`#d3demo${device}`).style("width")) -
      margin.left -
      margin.right;
    const height =
      parseInt(d3.select(`#d3demo${device}`).style("height")) -
      margin.top -
      margin.bottom;

    const svg = d3
      .select(d3Chart.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const x = d3
      .scaleBand()
      .rangeRound([0, width])
      .padding(0.1)
      .domain([
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ]);

    svg
      .append("text")
      .attr("class", "x-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "translate(" + width / 2 + "," + (height + margin.bottom - 6) + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Week Day");

    const yScale = d3.scaleLinear().rangeRound([height, 0]).domain([0, 5]);

    const xAxis2 = d3.axisBottom(x);

    const customTickLabels = ["Less Joy", "More Joy"];
    const customTicks = [0, 5];

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues(customTicks)
      .tickFormat((d, i) => customTickLabels[i]);

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis2)
      .style("font-family", "Futura")
      .style("font-size", "8px")
      .style("color", "#e2dfcf")
      .style("font-weight", 600);

    const max1 = d3.max(ssDataFinalFinal().graphData, function (d) {
      return d.average;
    });
    const min1 = d3.min(ssDataFinalFinal().graphData, function (d) {
      return d.average;
    });

    svg
      .append("g")
      .call(yAxis)
      .style("font-family", "futura")
      .style("font-size", "8px")
      .style("color", "#e2dfcf")
      .style("font-weight", 200);

    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "rotate(-90) translate(" + -height / 2 + "," + -margin.left / 2 + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Average Feeling");

    const Tooltip4 = d3
      .select(document.body)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "#e2dfcf")
      .style("border", "2px solid #004a22")
      .style("border-radius", "2.5px")
      .style("padding", "4px 6px")
      .style("font-size", "8px");

    var mouseover1 = function (d) {
      Tooltip4.style("opacity", 1);
      Tooltip4.style("display", "flex");

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)")
        .style("stroke-width", "2")
        .style("cursor", "pointer");
    };

    const mousemove1 = (event, d) => {
      const [mx, my] = d3.pointer(event, d3.select(`#d3demo${device}`));

      Tooltip4.text(`${d.average}`)
        .style("left", mx + 18 + "px")
        .style("top", my - 30 + "px")
        .style("font-family", "futura")
        .style("font-weight", "600")
        .style("font-size", "8px")
        .style("color", "#004a22");
    };

    var mouseleave1 = function (d) {
      Tooltip4.style("opacity", 0);
      Tooltip4.style("display", "none");

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)")
        .style("stroke-width", "1");
    };

    svg
      .append("g")
      .selectAll("rect") // Change to 'rect'
      .data(ssDataFinalFinal().graphData)
      .enter()
      .append("rect") // Change to 'rect'
      .attr("x", function (d) {
        return x(d.weekDay) - 2.5;
      }) // Adjust x position as needed
      .attr("y", function (d) {
        return yScale(d.average);
      }) // Use y1 scale for the first dataset
      .attr("width", x.bandwidth) // Set the width of the bars
      .attr("height", function (d) {
        return height - yScale(d.average);
      }) // Calculate the height of the bars
      .attr("rx", 1) // Set the radius of the rounded corners
      .attr("ry", 1) // Set the radius of the rounded corners (ry is for
      .attr("fill", function (d) {
        // Check if the value falls within the specified range
        if (d.average === max1 || d.average === min1) {
          return "rgba(103, 36, 131,1)"; // Change color for values within range
        } else {
          return "rgba(0,74,34,1)"; // Default color for other values
        }
      })
      .style("stroke", "#e2dfcf")
      .style("stroke-width", "1")
      .style("opacity", 1)
      .style("z-index", "1")
      .on("mouseover", mouseover1)
      .on("mousemove", mousemove1)
      .on("mouseleave", mouseleave1);

    svg
      .append("path")
      .datum(ssDataFinalFinal().graphData)
      .attr("fill", "none")
      .attr("stroke", "#e2dfcf")
      .style("opacity", "1")
      .style("z-index", "11")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", 4)
      .attr(
        "d",
        d3
          .line()
          .x(function (d) {
            return x(d.weekDay);
          })
          .y(function (d) {
            return yScale(ssDataFinalFinal().avg);
          })
      );
  }, []);

  return (
    <div
      id={`d3demo${device}`}
      style={props.graphWidth && { width: `${props.graphWidth}px` }}
    >
      <svg ref={d3Chart}></svg>
    </div>
  );
}
