// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp,
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

// Images
import wavy_bottom from "../../Images/Assets/PageBreakers/1920x100_Top.svg";
import wavy_top from "../../Images/Assets/PageBreakers/1920x100_Bottom.svg";

// Data Actions
import { listQuizes } from "../../actions/quizActions";

// Styles
import "./footer.css";

const TestFooter = (props) => {
  const [darkMode, setDarkMode] = useState(false);

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const currentWindow = window.location.href;

  useEffect(() => {
    if (
      window.location.href.includes("/insight") ||
      window.location.href.includes("/tutorial")
    ) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  });

  return (
    <>
      {darkMode && (
        <img
          className="wave--top--footer"
          src={wavy_bottom}
          alt="wavy asset top"
          width="110%"
        />
      )}
      <footer className="test-footer">
        <Container fluid className="px-5">
          <Row className="py-4">
            <Col className="mt-4" lg={3} md={6}>
              <h4 className="mb-4" style={{ color: "#e2dfcf" }}>
                NoWastedDays
              </h4>
              <ul className="list-unstyled">
                {currentWindow.endsWith("/") ? (
                  <li>
                    <a href="/register">Get Started</a>
                  </li>
                ) : (
                  <></>
                )}
                <li>
                  <a href="/about-nowasteddays">About NoWastedDays</a>
                </li>
                <li>
                  <a
                    href={vw > 500 ? "/tutorial/today" : "/tutorial/mobile"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How it Works
                  </a>
                </li>
                <li>
                  <a href="/our-mission">The Mission</a>
                </li>
                <li>
                  <hr className="footer--hr"></hr>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/terms-and-conditions">Terms and Conditions</a>
                </li>
              </ul>
            </Col>

            <Col lg={3} md={6} className="mt-4">
              <h4 className="mb-4">Experiences</h4>
              <ul className="list-unstyled">
                <li>
                  <a href="/values">Your Values.</a>
                </li>
                <li>
                  <a href="/debrief">Your Outcomes.</a>
                </li>
                <li>
                  <a href={`/insight/quiz/`}>The Art of Looking.</a>
                </li>
                <li style={{ opacity: "0.25" }}>
                  <a href="#" style={{ textDecoration: "none" }}>
                    Thinking about your ikigai
                  </a>
                </li>
                <li style={{ opacity: "0.25" }}>
                  <a href="#" style={{ textDecoration: "none" }}>
                    Setting a vision for the year
                  </a>
                </li>
                <li style={{ opacity: "0.25" }}>
                  <a href="#" style={{ textDecoration: "none" }}>
                    Finding great quotes
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={6} className="mt-4">
              <h4 className="mb-4">Contact Us</h4>
              <ul className="list-unstyled">
                <li>
                  <a
                    data-formkit-toggle="ac5ca39c6b"
                    href="https://nowasteddays.ck.page/ac5ca39c6b"
                  >
                    Sign Up to The NWD Monthly Newsletter
                  </a>
                </li>
                <li>
                  <hr className="footer--hr"></hr>
                </li>
                <li>
                  <a href="/contact">
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                    hello@nowasteddays.org
                  </a>
                </li>
                <li>
                  <a href="/contact">Feedback & Media Request</a>
                </li>
                <li>
                  <a href="/contact">Join the Team</a>
                </li>
                <li style={{ opacity: "0.25" }}>
                  <a href="/contact" style={{ textDecoration: "none" }}>
                    Frequently Asked Questions
                  </a>
                </li>
              </ul>
            </Col>

            <Col lg={3} md={6} className="mt-4">
              <h4 className="mb-4">Share & Follow</h4>
              <ul className="list-unstyled d-flex justify-content-center">
                <li className="me-4">
                  <a
                    href="https://wa.me/?text=Start%20your%20NoWastedDays%20journal%20today%20for%20free%21%20%3A%20https%3A%2F%2Fnowasteddays.io/register"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                </li>
                <li className="me-4">
                  <a
                    href="https://www.instagram.com/nowasteddays/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li className="me-4">
                  <a
                    href="https://www.youtube.com/channel/UCMqVstA-okOwsuZK732k4zQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
                <li className="me-4">
                  <a
                    href="https://www.tiktok.com/@nowasteddays.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTiktok} />
                  </a>
                </li>
              </ul>
              <ul className="list-unstyled">
                <li>
                  <a href="/blog">Blog</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default TestFooter;
