// React
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// D3
import * as d3 from "d3";

//External Packages
import CryptoJS from "crypto-js";
import generateSecret from "../../../utils/generateSecret";

// Styling
import "./feelingdoing.css";

const FeelingDoing = (props) => {
  const navigate = useNavigate();
  const d3Chart8 = useRef();
  const parseDate = d3.timeParse("%Y-%m-%d");
  const key = generateSecret().toString();
  let device = props.device;

  let allData = Array.isArray(props.data) && props.data;

  const getDataTransform = () => {
    props.data &&
      allData.forEach((day) => {
        let sum =
          (!isNaN(day.momentOneScore) && day.momentOneScore) +
          (!isNaN(day.momentTwoScore) && day.momentTwoScore) +
          (!isNaN(day.momentThreeScore) && day.momentThreeScore) +
          (!isNaN(day.momentFourScore) && day.momentFourScore) +
          (!isNaN(day.momentFiveScore) && day.momentFiveScore);
        day.total = sum;

        let count =
          (day.momentOneScore / day.momentOneScore === 1 ||
          day.momentOneScore == 0
            ? 1
            : 0) +
          (day.momentTwoScore / day.momentTwoScore == 1 ||
          day.momentTwoScore == 0
            ? 1
            : 0) +
          (day.momentThreeScore / day.momentThreeScore == 1 ||
          day.momentThreeScore == 0
            ? 1
            : 0) +
          (day.momentFourScore / day.momentFourScore === 1 ||
          day.momentFourScore == 0
            ? 1
            : 0) +
          (day.momentFiveScore / day.momentFiveScore === 1 ||
          day.momentFiveScore == 0
            ? 1
            : 0);
        day.tasksAttempted = count;

        let tcr = day.total / 10 / day.tasksAttempted;
        day.taskCompletionRate = tcr;
      });

    const tasksCompletedData =
      Array.isArray(props.data) &&
      allData.map((each) => {
        let node = false;
        let mem = each.rememberToday;
        let leave = each.leaveBehind;

        if (props.nodeSelected === each.logDate.slice(0, 10)) {
          node = true;
        }

        if (
          !each.rememberToday.includes(" ") &&
          each.rememberToday.length > 25 &&
          typeof each.rememberToday === "string"
        ) {
          mem = CryptoJS.AES.decrypt(each.rememberToday, key).toString(
            CryptoJS.enc.Utf8
          );
        }

        if (
          !each.leaveBehind.includes(" ") &&
          each.leaveBehind.length > 25 &&
          typeof each.rememberToday === "string"
        ) {
          leave = CryptoJS.AES.decrypt(each.leaveBehind, key).toString(
            CryptoJS.enc.Utf8
          );
        }

        return {
          id: each._id,
          date: parseDate(each.logDate.slice(0, 10)),
          es: each.endScore,
          total: each.total,
          node: node,
          memory: mem,
          leave: leave,
        };
      });

    const tcDataFinal = tasksCompletedData.sort((a, b) => a.date - b.date);

    if (tcDataFinal.length > 5) {
      let tcDataLength = tcDataFinal.length - 1;

      if (tcDataFinal[tcDataLength].es === undefined) {
        tcDataFinal.pop();
      }
    }

    return tcDataFinal;
  };

  useEffect(() => {
    let tcData = getDataTransform();

    const margin = { top: 24, right: 36, bottom: 48, left: 72 };
    const width =
      parseInt(d3.select(`#d3demo8${device}`).style("width")) -
      margin.left -
      margin.right;
    const height =
      parseInt(d3.select(`#d3demo8${device}`).style("height")) -
      margin.top -
      margin.bottom;

    // Set up chart
    const svg = d3
      .select(d3Chart8.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const customXTickLabels = ["A Little", "A Lot"];
    const customXTicks = [0, 50];

    const customTickLabels = ["Less Joy", "More Joy"];
    const customTicks = [0, 5];

    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(tcData, (d) => d.total)])
      .range([0, width]);

    const xAxis = d3
      .axisBottom(xScale)
      .tickValues(customXTicks)
      .tickFormat((d, i) => customXTickLabels[i]);

    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(xAxis)
      .style("font-family", "futura")
      .style("font-weight", 600)
      .style("font-size", "8px")
      .style("color", "#e2dfcf");

    svg
      .append("text")
      .attr("class", "x-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "translate(" + width / 2 + "," + (height + margin.bottom - 12.5) + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Productivity");

    const minFeeling = d3.min(tcData, function (d) {
      return d.es;
    });

    const yScale = d3.scaleLinear().domain([minFeeling, 5]).range([height, 0]);

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues([minFeeling, 5])
      .tickFormat((d, i) => customTickLabels[i]);

    const maxFeeling = d3.max(tcData, function (d) {
      return d.es;
    });

    const upperLimitFeeling = maxFeeling - maxFeeling / 10;

    const maxTotal = d3.max(tcData, function (d) {
      return d.total;
    });

    const upperLimitTotal = maxTotal - maxTotal / 10;
    const lowerLimitTotal = 0 + maxTotal / 5;

    var handleNavigate = (id) => {
      // navigate(`/today/${id}`);

      window.open(`/today/${id}`, "_blank");
    };

    svg
      .append("g")
      .call(yAxis)
      .style("font-family", "futura")
      .style("font-weight", 600)
      .style("font-size", "8px")
      .style("color", "#e2dfcf");

    svg
      .append("text")
      .attr("class", "y-axis-title")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        "rotate(-90) translate(" + -height / 2 + "," + -margin.left / 2 + ")"
      )
      .style("font-family", "Futura")
      .style("font-weight", 600)
      .style("font-size", "10px")
      .style("fill", "#e2dfcf")
      .text("Journal Entry Feeling");

    var Tooltip = d3
      .select(document.body)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "#e2dfcf")
      .style("max-width", "250px")
      .style("border", "2px solid #004a22")
      .style("border-radius", "2.5px")
      .style("padding", "4px 6px")
      .style("font-size", "8px");

    var mouseover = function (d) {
      Tooltip.style("opacity", 1);

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)") // Set the stroke color
        .style("stroke-width", "2")
        .style("cursor", "pointer")
        .attr("width", 10) // Set the width of the square
        .attr("height", 10); // Set the height of the square
    };

    const mousemove = (event, d) => {
      const [mx, my] = d3.pointer(event, d3.select(`#d3demo8${device}`));

      Tooltip.html(
        `<div style={{ display: 'flex', flex-direction: 'column'}}>
								<strong>Remember From Today:</strong>
								<p>${d.memory}</p>
								<hr/>
								<strong>Leave Behind Today:</strong>
								<p>${d.leave}</p>
								</div>`
      )
        .style("left", mx + 18 + "px")
        .style("top", my - 30 + "px")
        .style("font-family", "futura")
        .style("font-weight", "600")
        .style("font-size", "8px")
        .style("color", "#004a22");
    };

    var mouseleave = function (d) {
      Tooltip.style("opacity", 0);

      d3.select(this)
        .style("stroke", "rgba(226, 223, 207, 1)") // Set the stroke color
        .style("stroke-width", "1")
        .style("cursor", "auto")
        .attr("width", 10) // Set the width of the square
        .attr("height", 10); // Set the height of the square
    };

    svg
      .append("g")
      .selectAll("square")
      .data(tcData)
      .enter()
      .append("rect") // Change "circle" to "rect"
      .attr("x", (d) => xScale(d.total) - 2.5)
      .attr("y", (d) => yScale(d.es) - 2.5)
      .attr("width", 10) // Set the width of the square
      .attr("height", 10) // Set the height of the square
      .attr("rx", 1) // Set the radius of the rounded corners
      .attr("ry", 1) // Set the radius of the rounded corners (ry is for vertical rounded corners, not required for squares)
      .attr("fill", function (d) {
        // Check if the value falls within the specified range
        if (
          d.es >= upperLimitFeeling &&
          (d.total < lowerLimitTotal || d.total > upperLimitTotal)
        ) {
          if (d.node === true) {
            return "rgba(255, 217, 0,1)";
          } else {
            return "rgba(103, 36, 131,1)";
          }
        } else {
          if (d.node === true) {
            return "rgba(255, 217, 0,1)";
          } else {
            return "rgba(0,74,34,1)";
          }
        }
      }) // Set the fill color
      .style("stroke", function (d) {
        // Check if the value falls within the specified range
        if (d.node === true) {
          return "#f5f5f5";
        } else {
          return "#e2dfcf";
        }
      }) // Set the stroke color
      .style("stroke-width", "1")
      .style("z-index", 10)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
      .on("click", function (d) {
        Tooltip.style("opacity", 0);
        handleNavigate(d.srcElement.__data__.id);
      });
  }, [props.nodeSelected]);

  return (
    <div
      id={`d3demo8${device}`}
      style={props.graphWidth && { width: `${props.graphWidth}px` }}
    >
      <svg ref={d3Chart8}></svg>
    </div>
  );
};

export default FeelingDoing;
