// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Internal Components
import CentralHeader from "../../components/Header/CentralHeader";
import CentralHeaderLP from "../../components/Header/CentralHeaderLP";
import TestFooter from "../../components/Footer/TestFooter";

// External Packages
import axios from "axios";
import * as d3 from "d3";

// Sanity Client
import { client } from "../../client";

import "./blog.css";

function BlogScreen() {
  const [content, setContent] = useState();
  const [rowNum, setRowNum] = useState(4);
  const [all, setAll] = useState();
  const parseDate = d3.timeParse("%Y-%m-%d");

  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  async function getPosts() {
    const posts = await client.fetch(`*[_type == 'post']{
      _id,
      title,
      body,
      slug,
      categories[] -> {
        _id,
        title,
        description
      },
      _createdAt,
      mainImage {
        asset -> {
          _id,
          url,
          alt,
        },
      },
    }`);
    return posts;
  }

  async function getAll() {
    const posts = await client.fetch(`*[_type == 'category']`);

    return posts;
  }

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const response = await getAll();

        if (response) {
          setAll(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAll();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let blogPosts = [];
      try {
        const response = await getPosts();

        if (response) {
          for (let i = 0; i < response.length; i++) {
            if (
              response[i].title.startsWith("LP_") ||
              response[i].title.startsWith("TUTORIAL_") ||
              response[i].title.startsWith("AOFL_") ||
              response[i].title.startsWith("POSTER_") ||
              response[i].title.startsWith("PLAN_") ||
              response[i].title.startsWith("PRIME_") ||
              response[i].title.startsWith("PROCESS_") ||
              response[i].title.startsWith("PAGE_") ||
              response[i].title.startsWith("LOGO_")
            ) {
              console.log("not right");
            } else {
              blogPosts.push(response[i]);
            }
          }
        }
        setContent(blogPosts);
        setRowNum((rowNum) => Math.round(blogPosts.length / 5));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    console.log(content);
  }, []);

  const vw = Math.max(document.documentElement.clientWidth);

  const [viewWidth, setViewWidth] = useState(vw);

  const handleSelectChange = (event) => {
    const selectedCategory = event.target.value;
    navigate(`/blog/search/${selectedCategory}`);
  };

  return (
    <>
      {userInfo ? <CentralHeader /> : <CentralHeaderLP />}
      <main className="blog__main">
        <section className="blog__category--list">
          {all ? (
            viewWidth > 480 ? (
              all.map((cat, i) => (
                <a key={i} href={`/blog/search/${cat.title}`}>
                  {cat.title}
                </a>
              ))
            ) : (
              <>
                <h6>Filter by Category</h6>
                <select
                  name="cat__selector"
                  defaultValue="nowasteddays"
                  className="select-style"
                  onChange={handleSelectChange}
                >
                  {all.map((cat, i) => (
                    <option key={i} value={cat.title} className="option-style">
                      {cat.title}
                    </option>
                  ))}
                </select>
              </>
            )
          ) : (
            <></>
          )}
        </section>
        <section
          className="blog__list"
          style={{ gridTemplateRows: `repeat(${rowNum}, 1fr)` }}
        >
          {content &&
            content.map((post) => (
              <div
                key={post._id}
                className={
                  post.mainImage
                    ? post.mainImage.asset.url.includes("1280")
                      ? "blog__tile--wide"
                      : "blog__tile"
                    : "blog__tile"
                }
              >
                {post.mainImage && (
                  <img
                    className="post--image"
                    src={post.mainImage.asset.url}
                    alt={post.mainImage._type}
                    width="100%"
                  />
                )}
                <div className="blog__tile--details">
                  <strong className="blog__tile--header">{post.title}</strong>
                  <div>
                    <div style={{ display: "inline", padding: "6px 0" }}>
                      {post.body &&
                        post.body[0].children.map((p, i) => (
                          <span key={p._key} className="blog__tile--excerpt">
                            {p.text}
                          </span>
                        ))}
                    </div>
                  </div>
                  <a
                    className="blog__tile--link"
                    key={post._id}
                    href={`/blog/${post.slug.current}`}
                  >
                    <div className="blog__tile--date-cta">
                      {/* <span>{post._createdAt.substring(0, 10)}</span> */}
                      <strong>Read Now</strong>
                    </div>
                  </a>
                </div>
              </div>
            ))}
        </section>
      </main>
      <TestFooter />
    </>
  );
}

export default BlogScreen;
