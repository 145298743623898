export const DEBRIEFS_CREATE_REQUEST = "DEBRIEFS_CREATE_REQUEST";
export const DEBRIEFS_CREATE_SUCCESS = "DEBRIEFS_CREATE_SUCCESS";
export const DEBRIEFS_CREATE_FAIL = "DEBRIEFS_CREATE_FAIL";

export const DEBRIEFS_LIST_REQUEST = "DEBRIEFS_LIST_REQUEST";
export const DEBRIEFS_LIST_SUCCESS = "DEBRIEFS_LIST_SUCCESS";
export const DEBRIEFS_LIST_FAIL = "DEBRIEFS_LIST_FAIL";

export const DEBRIEFS_UPDATE_REQUEST = "DEBRIEFS_UPDATE_REQUEST";
export const DEBRIEFS_UPDATE_SUCCESS = "DEBRIEFS_UPDATE_SUCCESS";
export const DEBRIEFS_UPDATE_FAIL = "DEBRIEFS_UPDATE_FAIL";

export const DEBRIEFS_DELETE_REQUEST = "DEBRIEFS_DELETE_REQUEST";
export const DEBRIEFS_DELETE_SUCCESS = "DEBRIEFS_DELETE_SUCCESS";
export const DEBRIEFS_DELETE_FAIL = "DEBRIEFS_DELETE_FAIL";