//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faPlusCircle,
  faFloppyDisk,
  faSignature,
  faCalendarDays,
  faCompassDrafting,
  faLandmarkDome,
  faDna,
  faGlasses,
  faQuoteLeft,
  faPersonCircleQuestion,
  faGripLinesVertical,
  faBookOpen,
  faEyeSlash,
  fa0,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
  faBolt,
  faCalendarPlus,
  faPlusSquare,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

//Internal Components
import {
  CreateDayIcon,
  CreateDayIconV2,
  ValuesIcon,
  NameIcon,
  NameIconV2,
  NameIconV3,
  NameIconV4,
  DobIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  SaveIcon,
  TickIcon,
  VitIcon,
  PreviousDay,
} from "../../components/Icons/Icon";

//Styling
import "./dock.css";

import formatISO9075 from "date-fns/formatISO9075";

export default function FullDock(props) {
  const navigate = useNavigate();
  const [yourName, setYourName] = useState();
  const [valuesShowState, setValuesShowState] = useState(
    "disabledmini node inactive"
  );
  const [visionShowState, setVisionShowState] = useState(
    "disabledmini node inactive"
  );

  const [colorStateOne, setColorStateOne] = useState("cls-1-invert");
  const [colorStateTwo, setColorStateTwo] = useState("cls-2-invert");

  const valuesShow = () => {
    if (valuesShowState === "disabledmini node inactive") {
      setValuesShowState((valuesShowState) => "disabledmini node");
      setVisionShowState((visionShowState) => "disabledmini node inactive");
    } else {
      setValuesShowState((valuesShowState) => "disabledmini node inactive");
    }
  };

  const visionShow = () => {
    if (visionShowState === "disabledmini node inactive") {
      setVisionShowState((visionShowState) => "disabledmini node");
      setValuesShowState((valuesShowState) => "disabledmini node inactive");
    } else {
      setVisionShowState((visionShowState) => "disabledmini node inactive");
    }
  };

  const valuesHide = () => {
    setValuesShowState((valuesShowState) => "disableddock node inactive");
  };

  const visionHide = () => {
    setVisionShowState((visionShowState) => "disableddock node inactive");
  };

  const currentWindow = window.location.href;

  const [isAnimatingName, setIsAnimatingName] = useState(false);
  const [isAnimatingDOB, setIsAnimatingDOB] = useState(false);
  const [isAnimatingValues, setIsAnimatingValues] = useState(false);
  const [isAnimatingValuesOne, setIsAnimatingValuesOne] = useState(false);
  const [isAnimatingValuesTwo, setIsAnimatingValuesTwo] = useState(false);
  const [isAnimatingValuesThree, setIsAnimatingValuesThree] = useState(false);
  const [isAnimatingValuesFour, setIsAnimatingValuesFour] = useState(false);
  const [isAnimatingValuesFive, setIsAnimatingValuesFive] = useState(false);
  const [isAnimatingVision, setIsAnimatingVision] = useState(false);
  const [isAnimatingVisionOne, setIsAnimatingVisionOne] = useState(false);
  const [isAnimatingVisionTwo, setIsAnimatingVisionTwo] = useState(false);
  const [isAnimatingVisionThree, setIsAnimatingVisionThree] = useState(false);
  const [isAnimatingVisionFour, setIsAnimatingVisionFour] = useState(false);
  const [isAnimatingVisionsFive, setIsAnimatingVisionFive] = useState(false);
  const [isAnimatingIki, setIsAnimatingIki] = useState(false);
  const [isAnimatingQuote, setIsAnimatingQuote] = useState(false);
  const [isAnimatingMemories, setIsAnimatingMemories] = useState(false);
  const [isAnimatingEye, setIsAnimatingEye] = useState(false);
  const [isAnimatingOne, setIsAnimatingOne] = useState(false);
  const [isAnimatingTwo, setIsAnimatingTwo] = useState(false);
  const [isAnimatingThree, setIsAnimatingThree] = useState(false);
  const [isAnimatingFour, setIsAnimatingFour] = useState(false);
  const [isAnimatingFive, setIsAnimatingFive] = useState(false);
  const [isAnimatingSix, setIsAnimatingSix] = useState(false);
  const [isAnimatingSeven, setIsAnimatingSeven] = useState(false);
  const [isAnimatingEight, setIsAnimatingEight] = useState(false);
  const [isAnimatingNine, setIsAnimatingNine] = useState(false);
  const [isAnimatingTen, setIsAnimatingTen] = useState(false);
  const [isAnimatingSave, setIsAnimatingSave] = useState(false);
  const [isAnimatingTutorial, setIsAnimatingTutorial] = useState(false);
  return (
    <div className="disableddock--container">
      {currentWindow.endsWith("/today") || currentWindow.match("/today/") ? (
        <>
          <div
            className="disableddock node indicate"
            data-tooltip={"Start a New Day"}
          >
            <CreateDayIconV2 />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip={"Start a New Day"}
          >
            <PreviousDay />
          </div>

          <div className="disableddock separator indicate">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Start your Year to activate this dock"
          >
            <VitIcon />
          </div>

          <div className="disableddockNodeWrapper">
            <div className="nodeRow">
              <div
                className={valuesShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingValuesOne(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingValuesOne(false);
                }}
              >
                <ValuesIcon />
              </div>
              <div
                className={valuesShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingValuesTwo(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingValuesTwo(false);
                }}
              >
                <ValuesIcon />
              </div>
              <div
                className={valuesShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingValuesThree(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingValuesThree(false);
                }}
              >
                <ValuesIcon />
              </div>
              <div
                className={valuesShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingValuesFour(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingValuesFour(false);
                }}
              >
                <ValuesIcon />
              </div>
              <div
                className={valuesShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingValuesFive(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingValuesFive(false);
                }}
              >
                <ValuesIcon />
              </div>
            </div>

            <div
              className="disableddock node indicate"
              data-tooltip="Start your Year to activate this dock"
              onMouseDown={() => {
                setIsAnimatingValues(true);
              }}
              onMouseUp={() => {
                setIsAnimatingValues(false);
              }}
            >
              <ValuesIcon />
            </div>
          </div>

          <div className="disableddockNodeWrapper">
            <div className="nodeRow">
              <div
                className={visionShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingVisionOne(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingVisionOne(false);
                }}
              >
                <VisionIcon />
              </div>
              <div
                className={visionShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingVisionTwo(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingVisionTwo(false);
                }}
              >
                <VisionIcon />
              </div>
              <div
                className={visionShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingVisionThree(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingVisionThree(false);
                }}
              >
                <VisionIcon />
              </div>
              <div
                className={visionShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingVisionFour(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingVisionFour(false);
                }}
              >
                <VisionIcon />
              </div>
              <div
                className={visionShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingVisionFive(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingVisionFive(false);
                }}
              >
                <VisionIcon />
              </div>
            </div>

            <div
              className="disableddock node indicate"
              data-tooltip="Start your Year to activate this dock"
              onMouseDown={() => {
                setIsAnimatingVision(true);
              }}
              onMouseUp={() => {
                setIsAnimatingVision(false);
              }}
            >
              <VisionIcon />
            </div>
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Start your Year to activate this dock"
            onMouseDown={() => {
              setIsAnimatingIki(true);
            }}
            onMouseUp={() => {
              setIsAnimatingIki(false);
            }}
          >
            <IkigaiIcon />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Start your Year to activate this dock"
            onMouseDown={() => {
              setIsAnimatingQuote(true);
            }}
            onMouseUp={() => {
              setIsAnimatingQuote(false);
            }}
          >
            <QuoteIconV2 />
          </div>

          {/* <div className="disableddock node indicate"
    data-tooltip="Start your Year to activate this dock"
    onMouseDown={(()=>{
      setIsAnimatingMemories(true);
    })}
    onMouseUp={(()=>{
      setIsAnimatingMemories(false);
    })}>
    <FontAwesomeIcon
    id="memoryIcon"
    className="icon"
    icon={faLandmarkDome}/>
      </div> */}

          <div
            className="disableddock node indicate"
            data-tooltip="Start your Year to activate this dock"
            onMouseOver={() => {
              setVisionShowState((visionShowState) => "mini node inactive");
              setValuesShowState((valuesShowState) => "mini node inactive");
            }}
            onMouseDown={() => {
              setIsAnimatingEye(true);
            }}
            onMouseUp={() => {
              setIsAnimatingEye(false);
            }}
          >
            <HideOverlayIcon />
          </div>

          <div className="disableddock separator indicate">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Fill in your first Week to activate this dock"
            onMouseDown={() => {
              setIsAnimatingOne(true);
            }}
            onMouseUp={() => {
              setIsAnimatingOne(false);
            }}
          >
            <OneIcon opacity={props.opacityOne} />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Fill in your first Week to activate this dock"
            onMouseDown={() => {
              setIsAnimatingTwo(true);
            }}
            onMouseUp={() => {
              setIsAnimatingTwo(false);
            }}
          >
            <TwoIcon opacity={props.opacityTwo} />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Fill in your first Week to activate this dock"
            onMouseDown={() => {
              setIsAnimatingThree(true);
            }}
            onMouseUp={() => {
              setIsAnimatingThree(false);
            }}
          >
            <ThreeIcon opacity={props.opacityThree} />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Fill in your first Week to activate this dock"
            onMouseDown={() => {
              setIsAnimatingFour(true);
            }}
            onMouseUp={() => {
              setIsAnimatingFour(false);
            }}
          >
            <FourIcon opacity={props.opacityFour} />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Fill in your first Week to activate this dock"
            onMouseDown={() => {
              setIsAnimatingFive(true);
            }}
            onMouseUp={() => {
              setIsAnimatingFive(false);
            }}
          >
            <FiveIcon opacity={props.opacityFive} />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Fill in your first Week to activate this dock"
            onMouseDown={() => {
              setIsAnimatingSix(true);
            }}
            onMouseUp={() => {
              setIsAnimatingSix(false);
            }}
          >
            <SixIcon opacity={props.opacitySix} />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Fill in your first Week to activate this dock"
            onMouseDown={() => {
              setIsAnimatingSeven(true);
            }}
            onMouseUp={() => {
              setIsAnimatingSeven(false);
            }}
          >
            <SevenIcon opacity={props.opacitySeven} />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Fill in your first Week to activate this dock"
            onMouseDown={() => {
              setIsAnimatingEight(true);
            }}
            onMouseUp={() => {
              setIsAnimatingEight(false);
            }}
          >
            <EightIcon opacity={props.opacityEight} />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Fill in your first Week to activate this dock"
            onMouseDown={() => {
              setIsAnimatingNine(true);
            }}
            onMouseUp={() => {
              setIsAnimatingNine(false);
            }}
          >
            <NineIcon opacity={props.opacityNine} />
          </div>

          <div
            id="finalnode"
            className="disableddock node indicate"
            data-tooltip="Fill in your first Week to activate this dock"
            onMouseDown={() => {
              setIsAnimatingTen(true);
            }}
            onMouseUp={() => {
              setIsAnimatingTen(false);
            }}
          >
            <TenIcon opacity={props.opacityTen} />
          </div>

          <div className="disableddock separator indicate">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>

          <div
            className="disableddock node indicate"
            onMouseDown={() => {
              setIsAnimatingSave(true);
            }}
            onMouseUp={() => {
              setIsAnimatingSave(false);
            }}
          >
            <SaveIcon colorStateTwo={colorStateTwo} />
          </div>
        </>
      ) : (
        <>
          <div
            className="disableddock node indicate"
            data-tooltip={"Start a New Day"}
          >
            <CreateDayIconV2 />
          </div>

          <div className="disableddock separator indicate">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>

          <div
            className="disableddock node indicate"
            onMouseDown={() => {
              setIsAnimatingName(true);
            }}
            onMouseUp={() => {
              setIsAnimatingName(false);
            }}
            data-tooltip="Start your Year to activate this dock"
          >
            <VitIcon />
          </div>

          {/* <div className="disableddock node indicate"
  data-tooltip="Start your Year to activate this dock"
  onMouseDown={(()=>{
    setIsAnimatingDOB(true);
  })}
  onMouseUp={(()=>{
    setIsAnimatingDOB(false);
  })}
  >
  <FontAwesomeIcon
    id="dobIcon"
    className="icon"
    icon={faCalendarDays}/>
      </div> */}

          <div className="disableddockNodeWrapper">
            <div className="nodeRow">
              <div
                className={valuesShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingValuesOne(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingValuesOne(false);
                }}
              >
                <ValuesIcon />
              </div>
              <div
                className={valuesShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingValuesTwo(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingValuesTwo(false);
                }}
              >
                <ValuesIcon />
              </div>
              <div
                className={valuesShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingValuesThree(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingValuesThree(false);
                }}
              >
                <ValuesIcon />
              </div>
              <div
                className={valuesShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingValuesFour(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingValuesFour(false);
                }}
              >
                <ValuesIcon />
              </div>
              <div
                className={valuesShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingValuesFive(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingValuesFive(false);
                }}
              >
                <ValuesIcon />
              </div>
            </div>

            <div
              className="disableddock node indicate"
              data-tooltip="Start your Year to activate this dock"
              onMouseDown={() => {
                setIsAnimatingValues(true);
              }}
              onMouseUp={() => {
                setIsAnimatingValues(false);
              }}
            >
              <ValuesIcon />
            </div>
          </div>

          <div className="disableddockNodeWrapper">
            <div className="nodeRow">
              <div
                className={visionShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingVisionOne(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingVisionOne(false);
                }}
              >
                <VisionIcon />
              </div>
              <div
                className={visionShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingVisionTwo(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingVisionTwo(false);
                }}
              >
                <VisionIcon />
              </div>
              <div
                className={visionShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingVisionThree(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingVisionThree(false);
                }}
              >
                <VisionIcon />
              </div>
              <div
                className={visionShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingVisionFour(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingVisionFour(false);
                }}
              >
                <VisionIcon />
              </div>
              <div
                className={visionShowState}
                data-tooltip="Start your Year to activate this dock"
                onMouseDown={() => {
                  setIsAnimatingVisionFive(true);
                }}
                onMouseUp={() => {
                  setIsAnimatingVisionFive(false);
                }}
              >
                <VisionIcon />
              </div>
            </div>

            <div
              className="disableddockwide node indicate"
              data-tooltip="Start your Year to activate this dock"
              onMouseDown={() => {
                setIsAnimatingVision(true);
              }}
              onMouseUp={() => {
                setIsAnimatingVision(false);
              }}
            >
              <VisionIcon />
            </div>
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Start your Year to activate this dock"
            onMouseDown={() => {
              setIsAnimatingIki(true);
            }}
            onMouseUp={() => {
              setIsAnimatingIki(false);
            }}
          >
            <IkigaiIcon />
          </div>

          <div
            className="disableddock node indicate"
            data-tooltip="Start your Year to activate this dock"
            onMouseDown={() => {
              setIsAnimatingQuote(true);
            }}
            onMouseUp={() => {
              setIsAnimatingQuote(false);
            }}
          >
            <QuoteIconV2 />
          </div>

          {/* <div className="disableddock node indicate"
    data-tooltip="Start your Year to activate this dock"
    onMouseDown={(()=>{
      setIsAnimatingMemories(true);
    })}
    onMouseUp={(()=>{
      setIsAnimatingMemories(false);
    })}>
    <FontAwesomeIcon
    id="memoryIcon"
    className="icon"
    icon={faLandmarkDome}/>
      </div> */}

          <div
            className="disableddock node indicate"
            data-tooltip="Start your Year to activate this dock"
            onMouseOver={() => {
              setVisionShowState((visionShowState) => "mini node inactive");
              setValuesShowState((valuesShowState) => "mini node inactive");
            }}
            onMouseDown={() => {
              setIsAnimatingEye(true);
            }}
            onMouseUp={() => {
              setIsAnimatingEye(false);
            }}
          >
            <HideOverlayIcon />
          </div>

          {/* <div className="disableddock node indicate"
>
<FontAwesomeIcon
id="questionIcon"
className="icon"
icon={faQuestion}/>
</div> */}

          <div className="disableddock separator indicate">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>
          <div
            className="disableddock node indicate"
            onMouseDown={() => {
              setIsAnimatingSave(true);
            }}
            onMouseUp={() => {
              setIsAnimatingSave(false);
            }}
          >
            <SaveIcon colorStateTwo={colorStateTwo} />
          </div>
        </>
      )}
    </div>
  );
}
