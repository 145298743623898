// React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { listDays } from "../../actions/dayActions";
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";

// External Packages
import CryptoJS from "crypto-js";
import { faqQuestionData, faqAnswerData } from "./FAQ_DATA";
// External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

// Internal Components & Utilities
import Confetti from "../../components/Animations/Confetti";
import quoteObj from "../../components/Quotes/Quotes";
import generateSecret from "../../utils/generateSecret";
import { MemoryIcon, QuoteIconV2 } from "../../components/Icons/Icon";

// Styling
import "./faq.css";

const FAQ = (props) => {
  const dispatch = useDispatch();
  const divRef = useRef();

  const [tileTextState, setTileTextState] = useState("");
  const [quoteAuthor, setQuoteAuthor] = useState("");
  const [coin, setCoin] = useState("");
  const [quote, setQuote] = useState(0);
  const [memory, setMemory] = useState();
  const [celebration, setCelebration] = useState(false);
  const [quoteOrMemory, setQuoteOrMemory] = useState("");

  const [select, setSelect] = useState(0);

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <section className="faq__section">
      <div className="faq__tile--question">
        <h4>Frequently Asked Questions</h4>
        {faqQuestionData &&
          faqQuestionData.map((q, i) => (
            <div key={i} className="question--row" onClick={() => setSelect(i)}>
              <p style={{ padding: "6px 0", fontWeight: "600" }}>
                {q.question}
              </p>
              <FontAwesomeIcon
                className="question--arrow"
                icon={faChevronRight}
              />
            </div>
          ))}
      </div>
      <div className="faq__tile--answer">
        <h4>Answer</h4>
        {faqAnswerData &&
          faqAnswerData.map((a, i) => (
            <p
              key={i}
              style={
                select === i
                  ? { display: "block", paddingTop: "12px", fontWeight: "400" }
                  : { display: "none" }
              }
            >
              {a.answer}
            </p>
          ))}
      </div>
    </section>
  );
};

export default FAQ;
