//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faFloppyDisk,
  faSignature,
  faCalendarDays,
  faCompassDrafting,
  faLandmarkDome,
  faDna,
  faGlasses,
  faQuoteLeft,
  faPersonCircleQuestion,
  faGripLinesVertical,
  faBookOpen,
  faEyeSlash,
  fa0,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
  faBolt,
  faCalendarPlus,
  faPlusSquare,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

//Internal Components
import {
  CreateDayIcon,
  CreateDayIconV2,
  ValuesIcon,
  NameIcon,
  NameIconV2,
  NameIconV3,
  NameIconV4,
  DobIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  OneIconMob,
  TwoIconMob,
  ThreeIconMob,
  FourIconMob,
  FiveIconMob,
  SixIconMob,
  SevenIconMob,
  EightIconMob,
  NineIconMob,
  TenIconMob,
  SaveIcon,
  VitIcon,
} from "../../components/Icons/Icon";

//Styling
import "./dock.css";
import formatISO9075 from "date-fns/formatISO9075";

export function FullDockMobileOne(props) {
  const [yourName, setYourName] = useState();
  const [valuesShowState, setValuesShowState] = useState("mini node inactive");
  const [visionShowState, setVisionShowState] = useState("mini node inactive");

  const [isAnimatingName, setIsAnimatingName] = useState(false);
  const [isAnimatingDOB, setIsAnimatingDOB] = useState(false);
  const [isAnimatingValues, setIsAnimatingValues] = useState(false);
  const [isAnimatingValuesOne, setIsAnimatingValuesOne] = useState(false);
  const [isAnimatingValuesTwo, setIsAnimatingValuesTwo] = useState(false);
  const [isAnimatingValuesThree, setIsAnimatingValuesThree] = useState(false);
  const [isAnimatingValuesFour, setIsAnimatingValuesFour] = useState(false);
  const [isAnimatingValuesFive, setIsAnimatingValuesFive] = useState(false);
  const [isAnimatingVision, setIsAnimatingVision] = useState(false);
  const [isAnimatingVisionOne, setIsAnimatingVisionOne] = useState(false);
  const [isAnimatingVisionTwo, setIsAnimatingVisionTwo] = useState(false);
  const [isAnimatingVisionThree, setIsAnimatingVisionThree] = useState(false);
  const [isAnimatingVisionFour, setIsAnimatingVisionFour] = useState(false);
  const [isAnimatingVisionsFive, setIsAnimatingVisionFive] = useState(false);
  const [isAnimatingIki, setIsAnimatingIki] = useState(false);
  const [isAnimatingQuote, setIsAnimatingQuote] = useState(false);
  const [isAnimatingMemories, setIsAnimatingMemories] = useState(false);
  const [isAnimatingEye, setIsAnimatingEye] = useState(false);

  const [isAnimatingSave, setIsAnimatingSave] = useState(false);
  const [isAnimatingTutorial, setIsAnimatingTutorial] = useState(false);

  const valuesShow = () => {
    if (valuesShowState === "mini node inactive") {
      setValuesShowState((valuesShowState) => "mini node");
      setVisionShowState((visionShowState) => "mini node inactive");
    } else {
      setValuesShowState((valuesShowState) => "mini node inactive");
    }
  };

  const visionShow = () => {
    if (visionShowState === "mini node inactive") {
      setVisionShowState((visionShowState) => "mini node");
      setValuesShowState((valuesShowState) => "mini node inactive");
    } else {
      setVisionShowState((visionShowState) => "mini node inactive");
    }
  };

  const valuesHide = () => {
    setValuesShowState((valuesShowState) => "dock node inactive");
  };

  const visionHide = () => {
    setVisionShowState((visionShowState) => "dock node inactive");
  };

  const currentWindow = window.location.href;

  return (
    <div
      className={
        props.tutorialOpen
          ? "mobiledock--container center tute"
          : "mobiledock--container center"
      }
    >
      <div
        className={
          props.namePresent() ? `mobiledock node` : `disableddock node`
        }
        onClick={props.namePresent() ? props.changeName : props.cursorYearAlert}
      >
        <VitIcon />
      </div>

      <div className="mobileDockNodeWrapper">
        <div
          className={
            props.valuesOnePresent() ||
            props.valuesTwoPresent() ||
            props.valuesThreePresent() ||
            props.valuesFourPresent() ||
            props.valuesFivePresent()
              ? `mobiledock node`
              : `disableddock node`
          }
          onClick={valuesShow}
        >
          <ValuesIcon />
        </div>
        <div className="mobileNodeRow">
          <div
            className={valuesShowState}
            data-tooltip="Value #1"
            onClick={props.changeValuesOne}
          >
            <ValuesIcon />
          </div>
          <div
            className={valuesShowState}
            data-tooltip="Value #2"
            onClick={props.changeValuesTwo}
          >
            <ValuesIcon />
          </div>
          <div
            className={valuesShowState}
            data-tooltip="Value #3"
            onClick={props.changeValuesThree}
          >
            <ValuesIcon />
          </div>
          <div
            className={valuesShowState}
            data-tooltip="Value #4"
            onClick={props.changeValuesFour}
          >
            <ValuesIcon />
          </div>
          <div
            className={valuesShowState}
            data-tooltip="Value #5"
            onClick={props.changeValuesFive}
          >
            <ValuesIcon />
          </div>
        </div>
      </div>

      <div className="mobileDockNodeWrapper">
        <div
          className={
            props.visionOnePresent() ||
            props.visionTwoPresent() ||
            props.visionThreePresent() ||
            props.visionFourPresent() ||
            props.visionFivePresent()
              ? `mobiledockwide node`
              : "disableddock node"
          }
          onClick={visionShow}
        >
          <VisionIcon />
        </div>

        <div className="mobileNodeRow">
          <div
            className={visionShowState}
            data-tooltip="Vision #1"
            onClick={props.changeVisionOne}
          >
            <VisionIcon />
          </div>
          <div
            className={visionShowState}
            data-tooltip="Vision #2"
            onClick={props.changeVisionTwo}
          >
            <VisionIcon />
          </div>
          <div
            className={visionShowState}
            data-tooltip="Vision #2"
            onClick={props.changeVisionThree}
          >
            <VisionIcon />
          </div>
          <div
            className={visionShowState}
            data-tooltip="Vision #2"
            onClick={props.changeVisionFour}
          >
            <VisionIcon />
          </div>
          <div
            className={visionShowState}
            data-tooltip="Vision #2"
            onClick={props.changeVisionFive}
          >
            <VisionIcon />
          </div>
        </div>
      </div>

      <div
        className={
          props.ikigaiPresent() ? `mobiledock node` : `disableddock node`
        }
        onClick={
          props.ikigaiPresent() ? props.changeIkigai : props.cursorYearAlert
        }
      >
        <IkigaiIcon />
      </div>
      <div
        className={
          props.quotePresent() ? `mobiledock node` : `disableddock node`
        }
        data-tooltip="My Quote"
        onClick={
          props.quotePresent() ? props.changeQuote : props.cursorYearAlert
        }
      >
        <QuoteIconV2 />
      </div>

      <div
        className={`mobiledockwide node`}
        data-tooltip="Hide Overlay"
        onClick={props.hideOverlay}
      >
        <HideOverlayIcon />
      </div>
    </div>
  );
}

export function FullDockMobileTwo(props) {
  const [yourName, setYourName] = useState();
  const [valuesShowState, setValuesShowState] = useState("mini node inactive");
  const [visionShowState, setVisionShowState] = useState("mini node inactive");
  const [isAnimatingOne, setIsAnimatingOne] = useState(false);
  const [isAnimatingTwo, setIsAnimatingTwo] = useState(false);
  const [isAnimatingThree, setIsAnimatingThree] = useState(false);
  const [isAnimatingFour, setIsAnimatingFour] = useState(false);
  const [isAnimatingFive, setIsAnimatingFive] = useState(false);
  const [isAnimatingSix, setIsAnimatingSix] = useState(false);
  const [isAnimatingSeven, setIsAnimatingSeven] = useState(false);
  const [isAnimatingEight, setIsAnimatingEight] = useState(false);
  const [isAnimatingNine, setIsAnimatingNine] = useState(false);
  const [isAnimatingTen, setIsAnimatingTen] = useState(false);

  const valuesShow = () => {
    if (valuesShowState === "mini node inactive") {
      setValuesShowState((valuesShowState) => "mini node");
      setVisionShowState((visionShowState) => "mini node inactive");
    } else {
      setValuesShowState((valuesShowState) => "mini node inactive");
    }
  };

  const visionShow = () => {
    if (visionShowState === "mini node inactive") {
      setVisionShowState((visionShowState) => "mini node");
      setValuesShowState((valuesShowState) => "mini node inactive");
    } else {
      setVisionShowState((visionShowState) => "mini node inactive");
    }
  };

  const valuesHide = () => {
    setValuesShowState((valuesShowState) => "dock node inactive");
  };

  const visionHide = () => {
    setVisionShowState((visionShowState) => "dock node inactive");
  };

  const currentWindow = window.location.href;

  return (
    <div
      className={
        props.tutorialOpen
          ? "mobiledock--container tute"
          : "mobiledock--container "
      }
    >
      <div
        className={
          props.objOnePresent() ? `mobiledock node` : "disableddock node"
        }
        data-tooltip="Objective #1"
        onClick={
          props.objOnePresent() ? props.changeObjOne : props.cursorWeekAlert
        }
      >
        <OneIconMob opacity={props.opacityOne} />
      </div>
      <div
        className={
          props.objTwoPresent() ? `mobiledock node` : "disableddock node"
        }
        data-tooltip="Objective #2"
        onClick={
          props.objTwoPresent() ? props.changeObjTwo : props.cursorWeekAlert
        }
      >
        <TwoIconMob opacity={props.opacityTwo} />
      </div>
      <div
        className={
          props.objThreePresent() ? `mobiledock node` : "disableddock node"
        }
        data-tooltip="Objective #3"
        onClick={
          props.objThreePresent() ? props.changeObjThree : props.cursorWeekAlert
        }
      >
        <ThreeIconMob opacity={props.opacityThree} />
      </div>
      <div
        className={
          props.objFourPresent() ? `mobiledock node` : "disableddock node"
        }
        data-tooltip="Objective #4"
        onClick={
          props.objFourPresent() ? props.changeObjFour : props.cursorWeekAlert
        }
      >
        <FourIconMob opacity={props.opacityFour} />
      </div>
      <div
        className={
          props.objFivePresent() ? `mobiledock node` : "disableddock node"
        }
        data-tooltip="Objective #5"
        onClick={
          props.objFivePresent() ? props.changeObjFive : props.cursorWeekAlert
        }
      >
        <FiveIconMob opacity={props.opacityFive} />
      </div>
      <div
        className={
          props.objSixPresent() ? `mobiledock node` : "disableddock node"
        }
        data-tooltip="Objective #6"
        onClick={
          props.objSixPresent() ? props.changeObjSix : props.cursorWeekAlert
        }
      >
        <SixIconMob opacity={props.opacitySix} />
      </div>
      <div
        className={
          props.objSevenPresent() ? `mobiledock node` : "disableddock node"
        }
        data-tooltip="Objective #7"
        onClick={
          props.objSevenPresent() ? props.changeObjSeven : props.cursorWeekAlert
        }
      >
        <SevenIconMob opacity={props.opacitySeven} />
      </div>
      <div
        className={
          props.objEightPresent() ? `mobiledock node` : "disableddock node"
        }
        data-tooltip="Objective #8"
        onClick={
          props.objEightPresent() ? props.changeObjEight : props.cursorWeekAlert
        }
      >
        <EightIconMob opacity={props.opacityEight} />
      </div>
      <div
        className={
          props.objNinePresent() ? `mobiledock node` : "disableddock node"
        }
        data-tooltip="Objective #9"
        onClick={
          props.objNinePresent() ? props.changeObjNine : props.cursorWeekAlert
        }
      >
        <NineIconMob opacity={props.opacityNine} />
      </div>
      <div
        id="finalnode"
        className={
          props.objTenPresent() ? `mobiledock node` : "disableddock node"
        }
        data-tooltip="Objective #10"
        onClick={
          props.objTenPresent() ? props.changeObjTen : props.cursorWeekAlert
        }
      >
        <TenIconMob opacity={props.opacityTen} />
      </div>
    </div>
  );
}
