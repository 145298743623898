//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDoorOpen,
  faFaceGrinWide,
  faCircleArrowLeft,
  faQuestion,
  faPlusCircle,
  faFloppyDisk,
  faSignature,
  faCalendarDays,
  faCompassDrafting,
  faLandmarkDome,
  faDna,
  faGlasses,
  faQuoteLeft,
  faPersonCircleQuestion,
  faGripLinesVertical,
  faBookOpen,
  faEyeSlash,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
  faBolt,
  faCalendarPlus,
  faPlusSquare,
  faTicket,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";

//Internal Components
import {
  CreateDayIcon,
  CreateDayIconV2,
  SevenIcon,
  ValuesIcon,
  NameIcon,
  NameIconV2,
  NameIconV3,
  NameIconV4,
  DobIcon,
  VitIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIcon,
  QuoteIconV2,
  TickIcon,
  HideOverlayIcon,
  QuestionIcon,
  SaveIcon,
  LoginIcon,
  PreviousDay,
} from "../../components/Icons/Icon";

import "./dock.css";

export default function MidDock(props) {
  const navigate = useNavigate();
  const [yourName, setYourName] = useState();
  const [valuesShowState, setValuesShowState] = useState("mini node inactive");
  const [visionShowState, setVisionShowState] = useState("mini node inactive");
  const [isAnimatingStart, setIsAnimatingStart] = useState(false);
  const [isAnimatingName, setIsAnimatingName] = useState(false);
  const [isAnimatingDOB, setIsAnimatingDOB] = useState(false);
  const [isAnimatingValues, setIsAnimatingValues] = useState(false);
  const [isAnimatingValuesOne, setIsAnimatingValuesOne] = useState(false);
  const [isAnimatingValuesTwo, setIsAnimatingValuesTwo] = useState(false);
  const [isAnimatingValuesThree, setIsAnimatingValuesThree] = useState(false);
  const [isAnimatingValuesFour, setIsAnimatingValuesFour] = useState(false);
  const [isAnimatingValuesFive, setIsAnimatingValuesFive] = useState(false);
  const [isAnimatingVision, setIsAnimatingVision] = useState(false);
  const [isAnimatingVisionOne, setIsAnimatingVisionOne] = useState(false);
  const [isAnimatingVisionTwo, setIsAnimatingVisionTwo] = useState(false);
  const [isAnimatingVisionThree, setIsAnimatingVisionThree] = useState(false);
  const [isAnimatingVisionFour, setIsAnimatingVisionFour] = useState(false);
  const [isAnimatingVisionsFive, setIsAnimatingVisionFive] = useState(false);
  const [isAnimatingSave, setIsAnimatingSave] = useState(false);
  const [isAnimatingIki, setIsAnimatingIki] = useState(false);
  const [isAnimatingQuote, setIsAnimatingQuote] = useState(false);
  const [isAnimatingMemories, setIsAnimatingMemories] = useState(false);
  const [isAnimatingEye, setIsAnimatingEye] = useState(false);
  const [isAnimatingTutorial, setIsAnimatingTutorial] = useState(false);
  const [isAnimatingPrevious, setIsAnimatingPrevious] = useState(false);

  const [colorStateOne, setColorStateOne] = useState("cls-1-invert");
  const [colorStateTwo, setColorStateTwo] = useState("cls-2-invert");

  const valuesShow = () => {
    if (valuesShowState === "mini node inactive") {
      setValuesShowState((valuesShowState) => "mini node");
      setVisionShowState((visionShowState) => "mini node inactive");
    } else {
      setValuesShowState((valuesShowState) => "mini node inactive");
    }
  };

  const visionShow = () => {
    if (visionShowState === "mini node inactive") {
      setVisionShowState((visionShowState) => "mini node");
      setValuesShowState((valuesShowState) => "mini node inactive");
    } else {
      setVisionShowState((visionShowState) => "mini node inactive");
    }
  };

  const valuesHide = () => {
    setValuesShowState((valuesShowState) => "dock node inactive");
  };

  const visionHide = () => {
    setVisionShowState((visionShowState) => "dock node inactive");
  };

  const [showDock, setShowDock] = useState(false);

  const showDockFunc = () => {
    setShowDock((showDock) => !showDock);
  };

  const currentWindow = window.location.href;
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  return vw < 1024 && currentWindow.match("/week/") ? (
    !showDock ? (
      <div className="dock--container">
        <div
          id="openDock"
          className="jordandock node"
          data-tooltip="Show Dock"
          onClick={() => {
            showDockFunc();
          }}
        >
          <LoginIcon />
        </div>
      </div>
    ) : (
      <div className="dock--container">
        {currentWindow.match("/week/") ||
        currentWindow.endsWith("/week/create") ? (
          <>
            <div
              className="jordandock node"
              data-tooltip="Start a New Week"
              onClick={() => {
                navigate("/week/create");
              }}
            >
              <CreateDayIconV2 />
            </div>
            <div
              id="openDock"
              className="jordandock node"
              data-tooltip="Show Dock"
              onClick={() => {
                showDockFunc();
              }}
            >
              <LoginIcon />
            </div>

            <div className="dock separator">
              <FontAwesomeIcon
                id="separatorIcon"
                className="separator"
                icon={faGripLinesVertical}
              />
            </div>

            <div
              className={
                props.namePresent()
                  ? `jordandock node ${isAnimatingName ? "animate" : ""}`
                  : `disableddock node`
              }
              data-tooltip={
                props.namePresent()
                  ? "Me"
                  : "Add your name to your year map to access it in the dock."
              }
              onClick={props.changeName}
            >
              <VitIcon />
            </div>

            <div className="dockNodeWrapper">
              <div className="nodeRow">
                <div
                  className={valuesShowState}
                  data-tooltip={
                    props.valuesOnePresent()
                      ? "Value #1"
                      : "Add your values to your year map to access them in the dock."
                  }
                  onClick={props.changeValuesOne}
                >
                  <ValuesIcon />
                </div>
                <div
                  className={valuesShowState}
                  data-tooltip={
                    props.valuesTwoPresent()
                      ? "Value #2"
                      : "Add your values to your year map to access them in the dock."
                  }
                  onClick={props.changeValuesTwo}
                >
                  <ValuesIcon />
                </div>
                <div
                  className={valuesShowState}
                  data-tooltip={
                    props.valuesThreePresent()
                      ? "Value #3"
                      : "Add your values to your year map to access them in the dock."
                  }
                  onClick={props.changeValuesThree}
                >
                  <ValuesIcon />
                </div>
                <div
                  className={valuesShowState}
                  data-tooltip={
                    props.valuesFourPresent()
                      ? "Value #4"
                      : "Add your values to your year map to access them in the dock."
                  }
                  onClick={props.changeValuesFour}
                >
                  <ValuesIcon />
                </div>
                <div
                  className={valuesShowState}
                  data-tooltip={
                    props.valuesFivePresent()
                      ? "Value #5"
                      : "Add your values to your year map to access them in the dock."
                  }
                  onClick={props.changeValuesFive}
                >
                  <ValuesIcon />
                </div>
              </div>
              <div
                className={
                  props.valuesOnePresent() ||
                  props.valuesTwoPresent() ||
                  props.valuesThreePresent() ||
                  props.valuesFourPresent() ||
                  props.valuesFivePresent()
                    ? `jordandock node ${isAnimatingValues ? "animate" : ""}`
                    : `disableddock node`
                }
                data-tooltip={
                  props.valuesOnePresent() ||
                  props.valuesTwoPresent() ||
                  props.valuesThreePresent() ||
                  props.valuesFourPresent() ||
                  props.valuesFivePresent()
                    ? "My Values"
                    : "Add your values in your year map to access them in the dock."
                }
                onClick={valuesShow}
              >
                <ValuesIcon />
              </div>
            </div>

            <div className="dockNodeWrapper">
              <div className="nodeRow">
                <div
                  className={visionShowState}
                  data-tooltip={
                    props.visionOnePresent()
                      ? "Vision #1"
                      : "Add your vision to your year map to access them in the dock."
                  }
                  onClick={props.changeVisionOne}
                >
                  <VisionIcon />
                </div>
                <div
                  className={visionShowState}
                  data-tooltip={
                    props.visionTwoPresent()
                      ? "Vision #2"
                      : "Add your vision to your year map to access them in the dock."
                  }
                  onClick={props.changeVisionTwo}
                >
                  <VisionIcon />
                </div>
                <div
                  className={visionShowState}
                  data-tooltip={
                    props.visionThreePresent()
                      ? "Vision #3"
                      : "Add your vision to your year map to access them in the dock."
                  }
                  onClick={props.changeVisionThree}
                >
                  <VisionIcon />
                </div>
                <div
                  className={visionShowState}
                  data-tooltip={
                    props.visionFourPresent()
                      ? "Vision #4"
                      : "Add your vision to your year map to access them in the dock."
                  }
                  onClick={props.changeVisionFour}
                >
                  <VisionIcon />
                </div>
                <div
                  className={visionShowState}
                  data-tooltip={
                    props.visionFivePresent()
                      ? "Vision #5"
                      : "Add your vision to your year map to access them in the dock."
                  }
                  onClick={props.changeVisionFive}
                >
                  <VisionIcon />
                </div>
              </div>

              <div
                className={
                  props.visionOnePresent() ||
                  props.visionTwoPresent() ||
                  props.visionThreePresent() ||
                  props.visionFourPresent() ||
                  props.visionFivePresent()
                    ? `jordandockwide node ${
                        isAnimatingVision ? "animate" : ""
                      }`
                    : "disableddock node"
                }
                data-tooltip={
                  props.visionOnePresent() ||
                  props.visionTwoPresent() ||
                  props.visionThreePresent() ||
                  props.visionFourPresent() ||
                  props.visionFivePresent()
                    ? "My Vision"
                    : "Add your vision to your year map to access it through the dock."
                }
                onClick={visionShow}
              >
                <VisionIcon />
              </div>
            </div>

            <div
              className={
                props.ikigaiPresent()
                  ? `jordandock node ${isAnimatingIki ? "animate" : ""}`
                  : `disableddock node`
              }
              data-tooltip={
                props.ikigaiPresent()
                  ? "My Ikigai"
                  : "Add your ikigai to your year map to access it in the dock"
              }
              onClick={props.changeIkigai}
            >
              <IkigaiIcon />
            </div>
            <div
              className={
                props.quotePresent()
                  ? `jordandock node ${isAnimatingQuote ? "animate" : ""}`
                  : `disableddock node`
              }
              data-tooltip={
                props.quotePresent()
                  ? "My Quote"
                  : "Add a quote to your year map to access it in the dock."
              }
              onClick={props.changeQuote}
            >
              <QuoteIconV2 />
            </div>

            {vw > 500 ? (
              <div
                className={`jordandockwide node ${
                  isAnimatingEye ? "animate" : ""
                }`}
                data-tooltip="Hide Overlay"
                onMouseOver={() => {
                  setVisionShowState((visionShowState) => "mini node inactive");
                  setValuesShowState((valuesShowState) => "mini node inactive");
                  props.toggleOverlay();
                }}
              >
                <HideOverlayIcon />
              </div>
            ) : (
              <></>
            )}
            {vw < 500 ? (
              <></>
            ) : props.weeksLogged() < 2 || !props.weeksLogged() ? (
              <div
                className={`jordandock node ${
                  isAnimatingTutorial ? "animate" : ""
                }`}
                data-tooltip="Click for Tutorial"
                onClick={() => {
                  navigate("/tutorial/week");
                }}
              >
                <QuestionIcon />
              </div>
            ) : (
              <></>
            )}
            <div className="dock separator">
              <FontAwesomeIcon
                id="separatorIcon"
                className="separator"
                icon={faGripLinesVertical}
              />
            </div>

            {currentWindow.endsWith("/week/create") ? (
              <motion.div
                id="start"
                className={`jordandock node_save  ${props.changeMade}`}
                data-tooltip="Save my week"
                onClick={props.saveWeek}
                whileTap={{ scale: 0.9 }}
                whileHover={{
                  scale: 0.95,
                }}
                onMouseOver={() => {
                  setColorStateTwo("cls-2");
                }}
                onMouseDown={() => {
                  setColorStateTwo("cls-2");
                }}
                onMouseOut={() => {
                  setColorStateTwo("cls-2-invert");
                }}
              >
                <SaveIcon
                  saved={props.saved}
                  indicate={props.changeMade}
                  colorStateOne={colorStateOne}
                  colorStateTwo={colorStateTwo}
                />
              </motion.div>
            ) : (
              <motion.div
                className={`jordandock node_save ${props.changeMade}`}
                data-tooltip={props.saved ? "Week Updated" : "Click to Save"}
                onClick={props.updateWeek}
                whileTap={{ scale: 0.9 }}
                whileHover={{
                  scale: 0.95,
                }}
                onMouseOver={() => {
                  setColorStateTwo("cls-2");
                }}
                onMouseOut={() => {
                  setColorStateTwo("cls-2-invert");
                }}
              >
                <SaveIcon
                  saved={props.saved}
                  indicate={props.changeMade}
                  colorStateOne={colorStateOne}
                  colorStateTwo={colorStateTwo}
                />
              </motion.div>
            )}
          </>
        ) : currentWindow.endsWith("/today") ? (
          <>
            <div
              className={`jordandock node_save`}
              onClick={props.tutorialChange}
            >
              <QuestionIcon />
            </div>
            <div
              id={
                props.submitDisabled === "disabled" ||
                props.noCreate === "disabled" ||
                props.tutorialOpen
                  ? "disabled"
                  : ""
              }
              className={`jordandock node_save  ${props.changeMade}`}
              data-tooltip={
                props.submitDisabled === "disabled"
                  ? "Diary date already used or possibly incorrect"
                  : props.noCreate === "disabled"
                    ? "Click Create Day to Complete Previous Day First"
                    : "Click to Save"
              }
              onClick={
                props.submitDisabled !== "disabled" &&
                props.logDate >= 2023 &&
                props.submitDay
              }
              onMouseOver={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseDown={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseOut={() => {
                setColorStateTwo("cls-2-invert");
              }}
            >
              <SaveIcon
                saved={props.saved}
                indicate={props.changeMade}
                colorStateOne={colorStateOne}
                colorStateTwo={colorStateTwo}
              />
            </div>
            <div
              className={
                props.previousDayPresent()
                  ? `jordandock node ${isAnimatingPrevious ? "animate" : ""}`
                  : `disableddock node`
              }
              onClick={props.previousDay}
              data-tooltip={
                props.previousDayPresent()
                  ? "Go to previous day"
                  : "Add another day to your diary to activate this node"
              }
            >
              <PreviousDay />
            </div>
          </>
        ) : props.momentsError === true ? (
          <>
            <div
              id={!props.endScore ? "disabled" : ""}
              data-tooltip={
                props.saved
                  ? props.endScore
                    ? `Start a New Day ABC`
                    : `Add Feeling Score ABC`
                  : `Click To Save ABC`
              }
              onClick={props.updateDay}
              onMouseOver={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseDown={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseOut={() => {
                setColorStateTwo("cls-2-invert");
              }}
            >
              <a href={props.saved ? (props.endScore ? "/today" : "") : ""}>
                <SaveIcon
                  saved={props.saved}
                  colorStateOne={colorStateOne}
                  colorStateTwo={colorStateTwo}
                />
              </a>
            </div>
            <div
              className={`jordandock node_save  ${props.changeMade}`}
              // style={{ backgroundColor: "green", padding: "4px" }}
              onMouseOver={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseDown={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseOut={() => {
                setColorStateTwo("cls-2-invert");
              }}
            >
              <SaveIcon
                saved={props.saved}
                colorStateOne={colorStateOne}
                colorStateTwo={colorStateTwo}
              />
            </div>
          </>
        ) : (
          <div
            className={`jordandock node_save  ${props.changeMade}`}
            data-tooltip="You have a moment without a score. Resolve before saving"
            // style={{ backgroundColor: "red", padding: "4px" }}
            onMouseOver={() => {
              setColorStateTwo("cls-2");
            }}
            onMouseDown={() => {
              setColorStateTwo("cls-2");
            }}
            onMouseOut={() => {
              setColorStateTwo("cls-2-invert");
            }}
          >
            <SaveIcon
              saved={props.saved}
              colorStateOne={colorStateOne}
              colorStateTwo={colorStateTwo}
            />
          </div>
        )}
      </div>
    )
  ) : (
    <div className="dock--container">
      {currentWindow.match("/week/") ||
      currentWindow.endsWith("/week/create") ? (
        <>
          <div
            id="start"
            className="jordandock node"
            data-tooltip="Start a New Week"
            onClick={() => {
              navigate("/week/create");
            }}
            onMouseDown={() => {
              setIsAnimatingStart(true);
            }}
            onMouseUp={() => {
              setIsAnimatingStart(false);
            }}
          >
            <CreateDayIconV2 />
          </div>

          <div className="dock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>
          <div
            className={
              props.namePresent()
                ? `jordandock node ${isAnimatingName ? "animate" : ""}`
                : `disableddock node`
            }
            data-tooltip={
              props.namePresent()
                ? "Me"
                : "Add your name to your year map to access it in the dock."
            }
            onClick={props.changeName}
          >
            <VitIcon />
          </div>

          <div className="dockNodeWrapper">
            <div className="nodeRow">
              <div
                className={valuesShowState}
                data-tooltip={
                  props.valuesOnePresent()
                    ? "Value #1"
                    : "Add your values to your year map to access them in the dock."
                }
                onClick={props.changeValuesOne}
              >
                <ValuesIcon />
              </div>
              <div
                className={valuesShowState}
                data-tooltip={
                  props.valuesTwoPresent()
                    ? "Value #2"
                    : "Add your values to your year map to access them in the dock."
                }
                onClick={props.changeValuesTwo}
              >
                <ValuesIcon />
              </div>
              <div
                className={valuesShowState}
                data-tooltip={
                  props.valuesThreePresent()
                    ? "Value #3"
                    : "Add your values to your year map to access them in the dock."
                }
                onClick={props.changeValuesThree}
              >
                <ValuesIcon />
              </div>
              <div
                className={valuesShowState}
                data-tooltip={
                  props.valuesFourPresent()
                    ? "Value #4"
                    : "Add your values to your year map to access them in the dock."
                }
                onClick={props.changeValuesFour}
              >
                <ValuesIcon />
              </div>
              <div
                className={valuesShowState}
                data-tooltip={
                  props.valuesFivePresent()
                    ? "Value #5"
                    : "Add your values to your year map to access them in the dock."
                }
                onClick={props.changeValuesFive}
              >
                <ValuesIcon />
              </div>
            </div>
            <div
              className={
                props.valuesOnePresent() ||
                props.valuesTwoPresent() ||
                props.valuesThreePresent() ||
                props.valuesFourPresent() ||
                props.valuesFivePresent()
                  ? `jordandock node ${isAnimatingValues ? "animate" : ""}`
                  : `disableddock node`
              }
              data-tooltip={
                props.valuesOnePresent() ||
                props.valuesTwoPresent() ||
                props.valuesThreePresent() ||
                props.valuesFourPresent() ||
                props.valuesFivePresent()
                  ? "My Values"
                  : "Add your values in your year map to access them in the dock."
              }
              onClick={valuesShow}
            >
              <ValuesIcon />
            </div>
          </div>

          <div className="dockNodeWrapper">
            <div className="nodeRow">
              <div
                className={visionShowState}
                data-tooltip={
                  props.visionOnePresent()
                    ? "Vision #1"
                    : "Add your vision to your year map to access them in the dock."
                }
                onClick={props.changeVisionOne}
              >
                <VisionIcon />
              </div>
              <div
                className={visionShowState}
                data-tooltip={
                  props.visionTwoPresent()
                    ? "Vision #2"
                    : "Add your vision to your year map to access them in the dock."
                }
                onClick={props.changeVisionTwo}
              >
                <VisionIcon />
              </div>
              <div
                className={visionShowState}
                data-tooltip={
                  props.visionThreePresent()
                    ? "Vision #3"
                    : "Add your vision to your year map to access them in the dock."
                }
                onClick={props.changeVisionThree}
              >
                <VisionIcon />
              </div>
              <div
                className={visionShowState}
                data-tooltip={
                  props.visionFourPresent()
                    ? "Vision #4"
                    : "Add your vision to your year map to access them in the dock."
                }
                onClick={props.changeVisionFour}
              >
                <VisionIcon />
              </div>
              <div
                className={visionShowState}
                data-tooltip={
                  props.visionFivePresent()
                    ? "Vision #5"
                    : "Add your vision to your year map to access them in the dock."
                }
                onClick={props.changeVisionFive}
              >
                <VisionIcon />
              </div>
            </div>

            <div
              className={
                props.visionOnePresent() ||
                props.visionTwoPresent() ||
                props.visionThreePresent() ||
                props.visionFourPresent() ||
                props.visionFivePresent()
                  ? `jordandockwide node ${isAnimatingVision ? "animate" : ""}`
                  : "disableddock node"
              }
              data-tooltip={
                props.visionOnePresent() ||
                props.visionTwoPresent() ||
                props.visionThreePresent() ||
                props.visionFourPresent() ||
                props.visionFivePresent()
                  ? "My Vision"
                  : "Add your vision to your year map to access it through the dock."
              }
              onClick={visionShow}
            >
              <VisionIcon />
            </div>
          </div>

          <div
            className={
              props.ikigaiPresent()
                ? `jordandock node ${isAnimatingIki ? "animate" : ""}`
                : `disableddock node`
            }
            data-tooltip={
              props.ikigaiPresent()
                ? "My Ikigai"
                : "Add your ikigai to your year map to access it in the dock"
            }
            onClick={props.changeIkigai}
          >
            <IkigaiIcon />
          </div>
          <div
            className={
              props.quotePresent()
                ? `jordandock node ${isAnimatingQuote ? "animate" : ""}`
                : `disableddock node`
            }
            data-tooltip={
              props.quotePresent()
                ? "My Quote"
                : "Add a quote to your year map to access it in the dock."
            }
            onClick={props.changeQuote}
          >
            <QuoteIconV2 />
          </div>

          {vw > 500 ? (
            <div
              className={`jordandockwide node ${
                isAnimatingEye ? "animate" : ""
              }`}
              data-tooltip="Hide Overlay"
              onMouseOver={() => {
                setVisionShowState((visionShowState) => "mini node inactive");
                setValuesShowState((valuesShowState) => "mini node inactive");
                props.toggleOverlay();
              }}
            >
              <HideOverlayIcon />
            </div>
          ) : (
            <></>
          )}
          {vw < 500 ? (
            <></>
          ) : props.weeksLogged() < 2 || !props.weeksLogged() ? (
            <div
              className={`jordandock node`}
              data-tooltip="Click for Tutorial"
              onClick={() => {
                navigate("/tutorial/week");
              }}
            >
              <QuestionIcon />
            </div>
          ) : (
            <></>
          )}
          <div className="dock separator">
            <FontAwesomeIcon
              id="separatorIcon"
              className="separator"
              icon={faGripLinesVertical}
            />
          </div>

          {currentWindow.endsWith("/week/create") ? (
            <motion.div
              id="start"
              className={`jordandock node_save  ${props.changeMade}`}
              data-tooltip="Save my week"
              onClick={props.saveWeek}
              onMouseOver={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseDown={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseOut={() => {
                setColorStateTwo("cls-2-invert");
              }}
              whileTap={{ scale: 0.9 }}
              whileHover={{
                scale: 0.95,
              }}
            >
              <SaveIcon
                saved={props.saved}
                indicate={props.changeMade}
                colorStateOne={colorStateOne}
                colorStateTwo={colorStateTwo}
              />
            </motion.div>
          ) : (
            <motion.div
              className="jordandock node_save"
              data-tooltip={props.saved ? "Week Updated" : "Click to Save"}
              onClick={props.updateWeek}
              onMouseOver={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseOut={() => {
                setColorStateTwo("cls-2-invert");
              }}
              whileTap={{ scale: 0.9 }}
              whileHover={{
                scale: 0.95,
              }}
            >
              <SaveIcon
                saved={props.saved}
                colorStateOne={colorStateOne}
                colorStateTwo={colorStateTwo}
              />
            </motion.div>
          )}
        </>
      ) : currentWindow.endsWith("/today") ? (
        <>
          <div
            className={`jordandock node_save`}
            onClick={props.tutorialChange}
          >
            <QuestionIcon />
          </div>
          <div
            id={
              props.submitDisabled === "disabled"
                ? "disabled"
                : props.noCreate === "disabled"
                  ? "disabled"
                  : ""
            }
            className={`jordandock node_save  ${props.changeMade}`}
            data-tooltip={
              props.submitDisabled === "disabled"
                ? "Diary date already used or possibly incorrect"
                : props.noCreate === "disabled"
                  ? "Click Create Day to Complete Previous Day First"
                  : "Click to Save"
            }
            onClick={
              props.submitDisabled !== "disabled" &&
              props.logDate >= 2023 &&
              props.submitDay
            }
            onMouseOver={() => {
              setColorStateTwo("cls-2");
            }}
            onMouseDown={() => {
              setColorStateTwo("cls-2");
            }}
            onMouseOut={() => {
              setColorStateTwo("cls-2-invert");
            }}
          >
            <SaveIcon
              saved={props.saved}
              indicate={props.changeMade}
              colorStateOne={colorStateOne}
              colorStateTwo={colorStateTwo}
            />
          </div>
          <div
            className={
              props.previousDayPresent()
                ? `jordandock node ${isAnimatingPrevious ? "animate" : ""}`
                : `disableddock node`
            }
            onMouseDown={() => {
              setIsAnimatingPrevious(true);
            }}
            onMouseUp={() => {
              setIsAnimatingPrevious(false);
            }}
            onClick={props.previousDay}
            data-tooltip={
              props.previousDayPresent()
                ? "Go to previous day"
                : "Add another day to your diary to activate this node"
            }
          >
            <PreviousDay />
          </div>
        </>
      ) : props.momentsError === true ? (
        <>
          <div
            id={!props.endScore ? "disabled" : ""}
            className={`jordandock node_save  ${props.changeMade}`}
            data-tooltip={
              props.saved
                ? props.endScore
                  ? `Start a New Day DEF`
                  : `Add Feeling Score DEF`
                : `Click To Save DEF`
            }
            onClick={props.updateDay}
            onMouseOver={() => {
              setColorStateTwo("cls-2");
            }}
            onMouseDown={() => {
              setColorStateTwo("cls-2");
            }}
            onMouseOut={() => {
              setColorStateTwo("cls-2-invert");
            }}
          >
            <a href={props.saved ? (props.endScore ? "/today" : "") : ""}>
              {props.saved ? (
                props.endScore ? (
                  <CreateDayIconV2 />
                ) : (
                  <FontAwesomeIcon
                    id={"saveIcon"}
                    className="icon"
                    style={!props.endScore && { opacity: "0.5" }}
                    icon={faFaceGrinWide}
                  />
                )
              ) : (
                <SaveIcon
                  saved={props.saved}
                  colorStateOne={colorStateOne}
                  colorStateTwo={colorStateTwo}
                />
              )}
            </a>
          </div>

          {props.saved ? (
            <div
              className={`jordandock node_save ${props.changeMade}`}
              // style={{ backgroundColor: "purple", padding: "4px" }}
              onClick={props.updateDay}
              onMouseOver={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseDown={() => {
                setColorStateTwo("cls-2");
              }}
              onMouseOut={() => {
                setColorStateTwo("cls-2-invert");
              }}
            >
              <SaveIcon
                saved={props.saved}
                colorStateOne={colorStateOne}
                colorStateTwo={colorStateTwo}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div
          className={`jordandock node_save  ${props.changeMade}`}
          data-tooltip="You have a moment without a score. Resolve before saving"
          // style={{ backgroundColor: "orange", padding: "4px" }}
          onMouseOver={() => {
            setColorStateTwo("cls-2");
          }}
          onMouseDown={() => {
            setColorStateTwo("cls-2");
          }}
          onMouseOut={() => {
            setColorStateTwo("cls-2-invert");
          }}
        >
          <SaveIcon
            saved={props.saved}
            colorStateOne={colorStateOne}
            colorStateTwo={colorStateTwo}
          />
        </div>
      )}
    </div>
  );
}
