// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, Button, Row, Col, Container, Form } from "react-bootstrap";

// External Packages
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faEraser,
  faSquareCaretLeft,
  faSquareCaretRight,
} from "@fortawesome/free-solid-svg-icons";

// Internal Components
import generateSecret from "../../../utils/generateSecret";
import { ErrorMessage } from "../../../components/Error/ErrorMessage";
import CentralHeader from "../../../components/Header/CentralHeader";
import TileLoading from "../../../components/Loading/TileLoading";
import TestFooter from "../../../components/Footer/TestFooter";

// User Actions
import { updateProfile } from "../../../actions/dayActions";
import { updateQuizAction, listQuizes } from "../../../actions/quizActions";

// CSS Styling
import "./quizfeeling.css";

export default function QuizFeelingScreen(history) {
  const [titleDiv, setTitleDiv] = useState(true);
  const [whatYou, setWhatYou] = useState(false);
  const [myLearnings, setMyLearnings] = useState(false);
  const [myLearnings1, setMyLearnings1] = useState(false);
  const [myLearnings2, setMyLearnings2] = useState(false);
  const [myLearnings3, setMyLearnings3] = useState(false);

  const [input, setInput] = useState(true);
  const [output, setOutput] = useState(false);

  const [wave, setWave] = useState(true);
  const [spiral, setSpiral] = useState(true);

  const [chapterOneScore, setChapterOneScore] = useState(8.3);
  const [chapterTwoScore, setChapterTwoScore] = useState(0);
  const [chapterThreeScore, setChapterThreeScore] = useState(0);
  const [chapterFourScore, setChapterFourScore] = useState(0);

  const [chapterOneScoreLive, setChapterOneScoreLive] = useState();
  const [chapterTwoScoreLive, setChapterTwoScoreLive] = useState();
  const [chapterThreeScoreLive, setChapterThreeScoreLive] = useState();
  const [chapterFourScoreLive, setChapterFourScoreLive] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const quizUpdate = useSelector((state) => state.quizUpdate); //
  const { success: successUpdate } = quizUpdate;

  const quizList = useSelector((state) => state.quizList);
  const { quizes } = quizList;

  useEffect(() => {
    dispatch(listQuizes());
  }, [dispatch, userInfo, history]);

  const showHideState = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const showHideGrid = {
    hide: {
      display: "none",
    },
    show: {
      display: "grid",
    },
  };

  const showHideFlex = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const showHideFlexCol = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
      flexDirection: "column",
    },
  };

  useEffect(() => {
    document.title = "Art of Looking";
  }, []);

  const [hasQuiz, setHasQuiz] = useState(false);
  const [linkId, setLinkId] = useState();

  useEffect(() => {
    if (Array.isArray(quizes)) {
      if (quizes.length === 0) {
        setHasQuiz((hasQuiz) => false);
      } else if (quizes.length === 1) {
        setLinkId((linkId) => quizes[0]._id);

        setChapterOneScoreLive(quizes[0].chapterOneScore);

        if (chapterOneScoreLive > chapterOneScore) {
          setChapterOneScore(chapterOneScoreLive);
        }

        setChapterTwoScoreLive(quizes[0].chapterTwoScore);

        if (chapterTwoScoreLive > chapterTwoScore) {
          setChapterTwoScore(chapterTwoScoreLive);
        }

        setChapterThreeScoreLive(quizes[0].chapterThreeScore);

        if (chapterThreeScoreLive > chapterThreeScore) {
          setChapterThreeScore(chapterThreeScoreLive);
        }

        setChapterFourScoreLive(quizes[0].chapterFourScore);

        if (chapterFourScoreLive > chapterFourScore) {
          setChapterFourScore(chapterFourScoreLive);
        }
      }
    } else {
      setHasQuiz((hasQuiz) => true);
    }
  });

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const updateHandler = (e) => {
    const key = generateSecret().toString();
    // e.preventDefault();

    dispatch(
      updateQuizAction(
        id,
        chapterOneScore,
        chapterTwoScore,
        chapterThreeScore,
        chapterFourScore
      )
    )
      .then((response) => {
        if (response) {
          console.log("all jiggy");
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (
      !chapterOneScore ||
      !chapterTwoScore ||
      !chapterThreeScore ||
      !chapterFourScore
    )
      return;

    // resetHandler();
  };

  const [pageLoading, setPageLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);
  const handleImageLoad = () => {
    setImageLoading(false);
  };

  const loadingTimeout = () => {
    setTimeout(() => {
      setPageLoading(false);
    }, 5000);
  };

  useEffect(() => {
    loadingTimeout();
  });

  return (
    <>
      <CentralHeader />
      <main style={{ display: "flex", justifyContent: "center" }}>
        {pageLoading && imageLoading ? (
          <TileLoading />
        ) : (
          <motion.div
            id="titleDivMain"
            // style={{ overflow: "hidden" }}
            animate={titleDiv ? "show" : "hide"}
            variants={showHideState}
            onClick={() => {
              setTitleDiv((titleDiv) => false);
              setWhatYou((whatYou) => true);
            }}
          >
            <motion.img
              className="title__image"
              src="https://cdn.sanity.io/images/ou6d2gec/production/e3ddf445d9914be10db3791eb66b75edf2dbecb4-5000x3250.png"
              width="100%"
              alt="your data cover"
              onLoad={handleImageLoad}
              animate={titleDiv ? "show" : "hide"}
              variants={showHideState}
              loading="lazy"
            />
          </motion.div>
        )}

        <motion.div
          animate={whatYou ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">Your Feeling Score</h4>
            </div>
            <br />
            <p>Time to get started!</p>
            <p>
              One of the key data points in your daily <strong>diary</strong> is
              the number you assign using the slider underneath the 'After the
              day described above, I felt...' heading. I call it the{" "}
              <strong>feeling score</strong>.
            </p>
            <p>
              <strong>
                Your feeling score is simply your own numerical assessment of
                how you felt on average across any particular day since your
                inputs earlier that morning.
              </strong>
            </p>

            {vw < 500 ? (
              <img
                className="feelingScoreToday"
                src="https://cdn.sanity.io/images/ou6d2gec/production/cd837cafe11e48ec5e7041fdb7ce402505cf68d7-5000x3250.png"
                width="100%"
                alt="feeling score overview"
              />
            ) : (
              <></>
            )}

            <p>
              Within your <strong>Insights</strong>, this score is shown over
              time by clicking on a banner that says{" "}
              <strong>How you felt</strong>. You can interact with the graph by
              hovering over the data points. This will show you your memories
              fro that day and what you wanted to leave behind. You can also
              click on the data points to visit that particular day in your
              diary.
            </p>
            {/* <p>
              Below the chart itself are three revealing pieces of context about
              your feeling score.
            </p>
            <strong style={{ fontSize: "1.5em" }}>Average:</strong>
            <span>
              {" "}
              The result of adding up all of your feeling scores, and dividing
              them by the total number of days you have logged. This type of
              average is otherwise known as the 'mean'.
            </span>
            <br />
            <br />
            <strong style={{ fontSize: "1.5em" }}>Variance:</strong>
            <span>
              {" "}
              This is how much your feeling score is likely to differ from your
              average. If this number is high, it suggests how you feel varies a
              lot from day to day. The smaller the number, the more consistent
              your feeling is.
            </span>
            <br />
            <br />
            <strong style={{ fontSize: "1.5em" }}>Recent:</strong>
            <span>
              {" "}
              This is the degree to which your average feeling score from the
              last 14 days is more or less than your overall average. If the
              recent score is above zero, this suggests you have felt better in
              the past two weeks than the average across your diary dataset. If
              it is below zero, it suggests you have not felt quite as good.
            </span> */}
            <hr className="dividingLine" />
            <p>
              I have learned so much about myself using NoWastedDays. I have
              also managed to turn these learnings into a real improvement in
              how I feel on average each day.
            </p>
            <p>
              Keep an eye on{" "}
              <a
                href="https://www.instagram.com/nowasteddays/"
                target="_blank"
                style={{ color: "#004a22" }}
              >
                @nowasteddays
              </a>{" "}
              on Instagram, or have a listen to '
              <a
                href="https://www.youtube.com/channel/UCMqVstA-okOwsuZK732k4zQ"
                target="_blank"
                style={{ color: "#004a22" }}
              >
                The NoWastedDays Podcast
              </a>
              ', for many of these learnings. Here and now, however, we are
              going to focus on the things that provide the biggest bang for
              your buck; the top thoughts regarding your insights and data that
              can have the biggest impact on your ability to get value from your
              own data.
            </p>
            <p>
              With no further ado, here are 3 things that stand out as helping
              enrich these early interactions most:
            </p>
            <span>1.</span>
            <strong style={{ fontSize: "1.5em" }}>
              The Most Important Insight of Them All.
            </strong>
            <br />
            <br />
            <span>2.</span>
            <strong style={{ fontSize: "1.5em" }}>The Almighty Average.</strong>
            <br />
            <br />
            <span>3.</span>
            <strong style={{ fontSize: "1.5em" }}>It's noisy in here!</strong>
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/cd837cafe11e48ec5e7041fdb7ce402505cf68d7-5000x3250.png"
              alt="feeling score overview"
              style={{ border: "2px solid rgba(0, 74, 34, 0.4)" }}
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setTitleDiv((titleDiv) => true);
                setWhatYou((whatYou) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setWhatYou((whatYou) => false);
                setMyLearnings((myLearnings) => false);
                setMyLearnings1((myLearnings1) => true);
                if (chapterOneScore > chapterOneScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterOneScore((chapterOneScore) => 16.6);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={myLearnings ? "show" : "hide"}
          variants={showHideGrid}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h3 className="quizHeaderWhite">3 Key Starting Points</h3>
            </div>
            <br />
            <br />
            <span>
              I have learned so much about myself using NWD. As a result, how I
              feel moment-by-moment each day has seen a stark improvement over
              time.
              <br />
              <br />
              This course couldn't possibly cram in all of these learnings.
              However, what it does do is share the learnings that will help the
              most in your early interactions with each part of the data.
            </span>
            <br />
            <br />
            <span>
              In relation to your feeling score, there are 3 things that stand
              out as helping enrich those early interactions most:
            </span>
            <br />
            <br />
            <span>1.</span>
            <strong className="emphasis">
              The Most Important Insight of Them All
            </strong>
            <br />
            <br />
            <span>2.</span>
            <strong className="emphasis">The Almighty Average ;)</strong>
            <br />
            <br />
            <span>3.</span>
            <strong className="emphasis">
              "Can someone turn the volume down!"
            </strong>
            <br />
            <br />
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/cd837cafe11e48ec5e7041fdb7ce402505cf68d7-5000x3250.png"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setMyLearnings((myLearnings) => false);
                setWhatYou((whatYou) => true);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setMyLearnings((myLearnings) => false);
                setMyLearnings1((myLearnings1) => true);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={myLearnings1 ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">
                {" "}
                The Most Important Insight of Them All.
              </h4>
            </div>
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/39aacdb6823278f0a0dab1e80d6903a11b395b30-5000x3250.png"
                width="90%"
              ></img>
            </div>
            <br />
            <p>
              We owe a lot to the ancient greeks, and I often wonder what on
              earth they would think of our global society today.
            </p>
            <p>
              One of their many legacies that survives today is that of the
              Greek tragic play. Whilst this form of entertainment won't be
              drawing in crowds like the latest chapter in the Marvel Cinematic
              Universe or the next Lin Manuel Miranda musical epic anytime soon,
              there are many poignant ideas Greek tragedies that relate directly
              to this very first thing that I think is so important. Of them
              all, for our needs right now, there are two that stand out ahead
              of the rest.
            </p>
            <span>1.</span>
            <strong>
              <a
                href="https://en.wikipedia.org/wiki/Peripeteia"
                target="_blank"
                style={{ color: "#004a22" }}
                rel="noreferrer"
              >
                Peripeteia:
              </a>{" "}
            </strong>
            <span>
              the sudden reversal of fortune or change in circumstances,
              especially in reference to fictional narrative.
            </span>
            <br />
            <br />
            <span>2.</span>
            <strong>
              <a
                href="https://en.wikipedia.org/wiki/Anagnorisis"
                target="_blank"
                style={{ color: "#004a22" }}
                rel="noreferrer"
              >
                Anagorisis:
              </a>{" "}
            </strong>
            <span>
              the point in a play / novel in which a principal character
              recognizes or discovers another character's true identity or the
              true nature of their own circumstances.
            </span>
            <br />
            <br />
            <p>
              In essence, these words relate to the feeling of coming face to
              face with a truth or, speaking more generally, undergoing the
              transition from ignorance to knowledge (which in any Greek tragic
              play rarely makes for a very happy time!).
            </p>
            <p>
              Whilst our lives will rarely present such stark revelations as
              Oedipus learning that he had murdered his father and married his
              mother, having our 'true identity' or something closer to the true
              nature of our actions played out before us can be quite
              uncomfortable and, for some, highly anxiety inducing or even
              outright triggering.
            </p>
            <p>
              The <strong>Art of Looking</strong> is written in an attempt to
              massively mitigate any triggering effects of seeing your feeling,
              moments and more played out in front of you. If you journey
              through each section, you will find the essential mental tools,
              and the benefit of my many mistakes, that can help you capably
              manage any arising discomfort and, most importantly, turn it into
              great personal insights from which you can feel better and do more
              of what you love.
            </p>
            <p>
              These intentions, however, do not take away from the fact that if
              you even suspect you are going to find it too difficult, too
              triggering - particularly to the point where you may regress in
              good feeling in the medium to long term -{" "}
              <strong>this is where I would recommend you stop.</strong>
            </p>
            <p>
              I would like NoWastedDays to help as many people as possible and a
              surefire way <strong>not</strong> to do that is to pretend that
              this whole format will work impeccably - without treacherous trial
              or tribulation - for anyone who uses it.
            </p>
            <p>
              I believe that data and technology can facilitate the far better,
              more proactive processing of our lives; helping anyone reach
              levels of joy, presence and growth that they would have perhaps
              struggled to reach in a lifetime without the new perspectives and
              processes data and technology has afforded.
            </p>
            <p>
              Despite the abundance of data and technology that underpins global
              civilisation today, how we process our experiences, bolster our
              brains and relate to our environments has been left to its
              persistent but pre-historic devices. If anything, it has been
              complicated with the rise of new media, fake news and generative
              A.I! This lack of progress and safety doesn't feel satisfactory
              for me, and I want to be part of making a positive change in that
              regard; show what can be accomplished if you empower people
              unconditionally with data and technology.
            </p>
            <p>
              This all being true, and as above, I want to state that stopping
              now is absolutely 100% not an omission of weakness on your part,
              or in any way a sign of your being unfit for anything in life.
              There are a million and one ways to find greater joy and growth or
              improve your self-awareness and assurance, and some phenomenal
              resources already exist online. I will link to as many of these as
              I can think of at the bottom of the page.
            </p>
            <p>
              I am working exceptionally hard to maximise NoWastedDays'
              effectiveness and suitability in a bid to help anyone experience
              the wild benefit I know I have gotten from it and hopefully more.
              But the reality is that if you are to use NoWastedDays and push to
              get even half the potential value from the platform, you are going
              to experience a bit of peripeteia and anagorisis (and hopefully
              quite a lot because it is really the more, the better). If your
              time on the platform is only telling you things you already know
              and taking you to mental places you've already been, we both have
              more work to do!
            </p>
            <p>
              Hoping off this journey now is nothing but a nice bit of
              self-awareness, and I hope you find tremendous value in the
              resources I have linked to and that you live astonishingly happy,
              healthy lives; that exists for you I'm sure and you deserve it!
            </p>
            <p>
              All that being said, if you are still here, and are perhaps even
              more excited for the road ahead than when you started reading,{" "}
              <strong>let's press on to point #2.</strong>
            </p>
          </div>

          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/39aacdb6823278f0a0dab1e80d6903a11b395b30-5000x3250.png"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setMyLearnings((myLearnings) => false);
                setMyLearnings1((myLearnings) => false);
                setWhatYou((whatYou) => true);
              }}
            />

            <a
              style={{ fontFamily: "monospace", color: "#004a22" }}
              href="/resources"
              target="_blank"
            >
              The Links I Mentioned
            </a>

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setMyLearnings1((myLearnings1) => false);
                setMyLearnings2((myLearnings2) => true);

                if (chapterOneScore > chapterOneScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterOneScore((chapterOneScore) => 24.9);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={myLearnings2 ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">The Almighty Average?</h4>
            </div>
            <br />
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/c3bdc6191faaecfbbeed054e9df5e8396decfe0b-5000x3250.png"
                width="90%"
              ></img>
            </div>
            <p>
              Ahh, the good old average. Easy to calculate and often remembered
              from school, averages of one kind or another slide into nearly
              every presentation of data that exists today. Whilst having a role
              to play, on the journey we are on, we need to understand it more
              than just as a humble metric. Its power and potential can be far
              more wide reaching than you may have ever realised, and it is not
              always positive!
            </p>
            <p>
              The first and most valuable way in which any average can be
              understood is as a <strong>signal.</strong>
            </p>
            <p>
              This thought will be the loudest, most consistent drumbeat
              throughout this little journey. Every part of every past day in
              your diary, every metric, every average, every shift in a graph
              that exists in your insights is a <strong>signal</strong>.
            </p>
            <p>
              <strong>
                A signal is an objective, unemotional nudge towards greater
                self-understanding.
              </strong>
            </p>
            <p>
              If you choose to do so, any signal can set in motion the following
              cycle: (Example thought process below)
            </p>
            <br />
            <strong>
              You spot a <strong>signal.</strong>
            </strong>
            <br />
            <p>"Oh, cool, I had my top feeling score that day."</p>
            <br />

            <strong>
              This <strong>signal</strong> sparks and helps you ask a better{" "}
              <strong>question.</strong>
            </strong>
            <br />
            <p>"I wonder why that day was so good?"</p>
            <br />

            <strong>
              The <strong>question</strong> helps inspire a more purposeful and
              specific <strong>exploration</strong> of your diary and data.
            </strong>
            <br />
            <p>
              "Looking through that day, it seems that the joy came from freeing
              myself to be creative without any pressure, and then spending time
              with friends."
            </p>
            <br />

            <strong>
              This <strong>exploration</strong> then either produces a new
              signal, and ever more specific and purposeful questioning, or an
              observation than can inspire new <strong>action</strong>.
            </strong>
            <br />
            <p>
              "I will look to make more time for pressure-less creativity; maybe
              a minimum of 1 day a month."
            </p>
            <br />

            <strong>
              These new <strong>actions</strong> then become new moments or
              memories in your diary and will, in time, become new{" "}
              <strong>signals</strong> of their own.
            </strong>
            <br />
            <p>
              "I think today is time for another one of those creative days!"
            </p>
            <br />

            <p>
              This process is magical as it a) helps you tackle anything in your
              diary or data with maximum objectivity and detachment, and b) it
              is an enjoyable, repeatable and, therefore, sustainable journey
              you can go on whenever you want to learn more about yourself.
              Whether your 'best' day or your 'worst', your most productivity
              moment or the least, they are all just <strong>signals</strong>.
              They are not value judgements. They are not a sign of your
              fallibility. Not a weakness, nor a strength; just a signal.
            </p>
            <p>
              To close, your average, like everything else in your diary and
              insights, is simply a signal.
            </p>
            <hr></hr>
            <p>
              The second important insight surrounding the feeling score average
              is that it is unique to you and cannot be compared to anyone
              elses.
            </p>
            <p>
              We all at a different points in life with different personalities,
              experiences and perspectives. As such, you might give an average
              day a 3.5 whereas someone else would give it a 2.5. You could be a
              having a equivalent experience with identical rise and fall of
              positive emotions, but your instinctive grading is different.
            </p>
            <p>
              My average, for example, started off hugging the 2.6-2.8 mark.
              Over time, my 'average' day, is more like a 3.8 - 4.2. As a
              result, in my case, I know that a 3.4 represents, if not a down
              day, at least one worth note and particular inquiry - and I can
              study my <strong>diary</strong> more clearly with that in mind. In
              contrast, that score could be very typical for someone else, and
              not hold the same intrigue. Similarly, I know I am quite a tough
              judge when it comes to giving a day a 5.0, whereas others might
              use that score more liberally.
            </p>
            <p>
              The rise in score I have seen over time is a byproduct of how I
              have learned things about myself and how I can make myself
              happier. But my scores and my journey only mean something to me
              and, whilst I can certainly talk to anyone about the steps I've
              taken to make that kind of transition, the numbers themselves have
              no comparative value.
            </p>
            <p>
              To round up, remember no one is better or worse, or even
              necessarily happier or sadder, than you simply because your scores
              are different. Your only objective is to use said scores to
              improve your self-understanding. I wouldn't even say your main
              objective was to get a rise in feeling. Your primary objective is
              to learn about yourself, and then see if you can use those
              revelations to make a happier day in the future.
            </p>

            <p>
              Overall, before we stride onto the next step, remember everything
              you see is a signal, that comparison is futile and to try and
              consolidate greater self-knowledge objective before thinking about
              how that turns into better feeling in the future.
            </p>
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/c3bdc6191faaecfbbeed054e9df5e8396decfe0b-5000x3250.png"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setMyLearnings2((myLearnings2) => false);
                setMyLearnings1((myLearnings1) => true);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setMyLearnings2((myLearnings2) => false);
                setMyLearnings3((myLearnings3) => true);

                if (chapterOneScore > chapterOneScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterOneScore((chapterOneScore) => 33.2);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={myLearnings3 ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">It's noisy in here!</h4>
            </div>
            <br />
            <p>
              As we have explored in the previous step, your data is unique to
              you and has a context that only you understand.
            </p>
            <p>
              There is a concept in data related to both the apparently almighty
              average from the last section and the idea of your specific
              context that kicks off this one. It is our next big insight, and
              the concept is called <strong>'Noise'</strong>.
            </p>
            <p>
              This is the idea that any data point, metric or average,
              particularly ones that are combinations of multiple different data
              points or involving different datasets, can hide or cloud
              understanding whilst trying to enhance it.
            </p>
            <p>To demonstrate the idea of noise, have a glance at the chart.</p>
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/2d11365ef3302237fdafcb0ca4f882f8ec991126-10001x6501.png"
                width="90%"
              ></img>
            </div>
            <p>
              The graph includes 3 lines that represent 3 people performing a
              series of tests which are scored between 0 and 10. Their average
              scores across a given school week are identical. They have all
              performed as well through the eyes of the average.
            </p>
            <p>
              But I'm sure you are all screaming at the screen saying,{" "}
              <strong>
                'No they haven't. Clearly they have not performed the same.'
              </strong>
              , and you are exactly right! It is clear from the looking at the
              visualisation that they don't all have the same relationship with
              the tests they are completing.
            </p>
            <p>
              Person Three never got above or below a 5 - demonstrating curious
              consistency - whereas Person One reached the heights of 10, but
              the lows of a 0. Their relationship with and knowledge of the
              tests they completed is clearly completely different, but the
              notion of the average disguises this. In data speak, you'd say
              that the average is a 'noisy' metric or is creating some noise
              which, as we have already touched on, means, in its attempt to
              tell us something by bringing data together, it has also hidden
              things from us.
            </p>
            <p>
              The question is what can we do about this? Obviously, seeing the
              graph is super helpful because the sense of difference is very
              evident visually. But, what if you are just looking at a table?
            </p>
            <p>
              In this case, you will want to calculate the{" "}
              <strong>variance</strong>.
            </p>
            <p>
              The variance is how much your data is likely to differ from your
              average. In the case of the test results, if this number is high,
              it suggests the score varies a lot from test to test. The smaller
              the number, the results are very similar from test-to-test.
            </p>
            <p>
              Now, lets look at the graph to the right with the added context of
              the variance:
            </p>
            <strong>Person One: Average = 5 | Variance = 2.5</strong>
            <br />
            <br />
            <strong>Person Two: Average = 5 | Variance = 1</strong>
            <br />
            <br />
            <strong>Person Three: Average = 5 | Variance = 0</strong>
            <br />
            <br />
            <p>
              The variance is cutting through this noise and gives us greater
              clarity. Referring back to our previous signal feedback cycle, a
              teacher seeing the variance could have gone through the following
              series of thoughts:
            </p>
            <p>
              <strong>Spot a Signal:</strong> "Ooh, interesting. Person One's
              variance is high."
            </p>
            <p>
              <strong>Ask a better question:</strong> "I wonder which tests
              contained their highest and lowest scores."
            </p>
            <p>
              <strong>Explore more specifically:</strong> "Ah, it was Tuesday
              and Thursday. I didn't know they were struggling with those!"
            </p>
            <p>
              <strong>Find an action for the future:</strong> "If I give him
              more support on Tuesday and Thursdays lessons, they can boost
              their average."
            </p>
            <p>
              When presented alone, any metric creates a not insignificant
              amount of noise. Yet, when presented together, we can massively
              reduce the noise and spot far stronger and salient signals
              instead.
            </p>
            <p>
              Following on from this, is another idea. It is the fact we can
              obsess about improving our average all we want, but if we are't
              reducing variance at the same time, then we may not be helping
              ourselves as much as we would hope.
            </p>
            <p>
              Overall, remember to silence the noise by looking at multiple
              relevant metrics and think about how you can improve not just your
              feeling, but decrease variance at the same time.
            </p>
          </div>
          <div className="rightCol silvs" style={{ overflow: "hidden" }}>
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/2d11365ef3302237fdafcb0ca4f882f8ec991126-10001x6501.png"
              style={{ transform: "scale(1.2)" }}
              alt="variance graph"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setMyLearnings2((myLearnings2) => true);
                setMyLearnings3((myLearnings3) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                if (chapterOneScore > chapterOneScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterOneScore((chapterOneScore) => 41.5);
                  navigate(`/insight/quiz/${linkId}/moments`);
                }, 1000);
              }}
            />
          </div>
        </motion.div>
      </main>
      <TestFooter />
    </>
  );
}
