import React, { useState, useEffect } from 'react';
import './confetti.css'; // CSS file for styling the animation

const Confetti = () => {

  return (
<div className="confetti">
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
    <div className="confetti-piece"></div>
</div>
  );
};

export default Confetti;
