// React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//External Packages

// Internal Components
import UserDemographic from "../../components/Register/UserDemographics";
import TestFooter from "../../components/Footer/TestFooter";
import CentralHeader from "../../components/Header/CentralHeader";
import CentralHeaderLP from "../../components/Header/CentralHeaderLP";

// User Actions

// Images

// Styling
import "./registerscreen.css";

export default function RegisterDemographicScreen() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <>
      {userInfo ? <CentralHeader /> : <CentralHeaderLP />}
      <section id="registerScreen">
        <UserDemographic />
      </section>
      <TestFooter />
    </>
  );
}
