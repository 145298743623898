import React, { useRef, useEffect } from "react";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import "./video.css";

const VideoComponent = (props) => {
  const videoRef = useRef(null);

  useEffect(() => {
    // Ensure the video element is not null
    if (videoRef.current) {
      // Set attributes for autoplay and loop
      videoRef.current.setAttribute("autoplay", "autoplay");
      videoRef.current.setAttribute("loop", "loop");

      // Hide controls
      videoRef.current.controls = false;
    }
  }, []);

  const introState = {
    hidden: {
      opacity: 0,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeIn",
      },
    },
    open: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: 0,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  return (
    <>
      <motion.video
        className="video__component"
        ref={videoRef}
        height="auto"
        width="100%"
        muted
        variants={introState}
        initial="hidden"
        animate={props.state >= props.num ? "open" : "closed"}
        poster={props.poster}
      >
        <source src={props.path} type="video/mp4" />
        Your browser does not support the video tag.
      </motion.video>
    </>
  );
};

export default VideoComponent;
