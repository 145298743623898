//React
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

//Internal Components
import Login from "../../components/Login/Login";
import MouseTooltip from "../../components/MouseTooltip/MouseTooltip";
import TestFooter from "../../components/Footer/TestFooter";
import CentralHeaderLP from "../../components/Header/CentralHeaderLP";

//CSS
import "./loginscreen.css";

export default function ViewLoginScreen() {
  const [paycPicker, setPaycPicker] = useState("");

  return (
    <>
      <CentralHeaderLP />
      <main id="registerScreen">
        <Login />
      </main>
      <TestFooter />
    </>
  );
}
