export const hardData = [
    {
      _id: 1, 
      logDate: '2023-02-01',
      startScore: 4.1,
      thankYou: 'the School of Life; so wise and helpful',
      selfLess: 'just playing with situations and breathing.',
      mindBody: 'of my funny shoulder and somewhat sound mind',
      momentOne: 'Wake up at 06:00 > Meditate and Stretch',
      momentOneScore: 4.5,
      momentTwo: 'Be present and organised at work; clear outstanding briefs',
      momentTwoScore: 7.6,
      momentThree: 'Enjoy football with the lads',
      momentThreeScore: 6.7,
      momentFour: 'Read before bed for 40 minutes',
      momentFourScore: 1,
      momentFive: '',
      momentFiveScore: null,
      rememberToday: 'Nice day; precious time with the lads and some good work.',
      leaveBehind: 'Not committing to my plan. If you are going to write it down, do it.',
      endScore: 3.8,
    },
    {
      _id: 2, 
      logDate: '2023-02-02',
      startScore: 3.9,
      thankYou: 'Fika - for being open and friendly to people',
      selfLess: 'not letting tiredness get to me; just enjoy the day',
      mindBody: 'it is here; relatively vital, relatively pain free',
      momentOne: 'Wake up at 06:00 > Meditate and Stretch',
      momentOneScore: 8.5,
      momentTwo: 'Complete focused, organised day',
      momentTwoScore: 7.1,
      momentThree: 'Complete next eLearning module at home',
      momentThreeScore: 0,
      momentFour: 'Workout: Chest & Triceps',
      momentFourScore: 5,
      momentFive: '',
      momentFiveScore: null,
      rememberToday: 'Great start to the day, being up early and dialling in. Strong work day; nice lunch with the team.',
      leaveBehind: 'Getting frustrated by not being able to achieve the plan for the day; things happen. Stay present and do your best.',
      endScore: 4,
    },
    {
      _id: 3, 
      logDate: '2023-02-03',
      startScore: 3.8,
      thankYou: 'Mum - for giving me the confidence that comes with knowing you can make your mum happy, and for the compassion and emotion.',
      selfLess: 'simply enjoying things',
      mindBody: 'I managed to cycle to work',
      momentOne: 'Wake up at 06:00 > Meditate and Stretch',
      momentOneScore: 10,
      momentTwo: 'Complete focused, organised day',
      momentTwoScore: 8,
      momentThree: 'Be present during team drinks',
      momentThreeScore: 9,
      momentFour: '',
      momentFourScore: null,
      momentFive: '',
      momentFiveScore: null,
      rememberToday: 'Great start to the day; more days should be like that. Awesome to connect with the team and relax at the end of a tough week.',
      leaveBehind: 'Dont try and be a robot; set an intention, make a simple plan and be present ',
      endScore: 4.4,
    },
    {
      _id: 4, 
      logDate: '2023-02-04',
      startScore: 3.9,
      thankYou: 'the positivity of anyone; just so necessary!',
      selfLess: 'centering my experience in the present moment',
      mindBody: 'it can wobble its head along to old soul music',
      momentOne: 'Morning workout in the park',
      momentOneScore: 8.5,
      momentTwo: 'Walk to the nature reserve with a coffee',
      momentTwoScore: 10,
      momentThree: 'Life admin: Get the house and things sorted for next week',
      momentThreeScore: 9,
      momentFour: 'Catch up with Jenny, Henry and Dave',
      momentFourScore: 10,
      momentFive: '',
      momentFiveScore: null,
      rememberToday: 'Such a lovely day; workout, nature, getting life together and connecting with great friends <3',
      leaveBehind: 'One thing at a time; control and enjoy what is in front of you.',
      endScore: 4.8,
    },
    {
      _id: 5, 
      logDate: '2023-02-05',
      startScore: 4,
      thankYou: 'pret for providing proper kitchen towel',
      selfLess: 'breathing deeply',
      mindBody: 'it is here with me',
      momentOne: 'Be present and make no plans',
      momentOneScore: 10,
      momentTwo: '',
      momentTwoScore: null,
      momentThree: '',
      momentThreeScore: null,
      momentFour: '',
      momentFourScore: null,
      momentFive: '',
      momentFiveScore: null,
      rememberToday: 'Having no plan and just focusing on breath and presence made for a beautiful day. Felt great, did more than I thought and felt super creative.',
      leaveBehind: 'Feeling rushed or behind the curve; one good decision at a time, on your own journey',
      endScore: 5,
    },
    {
      _id: 6, 
      logDate: '2023-02-06',
      startScore: 4,
      thankYou: 'Jacy from AloMoves; for making me laugh!',
      selfLess: 'being here now',
      mindBody: 'it keeps me pretty upright most days',
      momentOne: 'Wake up at 06:00 > Stretch & Meditate',
      momentOneScore: 3.1,
      momentTwo: 'Full complete, organised working day',
      momentTwoScore: 7.5,
      momentThree: 'Be present at football; have fun and compete',
      momentThreeScore: 8,
      momentFour: 'Read before bed for 40 minutes',
      momentFourScore: 0,
      momentFive: '',
      momentFiveScore: null,
      rememberToday: 'Overslept which meant I missed my good start. Strong work day and football was really fun.',
      leaveBehind: 'Why doom scroll before bed? It does not help your brain',
      endScore: 3.6,
    },
    {
      _id: 7, 
      logDate: '2023-02-07',
      startScore: 4.1,
      thankYou: 'my friends - current and lapsed <3',
      selfLess: 'coming back to the present moment',
      mindBody: 'I love them',
      momentOne: 'Wake up at 06:00 > Stretch & Meditate',
      momentOneScore: 8,
      momentTwo: 'Full complete, organised working day',
      momentTwoScore: 9,
      momentThree: 'Complete online learning course',
      momentThreeScore: 3,
      momentFour: '',
      momentFourScore: null,
      momentFive: '',
      momentFiveScore: null,
      rememberToday: 'Nice day; lovely chat over lunch with jen; great walk back home in the dying light.',
      leaveBehind: 'Feeling frustrated at things still not being finished; just enjoy it, one bit of progress and growth at a time.',
      endScore: 3.7,
    },
    {
      _id: 8, 
      logDate: '2023-02-08',
      startScore: 4.2,
      thankYou: 'my right eye; putting in an absolute shift everyday!',
      selfLess: 'focusing on presence',
      mindBody: 'it is here man; bones, flesh, plasma and all!',
      momentOne: 'Workout: Legs',
      momentOneScore: 1,
      momentTwo: 'Work before work: Complete online learning course',
      momentTwoScore: 10,
      momentThree: 'Complete day; be present during big meeting',
      momentThreeScore: 9,
      momentFour: 'Stretch & Recover after work',
      momentFourScore: 9,
      momentFive: '',
      momentFiveScore: null,
      rememberToday: 'Absolute whiff on legs first thing; really strong, fun day otherwise; great meeting',
      leaveBehind: 'Being impatient waiting for coffee',
      endScore: 4.3,
    },
    {
      _id: 9, 
      logDate: '2023-02-09',
      startScore: 3.5,
      thankYou: 'Nico Rosberg - putting together some good conversations from which I can learn',
      selfLess: 'focusing on what is important to me',
      mindBody: 'it is here now; my forearms and elbows are feeling the tables',
      momentOne: 'Wake up at 06:00 > Meditate and Stretch',
      momentOneScore: 3,
      momentTwo: 'Be present and organised at work; clear outstanding briefs',
      momentTwoScore: 3,
      momentThree: 'Enjoy football with the lads',
      momentThreeScore: 2,
      momentFour: 'Read before bed for 40 minutes',
      momentFourScore: 1,
      momentFive: '',
      momentFiveScore: null,
      rememberToday: '',
      leaveBehind: 'Got rattled by my phone early doors then struggled to lock in all day.',
      endScore: 3.2,
    },
    {
      _id: 10, 
      logDate: '2023-02-10',
      startScore: 3.8,
      thankYou: 'Paul - straight up G',
      selfLess: 'enjoying each thing in the present moment, on its own right',
      mindBody: 'it knows a boy named Sue, Monarch of the Glen and dev',
      momentOne: 'Workout: Legs',
      momentOneScore: 1,
      momentTwo: 'Work before work: Complete online learning course',
      momentTwoScore: 10,
      momentThree: 'Complete day; be present during big meeting',
      momentThreeScore: 9,
      momentFour: 'Stretch & Recover after work',
      momentFourScore: 9,
      momentFive: '',
      momentFiveScore: null,
      rememberToday: '',
      leaveBehind: 'Anything that detracts from presence.',
      endScore: 3.6,
    },
  ];