// React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { listDays } from "../../actions/dayActions";
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";

// External Packages
import CryptoJS from "crypto-js";

// Internal Components & Utilities
import Confetti from "../../components/Animations/Confetti";
import quoteObj from "../../components/Quotes/Quotes";
import generateSecret from "../../utils/generateSecret";
import { MemoryIcon, QuoteIconV2 } from "../../components/Icons/Icon";

// Styling
import "./pageloading.css";

const TileLoading = (props) => {
  const dispatch = useDispatch();
  const divRef = useRef();

  const [tileTextState, setTileTextState] = useState("");
  const [quoteAuthor, setQuoteAuthor] = useState("");
  const [coin, setCoin] = useState("");
  const [quote, setQuote] = useState(0);
  const [memory, setMemory] = useState();
  const [celebration, setCelebration] = useState(false);
  const [quoteOrMemory, setQuoteOrMemory] = useState("");

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const weekList = useSelector((state) => state.weekList);
  const { weeks } = weekList;

  const dayList = useSelector((state) => state.dayList);
  const { loading, error, days } = dayList;

  useEffect(() => {
    dispatch(listYears());
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(listWeeks());
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo]);

  const currentWindow = window.location.href;

  const coinFlip = () => {
    return Math.random() < 0.5
      ? setCoin((coin) => "heads")
      : setCoin((coin) => "tails");
  };

  const quoteSelector = () => {
    setQuote((quote) => Math.floor(Math.random() * quoteObj.length));
  };

  const memorySelector = () => {
    const key = generateSecret().toString();
    let memArrayFinal = [];
    let memArray = days && days.map((day) => day.rememberToday);

    // if (memArray[0] ==  ) {
    //   setMemory(memArray[0][0]);
    // }

    if (days && memArray) {
      for (let i = 0; i < memArray.length; i++) {
        if (memArray[i].includes(" ")) {
          memArrayFinal.push(memArray[i]);
        } else {
          memArrayFinal.push(
            CryptoJS.AES.decrypt(memArray[i], key).toString(CryptoJS.enc.Utf8)
          );
        }
      }
    }

    if (memArrayFinal && memArrayFinal.length > 0) {
      let randomIndex = Math.floor(Math.random() * memArrayFinal.length);

      if (
        memArrayFinal[randomIndex] === [] ||
        memArrayFinal[randomIndex] === "" ||
        memArrayFinal[randomIndex] === " "
      ) {
        setCoin("tails");
      } else {
        setMemory(memArrayFinal[randomIndex]);
      }
    }
  };

  const dayChecker = () => {
    if (days) {
      let totalDays = days.length;

      if (totalDays === 0 && (coin === "heads" || coin === "tails")) {
        setTileTextState(
          (tileTextState) => `Welcome to your NoWastedDays Journal!`
        );
      } else if (
        totalDays === 1 &&
        (coin === "heads" || coin === "tails") &&
        currentWindow.match("/today/")
      ) {
        setTileTextState(
          (tileTextState) =>
            `You've started you're first day! Well Done! Have you heard of the #NWDsChallenge? Complete 10 days and share on social to get £10!.`
        );
        setCelebration((celebration) => true);
        setTimeout(() => {
          setCelebration((celebration) => false);
        }, 4000);
      } else if (totalDays === 1 && (coin === "heads" || coin === "tails")) {
        setTileTextState(
          (tileTextState) =>
            `Start to build the habit by logging a day tomorrow. `
        );
      } else if (totalDays >= 2 && totalDays <= 6 && coin === "heads") {
        setTileTextState(
          (tileTextState) =>
            `Complete ${7 - totalDays} more days to activate your insights.`
        );
        setQuoteAuthor((quoteAuthor) => "");
      } else if (totalDays >= 2 && totalDays <= 6 && coin === "tails") {
        setTileTextState(
          (tileTextState) =>
            `Only ${
              7 - totalDays
            } more diary days needed activate your first insights.`
        );
        setQuoteAuthor((quoteAuthor) => "");
      } else if (totalDays === 7) {
        setTileTextState(
          (tileTextState) => `Your insights are live! Go check them out.`
        );
        setQuoteAuthor((quoteAuthor) => "");
        setCelebration((celebration) => true);
        setTimeout(() => {
          setCelebration((celebration) => false);
        }, 4000);
      } else if (totalDays >= 7 && totalDays < 14) {
        if (coin === "heads") {
          setTileTextState((tileTextState) => memory);
          setQuoteAuthor((quoteAuthor) => `Memory from the Past`);
          setQuoteOrMemory((quoteOrMemory) => "memory");
        } else {
          setTileTextState((tileTextState) => `"${quoteObj[quote].quote}"`);
          setQuoteAuthor((quoteAuthor) => `${quoteObj[quote].author}`);
          setQuoteOrMemory((quoteOrMemory) => "quote");
        }
      } else if (totalDays === 14) {
        setTileTextState(
          (tileTextState) =>
            `You've unlocked another week of insight data. Check out your graphs`
        );
        setQuoteAuthor((quoteAuthor) => "");
        setCelebration((celebration) => true);
        setTimeout(() => {
          setCelebration((celebration) => false);
        }, 4000);
      } else if (totalDays > 14 && totalDays % 7 === 0) {
        setTileTextState(
          (tileTextState) =>
            `You've unlocked another week of insight data. Check out your graphs`
        );
        setQuoteAuthor((quoteAuthor) => "");
        setCelebration((celebration) => true);
        setTimeout(() => {
          setCelebration((celebration) => false);
        }, 4000);
      } else if (totalDays > 14 && coin === "heads") {
        setTileTextState((tileTextState) => memory);
        setQuoteAuthor((quoteAuthor) => `Memory from the Past`);
        setQuoteOrMemory((quoteOrMemory) => "memory");
      } else if (totalDays > 14 && coin === "tails") {
        setTileTextState((tileTextState) => `"${quoteObj[quote].quote}"`);
        setQuoteAuthor((quoteAuthor) => `${quoteObj[quote].author}`);
        setQuoteOrMemory((quoteOrMemory) => "quote");
      }
    }
  };

  useEffect(() => {
    dayChecker();
  });

  useEffect(() => {
    const div = divRef.current;
    const size = Math.min(div.offsetWidth, div.offsetHeight);
    const borderRadius = `${size / 2}px`;
    div.style.borderRadius = borderRadius;
  }, []);

  useEffect(() => {
    coinFlip();
    quoteSelector();
    memorySelector();
  }, [days]);

  return (
    <div className="tileLoading" ref={divRef}>
      {celebration ? <Confetti /> : <></>}

      <div>
        <h5 style={{ color: "#e2dfcf", fontWeight: 600 }}>
          <strong>{tileTextState}</strong>
        </h5>
        <p style={{ color: "#e2dfcf", fontWeight: 200 }}>{quoteAuthor}</p>
      </div>
      {/* <div className="newIcon loading">
        {quoteOrMemory === "quote" ? <QuoteIconV2 /> : <></>}
        {quoteOrMemory === "memory" ? <MemoryIcon /> : <></>}
      </div> */}
    </div>
  );
};

export default TileLoading;
