//React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLinesVertical } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";

//Internal Components
import {
  CreateDayIcon,
  CreateDayIconV2,
  ValuesIcon,
  VitIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  SaveIcon,
  TickIcon,
  PreviousDay,
} from "../../components/Icons/Icon";

//CSS Style
import "./dock.css";

export default function FullDock(props) {
  const navigate = useNavigate();
  const [yourName, setYourName] = useState();
  const [valuesShowState, setValuesShowState] = useState("mini node inactive");
  const [visionShowState, setVisionShowState] = useState("mini node inactive");

  const [objShowStateOne, setObjShowStateOne] = useState(
    "objectiveButton inactive"
  );
  const [objShowStateTwo, setObjShowStateTwo] = useState(
    "objectiveButton inactive"
  );
  const [objShowStateThree, setObjShowStateThree] = useState(
    "objectiveButton inactive"
  );
  const [objShowStateFour, setObjShowStateFour] = useState(
    "objectiveButton inactive"
  );
  const [objShowStateFive, setObjShowStateFive] = useState(
    "objectiveButton inactive"
  );
  const [objShowStateSix, setObjShowStateSix] = useState(
    "objectiveButton inactive"
  );
  const [objShowStateSeven, setObjShowStateSeven] = useState(
    "objectiveButton inactive"
  );
  const [objShowStateEight, setObjShowStateEight] = useState(
    "objectiveButton inactive"
  );
  const [objShowStateNine, setObjShowStateNine] = useState(
    "objectiveButton inactive"
  );
  const [objShowStateTen, setObjShowStateTen] = useState(
    "objectiveButton inactive"
  );

  const valuesShow = () => {
    if (valuesShowState === "mini node inactive") {
      setValuesShowState((valuesShowState) => "mini node");
      setVisionShowState((visionShowState) => "mini node inactive");
    } else {
      setValuesShowState((valuesShowState) => "mini node inactive");
    }
  };

  const visionShow = () => {
    if (visionShowState === "mini node inactive") {
      setVisionShowState((visionShowState) => "mini node");
      setValuesShowState((valuesShowState) => "mini node inactive");
    } else {
      setVisionShowState((visionShowState) => "mini node inactive");
    }
  };

  const objectiveShowOne = () => {
    if (objShowStateOne === "objectiveButton inactive") {
      setObjShowStateOne((objShowStateOne) => "objectiveButton");
      setObjShowStateTwo((objShowStateTwo) => "objectiveButton inactive");
      setObjShowStateThree((objShowStateThree) => "objectiveButton inactive");
      setObjShowStateFour((objShowStateFour) => "objectiveButton inactive");
      setObjShowStateFive((objShowStateFive) => "objectiveButton inactive");
      setObjShowStateSix((objShowStateSix) => "objectiveButton inactive");
      setObjShowStateSeven((objShowStateSeven) => "objectiveButton inactive");
      setObjShowStateEight((objShowStateEight) => "objectiveButton inactive");
      setObjShowStateNine((objShowStateNine) => "objectiveButton inactive");
      setObjShowStateTen((objShowStateTen) => "objectiveButton inactive");
    } else {
      setObjShowStateOne((objShowStateOne) => "objectiveButton inactive");
    }
  };

  const objectiveShowTwo = () => {
    if (objShowStateTwo === "objectiveButton inactive") {
      setObjShowStateOne((objShowStateOne) => "objectiveButton inactive");
      setObjShowStateTwo((objShowStateTwo) => "objectiveButton");
      setObjShowStateThree((objShowStateThree) => "objectiveButton inactive");
      setObjShowStateFour((objShowStateFour) => "objectiveButton inactive");
      setObjShowStateFive((objShowStateFive) => "objectiveButton inactive");
      setObjShowStateSix((objShowStateSix) => "objectiveButton inactive");
      setObjShowStateSeven((objShowStateSeven) => "objectiveButton inactive");
      setObjShowStateEight((objShowStateEight) => "objectiveButton inactive");
      setObjShowStateNine((objShowStateNine) => "objectiveButton inactive");
      setObjShowStateTen((objShowStateTen) => "objectiveButton inactive");
    } else {
      setObjShowStateTwo((objShowStateTwo) => "objectiveButton inactive");
    }
  };

  const objectiveShowThree = () => {
    if (objShowStateThree === "objectiveButton inactive") {
      setObjShowStateOne((objShowStateOne) => "objectiveButton inactive");
      setObjShowStateTwo((objShowStateTwo) => "objectiveButton inactive");
      setObjShowStateThree((objShowStateThree) => "objectiveButton");
      setObjShowStateFour((objShowStateFour) => "objectiveButton inactive");
      setObjShowStateFive((objShowStateFive) => "objectiveButton inactive");
      setObjShowStateSix((objShowStateSix) => "objectiveButton inactive");
      setObjShowStateSeven((objShowStateSeven) => "objectiveButton inactive");
      setObjShowStateEight((objShowStateEight) => "objectiveButton inactive");
      setObjShowStateNine((objShowStateNine) => "objectiveButton inactive");
      setObjShowStateTen((objShowStateTen) => "objectiveButton inactive");
    } else {
      setObjShowStateThree((objShowStateThree) => "objectiveButton inactive");
    }
  };

  const objectiveShowFour = () => {
    if (objShowStateFour === "objectiveButton inactive") {
      setObjShowStateOne((objShowStateOne) => "objectiveButton inactive");
      setObjShowStateTwo((objShowStateTwo) => "objectiveButton inactive");
      setObjShowStateThree((objShowStateThree) => "objectiveButton inactive");
      setObjShowStateFour((objShowStateFour) => "objectiveButton");
      setObjShowStateFive((objShowStateFive) => "objectiveButton inactive");
      setObjShowStateSix((objShowStateSix) => "objectiveButton inactive");
      setObjShowStateSeven((objShowStateSeven) => "objectiveButton inactive");
      setObjShowStateEight((objShowStateEight) => "objectiveButton inactive");
      setObjShowStateNine((objShowStateNine) => "objectiveButton inactive");
      setObjShowStateTen((objShowStateTen) => "objectiveButton inactive");
    } else {
      setObjShowStateFour((objShowStateFour) => "objectiveButton inactive");
    }
  };

  const objectiveShowFive = () => {
    if (objShowStateFive === "objectiveButton inactive") {
      setObjShowStateOne((objShowStateOne) => "objectiveButton inactive");
      setObjShowStateTwo((objShowStateTwo) => "objectiveButton inactive");
      setObjShowStateThree((objShowStateThree) => "objectiveButton inactive");
      setObjShowStateFour((objShowStateFour) => "objectiveButton inactive");
      setObjShowStateFive((objShowStateFive) => "objectiveButton");
      setObjShowStateSix((objShowStateSix) => "objectiveButton inactive");
      setObjShowStateSeven((objShowStateSeven) => "objectiveButton inactive");
      setObjShowStateEight((objShowStateEight) => "objectiveButton inactive");
      setObjShowStateNine((objShowStateNine) => "objectiveButton inactive");
      setObjShowStateTen((objShowStateTen) => "objectiveButton inactive");
    } else {
      setObjShowStateFive((objShowStateFive) => "objectiveButton inactive");
    }
  };

  const objectiveShowSix = () => {
    if (objShowStateSix === "objectiveButton inactive") {
      setObjShowStateOne((objShowStateOne) => "objectiveButton inactive");
      setObjShowStateTwo((objShowStateTwo) => "objectiveButton inactive");
      setObjShowStateThree((objShowStateThree) => "objectiveButton inactive");
      setObjShowStateFour((objShowStateFour) => "objectiveButton inactive");
      setObjShowStateFive((objShowStateFive) => "objectiveButton inactive");
      setObjShowStateSix((objShowStateSix) => "objectiveButton");
      setObjShowStateSeven((objShowStateSeven) => "objectiveButton inactive");
      setObjShowStateEight((objShowStateEight) => "objectiveButton inactive");
      setObjShowStateNine((objShowStateNine) => "objectiveButton inactive");
      setObjShowStateTen((objShowStateTen) => "objectiveButton inactive");
    } else {
      setObjShowStateSix((objShowStateSix) => "objectiveButton inactive");
    }
  };

  const objectiveShowSeven = () => {
    if (objShowStateSeven === "objectiveButton inactive") {
      setObjShowStateOne((objShowStateOne) => "objectiveButton inactive");
      setObjShowStateTwo((objShowStateTwo) => "objectiveButton inactive");
      setObjShowStateThree((objShowStateThree) => "objectiveButton inactive");
      setObjShowStateFour((objShowStateFour) => "objectiveButton inactive");
      setObjShowStateFive((objShowStateFive) => "objectiveButton inactive");
      setObjShowStateSix((objShowStateSix) => "objectiveButton inactive");
      setObjShowStateSeven((objShowStateSeven) => "objectiveButton");
      setObjShowStateEight((objShowStateEight) => "objectiveButton inactive");
      setObjShowStateNine((objShowStateNine) => "objectiveButton inactive");
      setObjShowStateTen((objShowStateTen) => "objectiveButton inactive");
    } else {
      setObjShowStateSeven((objShowStateSeven) => "objectiveButton inactive");
    }
  };

  const objectiveShowEight = () => {
    if (objShowStateEight === "objectiveButton inactive") {
      setObjShowStateOne((objShowStateOne) => "objectiveButton inactive");
      setObjShowStateTwo((objShowStateTwo) => "objectiveButton inactive");
      setObjShowStateThree((objShowStateThree) => "objectiveButton inactive");
      setObjShowStateFour((objShowStateFour) => "objectiveButton inactive");
      setObjShowStateFive((objShowStateFive) => "objectiveButton inactive");
      setObjShowStateSix((objShowStateSix) => "objectiveButton inactive");
      setObjShowStateSeven((objShowStateSeven) => "objectiveButton inactive");
      setObjShowStateEight((objShowStateEight) => "objectiveButton");
      setObjShowStateNine((objShowStateNine) => "objectiveButton inactive");
      setObjShowStateTen((objShowStateTen) => "objectiveButton inactive");
    } else {
      setObjShowStateEight((objShowStateEight) => "objectiveButton inactive");
    }
  };

  const objectiveShowNine = () => {
    if (objShowStateNine === "objectiveButton inactive") {
      setObjShowStateOne((objShowStateOne) => "objectiveButton inactive");
      setObjShowStateTwo((objShowStateTwo) => "objectiveButton inactive");
      setObjShowStateThree((objShowStateThree) => "objectiveButton inactive");
      setObjShowStateFour((objShowStateFour) => "objectiveButton inactive");
      setObjShowStateFive((objShowStateFive) => "objectiveButton inactive");
      setObjShowStateSix((objShowStateSix) => "objectiveButton inactive");
      setObjShowStateSeven((objShowStateSeven) => "objectiveButton inactive");
      setObjShowStateEight((objShowStateEight) => "objectiveButton inactive");
      setObjShowStateNine((objShowStateNine) => "objectiveButton");
      setObjShowStateTen((objShowStateTen) => "objectiveButton inactive");
    } else {
      setObjShowStateNine((objShowStateNine) => "objectiveButton inactive");
    }
  };

  const objectiveShowTen = () => {
    if (objShowStateTen === "objectiveButton inactive") {
      setObjShowStateOne((objShowStateOne) => "objectiveButton inactive");
      setObjShowStateTwo((objShowStateTwo) => "objectiveButton inactive");
      setObjShowStateThree((objShowStateThree) => "objectiveButton inactive");
      setObjShowStateFour((objShowStateFour) => "objectiveButton inactive");
      setObjShowStateFive((objShowStateFive) => "objectiveButton inactive");
      setObjShowStateSix((objShowStateSix) => "objectiveButton inactive");
      setObjShowStateSeven((objShowStateSeven) => "objectiveButton inactive");
      setObjShowStateEight((objShowStateEight) => "objectiveButton inactive");
      setObjShowStateNine((objShowStateNine) => "objectiveButton inactive");
      setObjShowStateTen((objShowStateTen) => "objectiveButton");
    } else {
      setObjShowStateTen((objShowStateTen) => "objectiveButton inactive");
    }
  };

  const valuesHide = () => {
    setValuesShowState((valuesShowState) => "dock node inactive");
  };

  const visionHide = () => {
    setVisionShowState((visionShowState) => "dock node inactive");
  };

  const currentWindow = window.location.href;

  const [colorStateOne, setColorStateOne] = useState("cls-1-invert");
  const [colorStateTwo, setColorStateTwo] = useState("cls-2-invert");

  return (
    <div
      className={
        props.tutorialStep === 5 || props.tutorialStep === 6
          ? "dock--container liveDock"
          : "dock--container"
      }
    >
      {currentWindow.endsWith("/today") ? (
        <div
          id={props.tutorialOpen && "opac50"}
          className={
            props.noCreate !== "disabled"
              ? `jordandock node`
              : `disableddock node`
          }
          data-tooltip={
            props.noCreate === "disabled"
              ? "Complete Previous Day Before Starting a New Day"
              : `Start a New Day`
          }
        >
          <CreateDayIconV2 />
        </div>
      ) : (
        <div
          id={props.tutorialOpen && "opac50"}
          className={
            props.noCreate !== "disabled"
              ? `jordandock node`
              : `disableddock node`
          }
          data-tooltip={
            props.noCreate !== "disabled"
              ? `Start a New Day`
              : "Complete Today First Before Starting a New Day."
          }
        >
          <a href={props.noCreate !== "disabled" ? "/today" : ""}>
            <CreateDayIconV2 />
          </a>
        </div>
      )}

      <div
        id={props.tutorialOpen && "opac50"}
        className={
          props.previousDayPresent() ? `jordandock node` : `disableddock node`
        }
        onClick={props.previousDay}
        data-tooltip={
          props.previousDayPresent()
            ? "Go to previous day"
            : "Add another day to your diary to activate this node"
        }
      >
        <PreviousDay />
      </div>
      <div
        id={props.tutorialOpen && "opac50"}
        className={`jordandock node`}
        onClick={props.tutorialChange}
        data-tooltip={"Quickly learn more about your journal"}
      >
        <QuestionIcon />
      </div>

      <div className="dock separator">
        <FontAwesomeIcon
          id="separatorIcon"
          className="separator"
          icon={faGripLinesVertical}
        />
      </div>

      <div
        id={props.tutorialOpen && props.tutorialStep !== 5 && "opac50"}
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.namePresent()
              ? `jordandock node`
              : `disableddock node`
        }
        data-tooltip={
          props.namePresent()
            ? "Me"
            : "Add your name and date of birth to your year map to access it in the dock."
        }
        onClick={
          props.namePresent()
            ? props.changeName
            : () => {
                navigate("/year/create");
              }
        }
      >
        <VitIcon />
      </div>

      <div className="dockNodeWrapper">
        <div className="nodeRow">
          <div
            className={valuesShowState}
            data-tooltip="Value #1"
            onClick={props.changeValuesOne}
          >
            <ValuesIcon />
          </div>
          <div
            className={valuesShowState}
            data-tooltip="Value #2"
            onClick={props.changeValuesTwo}
          >
            <ValuesIcon />
          </div>
          <div
            className={valuesShowState}
            data-tooltip="Value #3"
            onClick={props.changeValuesThree}
          >
            <ValuesIcon />
          </div>
          <div
            className={valuesShowState}
            data-tooltip="Value #4"
            onClick={props.changeValuesFour}
          >
            <ValuesIcon />
          </div>
          <div
            className={valuesShowState}
            data-tooltip="Value #5"
            onClick={props.changeValuesFive}
          >
            <ValuesIcon />
          </div>
        </div>
        <div
          id={props.tutorialOpen && props.tutorialStep !== 5 && "opac50"}
          style={{ height: "40px", width: "40px" }}
          className={
            props.tutorialOpen
              ? `jordandock node`
              : props.valuesOnePresent() ||
                  props.valuesTwoPresent() ||
                  props.valuesThreePresent() ||
                  props.valuesFourPresent() ||
                  props.valuesFivePresent()
                ? `jordandock node`
                : `disableddock node`
          }
          data-tooltip={
            props.valuesOnePresent() ||
            props.valuesTwoPresent() ||
            props.valuesThreePresent() ||
            props.valuesFourPresent() ||
            props.valuesFivePresent()
              ? "My Values"
              : "Add your values in your year map to access them in the dock."
          }
          onClick={
            props.valuesOnePresent() ||
            props.valuesTwoPresent() ||
            props.valuesThreePresent() ||
            props.valuesFourPresent() ||
            props.valuesFivePresent()
              ? valuesShow
              : () => {
                  navigate("/year/create");
                }
          }
        >
          <ValuesIcon />
        </div>
      </div>

      <div className="dockNodeWrapper">
        <div className="nodeRow">
          <div
            className={visionShowState}
            data-tooltip="Vision #1"
            onClick={props.changeVisionOne}
          >
            <VisionIcon />
          </div>
          <div
            className={visionShowState}
            data-tooltip="Vision #2"
            onClick={props.changeVisionTwo}
          >
            <VisionIcon />
          </div>
          <div
            className={visionShowState}
            data-tooltip="Vision #3"
            onClick={props.changeVisionThree}
          >
            <VisionIcon />
          </div>
          <div
            className={visionShowState}
            data-tooltip="Vision #4"
            onClick={props.changeVisionFour}
          >
            <VisionIcon />
          </div>
          <div
            className={visionShowState}
            data-tooltip="Vision #5"
            onClick={props.changeVisionFive}
          >
            <VisionIcon />
          </div>
        </div>

        <div
          id={props.tutorialOpen && props.tutorialStep !== 5 && "opac50"}
          className={
            props.tutorialOpen
              ? `jordandock node`
              : props.visionOnePresent() ||
                  props.visionTwoPresent() ||
                  props.visionThreePresent() ||
                  props.visionFourPresent() ||
                  props.visionFivePresent()
                ? `jordandockwide node`
                : "disableddock node"
          }
          data-tooltip={
            props.visionOnePresent() ||
            props.visionTwoPresent() ||
            props.visionThreePresent() ||
            props.visionFourPresent() ||
            props.visionFivePresent()
              ? "My Vision"
              : "Add your vision to your year map to access it through the dock."
          }
          onClick={
            props.visionOnePresent() ||
            props.visionTwoPresent() ||
            props.visionThreePresent() ||
            props.visionFourPresent() ||
            props.visionFivePresent()
              ? visionShow
              : () => {
                  navigate("/year/create");
                }
          }
        >
          <VisionIcon />
        </div>
      </div>

      <div
        id={props.tutorialOpen && props.tutorialStep !== 5 && "opac50"}
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.ikigaiPresent()
              ? `jordandock node`
              : `disableddock node`
        }
        data-tooltip={
          props.ikigaiPresent()
            ? "My Ikigai"
            : "Add your ikigai to your year map to access it in the dock"
        }
        onClick={
          props.ikigaiPresent()
            ? props.changeIkigai
            : () => {
                navigate("/year/create");
              }
        }
      >
        <IkigaiIcon />
      </div>
      <div
        id={props.tutorialOpen && props.tutorialStep !== 5 && "opac50"}
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.quotePresent()
              ? `jordandock node`
              : `disableddock node`
        }
        data-tooltip={
          props.quotePresent()
            ? "My Quote"
            : "Add a quote to your year map to access it in the dock."
        }
        onClick={
          props.quotePresent()
            ? props.changeQuote
            : () => {
                navigate("/year/create");
              }
        }
      >
        <QuoteIconV2 />
      </div>

      <div
        id={props.tutorialOpen && props.tutorialStep !== 5 && "opac50"}
        className={`jordandockwide node`}
        data-tooltip="Hide Overlay"
        onClick={() => {
          setVisionShowState((visionShowState) => "mini node inactive");
          setValuesShowState((valuesShowState) => "mini node inactive");
          props.hideOverlay();
        }}
      >
        <HideOverlayIcon />
      </div>

      <div className="dock separator">
        <FontAwesomeIcon
          id="separatorIcon"
          className="separator"
          icon={faGripLinesVertical}
        />
      </div>

      <div
        id={props.tutorialOpen && props.tutorialStep !== 6 && "opac50"}
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.objOnePresent()
              ? `jordandock node`
              : "disableddock node"
        }
        data-tooltip={
          props.objOnePresent() && typeof props.objData === "object"
            ? `${props.objData.columns["obj-one"].title}`
            : "Add an objective into your week planner to access it here."
        }
        onClick={() => {
          props.objOnePresent() && props.changeObjOne();
        }}
      >
        <OneIcon opacity={props.opacityOne} />
      </div>

      <div
        id={props.tutorialOpen && props.tutorialStep !== 6 && "opac50"}
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.objTwoPresent()
              ? `jordandock node`
              : "disableddock node"
        }
        data-tooltip={
          props.objTwoPresent() && typeof props.objData === "object"
            ? `${props.objData.columns["obj-two"].title}`
            : "Add an objective into your week planner to access it here."
        }
        onClick={() => {
          props.objTwoPresent() && props.changeObjTwo();
        }}
      >
        <TwoIcon opacity={props.opacityTwo} />
      </div>

      <div
        id={props.tutorialOpen && props.tutorialStep !== 6 && "opac50"}
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.objThreePresent()
              ? `jordandock node`
              : "disableddock node"
        }
        data-tooltip={
          props.objThreePresent() && typeof props.objData === "object"
            ? `${props.objData.columns["obj-three"].title}`
            : "Add an objective into your week planner to access it here."
        }
        onClick={() => {
          props.objThreePresent() && props.changeObjThree();
        }}
      >
        <ThreeIcon opacity={props.opacityThree} />
      </div>

      <div
        id={props.tutorialOpen && props.tutorialStep !== 6 && "opac50"}
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.objFourPresent()
              ? `jordandock node`
              : "disableddock node"
        }
        data-tooltip={
          props.objFourPresent() && typeof props.objData === "object"
            ? `${props.objData.columns["obj-four"].title}`
            : "Add an objective into your week planner to access it here."
        }
        onClick={() => {
          props.objFourPresent() && props.changeObjFour();
        }}
      >
        <FourIcon opacity={props.opacityFour} />
      </div>

      <div
        id={props.tutorialOpen && props.tutorialStep !== 6 && "opac50"}
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.objFivePresent()
              ? `jordandock node`
              : "disableddock node"
        }
        data-tooltip={
          props.objFivePresent() && typeof props.objData === "object"
            ? `${props.objData.columns["obj-five"].title}`
            : "Add an objective into your week planner to access it here."
        }
        onClick={() => {
          props.objFivePresent() && props.changeObjFive();
        }}
      >
        <FiveIcon opacity={props.opacityFive} />
      </div>

      <div
        id={props.tutorialOpen && props.tutorialStep !== 6 && "opac50"}
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.objSixPresent()
              ? `jordandock node`
              : "disableddock node"
        }
        data-tooltip={
          props.objSixPresent() && typeof props.objData === "object"
            ? `${props.objData.columns["obj-six"].title}`
            : "Add an objective into your week planner to access it here."
        }
        onClick={() => {
          props.objSixPresent() && props.changeObjSix();
        }}
      >
        <SixIcon opacity={props.opacitySix} />
      </div>

      <div
        id={props.tutorialOpen && props.tutorialStep !== 6 && "opac50"}
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.objSevenPresent()
              ? `jordandock node`
              : "disableddock node"
        }
        data-tooltip={
          props.objSevenPresent() && typeof props.objData === "object"
            ? `${props.objData.columns["obj-seven"].title}`
            : "Add an objective into your week planner to access it here."
        }
        onClick={() => {
          props.objSevenPresent() && props.changeObjSeven();
        }}
      >
        <SevenIcon opacity={props.opacitySeven} />
      </div>

      <div
        id={props.tutorialOpen && props.tutorialStep !== 6 && "opac50"}
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.objEightPresent()
              ? `jordandock node`
              : "disableddock node"
        }
        data-tooltip={
          props.objEightPresent() && typeof props.objData === "object"
            ? `${props.objData.columns["obj-eight"].title}`
            : "Add an objective into your week planner to access it here."
        }
        onClick={() => {
          props.objEightPresent() && props.changeObjEight();
        }}
      >
        <EightIcon opacity={props.opacityEight} />
      </div>

      <div
        id={props.tutorialOpen && props.tutorialStep !== 6 && "opac50"}
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.objNinePresent()
              ? `jordandock node`
              : "disableddock node"
        }
        data-tooltip={
          props.objNinePresent() && typeof props.objData === "object"
            ? `${props.objData.columns["obj-nine"].title}`
            : "Add an objective into your week planner to access it here."
        }
        onClick={() => {
          props.objNinePresent() && props.changeObjNine();
        }}
      >
        <NineIcon opacity={props.opacityNine} />
      </div>

      <div
        id={
          props.tutorialOpen && props.tutorialStep !== 6
            ? "opac50"
            : "finalnode"
        }
        className={
          props.tutorialOpen
            ? `jordandock node`
            : props.objTenPresent()
              ? `jordandock node`
              : "disableddock node"
        }
        data-tooltip={
          props.objTenPresent() && typeof props.objData === "object"
            ? `${props.objData.columns["obj-ten"].title}`
            : "Add an objective into your week planner to access it here."
        }
        onClick={() => {
          props.objTenPresent() && props.changeObjTen();
        }}
      >
        <TenIcon opacity={props.opacityTen} />
      </div>

      <div className="dock separator">
        <FontAwesomeIcon
          id="separatorIcon"
          className="separator"
          icon={faGripLinesVertical}
        />
      </div>

      {currentWindow.endsWith("/today") ? (
        <motion.div
          id={
            props.tutorialOpen ||
            props.submitDisabled === "disabled" ||
            props.noCreate === "disabled"
              ? "disabled"
              : ""
          }
          className={`jordandock node_save ${props.changeMade}`}
          data-tooltip={
            props.submitDisabled === "disabled"
              ? "Diary date already used or possibly incorrect"
              : props.noCreate === "disabled"
                ? "Click Create Day to Complete Previous Day First."
                : "Click to Save"
          }
          onClick={
            props.submitDisabled !== "disabled" && props.logDate >= 2023
              ? props.submitDay
              : undefined
          }
          whileTap={{ scale: 0.9 }}
          whileHover={{
            scale: 0.95,
          }}
          onMouseDown={() => {
            setColorStateTwo("cls-2");
          }}
          onMouseOver={() => {
            setColorStateTwo("cls-2");
          }}
          onMouseOut={() => {
            setColorStateTwo("cls-2-invert");
          }}
        >
          <SaveIcon
            saved={props.saved}
            indicate={props.changeMade}
            colorStateOne={colorStateOne}
            colorStateTwo={colorStateTwo}
          />
        </motion.div>
      ) : props.momentsError === true ? (
        <motion.div
          className={`jordandock node_save`}
          data-tooltip={props.saved ? "Day Updated" : "Click to Save"}
          onClick={props.updateDay}
          whileTap={{ scale: 0.9 }}
          whileHover={{
            scale: 0.95,
          }}
          onMouseDown={() => {
            setColorStateTwo("cls-2");
          }}
          onMouseOver={() => {
            setColorStateTwo("cls-2");
          }}
          onMouseOut={() => {
            setColorStateTwo("cls-2-invert");
          }}
        >
          <SaveIcon
            saved={props.saved}
            colorStateOne={colorStateOne}
            colorStateTwo={colorStateTwo}
          />
        </motion.div>
      ) : (
        <motion.div
          className={`jordandock node_save ${props.changeMade}`}
          data-tooltip="You have a moment without a score. Resolve before saving"
          style={{ opacity: 0.25 }}
          whileTap={{ scale: 0.9 }}
          whileHover={{
            scale: 0.95,
          }}
          onMouseDown={() => {
            setColorStateTwo("cls-2");
          }}
          onMouseOver={() => {
            setColorStateTwo("cls-2");
          }}
          onMouseOut={() => {
            setColorStateTwo("cls-2-invert");
          }}
        >
          <SaveIcon
            saved={props.saved}
            colorStateOne={colorStateOne}
            colorStateTwo={colorStateTwo}
          />
        </motion.div>
      )}
    </div>
  );
}
