import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { listDays } from "../../actions/dayActions";
import { Helmet } from "react-helmet";
import CentralHeader from "../../components/Header/CentralHeader";
import CentralHeaderLP from "../../components/Header/CentralHeaderLP";
import "./privacypolicy.css";
import TestFooter from "../../components/Footer/TestFooter";

const PrivacyPolicy = ({ history }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const weekList = useSelector((state) => state.weekList);
  const { weeks } = weekList;

  const dayList = useSelector((state) => state.dayList); //
  const { loading, error, days } = dayList;

  const dayUpdate = useSelector((state) => state.dayUpdate);
  const { success: successUpdate } = dayUpdate;

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo, history]);

  return (
    <>
      {userInfo ? <CentralHeader /> : <CentralHeaderLP />}

      <main
        className="privacypolicy--container"
        style={{ marginBottom: "48px" }}
      >
        <div className="tile">
          <h1>Privacy Policy</h1>
          <p>Last Updated: 20th May 2023</p>

          <p>
            Thank you for using NoWastedDays.io, a digital diary web app ("the
            App"). Your privacy is hugely important to us. This Privacy Policy
            explains the types of information we collect, how we use and protect
            that information, what fields are encryped and your choices
            regarding your information. By using the App, you consent to the
            collection and use of your information as described in this Privacy
            Policy.
          </p>

          <ul className="list main">
            1. Information We Collect
            <li className="listHeader">
              1.1 Personal Information: We may collect the following personal
              information from you
            </li>
            <ul className="list sub">
              <li className="listItem">
                - Name: We collect your name to personalize your experience
                within the App and allow you to refer to it through the year
                page and dock.
              </li>
              <li className="listItem">
                - Email Address: We collect your email address to create and
                secure your account and to communicate with you regarding
                important updates or account-related information.
              </li>
              <li className="listItem">
                - Password: We securely store an encrypted version of your
                password to protect your account.
              </li>
            </ul>
            <li>1.2 Diary Data</li>
            <ul className="list sub">
              The App captures and encrypts the following diary data from you:
              <li className="listItem">
                - Diary Date*: We collect the date associated with each diary
                entry to organize and display your entries chronologically.
              </li>
              <li className="listItem">
                - Thank You Statements: We collect your thank you statements to
                help you focus on positivity and gratitude, and have this prime
                the rest of your day.
              </li>
              <li className="listItem">
                - Detachment Statements: We collect your detachment statements
                to allow you to healthily separate from the idea of self, and be
                more present state aware and connected with all things.
              </li>
              <li className="listItem">
                - Assurance Statements: We collect your assurance statements to
                help increase gratitude for your mind and body; these spaces in
                which you live.
              </li>
              <li className="listItem">
                - Planned Moments: We collect your planned moments, which are
                the accumulated actions/thoughts you intend to have in a day.
              </li>
              <li className="listItem">
                - Remember Today Log: We collect your remember today log in
                enable you distill the day into a package that can help with the
                processing of events in the moment as well as spark deeper, more
                relevant reflections in the future.
              </li>
              <li className="listItem">
                - Leave Behind Statement: We collect your leave behind
                statements to help the processing of disconnecting from
                unhealthy or unsustainable thoughts or feelings.
              </li>
              <li className="listItem">
                - Moment Scores: We collect moment scores to allow you to rate
                how well you realised your intended moment.
              </li>
              <li className="listItem">
                - Start Score and End Score*: We collect start and end scores to
                track your overall well-being or mood changes over time.
              </li>
              *Please note that all fields except the Diary Date and Scoring
              fields are encrypted to ensure the privacy and security of your
              data. This means that no one else, including our staff, can see or
              aggregate the encrypted data. The diary date and score fields are
              no encrypted to increase processing speed and in the context of
              all other sensitive data being encrypted are render completely
              uncorruptable by a third party.
            </ul>
            <li className="listHeader">2. How We Use Your Information</li>
            <ul className="list sub">
              We use your information for the following purposes:
              <li className="listItem">
                - To provide and personalize the services and features of the
                App.
              </li>
              <li className="listItem">
                - To communicate with you and send important updates or
                notifications.
              </li>
              <li className="listItem">
                - To analyze usage trends and improve the App's functionality
                and user experience.
              </li>
              <li className="listItem">
                - To protect the security and integrity of the App and our
                users' information.
              </li>
              <li className="listItem">
                - To comply with legal obligations or enforce our rights arising
                from any contracts entered into between you and us.
              </li>
            </ul>
            <li className="listHeader">3. Data Retention</li>
            <p>
              We will retain your personal information for as long as necessary
              to fulfill the purposes outlined in this Privacy Policy, unless a
              longer retention period is required or permitted by law.
            </p>
            <li className="listHeader">4. Third-Party Services</li>
            <p>
              We may only use third-party services that collect, monitor, and
              analyze information to improve the App's functionality and user
              experience; never to sell or share your data. These third-party
              service providers have their own privacy policies that govern the
              use of your information.
            </p>
            <li className="listHeader">5. Children's Privacy</li>
            <p>
              The App is not intended for children under the age of 13. We do
              not knowingly collect or solicit personal information from
              children under 13. If we learn that we have collected personal
              information from a child under 13, we will promptly delete that
              information. If you believe that a child under 13 may have
              provided us with personal information, please contact us using the
              contact details provided below.
            </p>
            <li className="listHeader">6. Your Choices</li>
            <p>
              You have the right to access, correct, or delete your personal
              information. You can update or delete your account information
              within the App's settings. If you have any questions or need
              assistance, please contact us using the contact details provided
              below.
            </p>
            <li className="listHeader">7. Changes to this Privacy Policy</li>
            <p>
              We may update this Privacy Policy from time to time. The updated
              version will be indicated by an updated "Last Updated" date. We
              encourage you to review this Privacy Policy periodically for any
              changes. Your continued use of the App following the posting of
              changes to this Privacy Policy constitutes your acceptance of
              those changes.
            </p>
            <li className="listHeader">8. Contact Us</li>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our privacy practices, please contact us at:
            </p>
            <p>NoWastedDays.io</p>
            <p>Email: hello@nowastedays.org</p>
          </ul>
        </div>
      </main>
      <TestFooter />
    </>
  );
};

export default PrivacyPolicy;
