import React from "react";

const BlogPostComponent = ({ body }) => {
  const renderBlock = (block) => {
    return block.children.map((child) => {
      try {
        if (child.marks.length > 0) {
          const matchingMarkDef = block.markDefs.find((markDef) =>
            child.marks.includes(markDef._key)
          );

          if (matchingMarkDef) {
            if (child.marks.includes("em")) {
              return (
                <a
                  key={child._key}
                  href={matchingMarkDef.href}
                  target="_blank"
                  rel="noreferrer"
                  className="blog__link--italic"
                >
                  {child.text}
                </a>
              );
            } else {
              return (
                <a
                  key={child._key}
                  href={matchingMarkDef.href}
                  target="_blank"
                  rel="noreferrer"
                  className="blog__link"
                >
                  {child.text}
                </a>
              );
            }
          } else if (child._type === "span" && child.marks.includes("em")) {
            return (
              <span
                key={child._key}
                className={`blog__${child._type}--${child.marks[0]}`}
              >
                {child.text}
              </span>
            );
          } else if (child._type === "span" && child.marks.includes("strong")) {
            return (
              <strong key={child._key} className={`blog__${child._type}`}>
                {child.text}
              </strong>
            );
          } else if (child._type === "span" && !child.marks.includes("em")) {
            return (
              <span key={child._key} className={`blog__${child._type}`}>
                {child.text}
              </span>
            );
          }
        } else {
          return <span key={child._key}>{child.text}</span>;
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    });
  };

  const renderBody = () => {
    return body.map((block) => {
      if (block._type === "image") {
        return (
          <div key={block._key} className="blog__image">
            <img
              width="100%"
              src={block.asset.url}
              alt={block.alt || "Blog Image"}
            />
            {block.caption && <p className="image__caption">{block.caption}</p>}
          </div>
        );
      } else if (block._type === "block") {
        if (block.style.startsWith("h")) {
          if (block.style === "h1") {
            return (
              <div
                key={block._key}
                className={`blog__${block._type}--${block.style}`}
              >
                <h1>{renderBlock(block)}</h1>
              </div>
            );
          }

          if (block.style === "h2") {
            return (
              <div
                key={block._key}
                className={`blog__${block._type}--${block.style}`}
              >
                <h2>{renderBlock(block)}</h2>
              </div>
            );
          }

          if (block.style === "h3") {
            return (
              <div
                key={block._key}
                className={`blog__${block._type}--${block.style}`}
              >
                <h3>{renderBlock(block)}</h3>
              </div>
            );
          }

          if (block.style === "h4") {
            return (
              <div
                key={block._key}
                className={`blog__${block._type}--${block.style}`}
              >
                <h4>{renderBlock(block)}</h4>
              </div>
            );
          }
        } else if (block.style === "blockquote") {
          return (
            <div key={block._key} className={`blog__${block.style}`}>
              {renderBlock(block)}
            </div>
          );
        } else if (block.listItem) {
          if (block.level === 1) {
            return (
              <ul key={block._key} className={`blog__list--ul${block.level}`}>
                <li>{renderBlock(block)}</li>
              </ul>
            );
          } else if (block.level === 2) {
            return (
              <ul key={block._key} className={`blog__list--ul${block.level}`}>
                <ul>
                  <li>{renderBlock(block)}</li>
                </ul>
              </ul>
            );
          }
        } else {
          return (
            <div key={block._key} className={`blog__${block._type}`}>
              {renderBlock(block)}
            </div>
          );
        }
      }
      return null; // Ensure a return value for all branches
    });
  };

  return <section>{renderBody()}</section>;
};

export default BlogPostComponent;
