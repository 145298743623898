// React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Styling
import "./pageloading.css";

const BlogLoading = (props) => {
  const dispatch = useDispatch();
  const divRef = useRef();

  useEffect(() => {
    const div = divRef.current;
    const size = Math.min(div.offsetWidth, div.offsetHeight);
  }, []);

  return (
    <div className="blog__content--loading" ref={divRef}>
      <span className="blog__text--h4">Blog Content Loading</span>
    </div>
  );
};

export default BlogLoading;
