// React
import React, { useRef, useState, useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, ToggleButton } from "react-bootstrap";
import PropagateLoader from "react-spinners/PropagateLoader";
import { PDFDownloadLink } from "@react-pdf/renderer";

// User Actions
import {
  deleteDebriefAction,
  createDebriefAction,
  listDebriefs,
  updateDebriefAction,
} from "../../actions/debriefActions";

// Internal Components
import TestFooter from "../../components/Footer/TestFooter";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import CentralHeader from "../../components/Header/CentralHeader";
import CentralHeaderLP from "../../components/Header/CentralHeaderLP";
import PrintableContent from "../../components/ValuesProcess/PrintableContent";
import {
  CreateDayIcon,
  CreateDayIconV2,
  SevenIcon,
  ValuesIcon,
  NameIcon,
  NameIconV2,
  NameIconV3,
  NameIconV4,
  DobIcon,
  MemoryIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  SaveIcon,
  ProfileIcon,
} from "../../components/Icons/Icon";
import generateSecret from "../../utils/generateSecret";

// External Packages
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDumpster,
  faBullseye,
  faCirclePlus,
  faCircleMinus,
  faGlasses,
  faX,
  faPenToSquare,
  faEraser,
  faQuestion,
  faCompassDrafting,
  faSignature,
  faCalendarDay,
  faDna,
  faQuoteLeft,
  faLandmarkDome,
  faArrowUp91,
} from "@fortawesome/free-solid-svg-icons";

// Styling
import "../../screens/Values/values.css";

function DebriefCreateScreen(history) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isScroll, setIsScrollState] = useState(false);
  const [isTitle, setIsTitle] = useState(true);
  const [isIntro, setIsIntro] = useState(true);
  const [isExplanation, setIsExplanation] = useState(false);
  const [isDebrief, setIsDebrief] = useState(false);
  const [isMenu, setIsMenu] = useState(false);

  const [showTraits, setShowTraits] = useState(false);
  const [traitsButton, setTraitsButton] = useState(true);

  const [pic, setPic] = useState();
  const [poc, setPoc] = useState();
  const [nic, setNic] = useState();
  const [noc, setNoc] = useState();
  const [debriefName, setDebriefName] = useState();
  const [debriefOutcome, setDebriefOutcome] = useState();
  const [linkId, setLinkId] = useState();

  const [posInHover, setPosInHover] = useState(false);
  const handleHoverIn_PosIn = () => {
    setPosInHover((posInHover) => true);
  };
  const handleHoverOut_PosIn = () => {
    setPosInHover((posInHover) => false);
  };

  const [posOutHover, setPosOutHover] = useState(false);
  const handleHoverIn_PosOut = () => {
    setPosOutHover((posOutHover) => true);
  };
  const handleHoverOut_PosOut = () => {
    setPosOutHover((posOutHover) => false);
  };

  const [negInHover, setNegInHover] = useState(false);
  const handleHoverIn_NegIn = () => {
    setNegInHover((negInHover) => true);
  };
  const handleHoverOut_NegIn = () => {
    setNegInHover((negInHover) => false);
  };

  const [negOutHover, setNegOutHover] = useState(false);
  const handleHoverIn_NegOut = () => {
    setNegOutHover((negOutHover) => true);
  };
  const handleHoverOut_NegOut = () => {
    setNegOutHover((negOutHover) => false);
  };

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const debriefList = useSelector((state) => state.debriefList);
  const { debriefs } = debriefList;

  const debriefCreate = useSelector((state) => state.debriefCreate); //
  const { loading, error, debrief } = debriefCreate;

  useEffect(() => {
    dispatch(listDebriefs());
  }, [dispatch, userInfo, history]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteDebriefAction(id))
        .then((response) => {
          if (response) {
            setTimeout(() => {
              window.location.reload();
            }, 150);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const submitHandler = (e) => {
    // e.preventDefault();

    const key = generateSecret().toString();

    dispatch(
      createDebriefAction(debriefName, debriefOutcome, pic, poc, nic, noc)
    )
      .then((response) => {
        if (response) {
          setTimeout(() => {
            navigate(`/debrief/${response._id}`);
          }, 250);
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const scrollState = {
    hidden: {
      display: "none",
    },
    scrollFlash: {
      display: "block",
      color: "whitesmoke",
      transition: {
        duration: 0.5,
        ease: "linear",
        repeat: 20,
      },
    },
  };

  const titleState = {
    titleOff: {
      display: "none",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        repeat: 5,
      },
    },
    titleOn: {
      display: "block",
      color: "#004a22",
      marginBottom: "12px",
      fontSize: "3em",
    },
  };

  const introStateMob = {
    hidden: {
      opacity: "0",
      width: "0%",
      display: "none",
      scale: 0.1,
    },
    open: {
      opacity: "1",
      width: "95%",
      display: "flex",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: "0",
      width: "0%",
      display: "none",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const explanationStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      width: "95%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const menuState = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      flexDirection: "column",
      textAlign: "start",
      scale: 1,
      maxHeight: "50vh",
      overflow: "scroll",
      minWidth: "50%",
      width: "auto",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const menuStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      maxHeight: "50vh",
      width: "95%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const foundationStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      width: "95%",
      scale: 1,
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0.1,
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const consistencyStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      width: "95%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const experienceStateMob = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      width: "95%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const specificityStateMob = {
    hidden: {
      display: "none",
      width: "50%",
    },
    open: {
      display: "block",
      width: "95%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      width: "50%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const finalDisplayStateMob = {
    hidden: {
      display: "none",
      width: "50%",
    },
    open: {
      display: "block",
      width: "95%",
      transition: {
        duration: 2,
        ease: "easeInOut",
        delay: 0.25,
      },
    },
    closed: {
      display: "none",
      width: "50%",
      transition: {
        duration: 2,
        ease: "easeInOut",
        delay: 0.25,
      },
    },
  };

  const introState = {
    hidden: {
      display: "none",
      scale: 0.1,
    },
    open: {
      display: "block",
      scale: 1,
      width: "auto",
      transition: {
        duration: 2.5,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const explanationState = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      scale: 1,
      width: "65%",
      x: "0%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      width: "50%",
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const foundationState = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      width: "50%",
      x: "0%",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const debriefState = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      textAlign: "start",
      width: "90%",
      x: "0%",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      x: "-200%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const consistencyState = {
    hidden: {
      display: "none",
      scale: 0.1,
      x: "150%",
    },
    open: {
      display: "flex",
      width: "50%",
      x: "0%",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const experienceState = {
    hidden: {
      display: "none",
      scale: 0,
      x: "200%",
    },
    open: {
      display: "flex",
      width: "50%",
      x: "0%",
      scale: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      display: "none",
      scale: 0,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const specificityState = {
    hidden: {
      display: "none",
      width: "90%",
    },
    open: {
      display: "flex",
      width: "90%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      width: "90%",
      display: "none",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const finalDisplayState = {
    hidden: {
      display: "none",
      width: "90%",
    },
    open: {
      display: "flex",
      width: "90%",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
    closed: {
      width: "90%",
      display: "none",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };

  const valueState = {
    hide: {
      display: "none",
    },
    show: {
      display: "block",
      whiteSpace: "normal",
      textAlign: "start",
      paddingRight: "6px",
    },
  };

  const valuesState = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const testState = {
    hidden: {
      display: "none",
      width: "95%",
    },
    open: {
      display: "flex",
      width: "95%",
    },
    closed: {
      display: "none",
      width: "95%",
    },
  };

  const sortDebriefsOne = () => {
    debriefs &&
      debriefs.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  };
  const sortDebriefsTwo = () => {
    debriefs &&
      debriefs.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
  };

  const [sorted, setSorted] = useState(true);

  return (
    <>
      {vw > 500 ? (
        <main style={{ height: "100vh", width: "100%" }}>
          {userInfo ? <CentralHeader /> : <CentralHeaderLP />}

          <motion.div className="introStageMain">
            <motion.div
              className="introContainer"
              variants={introState}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial="hidden"
              animate={isIntro ? "open" : "closed"}
              onClick={() => {
                setIsIntro((isIntro) => false);
                setIsExplanation((isExplanation) => true);
              }}
            >
              <h1>Debrief.</h1>
              <p>
                Help with understanding any outcome more clearly and easily.
              </p>
              <hr></hr>
              <span>
                <strong>Click to get started.</strong>
              </span>
            </motion.div>

            <motion.div
              className="explanationContainer"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial="hidden"
              animate={isExplanation ? "open" : "closed"}
              variants={explanationState}
              onClick={() => {
                setIsExplanation((isExplanation) => false);
                // setIsDebrief(isDebrief => !isDebrief);
                setIsMenu((isMenu) => !isMenu);
                setIsTitle((isTitle) => false);
                setIsScrollState((isScroll) => true);
                setTimeout(() => {
                  setIsScrollState((isScroll) => false);
                }, 4000);
              }}
            >
              <motion.h1
                initial="hidden"
                animate={isScroll ? "scrollFlash" : "hidden"}
                variants={scrollState}
              >
                Scroll Down to the First Stage
              </motion.h1>
              <motion.div>
                <div className="explanationTitle">
                  <motion.h1
                    id="titleOn"
                    initial="titleOn"
                    animate={isTitle ? "titleOn" : "titleOff"}
                    variants={titleState}
                  >
                    How the process works
                  </motion.h1>
                </div>
                <motion.div>
                  <p>
                    A debrief is made of 4 columns with the following headers.
                    Each gives you access to a different perspective on an
                    outcome, and the opportunity to turn that perspective into
                    greater clarity on the task at hand.
                  </p>
                  <br></br>
                  <p>
                    <strong>1. Positive Impact & Inside Your Control:</strong>{" "}
                    How you, through preparation and performance, have optimised
                    an outcome.
                  </p>
                  <p>
                    <strong>2. Positive Impact & Outside Your Control:</strong>{" "}
                    How other people and processes have supported success.
                  </p>
                  <p>
                    <strong>3. Negative Impact & Inside Your Control:</strong>{" "}
                    How you could have controlled moments better, quicker or
                    more wholistically.
                  </p>
                  <p>
                    <strong>4. Negative Impact & Outside Your Control:</strong>{" "}
                    How external factors have hindered or halted progress.
                  </p>
                  <br></br>
                  <p>
                    <strong>Step #1:</strong> Give your debrief a name.
                  </p>

                  <p>
                    <strong>Step #2:</strong> Write down the very specific
                    outcome that you are looking to break down. A debrief can
                    only work well if you are looking at one specific outcome at
                    a time.
                  </p>

                  <p>
                    <strong>Your Objective:</strong> Fill each column with as
                    many things as you can honestly say were present.
                  </p>

                  <p>
                    <strong>Tips:</strong> Every column will have multiple items
                    in, even the positive columns if things have gone ‘badly’,
                    and even the negative columns if things have gone ‘well’.
                    Share with someone else involved in the same outcome, or
                    have them do the same break down and compare your respective
                    perspectives.
                  </p>
                </motion.div>
                <span>
                  <strong>Ready? Click to start your debrief</strong>
                </span>
              </motion.div>
            </motion.div>

            <motion.section
              className="explanationContainer"
              initial="hidden"
              animate={isMenu ? "open" : "closed"}
              variants={menuState}
            >
              <div className="menuRow">
                <h5>Your Debriefs</h5>
                <div>
                  <button
                    className="valuesButton"
                    style={{ marginRight: "12px" }}
                    onClick={() => {
                      if (sorted) {
                        setSorted(false);
                        sortDebriefsOne();
                      } else {
                        setSorted(true);
                        sortDebriefsTwo();
                      }
                    }}
                  >
                    {!sorted ? "Latest to Earliest" : "Earliest to Latest"}
                  </button>

                  <button
                    className="valuesButton"
                    onClick={() => {
                      setIsDebrief(true);
                      setIsMenu(false);
                    }}
                  >
                    Create New Debrief
                  </button>
                </div>
              </div>
              <div className="debriefMenu">
                {debriefs && debriefs.length > 0 ? (
                  debriefs.map((debrief) => (
                    <div key={debrief._id} className="card">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "auto",
                        }}
                        onClick={() => {
                          navigate(`/debrief/${debrief._id}`);
                        }}
                      >
                        <span>
                          <strong>{debrief.debriefName}</strong>
                        </span>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>{debrief.updatedAt.slice(0, 10)}</span>
                        <FontAwesomeIcon
                          icon={faDumpster}
                          style={{ color: "#004a22", cursor: "pointer" }}
                          onClick={() => {
                            deleteHandler(debrief._id);
                            // setTimeout(()=>{
                            //   window.location.reload();
                            // }, 250);
                          }}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <h3>Create a Debrief by clicking on the button above</h3>
                )}
              </div>
            </motion.section>

            <motion.section
              className="debriefContainer"
              initial="hidden"
              animate={isDebrief ? "open" : "closed"}
              variants={debriefState}
            >
              <div className="debriefRow_title">
                <div>
                  <label>Name of Debrief:</label>
                  <input
                    className="debriefInput_small"
                    onChange={(e) => setDebriefName(e.target.value)}
                    value={debriefName}
                    placeholder="Title your debrief"
                  ></input>
                </div>
                <div>
                  <label>
                    I want to understand the following more clearly and easily:
                  </label>
                  <input
                    className="debriefInput_small"
                    onChange={(e) => setDebriefOutcome(e.target.value)}
                    value={debriefOutcome}
                    placeholder="Be precise. Which exact outcome or event are you trying to process."
                  ></input>
                </div>
              </div>
              <div className="debriefRow_Cols">
                {posInHover === false && vw > 500 ? (
                  <motion.div
                    className="InControl column"
                    onMouseEnter={handleHoverIn_PosIn}
                  >
                    <h2>Positive</h2>
                    <h3>Inside Your Control</h3>
                  </motion.div>
                ) : (
                  <motion.div
                    className="InControl column active"
                    onMouseLeave={handleHoverOut_PosIn}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Positive & Inside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <FontAwesomeIcon className="icon" icon={faQuestion} />
                        <div className="question_mark icon">
                          <FontAwesomeIcon icon={faQuestion} />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setPic(e.target.value)}
                      value={pic}
                    ></textarea>
                  </motion.div>
                )}

                {posOutHover === false && vw > 500 ? (
                  <motion.div
                    className="OutControl column"
                    onMouseEnter={handleHoverIn_PosOut}
                  >
                    <h2>Positive</h2>
                    <h3>Outside Your Control</h3>
                  </motion.div>
                ) : (
                  <motion.div
                    className="column active"
                    onMouseLeave={handleHoverOut_PosOut}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Positive & Outside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <FontAwesomeIcon className="icon" icon={faQuestion} />
                        <div className="question_mark icon">
                          <FontAwesomeIcon icon={faQuestion} />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setPoc(e.target.value)}
                      value={poc}
                    ></textarea>
                  </motion.div>
                )}

                <div className="vertical-line"></div>

                {negInHover === false && vw > 500 ? (
                  <motion.div
                    className="InControl column"
                    onMouseEnter={handleHoverIn_NegIn}
                  >
                    <h2>Negative</h2>
                    <h3>Inside Your Control</h3>
                  </motion.div>
                ) : (
                  <motion.div
                    className="InControl column active"
                    onMouseLeave={handleHoverOut_NegIn}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Negative & Inside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <FontAwesomeIcon className="icon" icon={faQuestion} />
                        <div className="question_mark icon">
                          <FontAwesomeIcon icon={faQuestion} />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setNic(e.target.value)}
                      value={nic}
                    ></textarea>
                  </motion.div>
                )}

                {negOutHover === false && vw > 500 ? (
                  <motion.div
                    className="negInControl column"
                    onMouseEnter={handleHoverIn_NegOut}
                  >
                    <h2>Negative</h2>
                    <h3>Outside Your Control</h3>
                  </motion.div>
                ) : (
                  <motion.div
                    className="negOutControl column active"
                    onMouseLeave={handleHoverOut_NegOut}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Negative & Outside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <FontAwesomeIcon className="icon" icon={faQuestion} />
                        <div className="question_mark icon">
                          <FontAwesomeIcon icon={faQuestion} />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setNoc(e.target.value)}
                      value={noc}
                    ></textarea>
                  </motion.div>
                )}
              </div>
              <div className="debriefRow_buttons">
                <PDFDownloadLink
                  className="valuesButton"
                  document={
                    <PrintableContent
                      posIn={pic}
                      posOut={poc}
                      negIn={nic}
                      negOut={noc}
                      name={debriefName}
                      aim={debriefOutcome}
                    />
                  }
                  fileName={`${debriefName}.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download PDF"
                  }
                </PDFDownloadLink>

                <div className="showHideButtons">
                  <button className="valuesButton" onClick={submitHandler}>
                    Save
                  </button>
                </div>
              </div>
            </motion.section>
          </motion.div>
        </main>
      ) : (
        // ===========================================================================================
        <main style={{ height: "auto", width: "100%" }}>
          {userInfo ? <CentralHeader /> : <CentralHeaderLP />}
          <motion.div className="introStageMain">
            <motion.div
              className="introContainer"
              variants={introStateMob}
              initial="hidden"
              animate={isIntro ? "open" : "closed"}
              onClick={() => {
                setIsIntro((isIntro) => false);
                setIsExplanation((isExplanation) => true);
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <h1>Your Situations.</h1>
                <div style={{ height: "24px", width: "24px" }}>
                  <NameIconV2 />
                </div>
              </div>

              <p style={{ width: "65%" }}>
                Help with understanding any outcome more clearly and easily.
              </p>
              <p style={{ fontWeight: "bold" }}>Tap to get started.</p>
            </motion.div>

            <motion.div
              className="explanationContainer"
              variants={explanationStateMob}
              initial="hidden"
              animate={isExplanation ? "open" : "closed"}
            >
              <motion.h1
                initial="hidden"
                animate={isScroll ? "scrollFlash" : "hidden"}
                variants={scrollState}
              >
                Scroll Down to the First Stage
              </motion.h1>
              <motion.div>
                <div className="explanationTitle">
                  <motion.h1
                    initial="titleOn"
                    animate={isTitle ? "titleOn" : "titleOff"}
                    variants={titleState}
                  >
                    The Process
                  </motion.h1>
                </div>
                <motion.div>
                  <motion.div>
                    <p>
                      A debrief is made of 4 columns with the following headers.
                      Each gives you access to a different perspective on an
                      outcome, and the opportunity to turn that perspective into
                      greater clarity on the task at hand.
                    </p>
                    <br></br>
                    <p>
                      <strong>1. Positive Impact & Inside Your Control:</strong>{" "}
                      How you, through preparation and performance, have
                      optimised an outcome.
                    </p>
                    <p>
                      <strong>
                        2. Positive Impact & Outside Your Control:
                      </strong>{" "}
                      How other people and processes have supported success.
                    </p>
                    <p>
                      <strong>3. Negative Impact & Inside Your Control:</strong>{" "}
                      How you could have controlled moments better, quicker or
                      more wholistically.
                    </p>
                    <p>
                      <strong>
                        4. Negative Impact & Outside Your Control:
                      </strong>{" "}
                      How external factors have hindered or halted progress.
                    </p>
                    <br></br>
                    <p>
                      <strong>Step #1:</strong> Give your debrief a name.
                    </p>

                    <p>
                      <strong>Step #2:</strong> Write down the very specific
                      outcome that you are looking to break down. A debrief can
                      only work well if you are looking at one specific outcome
                      at a time.
                    </p>

                    <p>
                      <strong>Your Objective:</strong> Fill each column with as
                      many things as you can honestly say were present.
                    </p>

                    <p>
                      <strong>Tips:</strong> Every column will have multiple
                      items in, even the positive columns if things have gone
                      ‘badly’, and even the negative columns if things have gone
                      ‘well’. Share with someone else involved in the same
                      outcome, or have them do the same break down and compare
                      your respective perspectives.
                    </p>
                  </motion.div>
                </motion.div>
                <button
                  className="valuesButton"
                  onClick={() => {
                    setIsExplanation((isExplanation) => false);
                    setIsMenu((isMenu) => !isMenu);
                    setIsTitle((isTitle) => false);
                    setIsScrollState((isScroll) => true);
                    setTimeout(() => {
                      setIsScrollState((isScroll) => false);
                    }, 4000);
                  }}
                >
                  Ready? Start your Debrief
                </button>
              </motion.div>
            </motion.div>

            <motion.section
              className="explanationContainer"
              initial="hidden"
              animate={isMenu ? "open" : "closed"}
              variants={menuStateMob}
            >
              <div className="menuRow">
                <button
                  className="valuesButton"
                  onClick={() => {
                    setIsDebrief(true);
                    setIsMenu(false);
                  }}
                >
                  Create New Debrief
                </button>
                <button
                  className="valuesButton"
                  style={{ marginRight: "12px" }}
                  onClick={() => {
                    setSorted((sorted) => !sorted);
                  }}
                >
                  {sorted ? "Latest to Earliest" : "Earliest to Latest"}
                </button>
              </div>
              <div className="debriefMenu">
                {debriefs && debriefs.length > 0 ? (
                  debriefs.map((debrief) => (
                    <div key={debrief._id} className="card">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "auto",
                        }}
                        onClick={() => {
                          navigate(`/debrief/${debrief._id}`);
                        }}
                      >
                        <span>
                          <strong>{debrief.debriefName}</strong>
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>{debrief.updatedAt.slice(0, 10)}</span>
                        {/* <FontAwesomeIcon 
    icon={faDumpster} 
    style={{color: '#004a22', cursor: 'pointer'}} 
    onClick={()=>{
      deleteHandler(debrief._id);
      // setTimeout(()=>{
      //   window.location.reload();
      // }, 250);
    }} 
  /> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <h3>Create a Debrief by clicking on the button above</h3>
                )}
              </div>
            </motion.section>

            <motion.section
              className="debriefContainer"
              initial="hidden"
              animate={isDebrief ? "open" : "closed"}
              variants={debriefState}
            >
              <motion.div className="posInControl column">
                <div className="tutorial access">
                  <label className="score_label">
                    <strong>Name of Debrief:</strong>
                  </label>
                  <div className="icon_div">
                    <div className="newIcon">
                      <QuestionIcon />
                    </div>
                  </div>
                </div>
                <textarea
                  className="debriefInput_small"
                  wrap="soft"
                  onChange={(e) => setDebriefName(e.target.value)}
                  value={debriefName}
                  placeholder="Title your debrief"
                ></textarea>
              </motion.div>

              <motion.div className="posInControl column">
                <div className="tutorial access">
                  <label className="score_label">
                    <strong>
                      I want to understand the following more clearly and
                      easily:
                    </strong>
                  </label>
                  <div className="icon_div">
                    <div className="newIcon">
                      <QuestionIcon />
                    </div>
                  </div>
                </div>
                <textarea
                  className="debriefInput_small"
                  onChange={(e) => setDebriefOutcome(e.target.value)}
                  value={debriefOutcome}
                  wrap="soft"
                  placeholder="Be precise. Which exact outcome or event are you trying to understand."
                ></textarea>
              </motion.div>

              <hr className="debriefLine"></hr>
              <div className="debriefRow_buttons">
                <PDFDownloadLink
                  className="valuesButton"
                  document={
                    <PrintableContent
                      posIn={pic}
                      posOut={poc}
                      negIn={nic}
                      negOut={noc}
                      name={debriefName}
                      aim={debriefOutcome}
                    />
                  }
                  fileName={`${debriefName}.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download PDF"
                  }
                </PDFDownloadLink>
                <button className="valuesButton" onClick={submitHandler}>
                  Save
                </button>

                {/* <div className="showHideButtons">
  <button className="valuesButton" onClick={()=>{handleHoverIn_PosOut(); handleHoverIn_PosIn(); handleHoverIn_NegIn(); handleHoverIn_NegOut();}}>Show</button>
  <button className="valuesButton" onClick={()=>{handleHoverOut_PosOut(); handleHoverOut_PosIn(); handleHoverOut_NegIn(); handleHoverOut_NegOut();}}>Hide</button>
</div> */}
              </div>
              <div className="debriefRow_Cols">
                {posInHover === false && vw > 500 ? (
                  <motion.div
                    className="posInControl column"
                    onMouseEnter={handleHoverIn_PosIn}
                  >
                    <h2>Positive & Inside Your Control</h2>
                  </motion.div>
                ) : (
                  <motion.div
                    className="posInControl column active"
                    onMouseLeave={handleHoverOut_PosIn}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Positive & Inside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuestionIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setPic(e.target.value)}
                    >
                      {pic}
                    </textarea>
                  </motion.div>
                )}

                {posOutHover === false && vw > 500 ? (
                  <motion.div
                    className="posOutControl column"
                    onMouseEnter={handleHoverIn_PosOut}
                  >
                    <h2>Positive & Outside Your Control</h2>
                  </motion.div>
                ) : (
                  <motion.div
                    className="posOutControl column active"
                    onMouseLeave={handleHoverOut_PosOut}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Positive & Outside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuestionIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setPoc(e.target.value)}
                    >
                      {poc}
                    </textarea>
                  </motion.div>
                )}

                {negInHover === false && vw > 500 ? (
                  <motion.div
                    className="negInControl column"
                    onMouseEnter={handleHoverIn_NegIn}
                  >
                    <h2>Negative & Inside of Your Control</h2>
                  </motion.div>
                ) : (
                  <motion.div
                    className="negInControl column active"
                    onMouseLeave={handleHoverOut_NegIn}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Negative & Inside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuestionIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setNic(e.target.value)}
                    >
                      {nic}
                    </textarea>
                  </motion.div>
                )}

                {negOutHover === false && vw > 500 ? (
                  <motion.div
                    className="negInControl column"
                    onMouseEnter={handleHoverIn_NegOut}
                  >
                    <h2>Negative & Outside of Your Control</h2>
                  </motion.div>
                ) : (
                  <motion.div
                    className="negOutControl column active"
                    onMouseLeave={handleHoverOut_NegOut}
                  >
                    <div className="tutorial access">
                      <label className="score_label">
                        <strong>Negative & Outside Your Control</strong>
                      </label>
                      <div className="icon_div">
                        <div className="newIcon">
                          <QuestionIcon />
                        </div>
                      </div>
                    </div>
                    <textarea
                      className="debriefInput"
                      onChange={(e) => setNoc(e.target.value)}
                    >
                      {noc}
                    </textarea>
                  </motion.div>
                )}
              </div>
            </motion.section>
          </motion.div>
        </main>
      )}
      <TestFooter />
    </>
  );
}

export default DebriefCreateScreen;
