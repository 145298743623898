import react, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // If you're using react-router-dom

const useSubscriptionCheck = (userInfo) => {
  const navigate = useNavigate();

  useEffect(() => {
    const currentWindow = window.location.href;

    if (
      !userInfo ||
      !userInfo.subscription ||
      !userInfo.subscription.userStart
    ) {
      return;
    }

    if (
      currentWindow.includes("subscribe") ||
      currentWindow.includes("completion")
    ) {
      return;
    }

    const trialStartDate = userInfo.subscription.userStart;
    const currentTier = userInfo.subscription.plan;

    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const trialStartDateMs = new Date(trialStartDate).getTime();
    const currentDateMs = new Date().getTime();

    if (currentDateMs - trialStartDateMs > thirtyDaysInMilliseconds) {
      if (currentTier === "trial") {
        navigate("/plans/choose");
      }
    }
  }, [userInfo, navigate]);
};

export default useSubscriptionCheck;
