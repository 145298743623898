import sa from "./WHAT_IS_SA.jpeg";
import sa_wm from "./WHAT_IS_SA_WM.jpeg";

const artData = [
  {
    art_title: "Self-Assurance #1",
    art_src: sa,
    art_src_wm: sa_wm,
    art_meta: {
      author: "Richard Brookman",
      date: "01/03/2024",
      license: "",
    },
  },
  {
    art_title: "Self-Assurance #1",
    art_src: sa,
    art_src_wm: sa_wm,
    art_meta: {
      author: "Richard Brookman",
      date: "01/03/2024",
      license: "",
    },
  },
];

export { artData };
