import React, { useEffect, useRef, useCallback, useState } from "react";
import debounce from "lodash.debounce";

const AutoResizeTextArea = ({
  value,
  onChange,
  id,
  className,
  wrap,
  autoComplete,
  placeholder,
}) => {
  const textAreaRef = useRef(null);
  const [scrollHeight, setScrollHeight] = useState(0);

  const adjustHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      const newScrollHeight = textAreaRef.current.scrollHeight;
      if (newScrollHeight !== scrollHeight) {
        setScrollHeight(newScrollHeight);
        console.log("height adjusted");
      }
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [scrollHeight]);

  return (
    <textarea
      ref={textAreaRef}
      value={value}
      onChange={onChange}
      style={{ overflow: "hidden", resize: "none" }}
      id={id}
      className={className}
      placeholder={placeholder}
      wrap={wrap}
      autoComplete={autoComplete}
    />
  );
};

export default AutoResizeTextArea;
