import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userLoginReducer,
  userRegisterReducer,
  userUpdateReducer,
  userUpdateSubscriptionReducer,
  userListReducer,
} from "./reducers/userReducers";
import {
  dayCreateReducer,
  dayListReducer,
  dayUpdateReducer,
} from "./reducers/daysReducers";
import {
  quizCreateReducer,
  quizListReducer,
  quizUpdateReducer,
} from "./reducers/quizReducers";
import {
  yearCreateReducer,
  yearUpdateReducer,
  yearListReducer,
  valuesUpdateReducer,
} from "./reducers/yearsReducers";
import {
  weeksCreateReducer,
  weekListReducer,
  weekUpdateReducer,
} from "./reducers/weekReducers";
import {
  debriefCreateReducer,
  debriefListReducer,
  debriefUpdateReducer,
  debriefDeleteReducer,
} from "./reducers/debriefsReducers";

const reducer = combineReducers({
  yearList: yearListReducer,
  userList: userListReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,
  userUpdateSubscription: userUpdateSubscriptionReducer,
  dayList: dayListReducer,
  dayCreate: dayCreateReducer,
  quizCreate: quizCreateReducer,
  quizList: quizListReducer,
  quizUpdate: quizUpdateReducer,
  dayUpdate: dayUpdateReducer,
  yearCreate: yearCreateReducer,
  yearUpdate: yearUpdateReducer,
  valuesUpdate: valuesUpdateReducer,
  weekCreate: weeksCreateReducer,
  weekList: weekListReducer,
  weekUpdate: weekUpdateReducer,
  debriefList: debriefListReducer,
  debriefCreate: debriefCreateReducer,
  debriefUpdate: debriefUpdateReducer,
  debriefDelete: debriefDeleteReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
